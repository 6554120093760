// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */

// START cwn/reports/models.proto

class Geo {
  geofence_id: string;
  created: ?Date;
  updated: ?Date;
  metro: string;
  sub_metro: string;
  neighborhood: string;
  inactive: boolean;

  constructor(props: $Shape<Geo> = {}): void {
    if (!props) {
      props = {};
    }

    this.geofence_id = '0';
    if (props.hasOwnProperty('geofence_id')) {
      const v = props.geofence_id;
      this.geofence_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.metro = '';
    if (props.hasOwnProperty('metro')) {
      const v = props.metro;
      this.metro = v;
    }

    this.sub_metro = '';
    if (props.hasOwnProperty('sub_metro')) {
      const v = props.sub_metro;
      this.sub_metro = v;
    }

    this.neighborhood = '';
    if (props.hasOwnProperty('neighborhood')) {
      const v = props.neighborhood;
      this.neighborhood = v;
    }

    this.inactive = false;
    if (props.hasOwnProperty('inactive')) {
      const v = props.inactive;
      this.inactive = !!v;
    }
  }
}
export {Geo};

// END cwn/reports/models.proto
