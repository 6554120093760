// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../../google/protobuf';
/* eslint-enable */
import * as cwn_user from '../../../cwn/user';
import * as cwn_site_build from '../../../cwn/site/build';

// START cwn/site/plan/models.proto

class Plan {
  id: string;
  created: ?Date;
  updated: ?Date;
  completed: ?Date;
  invalidated: ?Date;
  deleted: ?Date;
  replaced: ?Date;
  created_by: ?cwn_user.User;
  updated_by: ?cwn_user.User;
  replaced_by_plan_id: string;
  site_id: string;
  previous: ?cwn_site_build.Build;
  next: ?cwn_site_build.Build;

  constructor(props: $Shape<Plan> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.completed = null;
    if (props.hasOwnProperty('completed')) {
      const v = props.completed;
      this.completed = v && new Date(v);
    }

    this.invalidated = null;
    if (props.hasOwnProperty('invalidated')) {
      const v = props.invalidated;
      this.invalidated = v && new Date(v);
    }

    this.deleted = null;
    if (props.hasOwnProperty('deleted')) {
      const v = props.deleted;
      this.deleted = v && new Date(v);
    }

    this.replaced = null;
    if (props.hasOwnProperty('replaced')) {
      const v = props.replaced;
      this.replaced = v && new Date(v);
    }

    this.created_by = null;
    if (props.hasOwnProperty('created_by')) {
      const v = props.created_by;
      this.created_by = v && new cwn_user.User(v);
    }

    this.updated_by = null;
    if (props.hasOwnProperty('updated_by')) {
      const v = props.updated_by;
      this.updated_by = v && new cwn_user.User(v);
    }

    this.replaced_by_plan_id = '0';
    if (props.hasOwnProperty('replaced_by_plan_id')) {
      const v = props.replaced_by_plan_id;
      this.replaced_by_plan_id = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.previous = null;
    if (props.hasOwnProperty('previous')) {
      const v = props.previous;
      this.previous = v && new cwn_site_build.Build(v);
    }

    this.next = null;
    if (props.hasOwnProperty('next')) {
      const v = props.next;
      this.next = v && new cwn_site_build.Build(v);
    }
  }

  getCreatedBy(): cwn_user.User {
    if (this.created_by) {
      return this.created_by;
    }
    return new cwn_user.User();
  }

  getUpdatedBy(): cwn_user.User {
    if (this.updated_by) {
      return this.updated_by;
    }
    return new cwn_user.User();
  }

  getPrevious(): cwn_site_build.Build {
    if (this.previous) {
      return this.previous;
    }
    return new cwn_site_build.Build();
  }

  getNext(): cwn_site_build.Build {
    if (this.next) {
      return this.next;
    }
    return new cwn_site_build.Build();
  }
}
export {Plan};

// END cwn/site/plan/models.proto
