// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */

// START cwn/emails/models.proto

export const CategoryValues = {
  INVALID: 0,
  RAW_HTML: 1,
  INSIDE_MARKET_LEAD_SIGNUP: 3,
  ACTIVE_POST_INSTALL: 5,
  OUTSIDE_MARKET_LEAD_SIGNUP: 6,
  UNSERVICEABLE_LOCATION: 7,
  UNSERVICEABLE_LOCATION_UPDATE: 8,
  REJECTED_CANNOT_ASSESS: 11,
  REJECTED_GETTING_APPROVAL: 12,
  REJECTED_APPROVAL_DENIED: 13,
  REJECTED_AREA_COMING_SOON: 21,
  HELPFUL_INFORMATION_FOR_YOUR_LANDLORD: 25,
  BOOKING_CONFIRMED: 10,
  BOOKING_CONFIRMED_ZENDESK: 24,
  FINISH_ACCOUNT: 27,
  CUP_ACCOUNT_CREATION: 14,
  CUP_RESET_PASSWORD: 26,
  PAYMENT_DECLINED: 9,
  PAYMENT_SUCCEEDED: 16,
  BILLING_INFO_REQUEST: 28,
  GO_TO_SIGNUP: 17,
  REFERRAL_CONFIRMED_SENDER: 18,
  REFERRAL_CONFIRMED_RECEIVER: 19,
  REFERRAL_MARKETING: 20,
  PARTNER_APPLICATION_SUCCESS: 22,
};
type Category = $Keys<typeof CategoryValues>;
export type {Category};

export function CategoryValue(n: number): Category {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'RAW_HTML';

    case 3:
      return 'INSIDE_MARKET_LEAD_SIGNUP';

    case 5:
      return 'ACTIVE_POST_INSTALL';

    case 6:
      return 'OUTSIDE_MARKET_LEAD_SIGNUP';

    case 7:
      return 'UNSERVICEABLE_LOCATION';

    case 8:
      return 'UNSERVICEABLE_LOCATION_UPDATE';

    case 11:
      return 'REJECTED_CANNOT_ASSESS';

    case 12:
      return 'REJECTED_GETTING_APPROVAL';

    case 13:
      return 'REJECTED_APPROVAL_DENIED';

    case 21:
      return 'REJECTED_AREA_COMING_SOON';

    case 25:
      return 'HELPFUL_INFORMATION_FOR_YOUR_LANDLORD';

    case 10:
      return 'BOOKING_CONFIRMED';

    case 24:
      return 'BOOKING_CONFIRMED_ZENDESK';

    case 27:
      return 'FINISH_ACCOUNT';

    case 14:
      return 'CUP_ACCOUNT_CREATION';

    case 26:
      return 'CUP_RESET_PASSWORD';

    case 9:
      return 'PAYMENT_DECLINED';

    case 16:
      return 'PAYMENT_SUCCEEDED';

    case 28:
      return 'BILLING_INFO_REQUEST';

    case 17:
      return 'GO_TO_SIGNUP';

    case 18:
      return 'REFERRAL_CONFIRMED_SENDER';

    case 19:
      return 'REFERRAL_CONFIRMED_RECEIVER';

    case 20:
      return 'REFERRAL_MARKETING';

    case 22:
      return 'PARTNER_APPLICATION_SUCCESS';

    default:
      return 'INVALID';
  }
}

export const EmailTypeValues = {
  TRANSACTIONAL: 0,
  MARKETING: 1,
};
type EmailType = $Keys<typeof EmailTypeValues>;
export type {EmailType};

export function EmailTypeValue(n: number): EmailType {
  switch (n) {
    case 0:
      return 'TRANSACTIONAL';

    case 1:
      return 'MARKETING';

    default:
      return 'TRANSACTIONAL';
  }
}

class Message {
  id: string;
  created: ?Date;
  customer_id: string;
  sender: string;
  reply_to: string;
  to: string;
  subject: string;
  body: string;
  html_body: string;

  constructor(props: $Shape<Message> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.customer_id = '';
    if (props.hasOwnProperty('customer_id')) {
      const v = props.customer_id;
      this.customer_id = v;
    }

    this.sender = '';
    if (props.hasOwnProperty('sender')) {
      const v = props.sender;
      this.sender = v;
    }

    this.reply_to = '';
    if (props.hasOwnProperty('reply_to')) {
      const v = props.reply_to;
      this.reply_to = v;
    }

    this.to = '';
    if (props.hasOwnProperty('to')) {
      const v = props.to;
      this.to = v;
    }

    this.subject = '';
    if (props.hasOwnProperty('subject')) {
      const v = props.subject;
      this.subject = v;
    }

    this.body = '';
    if (props.hasOwnProperty('body')) {
      const v = props.body;
      this.body = v;
    }

    this.html_body = '';
    if (props.hasOwnProperty('html_body')) {
      const v = props.html_body;
      this.html_body = v;
    }
  }
}
export {Message};

class Email {
  category: Category;
  delay: ?google_protobuf.Duration;
  emailType: EmailType;
  attemptsCount: string;

  constructor(props: $Shape<Email> = {}): void {
    if (!props) {
      props = {};
    }

    this.category = CategoryValue(0);
    if (props.hasOwnProperty('category')) {
      const v = props.category;
      this.category = v;
    }

    this.delay = null;
    if (props.hasOwnProperty('delay')) {
      const v = props.delay;
      this.delay = v && new google_protobuf.Duration(v);
    }

    this.emailType = EmailTypeValue(0);
    if (props.hasOwnProperty('emailType')) {
      const v = props.emailType;
      this.emailType = v;
    }

    this.attemptsCount = '0';
    if (props.hasOwnProperty('attemptsCount')) {
      const v = props.attemptsCount;
      this.attemptsCount = v;
    }
  }

  getDelay(): google_protobuf.Duration {
    if (this.delay) {
      return this.delay;
    }
    return new google_protobuf.Duration();
  }
}
export {Email};

class Task {
  id: string;
  emails: Array<Email>;
  lead_id: string;
  created: ?Date;
  updated: ?Date;
  last_sent: ?Date;
  inactive: boolean;

  constructor(props: $Shape<Task> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.emails = [];
    if (props.hasOwnProperty('emails')) {
      const v = props.emails;
      if (!Array.isArray(v)) {
        throw new Error('repeated field emails should be array');
      }
      this.emails = v.map(function(v) {
        return new Email(v);
      });
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.last_sent = null;
    if (props.hasOwnProperty('last_sent')) {
      const v = props.last_sent;
      this.last_sent = v && new Date(v);
    }

    this.inactive = false;
    if (props.hasOwnProperty('inactive')) {
      const v = props.inactive;
      this.inactive = !!v;
    }
  }
}
export {Task};

// END cwn/emails/models.proto
