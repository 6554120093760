// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_kml from '../../cwn/kml';

// START cwn/netplan/models.proto

export const LosAssessment_AssessmentValues = {
  INVALID_ASSESSMENT: 0,
  CLEAR: 1,
  OBSTRUCTED: 2,
};
type LosAssessment_Assessment = $Keys<typeof LosAssessment_AssessmentValues>;
export type {LosAssessment_Assessment};

export function LosAssessment_AssessmentValue(
  n: number,
): LosAssessment_Assessment {
  switch (n) {
    case 0:
      return 'INVALID_ASSESSMENT';

    case 1:
      return 'CLEAR';

    case 2:
      return 'OBSTRUCTED';

    default:
      return 'INVALID_ASSESSMENT';
  }
}

export const LosAssessment_ObstructedReasonValues = {
  INVALID_REASON: 0,
  PARTIAL_TREE_OR_BUSH: 1,
  PARTIAL_BUILDING: 2,
  PARTIAL_UTILITY_OR_OTHER_POLE: 3,
  PARTIAL_WIRES: 4,
  PARTIAL_OTHER: 5,
  FULL_TREE_OR_BUSH: 6,
  FULL_BUILDING: 7,
  FULL_UTILITY_OR_OTHER_POLE: 8,
  FULL_OTHER: 9,
};
type LosAssessment_ObstructedReason = $Keys<
  typeof LosAssessment_ObstructedReasonValues,
>;
export type {LosAssessment_ObstructedReason};

export function LosAssessment_ObstructedReasonValue(
  n: number,
): LosAssessment_ObstructedReason {
  switch (n) {
    case 0:
      return 'INVALID_REASON';

    case 1:
      return 'PARTIAL_TREE_OR_BUSH';

    case 2:
      return 'PARTIAL_BUILDING';

    case 3:
      return 'PARTIAL_UTILITY_OR_OTHER_POLE';

    case 4:
      return 'PARTIAL_WIRES';

    case 5:
      return 'PARTIAL_OTHER';

    case 6:
      return 'FULL_TREE_OR_BUSH';

    case 7:
      return 'FULL_BUILDING';

    case 8:
      return 'FULL_UTILITY_OR_OTHER_POLE';

    case 9:
      return 'FULL_OTHER';

    default:
      return 'INVALID_REASON';
  }
}

class LosAssessment {
  id: string;
  site_a_id: string;
  site_b_id: string;
  assessment: LosAssessment_Assessment;
  obstructed_reasons: Array<LosAssessment_ObstructedReason>;
  updated: ?Date;

  constructor(props: $Shape<LosAssessment> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.site_a_id = '';
    if (props.hasOwnProperty('site_a_id')) {
      const v = props.site_a_id;
      this.site_a_id = v;
    }

    this.site_b_id = '';
    if (props.hasOwnProperty('site_b_id')) {
      const v = props.site_b_id;
      this.site_b_id = v;
    }

    this.assessment = LosAssessment_AssessmentValue(0);
    if (props.hasOwnProperty('assessment')) {
      const v = props.assessment;
      this.assessment = v;
    }

    this.obstructed_reasons = [];
    if (props.hasOwnProperty('obstructed_reasons')) {
      const v = props.obstructed_reasons;
      if (!Array.isArray(v)) {
        throw new Error('repeated field obstructed_reasons should be array');
      }
      this.obstructed_reasons = v.map(function(v) {
        return v;
      });
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }
  }
}
export {LosAssessment};

class NetworkPlan {
  id: string;
  created: ?Date;
  site_id: string;
  backup_shots: Array<BackupShot>;
  appointment_id: string;
  appointment_time: ?Date;
  lead_id: string;

  constructor(props: $Shape<NetworkPlan> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.backup_shots = [];
    if (props.hasOwnProperty('backup_shots')) {
      const v = props.backup_shots;
      if (!Array.isArray(v)) {
        throw new Error('repeated field backup_shots should be array');
      }
      this.backup_shots = v.map(function(v) {
        return new BackupShot(v);
      });
    }

    this.appointment_id = '0';
    if (props.hasOwnProperty('appointment_id')) {
      const v = props.appointment_id;
      this.appointment_id = v;
    }

    this.appointment_time = null;
    if (props.hasOwnProperty('appointment_time')) {
      const v = props.appointment_time;
      this.appointment_time = v && new Date(v);
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }
  }
}
export {NetworkPlan};

class BackupShot {
  target_site_id: string;
  repoint_mac: string;

  constructor(props: $Shape<BackupShot> = {}): void {
    if (!props) {
      props = {};
    }

    this.target_site_id = '';
    if (props.hasOwnProperty('target_site_id')) {
      const v = props.target_site_id;
      this.target_site_id = v;
    }

    this.repoint_mac = '';
    if (props.hasOwnProperty('repoint_mac')) {
      const v = props.repoint_mac;
      this.repoint_mac = v;
    }
  }
}
export {BackupShot};

class CandidateNetworkPlan {
  id: string;
  created: ?Date;
  updated: ?Date;
  finished: ?Date;
  building_id: string;
  candidate_mounts: Array<CandidateMount>;
  serviceability_known: ?Date;
  plan_eligibility_calculable: ?Date;

  constructor(props: $Shape<CandidateNetworkPlan> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.finished = null;
    if (props.hasOwnProperty('finished')) {
      const v = props.finished;
      this.finished = v && new Date(v);
    }

    this.building_id = '';
    if (props.hasOwnProperty('building_id')) {
      const v = props.building_id;
      this.building_id = v;
    }

    this.candidate_mounts = [];
    if (props.hasOwnProperty('candidate_mounts')) {
      const v = props.candidate_mounts;
      if (!Array.isArray(v)) {
        throw new Error('repeated field candidate_mounts should be array');
      }
      this.candidate_mounts = v.map(function(v) {
        return new CandidateMount(v);
      });
    }

    this.serviceability_known = null;
    if (props.hasOwnProperty('serviceability_known')) {
      const v = props.serviceability_known;
      this.serviceability_known = v && new Date(v);
    }

    this.plan_eligibility_calculable = null;
    if (props.hasOwnProperty('plan_eligibility_calculable')) {
      const v = props.plan_eligibility_calculable;
      this.plan_eligibility_calculable = v && new Date(v);
    }
  }
}
export {CandidateNetworkPlan};

class CandidateMount {
  location: ?cwn_kml.Coord;
  candidate_upstream_mounts: Array<CandidateUpstreamMount>;

  constructor(props: $Shape<CandidateMount> = {}): void {
    if (!props) {
      props = {};
    }

    this.location = null;
    if (props.hasOwnProperty('location')) {
      const v = props.location;
      this.location = v && new cwn_kml.Coord(v);
    }

    this.candidate_upstream_mounts = [];
    if (props.hasOwnProperty('candidate_upstream_mounts')) {
      const v = props.candidate_upstream_mounts;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field candidate_upstream_mounts should be array',
        );
      }
      this.candidate_upstream_mounts = v.map(function(v) {
        return new CandidateUpstreamMount(v);
      });
    }
  }

  getLocation(): cwn_kml.Coord {
    if (this.location) {
      return this.location;
    }
    return new cwn_kml.Coord();
  }
}
export {CandidateMount};

class CandidateUpstreamMount {
  mount_id: string;
  los_cache_id: string;
  own_building_obstructions: string;
  not_own_building_obstructions: string;
  can_provide_high_speed: boolean;

  constructor(props: $Shape<CandidateUpstreamMount> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount_id = '0';
    if (props.hasOwnProperty('mount_id')) {
      const v = props.mount_id;
      this.mount_id = v;
    }

    this.los_cache_id = '';
    if (props.hasOwnProperty('los_cache_id')) {
      const v = props.los_cache_id;
      this.los_cache_id = v;
    }

    this.own_building_obstructions = '0';
    if (props.hasOwnProperty('own_building_obstructions')) {
      const v = props.own_building_obstructions;
      this.own_building_obstructions = v;
    }

    this.not_own_building_obstructions = '0';
    if (props.hasOwnProperty('not_own_building_obstructions')) {
      const v = props.not_own_building_obstructions;
      this.not_own_building_obstructions = v;
    }

    this.can_provide_high_speed = false;
    if (props.hasOwnProperty('can_provide_high_speed')) {
      const v = props.can_provide_high_speed;
      this.can_provide_high_speed = !!v;
    }
  }
}
export {CandidateUpstreamMount};

// END cwn/netplan/models.proto
