// @flow
import {type Node} from 'react';

// unhandled fatal exception with a unique description of the callsite, plus optional dialog overrides
export type ErrorDetails = {|
  error: Error,
  description: string,
  dialogMessage?: string,
  dialogTitle?: string,
|};

export type OnError = ErrorDetails => void;

// renders a generic "something went wrong" dialog, otherwise you can render your own custom UI in context
export type RenderErrorDialog = (
  open: boolean,
  onClose: () => void,
  ?ErrorDetails,
) => Node;

// second argument passed to componentDidCatch lifecycle hook
export type ErrorInfo = {
  componentStack: string,
};

export type ComponentDidCatch = (error: Error, info: ErrorInfo) => void;
