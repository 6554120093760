// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

import * as cwn_network from '../../../cwn/network';
import * as cwn_asset from '../../../cwn/asset';

// START cwn/ignitenet/metrolinq/models.proto

export const FieldValues = {
  INVALID_FIELD: 0,
  IP_ADDR: 1,
  MODEL: 2,
  NAME: 3,
  FIRMWARE_VERSION: 4,
  MAC_ADDR: 5,
};
type Field = $Keys<typeof FieldValues>;
export type {Field};

export function FieldValue(n: number): Field {
  switch (n) {
    case 0:
      return 'INVALID_FIELD';

    case 1:
      return 'IP_ADDR';

    case 2:
      return 'MODEL';

    case 3:
      return 'NAME';

    case 4:
      return 'FIRMWARE_VERSION';

    case 5:
      return 'MAC_ADDR';

    default:
      return 'INVALID_FIELD';
  }
}

class Device {
  name: string;
  firmware_version: string;
  hw_addr: ?cwn_network.HwAddr;
  fields: {[key: number]: string};
  asset_model: cwn_asset.Model;
  ip_addr: ?cwn_network.IPAddr;

  constructor(props: $Shape<Device> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.firmware_version = '';
    if (props.hasOwnProperty('firmware_version')) {
      const v = props.firmware_version;
      this.firmware_version = v;
    }

    this.hw_addr = null;
    if (props.hasOwnProperty('hw_addr')) {
      const v = props.hw_addr;
      this.hw_addr = v && new cwn_network.HwAddr(v);
    }

    this.fields = {};
    if (props.hasOwnProperty('fields')) {
      const v = props.fields;
      for (const key: any of Object.keys(v)) {
        this.fields[key] = v[key];
      }
    }

    this.asset_model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('asset_model')) {
      const v = props.asset_model;
      this.asset_model = v;
    }

    this.ip_addr = null;
    if (props.hasOwnProperty('ip_addr')) {
      const v = props.ip_addr;
      this.ip_addr = v && new cwn_network.IPAddr(v);
    }
  }

  getHwAddr(): cwn_network.HwAddr {
    if (this.hw_addr) {
      return this.hw_addr;
    }
    return new cwn_network.HwAddr();
  }

  getIpAddr(): cwn_network.IPAddr {
    if (this.ip_addr) {
      return this.ip_addr;
    }
    return new cwn_network.IPAddr();
  }
}
export {Device};

class Config_Parsed_EthernetPort {
  name: string;
  enabled: boolean;
  auto_negotiation: boolean;
  full_duplex: boolean;
  link_speed_mbps: string;

  constructor(props: $Shape<Config_Parsed_EthernetPort> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.enabled = false;
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = !!v;
    }

    this.auto_negotiation = false;
    if (props.hasOwnProperty('auto_negotiation')) {
      const v = props.auto_negotiation;
      this.auto_negotiation = !!v;
    }

    this.full_duplex = false;
    if (props.hasOwnProperty('full_duplex')) {
      const v = props.full_duplex;
      this.full_duplex = !!v;
    }

    this.link_speed_mbps = '0';
    if (props.hasOwnProperty('link_speed_mbps')) {
      const v = props.link_speed_mbps;
      this.link_speed_mbps = v;
    }
  }
}
export {Config_Parsed_EthernetPort};
class Config_Parsed_User {
  name: string;
  admin: boolean;
  password: string;
  enabled: boolean;

  constructor(props: $Shape<Config_Parsed_User> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.admin = false;
    if (props.hasOwnProperty('admin')) {
      const v = props.admin;
      this.admin = !!v;
    }

    this.password = '';
    if (props.hasOwnProperty('password')) {
      const v = props.password;
      this.password = v;
    }

    this.enabled = false;
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = !!v;
    }
  }
}
export {Config_Parsed_User};
class Config_Parsed_Network {
  primary: ?cwn_network.IPNet;
  secondary: ?cwn_network.IPNet;
  gateway: ?cwn_network.IP;
  mtu: string;
  dhcp_enabled: boolean;

  constructor(props: $Shape<Config_Parsed_Network> = {}): void {
    if (!props) {
      props = {};
    }

    this.primary = null;
    if (props.hasOwnProperty('primary')) {
      const v = props.primary;
      this.primary = v && new cwn_network.IPNet(v);
    }

    this.secondary = null;
    if (props.hasOwnProperty('secondary')) {
      const v = props.secondary;
      this.secondary = v && new cwn_network.IPNet(v);
    }

    this.gateway = null;
    if (props.hasOwnProperty('gateway')) {
      const v = props.gateway;
      this.gateway = v && new cwn_network.IP(v);
    }

    this.mtu = '0';
    if (props.hasOwnProperty('mtu')) {
      const v = props.mtu;
      this.mtu = v;
    }

    this.dhcp_enabled = false;
    if (props.hasOwnProperty('dhcp_enabled')) {
      const v = props.dhcp_enabled;
      this.dhcp_enabled = !!v;
    }
  }

  getPrimary(): cwn_network.IPNet {
    if (this.primary) {
      return this.primary;
    }
    return new cwn_network.IPNet();
  }

  getSecondary(): cwn_network.IPNet {
    if (this.secondary) {
      return this.secondary;
    }
    return new cwn_network.IPNet();
  }

  getGateway(): cwn_network.IP {
    if (this.gateway) {
      return this.gateway;
    }
    return new cwn_network.IP();
  }
}
export {Config_Parsed_Network};
export const Config_Parsed_Wireless_ModeValues = {
  UNKNOWN: 0,
  CLIENT: 1,
  MASTER: 2,
};
type Config_Parsed_Wireless_Mode = $Keys<
  typeof Config_Parsed_Wireless_ModeValues,
>;
export type {Config_Parsed_Wireless_Mode};

export function Config_Parsed_Wireless_ModeValue(
  n: number,
): Config_Parsed_Wireless_Mode {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'CLIENT';

    case 2:
      return 'MASTER';

    default:
      return 'UNKNOWN';
  }
}

class Config_Parsed_Wireless {
  enabled: boolean;
  mode: Config_Parsed_Wireless_Mode;
  center_freq_mhz: string;
  tx_power_dbm: number;
  mcs_rate: string;

  constructor(props: $Shape<Config_Parsed_Wireless> = {}): void {
    if (!props) {
      props = {};
    }

    this.enabled = false;
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = !!v;
    }

    this.mode = Config_Parsed_Wireless_ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }

    this.center_freq_mhz = '0';
    if (props.hasOwnProperty('center_freq_mhz')) {
      const v = props.center_freq_mhz;
      this.center_freq_mhz = v;
    }

    this.tx_power_dbm = 0;
    if (props.hasOwnProperty('tx_power_dbm')) {
      const v = props.tx_power_dbm;
      this.tx_power_dbm = v;
    }

    this.mcs_rate = '0';
    if (props.hasOwnProperty('mcs_rate')) {
      const v = props.mcs_rate;
      this.mcs_rate = v;
    }
  }
}
export {Config_Parsed_Wireless};
class Config_Parsed_BackupRadio_Wlan {
  enabled: boolean;
  ssid: string;
  key: string;

  constructor(props: $Shape<Config_Parsed_BackupRadio_Wlan> = {}): void {
    if (!props) {
      props = {};
    }

    this.enabled = false;
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = !!v;
    }

    this.ssid = '';
    if (props.hasOwnProperty('ssid')) {
      const v = props.ssid;
      this.ssid = v;
    }

    this.key = '';
    if (props.hasOwnProperty('key')) {
      const v = props.key;
      this.key = v;
    }
  }
}
export {Config_Parsed_BackupRadio_Wlan};
class Config_Parsed_BackupRadio {
  enabled: boolean;
  wlans: Array<Config_Parsed_BackupRadio_Wlan>;

  constructor(props: $Shape<Config_Parsed_BackupRadio> = {}): void {
    if (!props) {
      props = {};
    }

    this.enabled = false;
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = !!v;
    }

    this.wlans = [];
    if (props.hasOwnProperty('wlans')) {
      const v = props.wlans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field wlans should be array');
      }
      this.wlans = v.map(function(v) {
        return new Config_Parsed_BackupRadio_Wlan(v);
      });
    }
  }
}
export {Config_Parsed_BackupRadio};
class Config_Parsed {
  ethernet_ports: Array<Config_Parsed_EthernetPort>;
  users: Array<Config_Parsed_User>;
  network: ?Config_Parsed_Network;
  wireless: ?Config_Parsed_Wireless;
  backup_radio: ?Config_Parsed_BackupRadio;
  hostname: string;

  constructor(props: $Shape<Config_Parsed> = {}): void {
    if (!props) {
      props = {};
    }

    this.ethernet_ports = [];
    if (props.hasOwnProperty('ethernet_ports')) {
      const v = props.ethernet_ports;
      if (!Array.isArray(v)) {
        throw new Error('repeated field ethernet_ports should be array');
      }
      this.ethernet_ports = v.map(function(v) {
        return new Config_Parsed_EthernetPort(v);
      });
    }

    this.users = [];
    if (props.hasOwnProperty('users')) {
      const v = props.users;
      if (!Array.isArray(v)) {
        throw new Error('repeated field users should be array');
      }
      this.users = v.map(function(v) {
        return new Config_Parsed_User(v);
      });
    }

    this.network = null;
    if (props.hasOwnProperty('network')) {
      const v = props.network;
      this.network = v && new Config_Parsed_Network(v);
    }

    this.wireless = null;
    if (props.hasOwnProperty('wireless')) {
      const v = props.wireless;
      this.wireless = v && new Config_Parsed_Wireless(v);
    }

    this.backup_radio = null;
    if (props.hasOwnProperty('backup_radio')) {
      const v = props.backup_radio;
      this.backup_radio = v && new Config_Parsed_BackupRadio(v);
    }

    this.hostname = '';
    if (props.hasOwnProperty('hostname')) {
      const v = props.hostname;
      this.hostname = v;
    }
  }

  getNetwork(): Config_Parsed_Network {
    if (this.network) {
      return this.network;
    }
    return new Config_Parsed_Network();
  }

  getWireless(): Config_Parsed_Wireless {
    if (this.wireless) {
      return this.wireless;
    }
    return new Config_Parsed_Wireless();
  }

  getBackupRadio(): Config_Parsed_BackupRadio {
    if (this.backup_radio) {
      return this.backup_radio;
    }
    return new Config_Parsed_BackupRadio();
  }
}
export {Config_Parsed};
class Config {
  raw: {[key: string]: string};
  parsed: ?Config_Parsed;

  constructor(props: $Shape<Config> = {}): void {
    if (!props) {
      props = {};
    }

    this.raw = {};
    if (props.hasOwnProperty('raw')) {
      const v = props.raw;
      for (const key: any of Object.keys(v)) {
        this.raw[key] = v[key];
      }
    }

    this.parsed = null;
    if (props.hasOwnProperty('parsed')) {
      const v = props.parsed;
      this.parsed = v && new Config_Parsed(v);
    }
  }

  getParsed(): Config_Parsed {
    if (this.parsed) {
      return this.parsed;
    }
    return new Config_Parsed();
  }
}
export {Config};

class Status_Interface {
  disabled: boolean;
  link: boolean;
  if_index: string;
  duplex: string;
  speed: string;
  autoneg: boolean;
  mac_address: string;
  ip_address: Array<string>;
  ip6_address: Array<string>;
  tx_packets: string;
  rx_packets: string;
  tx_bytes: string;
  rx_bytes: string;
  tx_drop: string;
  rx_drop: string;
  tx_errors: string;
  rx_errors: string;

  constructor(props: $Shape<Status_Interface> = {}): void {
    if (!props) {
      props = {};
    }

    this.disabled = false;
    if (props.hasOwnProperty('disabled')) {
      const v = props.disabled;
      this.disabled = !!v;
    }

    this.link = false;
    if (props.hasOwnProperty('link')) {
      const v = props.link;
      this.link = !!v;
    }

    this.if_index = '0';
    if (props.hasOwnProperty('if_index')) {
      const v = props.if_index;
      this.if_index = v;
    }

    this.duplex = '';
    if (props.hasOwnProperty('duplex')) {
      const v = props.duplex;
      this.duplex = v;
    }

    this.speed = '0';
    if (props.hasOwnProperty('speed')) {
      const v = props.speed;
      this.speed = v;
    }

    this.autoneg = false;
    if (props.hasOwnProperty('autoneg')) {
      const v = props.autoneg;
      this.autoneg = !!v;
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.ip_address = [];
    if (props.hasOwnProperty('ip_address')) {
      const v = props.ip_address;
      if (!Array.isArray(v)) {
        throw new Error('repeated field ip_address should be array');
      }
      this.ip_address = v.map(function(v) {
        return v;
      });
    }

    this.ip6_address = [];
    if (props.hasOwnProperty('ip6_address')) {
      const v = props.ip6_address;
      if (!Array.isArray(v)) {
        throw new Error('repeated field ip6_address should be array');
      }
      this.ip6_address = v.map(function(v) {
        return v;
      });
    }

    this.tx_packets = '0';
    if (props.hasOwnProperty('tx_packets')) {
      const v = props.tx_packets;
      this.tx_packets = v;
    }

    this.rx_packets = '0';
    if (props.hasOwnProperty('rx_packets')) {
      const v = props.rx_packets;
      this.rx_packets = v;
    }

    this.tx_bytes = '0';
    if (props.hasOwnProperty('tx_bytes')) {
      const v = props.tx_bytes;
      this.tx_bytes = v;
    }

    this.rx_bytes = '0';
    if (props.hasOwnProperty('rx_bytes')) {
      const v = props.rx_bytes;
      this.rx_bytes = v;
    }

    this.tx_drop = '0';
    if (props.hasOwnProperty('tx_drop')) {
      const v = props.tx_drop;
      this.tx_drop = v;
    }

    this.rx_drop = '0';
    if (props.hasOwnProperty('rx_drop')) {
      const v = props.rx_drop;
      this.rx_drop = v;
    }

    this.tx_errors = '0';
    if (props.hasOwnProperty('tx_errors')) {
      const v = props.tx_errors;
      this.tx_errors = v;
    }

    this.rx_errors = '0';
    if (props.hasOwnProperty('rx_errors')) {
      const v = props.rx_errors;
      this.rx_errors = v;
    }
  }
}
export {Status_Interface};
class Status_Statistics {
  interfaces: {[key: string]: ?Status_Interface};
  device_ip: string;

  constructor(props: $Shape<Status_Statistics> = {}): void {
    if (!props) {
      props = {};
    }

    this.interfaces = {};
    if (props.hasOwnProperty('interfaces')) {
      const v = props.interfaces;
      for (const key: any of Object.keys(v)) {
        this.interfaces[key] = new Status_Interface((v[key]: any));
      }
    }

    this.device_ip = '';
    if (props.hasOwnProperty('device_ip')) {
      const v = props.device_ip;
      this.device_ip = v;
    }
  }
}
export {Status_Statistics};
class Status_Device {
  name: string;
  disabled: boolean;
  mac: string;
  vendor: string;
  protocol: string;
  radioMode: string;
  channel: string;
  channelWidth: string;
  txPower: string;
  frequency: string;

  constructor(props: $Shape<Status_Device> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.disabled = false;
    if (props.hasOwnProperty('disabled')) {
      const v = props.disabled;
      this.disabled = !!v;
    }

    this.mac = '';
    if (props.hasOwnProperty('mac')) {
      const v = props.mac;
      this.mac = v;
    }

    this.vendor = '';
    if (props.hasOwnProperty('vendor')) {
      const v = props.vendor;
      this.vendor = v;
    }

    this.protocol = '';
    if (props.hasOwnProperty('protocol')) {
      const v = props.protocol;
      this.protocol = v;
    }

    this.radioMode = '';
    if (props.hasOwnProperty('radioMode')) {
      const v = props.radioMode;
      this.radioMode = v;
    }

    this.channel = '0';
    if (props.hasOwnProperty('channel')) {
      const v = props.channel;
      this.channel = v;
    }

    this.channelWidth = '0';
    if (props.hasOwnProperty('channelWidth')) {
      const v = props.channelWidth;
      this.channelWidth = v;
    }

    this.txPower = '';
    if (props.hasOwnProperty('txPower')) {
      const v = props.txPower;
      this.txPower = v;
    }

    this.frequency = '0';
    if (props.hasOwnProperty('frequency')) {
      const v = props.frequency;
      this.frequency = v;
    }
  }
}
export {Status_Device};
class Status_Radios {
  dev: {[key: string]: ?Status_Device};

  constructor(props: $Shape<Status_Radios> = {}): void {
    if (!props) {
      props = {};
    }

    this.dev = {};
    if (props.hasOwnProperty('dev')) {
      const v = props.dev;
      for (const key: any of Object.keys(v)) {
        this.dev[key] = new Status_Device((v[key]: any));
      }
    }
  }
}
export {Status_Radios};
class Status_VAP {
  name: string;
  radio: string;
  mac: string;
  ifIndex: string;
  maxPeerCount: string;
  operationMode: string;
  peerCount: string;
  hiddenSsid: boolean;
  mcs: string;
  security: string;
  ssid: string;
  wds: boolean;
  txPackets: string;
  rxPackets: string;
  txErrors: string;
  rxErrors: string;

  constructor(props: $Shape<Status_VAP> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.radio = '';
    if (props.hasOwnProperty('radio')) {
      const v = props.radio;
      this.radio = v;
    }

    this.mac = '';
    if (props.hasOwnProperty('mac')) {
      const v = props.mac;
      this.mac = v;
    }

    this.ifIndex = '0';
    if (props.hasOwnProperty('ifIndex')) {
      const v = props.ifIndex;
      this.ifIndex = v;
    }

    this.maxPeerCount = '0';
    if (props.hasOwnProperty('maxPeerCount')) {
      const v = props.maxPeerCount;
      this.maxPeerCount = v;
    }

    this.operationMode = '';
    if (props.hasOwnProperty('operationMode')) {
      const v = props.operationMode;
      this.operationMode = v;
    }

    this.peerCount = '0';
    if (props.hasOwnProperty('peerCount')) {
      const v = props.peerCount;
      this.peerCount = v;
    }

    this.hiddenSsid = false;
    if (props.hasOwnProperty('hiddenSsid')) {
      const v = props.hiddenSsid;
      this.hiddenSsid = !!v;
    }

    this.mcs = '0';
    if (props.hasOwnProperty('mcs')) {
      const v = props.mcs;
      this.mcs = v;
    }

    this.security = '';
    if (props.hasOwnProperty('security')) {
      const v = props.security;
      this.security = v;
    }

    this.ssid = '';
    if (props.hasOwnProperty('ssid')) {
      const v = props.ssid;
      this.ssid = v;
    }

    this.wds = false;
    if (props.hasOwnProperty('wds')) {
      const v = props.wds;
      this.wds = !!v;
    }

    this.txPackets = '0';
    if (props.hasOwnProperty('txPackets')) {
      const v = props.txPackets;
      this.txPackets = v;
    }

    this.rxPackets = '0';
    if (props.hasOwnProperty('rxPackets')) {
      const v = props.rxPackets;
      this.rxPackets = v;
    }

    this.txErrors = '0';
    if (props.hasOwnProperty('txErrors')) {
      const v = props.txErrors;
      this.txErrors = v;
    }

    this.rxErrors = '0';
    if (props.hasOwnProperty('rxErrors')) {
      const v = props.rxErrors;
      this.rxErrors = v;
    }
  }
}
export {Status_VAP};
class Status_Peer {
  mac: string;
  vap: string;
  vapIfIndex: string;
  peerIfName: string;
  protocol: string;
  linkUptime: string;
  tx_throughput: string;
  rx_throughput: string;
  txBytes: string;
  rxBytes: string;
  txRate: string;
  rxRate: string;
  txPackets: string;
  rxPackets: string;
  lastLocalRssi: string;
  lastRemoteRssi: string;

  constructor(props: $Shape<Status_Peer> = {}): void {
    if (!props) {
      props = {};
    }

    this.mac = '';
    if (props.hasOwnProperty('mac')) {
      const v = props.mac;
      this.mac = v;
    }

    this.vap = '';
    if (props.hasOwnProperty('vap')) {
      const v = props.vap;
      this.vap = v;
    }

    this.vapIfIndex = '0';
    if (props.hasOwnProperty('vapIfIndex')) {
      const v = props.vapIfIndex;
      this.vapIfIndex = v;
    }

    this.peerIfName = '';
    if (props.hasOwnProperty('peerIfName')) {
      const v = props.peerIfName;
      this.peerIfName = v;
    }

    this.protocol = '';
    if (props.hasOwnProperty('protocol')) {
      const v = props.protocol;
      this.protocol = v;
    }

    this.linkUptime = '0';
    if (props.hasOwnProperty('linkUptime')) {
      const v = props.linkUptime;
      this.linkUptime = v;
    }

    this.tx_throughput = '0';
    if (props.hasOwnProperty('tx_throughput')) {
      const v = props.tx_throughput;
      this.tx_throughput = v;
    }

    this.rx_throughput = '0';
    if (props.hasOwnProperty('rx_throughput')) {
      const v = props.rx_throughput;
      this.rx_throughput = v;
    }

    this.txBytes = '0';
    if (props.hasOwnProperty('txBytes')) {
      const v = props.txBytes;
      this.txBytes = v;
    }

    this.rxBytes = '0';
    if (props.hasOwnProperty('rxBytes')) {
      const v = props.rxBytes;
      this.rxBytes = v;
    }

    this.txRate = '0';
    if (props.hasOwnProperty('txRate')) {
      const v = props.txRate;
      this.txRate = v;
    }

    this.rxRate = '0';
    if (props.hasOwnProperty('rxRate')) {
      const v = props.rxRate;
      this.rxRate = v;
    }

    this.txPackets = '0';
    if (props.hasOwnProperty('txPackets')) {
      const v = props.txPackets;
      this.txPackets = v;
    }

    this.rxPackets = '0';
    if (props.hasOwnProperty('rxPackets')) {
      const v = props.rxPackets;
      this.rxPackets = v;
    }

    this.lastLocalRssi = '0';
    if (props.hasOwnProperty('lastLocalRssi')) {
      const v = props.lastLocalRssi;
      this.lastLocalRssi = v;
    }

    this.lastRemoteRssi = '0';
    if (props.hasOwnProperty('lastRemoteRssi')) {
      const v = props.lastRemoteRssi;
      this.lastRemoteRssi = v;
    }
  }
}
export {Status_Peer};
class Status_Wireless {
  radios: ?Status_Radios;
  vaps: Array<Status_VAP>;
  peers: Array<Status_Peer>;
  timestamp: string;

  constructor(props: $Shape<Status_Wireless> = {}): void {
    if (!props) {
      props = {};
    }

    this.radios = null;
    if (props.hasOwnProperty('radios')) {
      const v = props.radios;
      this.radios = v && new Status_Radios(v);
    }

    this.vaps = [];
    if (props.hasOwnProperty('vaps')) {
      const v = props.vaps;
      if (!Array.isArray(v)) {
        throw new Error('repeated field vaps should be array');
      }
      this.vaps = v.map(function(v) {
        return new Status_VAP(v);
      });
    }

    this.peers = [];
    if (props.hasOwnProperty('peers')) {
      const v = props.peers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field peers should be array');
      }
      this.peers = v.map(function(v) {
        return new Status_Peer(v);
      });
    }

    this.timestamp = '0';
    if (props.hasOwnProperty('timestamp')) {
      const v = props.timestamp;
      this.timestamp = v;
    }
  }

  getRadios(): Status_Radios {
    if (this.radios) {
      return this.radios;
    }
    return new Status_Radios();
  }
}
export {Status_Wireless};
class Status_CPU {
  usage: string;

  constructor(props: $Shape<Status_CPU> = {}): void {
    if (!props) {
      props = {};
    }

    this.usage = '0';
    if (props.hasOwnProperty('usage')) {
      const v = props.usage;
      this.usage = v;
    }
  }
}
export {Status_CPU};
class Status_Memory {
  usage: number;
  total: string;
  free: string;
  used: string;

  constructor(props: $Shape<Status_Memory> = {}): void {
    if (!props) {
      props = {};
    }

    this.usage = 0;
    if (props.hasOwnProperty('usage')) {
      const v = props.usage;
      this.usage = v;
    }

    this.total = '0';
    if (props.hasOwnProperty('total')) {
      const v = props.total;
      this.total = v;
    }

    this.free = '0';
    if (props.hasOwnProperty('free')) {
      const v = props.free;
      this.free = v;
    }

    this.used = '0';
    if (props.hasOwnProperty('used')) {
      const v = props.used;
      this.used = v;
    }
  }
}
export {Status_Memory};
class Status_System {
  cpu: ?Status_CPU;
  memory: ?Status_Memory;
  uptime: string;

  constructor(props: $Shape<Status_System> = {}): void {
    if (!props) {
      props = {};
    }

    this.cpu = null;
    if (props.hasOwnProperty('cpu')) {
      const v = props.cpu;
      this.cpu = v && new Status_CPU(v);
    }

    this.memory = null;
    if (props.hasOwnProperty('memory')) {
      const v = props.memory;
      this.memory = v && new Status_Memory(v);
    }

    this.uptime = '0';
    if (props.hasOwnProperty('uptime')) {
      const v = props.uptime;
      this.uptime = v;
    }
  }

  getCpu(): Status_CPU {
    if (this.cpu) {
      return this.cpu;
    }
    return new Status_CPU();
  }

  getMemory(): Status_Memory {
    if (this.memory) {
      return this.memory;
    }
    return new Status_Memory();
  }
}
export {Status_System};
class Status {
  statistics: ?Status_Statistics;
  system: ?Status_System;
  wireless: ?Status_Wireless;
  wireless_prs: ?Status_Wireless;
  wireless_qca: ?Status_Wireless;
  fw_version: string;
  ifmac_to_peer_linkid: {[key: string]: string};

  constructor(props: $Shape<Status> = {}): void {
    if (!props) {
      props = {};
    }

    this.statistics = null;
    if (props.hasOwnProperty('statistics')) {
      const v = props.statistics;
      this.statistics = v && new Status_Statistics(v);
    }

    this.system = null;
    if (props.hasOwnProperty('system')) {
      const v = props.system;
      this.system = v && new Status_System(v);
    }

    this.wireless = null;
    if (props.hasOwnProperty('wireless')) {
      const v = props.wireless;
      this.wireless = v && new Status_Wireless(v);
    }

    this.wireless_prs = null;
    if (props.hasOwnProperty('wireless_prs')) {
      const v = props.wireless_prs;
      this.wireless_prs = v && new Status_Wireless(v);
    }

    this.wireless_qca = null;
    if (props.hasOwnProperty('wireless_qca')) {
      const v = props.wireless_qca;
      this.wireless_qca = v && new Status_Wireless(v);
    }

    this.fw_version = '';
    if (props.hasOwnProperty('fw_version')) {
      const v = props.fw_version;
      this.fw_version = v;
    }

    this.ifmac_to_peer_linkid = {};
    if (props.hasOwnProperty('ifmac_to_peer_linkid')) {
      const v = props.ifmac_to_peer_linkid;
      for (const key: any of Object.keys(v)) {
        this.ifmac_to_peer_linkid[key] = v[key];
      }
    }
  }

  getStatistics(): Status_Statistics {
    if (this.statistics) {
      return this.statistics;
    }
    return new Status_Statistics();
  }

  getSystem(): Status_System {
    if (this.system) {
      return this.system;
    }
    return new Status_System();
  }

  getWireless(): Status_Wireless {
    if (this.wireless) {
      return this.wireless;
    }
    return new Status_Wireless();
  }

  getWirelessPrs(): Status_Wireless {
    if (this.wireless_prs) {
      return this.wireless_prs;
    }
    return new Status_Wireless();
  }

  getWirelessQca(): Status_Wireless {
    if (this.wireless_qca) {
      return this.wireless_qca;
    }
    return new Status_Wireless();
  }
}
export {Status};

// END cwn/ignitenet/metrolinq/models.proto
