//@flow
import {useContext} from 'react';
import {WebServiceContext} from './WebService';
import {WebService} from 'pb/cwn/web';

export default function useWebService(): WebService {
  const {webService} = useContext<{webService: WebService}>(
    (WebServiceContext: any),
  );
  return webService;
}
