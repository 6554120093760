// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../../google/protobuf';
/* eslint-enable */
import * as cwn_network from '../../../cwn/network';
import * as cwn_asset from '../../../cwn/asset';

// START cwn/network/routing/models.proto

export const AlgorithmValues = {
  INVALID: 0,
  SPLIT_BY_AREA: 7,
  DIJKSTRA_INTERFACES: 14,
};
type Algorithm = $Keys<typeof AlgorithmValues>;
export type {Algorithm};

export function AlgorithmValue(n: number): Algorithm {
  switch (n) {
    case 0:
      return 'INVALID';

    case 7:
      return 'SPLIT_BY_AREA';

    case 14:
      return 'DIJKSTRA_INTERFACES';

    default:
      return 'INVALID';
  }
}

export const RoutingTableOverrideValues = {
  NONE: 0,
  PAUSE: 1,
  DRY_RUN: 2,
};
type RoutingTableOverride = $Keys<typeof RoutingTableOverrideValues>;
export type {RoutingTableOverride};

export function RoutingTableOverrideValue(n: number): RoutingTableOverride {
  switch (n) {
    case 0:
      return 'NONE';

    case 1:
      return 'PAUSE';

    case 2:
      return 'DRY_RUN';

    default:
      return 'NONE';
  }
}

export const RoutingExperimentValues = {
  NO_EXPERIMENT: 0,
  BAD_SITES: 1,
};
type RoutingExperiment = $Keys<typeof RoutingExperimentValues>;
export type {RoutingExperiment};

export function RoutingExperimentValue(n: number): RoutingExperiment {
  switch (n) {
    case 0:
      return 'NO_EXPERIMENT';

    case 1:
      return 'BAD_SITES';

    default:
      return 'NO_EXPERIMENT';
  }
}

export const Node_TypeValues = {
  LEAF: 0,
  RELAY: 1,
  SOURCE: 2,
};
type Node_Type = $Keys<typeof Node_TypeValues>;
export type {Node_Type};

export function Node_TypeValue(n: number): Node_Type {
  switch (n) {
    case 0:
      return 'LEAF';

    case 1:
      return 'RELAY';

    case 2:
      return 'SOURCE';

    default:
      return 'LEAF';
  }
}

class Node_Neighbor {
  device_id: string;
  unilateral: boolean;
  interfaces: Array<string>;

  constructor(props: $Shape<Node_Neighbor> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.unilateral = false;
    if (props.hasOwnProperty('unilateral')) {
      const v = props.unilateral;
      this.unilateral = !!v;
    }

    this.interfaces = [];
    if (props.hasOwnProperty('interfaces')) {
      const v = props.interfaces;
      if (!Array.isArray(v)) {
        throw new Error('repeated field interfaces should be array');
      }
      this.interfaces = v.map(function(v) {
        return v;
      });
    }
  }
}
export {Node_Neighbor};
class Node_Han {
  interface_name: string;
  prefix: string;

  constructor(props: $Shape<Node_Han> = {}): void {
    if (!props) {
      props = {};
    }

    this.interface_name = '';
    if (props.hasOwnProperty('interface_name')) {
      const v = props.interface_name;
      this.interface_name = v;
    }

    this.prefix = '0';
    if (props.hasOwnProperty('prefix')) {
      const v = props.prefix;
      this.prefix = v;
    }
  }
}
export {Node_Han};
class Node {
  device_id: string;
  ip: ?cwn_network.IP;
  neighbors: Array<Node_Neighbor>;
  type: Node_Type;
  prefix: ?cwn_network.IPNet;
  hans: Array<Node_Han>;
  suffix: ?cwn_network.IPNet;
  area_id: string;
  ip_six: ?cwn_network.IP;
  area_group_id: string;
  num_active_subs: string;
  site_id: string;
  expired_neighbors: Array<Node_Neighbor>;
  expired: boolean;

  constructor(props: $Shape<Node> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.ip = null;
    if (props.hasOwnProperty('ip')) {
      const v = props.ip;
      this.ip = v && new cwn_network.IP(v);
    }

    this.neighbors = [];
    if (props.hasOwnProperty('neighbors')) {
      const v = props.neighbors;
      if (!Array.isArray(v)) {
        throw new Error('repeated field neighbors should be array');
      }
      this.neighbors = v.map(function(v) {
        return new Node_Neighbor(v);
      });
    }

    this.type = Node_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.prefix = null;
    if (props.hasOwnProperty('prefix')) {
      const v = props.prefix;
      this.prefix = v && new cwn_network.IPNet(v);
    }

    this.hans = [];
    if (props.hasOwnProperty('hans')) {
      const v = props.hans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field hans should be array');
      }
      this.hans = v.map(function(v) {
        return new Node_Han(v);
      });
    }

    this.suffix = null;
    if (props.hasOwnProperty('suffix')) {
      const v = props.suffix;
      this.suffix = v && new cwn_network.IPNet(v);
    }

    this.area_id = '0';
    if (props.hasOwnProperty('area_id')) {
      const v = props.area_id;
      this.area_id = v;
    }

    this.ip_six = null;
    if (props.hasOwnProperty('ip_six')) {
      const v = props.ip_six;
      this.ip_six = v && new cwn_network.IP(v);
    }

    this.area_group_id = '0';
    if (props.hasOwnProperty('area_group_id')) {
      const v = props.area_group_id;
      this.area_group_id = v;
    }

    this.num_active_subs = '0';
    if (props.hasOwnProperty('num_active_subs')) {
      const v = props.num_active_subs;
      this.num_active_subs = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.expired_neighbors = [];
    if (props.hasOwnProperty('expired_neighbors')) {
      const v = props.expired_neighbors;
      if (!Array.isArray(v)) {
        throw new Error('repeated field expired_neighbors should be array');
      }
      this.expired_neighbors = v.map(function(v) {
        return new Node_Neighbor(v);
      });
    }

    this.expired = false;
    if (props.hasOwnProperty('expired')) {
      const v = props.expired;
      this.expired = !!v;
    }
  }

  getIp(): cwn_network.IP {
    if (this.ip) {
      return this.ip;
    }
    return new cwn_network.IP();
  }

  getPrefix(): cwn_network.IPNet {
    if (this.prefix) {
      return this.prefix;
    }
    return new cwn_network.IPNet();
  }

  getSuffix(): cwn_network.IPNet {
    if (this.suffix) {
      return this.suffix;
    }
    return new cwn_network.IPNet();
  }

  getIpSix(): cwn_network.IP {
    if (this.ip_six) {
      return this.ip_six;
    }
    return new cwn_network.IP();
  }
}
export {Node};

class Network {
  source_device_ids: Array<string>;
  nodes_by_device_id: {[key: string]: ?Node};
  id: string;
  created: ?Date;
  leases_by_device_id: {[key: string]: ?Lease};
  next_expiration: ?Date;

  constructor(props: $Shape<Network> = {}): void {
    if (!props) {
      props = {};
    }

    this.source_device_ids = [];
    if (props.hasOwnProperty('source_device_ids')) {
      const v = props.source_device_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field source_device_ids should be array');
      }
      this.source_device_ids = v.map(function(v) {
        return v;
      });
    }

    this.nodes_by_device_id = {};
    if (props.hasOwnProperty('nodes_by_device_id')) {
      const v = props.nodes_by_device_id;
      for (const key: any of Object.keys(v)) {
        this.nodes_by_device_id[key] = new Node((v[key]: any));
      }
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.leases_by_device_id = {};
    if (props.hasOwnProperty('leases_by_device_id')) {
      const v = props.leases_by_device_id;
      for (const key: any of Object.keys(v)) {
        this.leases_by_device_id[key] = new Lease((v[key]: any));
      }
    }

    this.next_expiration = null;
    if (props.hasOwnProperty('next_expiration')) {
      const v = props.next_expiration;
      this.next_expiration = v && new Date(v);
    }
  }
}
export {Network};

class Route {
  device_ids: Array<string>;
  destination_iface: string;
  upstream_ifaces: {[key: string]: string};

  constructor(props: $Shape<Route> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_ids = [];
    if (props.hasOwnProperty('device_ids')) {
      const v = props.device_ids;
      if (!Array.isArray(v)) {
        throw new Error('repeated field device_ids should be array');
      }
      this.device_ids = v.map(function(v) {
        return v;
      });
    }

    this.destination_iface = '';
    if (props.hasOwnProperty('destination_iface')) {
      const v = props.destination_iface;
      this.destination_iface = v;
    }

    this.upstream_ifaces = {};
    if (props.hasOwnProperty('upstream_ifaces')) {
      const v = props.upstream_ifaces;
      for (const key: any of Object.keys(v)) {
        this.upstream_ifaces[key] = v[key];
      }
    }
  }
}
export {Route};

class AppliedRoute {
  id: string;
  route: ?Route;
  routing_table_id: string;
  applied: ?Date;
  destination: string;
  deleted: boolean;

  constructor(props: $Shape<AppliedRoute> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.route = null;
    if (props.hasOwnProperty('route')) {
      const v = props.route;
      this.route = v && new Route(v);
    }

    this.routing_table_id = '0';
    if (props.hasOwnProperty('routing_table_id')) {
      const v = props.routing_table_id;
      this.routing_table_id = v;
    }

    this.applied = null;
    if (props.hasOwnProperty('applied')) {
      const v = props.applied;
      this.applied = v && new Date(v);
    }

    this.destination = '';
    if (props.hasOwnProperty('destination')) {
      const v = props.destination;
      this.destination = v;
    }

    this.deleted = false;
    if (props.hasOwnProperty('deleted')) {
      const v = props.deleted;
      this.deleted = !!v;
    }
  }

  getRoute(): Route {
    if (this.route) {
      return this.route;
    }
    return new Route();
  }
}
export {AppliedRoute};

class FailedRoutingTable {
  id: string;
  routing_table: ?RoutingTable;
  error: string;
  created: ?Date;

  constructor(props: $Shape<FailedRoutingTable> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.routing_table = null;
    if (props.hasOwnProperty('routing_table')) {
      const v = props.routing_table;
      this.routing_table = v && new RoutingTable(v);
    }

    this.error = '';
    if (props.hasOwnProperty('error')) {
      const v = props.error;
      this.error = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }
  }

  getRoutingTable(): RoutingTable {
    if (this.routing_table) {
      return this.routing_table;
    }
    return new RoutingTable();
  }
}
export {FailedRoutingTable};

export const RoutingTable_TriggerValues = {
  UNKNOWN: 0,
  CRON: 1,
  PEER_STATUS: 2,
  MANUAL: 3,
};
type RoutingTable_Trigger = $Keys<typeof RoutingTable_TriggerValues>;
export type {RoutingTable_Trigger};

export function RoutingTable_TriggerValue(n: number): RoutingTable_Trigger {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'CRON';

    case 2:
      return 'PEER_STATUS';

    case 3:
      return 'MANUAL';

    default:
      return 'UNKNOWN';
  }
}

class RoutingTable {
  id: string;
  created: ?Date;
  routes: Array<Route>;
  applied: ?Date;
  network: ?Network;
  previous_routing_table_id: string;
  trigger: RoutingTable_Trigger;
  area_group_has_interfaces: {[key: string]: boolean};

  constructor(props: $Shape<RoutingTable> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.routes = [];
    if (props.hasOwnProperty('routes')) {
      const v = props.routes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field routes should be array');
      }
      this.routes = v.map(function(v) {
        return new Route(v);
      });
    }

    this.applied = null;
    if (props.hasOwnProperty('applied')) {
      const v = props.applied;
      this.applied = v && new Date(v);
    }

    this.network = null;
    if (props.hasOwnProperty('network')) {
      const v = props.network;
      this.network = v && new Network(v);
    }

    this.previous_routing_table_id = '0';
    if (props.hasOwnProperty('previous_routing_table_id')) {
      const v = props.previous_routing_table_id;
      this.previous_routing_table_id = v;
    }

    this.trigger = RoutingTable_TriggerValue(0);
    if (props.hasOwnProperty('trigger')) {
      const v = props.trigger;
      this.trigger = v;
    }

    this.area_group_has_interfaces = {};
    if (props.hasOwnProperty('area_group_has_interfaces')) {
      const v = props.area_group_has_interfaces;
      for (const key: any of Object.keys(v)) {
        this.area_group_has_interfaces[key] = !!v[key];
      }
    }
  }

  getNetwork(): Network {
    if (this.network) {
      return this.network;
    }
    return new Network();
  }
}
export {RoutingTable};

class Edge {
  a: string;
  b: string;

  constructor(props: $Shape<Edge> = {}): void {
    if (!props) {
      props = {};
    }

    this.a = '';
    if (props.hasOwnProperty('a')) {
      const v = props.a;
      this.a = v;
    }

    this.b = '';
    if (props.hasOwnProperty('b')) {
      const v = props.b;
      this.b = v;
    }
  }
}
export {Edge};

class EdgeWeight {
  edge: ?Edge;
  weight: number;

  constructor(props: $Shape<EdgeWeight> = {}): void {
    if (!props) {
      props = {};
    }

    this.edge = null;
    if (props.hasOwnProperty('edge')) {
      const v = props.edge;
      this.edge = v && new Edge(v);
    }

    this.weight = 0;
    if (props.hasOwnProperty('weight')) {
      const v = props.weight;
      this.weight = v;
    }
  }

  getEdge(): Edge {
    if (this.edge) {
      return this.edge;
    }
    return new Edge();
  }
}
export {EdgeWeight};

class Metadata_Dijkstra {
  edge_weights: Array<EdgeWeight>;

  constructor(props: $Shape<Metadata_Dijkstra> = {}): void {
    if (!props) {
      props = {};
    }

    this.edge_weights = [];
    if (props.hasOwnProperty('edge_weights')) {
      const v = props.edge_weights;
      if (!Array.isArray(v)) {
        throw new Error('repeated field edge_weights should be array');
      }
      this.edge_weights = v.map(function(v) {
        return new EdgeWeight(v);
      });
    }
  }
}
export {Metadata_Dijkstra};
class Metadata_DijkstraInterfaces_InterfaceEdgeWeight {
  device_a: string;
  interface_a: string;
  device_b: string;
  weight: number;

  constructor(
    props: $Shape<Metadata_DijkstraInterfaces_InterfaceEdgeWeight> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.device_a = '';
    if (props.hasOwnProperty('device_a')) {
      const v = props.device_a;
      this.device_a = v;
    }

    this.interface_a = '';
    if (props.hasOwnProperty('interface_a')) {
      const v = props.interface_a;
      this.interface_a = v;
    }

    this.device_b = '';
    if (props.hasOwnProperty('device_b')) {
      const v = props.device_b;
      this.device_b = v;
    }

    this.weight = 0;
    if (props.hasOwnProperty('weight')) {
      const v = props.weight;
      this.weight = v;
    }
  }
}
export {Metadata_DijkstraInterfaces_InterfaceEdgeWeight};
class Metadata_DijkstraInterfaces {
  interface_edge_weights: Array<
    Metadata_DijkstraInterfaces_InterfaceEdgeWeight,
  >;

  constructor(props: $Shape<Metadata_DijkstraInterfaces> = {}): void {
    if (!props) {
      props = {};
    }

    this.interface_edge_weights = [];
    if (props.hasOwnProperty('interface_edge_weights')) {
      const v = props.interface_edge_weights;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field interface_edge_weights should be array',
        );
      }
      this.interface_edge_weights = v.map(function(v) {
        return new Metadata_DijkstraInterfaces_InterfaceEdgeWeight(v);
      });
    }
  }
}
export {Metadata_DijkstraInterfaces};
class Metadata {
  id: string;
  algorithm: Algorithm;
  dijkstra: ?Metadata_Dijkstra;
  area_metadatas: {[key: string]: ?Metadata};
  cloned_from: string;
  dijkstra_interfaces: ?Metadata_DijkstraInterfaces;

  constructor(props: $Shape<Metadata> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.algorithm = AlgorithmValue(0);
    if (props.hasOwnProperty('algorithm')) {
      const v = props.algorithm;
      this.algorithm = v;
    }

    this.dijkstra = null;
    if (props.hasOwnProperty('dijkstra')) {
      const v = props.dijkstra;
      this.dijkstra = v && new Metadata_Dijkstra(v);
    }

    this.area_metadatas = {};
    if (props.hasOwnProperty('area_metadatas')) {
      const v = props.area_metadatas;
      for (const key: any of Object.keys(v)) {
        this.area_metadatas[key] = new Metadata((v[key]: any));
      }
    }

    this.cloned_from = '0';
    if (props.hasOwnProperty('cloned_from')) {
      const v = props.cloned_from;
      this.cloned_from = v;
    }

    this.dijkstra_interfaces = null;
    if (props.hasOwnProperty('dijkstra_interfaces')) {
      const v = props.dijkstra_interfaces;
      this.dijkstra_interfaces = v && new Metadata_DijkstraInterfaces(v);
    }
  }

  getDijkstra(): Metadata_Dijkstra {
    if (this.dijkstra) {
      return this.dijkstra;
    }
    return new Metadata_Dijkstra();
  }

  getDijkstraInterfaces(): Metadata_DijkstraInterfaces {
    if (this.dijkstra_interfaces) {
      return this.dijkstra_interfaces;
    }
    return new Metadata_DijkstraInterfaces();
  }
}
export {Metadata};

class EdgeWeightOverride {
  edge_weight: ?EdgeWeight;
  algorithm: Algorithm;
  created: ?Date;
  id: string;
  deleted: boolean;
  expires: ?Date;
  initiator: string;
  sent_expiration_alert: boolean;

  constructor(props: $Shape<EdgeWeightOverride> = {}): void {
    if (!props) {
      props = {};
    }

    this.edge_weight = null;
    if (props.hasOwnProperty('edge_weight')) {
      const v = props.edge_weight;
      this.edge_weight = v && new EdgeWeight(v);
    }

    this.algorithm = AlgorithmValue(0);
    if (props.hasOwnProperty('algorithm')) {
      const v = props.algorithm;
      this.algorithm = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.deleted = false;
    if (props.hasOwnProperty('deleted')) {
      const v = props.deleted;
      this.deleted = !!v;
    }

    this.expires = null;
    if (props.hasOwnProperty('expires')) {
      const v = props.expires;
      this.expires = v && new Date(v);
    }

    this.initiator = '';
    if (props.hasOwnProperty('initiator')) {
      const v = props.initiator;
      this.initiator = v;
    }

    this.sent_expiration_alert = false;
    if (props.hasOwnProperty('sent_expiration_alert')) {
      const v = props.sent_expiration_alert;
      this.sent_expiration_alert = !!v;
    }
  }

  getEdgeWeight(): EdgeWeight {
    if (this.edge_weight) {
      return this.edge_weight;
    }
    return new EdgeWeight();
  }
}
export {EdgeWeightOverride};

class SiteWeightOverride {
  site_id: string;
  weight: number;
  algorithm: Algorithm;
  created: ?Date;
  id: string;
  experiment: RoutingExperiment;

  constructor(props: $Shape<SiteWeightOverride> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.weight = 0;
    if (props.hasOwnProperty('weight')) {
      const v = props.weight;
      this.weight = v;
    }

    this.algorithm = AlgorithmValue(0);
    if (props.hasOwnProperty('algorithm')) {
      const v = props.algorithm;
      this.algorithm = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.experiment = RoutingExperimentValue(0);
    if (props.hasOwnProperty('experiment')) {
      const v = props.experiment;
      this.experiment = v;
    }
  }
}
export {SiteWeightOverride};

class SourceRelayOverride {
  site_id: string;
  updated: ?Date;
  is_relay_mode: boolean;

  constructor(props: $Shape<SourceRelayOverride> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.is_relay_mode = false;
    if (props.hasOwnProperty('is_relay_mode')) {
      const v = props.is_relay_mode;
      this.is_relay_mode = !!v;
    }
  }
}
export {SourceRelayOverride};

class Lease {
  site_id: string;
  ip: ?cwn_network.IPNet;
  created: ?Date;

  constructor(props: $Shape<Lease> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.ip = null;
    if (props.hasOwnProperty('ip')) {
      const v = props.ip;
      this.ip = v && new cwn_network.IPNet(v);
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }
  }

  getIp(): cwn_network.IPNet {
    if (this.ip) {
      return this.ip;
    }
    return new cwn_network.IPNet();
  }
}
export {Lease};

class NodeChange {
  oldNode: ?Node;
  newNode: ?Node;

  constructor(props: $Shape<NodeChange> = {}): void {
    if (!props) {
      props = {};
    }

    this.oldNode = null;
    if (props.hasOwnProperty('oldNode')) {
      const v = props.oldNode;
      this.oldNode = v && new Node(v);
    }

    this.newNode = null;
    if (props.hasOwnProperty('newNode')) {
      const v = props.newNode;
      this.newNode = v && new Node(v);
    }
  }

  getOldNode(): Node {
    if (this.oldNode) {
      return this.oldNode;
    }
    return new Node();
  }

  getNewNode(): Node {
    if (this.newNode) {
      return this.newNode;
    }
    return new Node();
  }
}
export {NodeChange};

class RouteChange {
  oldRoute: ?Route;
  newRoute: ?Route;

  constructor(props: $Shape<RouteChange> = {}): void {
    if (!props) {
      props = {};
    }

    this.oldRoute = null;
    if (props.hasOwnProperty('oldRoute')) {
      const v = props.oldRoute;
      this.oldRoute = v && new Route(v);
    }

    this.newRoute = null;
    if (props.hasOwnProperty('newRoute')) {
      const v = props.newRoute;
      this.newRoute = v && new Route(v);
    }
  }

  getOldRoute(): Route {
    if (this.oldRoute) {
      return this.oldRoute;
    }
    return new Route();
  }

  getNewRoute(): Route {
    if (this.newRoute) {
      return this.newRoute;
    }
    return new Route();
  }
}
export {RouteChange};

class NetworkDifferences {
  addedNodes: Array<Node>;
  removedNodes: Array<Node>;
  changedNodes: Array<NodeChange>;

  constructor(props: $Shape<NetworkDifferences> = {}): void {
    if (!props) {
      props = {};
    }

    this.addedNodes = [];
    if (props.hasOwnProperty('addedNodes')) {
      const v = props.addedNodes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field addedNodes should be array');
      }
      this.addedNodes = v.map(function(v) {
        return new Node(v);
      });
    }

    this.removedNodes = [];
    if (props.hasOwnProperty('removedNodes')) {
      const v = props.removedNodes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field removedNodes should be array');
      }
      this.removedNodes = v.map(function(v) {
        return new Node(v);
      });
    }

    this.changedNodes = [];
    if (props.hasOwnProperty('changedNodes')) {
      const v = props.changedNodes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field changedNodes should be array');
      }
      this.changedNodes = v.map(function(v) {
        return new NodeChange(v);
      });
    }
  }
}
export {NetworkDifferences};

class RouteDifferences {
  addedRoutes: Array<Route>;
  removedRoutes: Array<Route>;
  changedRoutes: Array<RouteChange>;

  constructor(props: $Shape<RouteDifferences> = {}): void {
    if (!props) {
      props = {};
    }

    this.addedRoutes = [];
    if (props.hasOwnProperty('addedRoutes')) {
      const v = props.addedRoutes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field addedRoutes should be array');
      }
      this.addedRoutes = v.map(function(v) {
        return new Route(v);
      });
    }

    this.removedRoutes = [];
    if (props.hasOwnProperty('removedRoutes')) {
      const v = props.removedRoutes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field removedRoutes should be array');
      }
      this.removedRoutes = v.map(function(v) {
        return new Route(v);
      });
    }

    this.changedRoutes = [];
    if (props.hasOwnProperty('changedRoutes')) {
      const v = props.changedRoutes;
      if (!Array.isArray(v)) {
        throw new Error('repeated field changedRoutes should be array');
      }
      this.changedRoutes = v.map(function(v) {
        return new RouteChange(v);
      });
    }
  }
}
export {RouteDifferences};

class RoutingTableDifferences {
  new_id: string;
  route_differences: ?RouteDifferences;
  network_differences: ?NetworkDifferences;
  Created: ?Date;
  dry_run: boolean;
  old_id: string;

  constructor(props: $Shape<RoutingTableDifferences> = {}): void {
    if (!props) {
      props = {};
    }

    this.new_id = '0';
    if (props.hasOwnProperty('new_id')) {
      const v = props.new_id;
      this.new_id = v;
    }

    this.route_differences = null;
    if (props.hasOwnProperty('route_differences')) {
      const v = props.route_differences;
      this.route_differences = v && new RouteDifferences(v);
    }

    this.network_differences = null;
    if (props.hasOwnProperty('network_differences')) {
      const v = props.network_differences;
      this.network_differences = v && new NetworkDifferences(v);
    }

    this.Created = null;
    if (props.hasOwnProperty('Created')) {
      const v = props.Created;
      this.Created = v && new Date(v);
    }

    this.dry_run = false;
    if (props.hasOwnProperty('dry_run')) {
      const v = props.dry_run;
      this.dry_run = !!v;
    }

    this.old_id = '0';
    if (props.hasOwnProperty('old_id')) {
      const v = props.old_id;
      this.old_id = v;
    }
  }

  getRouteDifferences(): RouteDifferences {
    if (this.route_differences) {
      return this.route_differences;
    }
    return new RouteDifferences();
  }

  getNetworkDifferences(): NetworkDifferences {
    if (this.network_differences) {
      return this.network_differences;
    }
    return new NetworkDifferences();
  }
}
export {RoutingTableDifferences};

class InterfaceModels {
  devices: {[key: string]: ?DeviceInterfaceModels};

  constructor(props: $Shape<InterfaceModels> = {}): void {
    if (!props) {
      props = {};
    }

    this.devices = {};
    if (props.hasOwnProperty('devices')) {
      const v = props.devices;
      for (const key: any of Object.keys(v)) {
        this.devices[key] = new DeviceInterfaceModels((v[key]: any));
      }
    }
  }
}
export {InterfaceModels};

class DeviceInterfaceModels {
  interfaces: {[key: string]: cwn_asset.Model};

  constructor(props: $Shape<DeviceInterfaceModels> = {}): void {
    if (!props) {
      props = {};
    }

    this.interfaces = {};
    if (props.hasOwnProperty('interfaces')) {
      const v = props.interfaces;
      for (const key: any of Object.keys(v)) {
        this.interfaces[key] = v[key];
      }
    }
  }
}
export {DeviceInterfaceModels};

// END cwn/network/routing/models.proto
