// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_network_sys from '../../cwn/network/sys';
import * as cwn_network_routing from '../../cwn/network/routing';
import * as cwn_network from '../../cwn/network';

// START cwn/speedtest/models.proto

export const DirectionValues = {
  INVALID: 0,
  INBOUND: 1,
  OUTBOUND: 2,
};
type Direction = $Keys<typeof DirectionValues>;
export type {Direction};

export function DirectionValue(n: number): Direction {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'INBOUND';

    case 2:
      return 'OUTBOUND';

    default:
      return 'INVALID';
  }
}

class SpeedTestResultV3_Metadata {
  route: string;

  constructor(props: $Shape<SpeedTestResultV3_Metadata> = {}): void {
    if (!props) {
      props = {};
    }

    this.route = '';
    if (props.hasOwnProperty('route')) {
      const v = props.route;
      this.route = v;
    }
  }
}
export {SpeedTestResultV3_Metadata};
class SpeedTestResultV3 {
  id: string;
  created: ?Date;
  initiator_id: string;
  speedtest: ?SpeedTest;
  result: ?Result;
  computed: ?Computed;
  metadata: ?SpeedTestResultV3_Metadata;
  ErrorString: string;

  constructor(props: $Shape<SpeedTestResultV3> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.initiator_id = '';
    if (props.hasOwnProperty('initiator_id')) {
      const v = props.initiator_id;
      this.initiator_id = v;
    }

    this.speedtest = null;
    if (props.hasOwnProperty('speedtest')) {
      const v = props.speedtest;
      this.speedtest = v && new SpeedTest(v);
    }

    this.result = null;
    if (props.hasOwnProperty('result')) {
      const v = props.result;
      this.result = v && new Result(v);
    }

    this.computed = null;
    if (props.hasOwnProperty('computed')) {
      const v = props.computed;
      this.computed = v && new Computed(v);
    }

    this.metadata = null;
    if (props.hasOwnProperty('metadata')) {
      const v = props.metadata;
      this.metadata = v && new SpeedTestResultV3_Metadata(v);
    }

    this.ErrorString = '';
    if (props.hasOwnProperty('ErrorString')) {
      const v = props.ErrorString;
      this.ErrorString = v;
    }
  }

  getSpeedtest(): SpeedTest {
    if (this.speedtest) {
      return this.speedtest;
    }
    return new SpeedTest();
  }

  getResult(): Result {
    if (this.result) {
      return this.result;
    }
    return new Result();
  }

  getComputed(): Computed {
    if (this.computed) {
      return this.computed;
    }
    return new Computed();
  }

  getMetadata(): SpeedTestResultV3_Metadata {
    if (this.metadata) {
      return this.metadata;
    }
    return new SpeedTestResultV3_Metadata();
  }
}
export {SpeedTestResultV3};

class SpeedTestResultV3WithRoute {
  result: ?SpeedTestResultV3;
  route: ?cwn_network_routing.Route;

  constructor(props: $Shape<SpeedTestResultV3WithRoute> = {}): void {
    if (!props) {
      props = {};
    }

    this.result = null;
    if (props.hasOwnProperty('result')) {
      const v = props.result;
      this.result = v && new SpeedTestResultV3(v);
    }

    this.route = null;
    if (props.hasOwnProperty('route')) {
      const v = props.route;
      this.route = v && new cwn_network_routing.Route(v);
    }
  }

  getResult(): SpeedTestResultV3 {
    if (this.result) {
      return this.result;
    }
    return new SpeedTestResultV3();
  }

  getRoute(): cwn_network_routing.Route {
    if (this.route) {
      return this.route;
    }
    return new cwn_network_routing.Route();
  }
}
export {SpeedTestResultV3WithRoute};

class SpeedTest {
  receiver_ip: ?cwn_network.IP;
  sender_ip: ?cwn_network.IP;
  receiver_id: string;
  sender_id: string;
  receiver_is_source: boolean;
  sender_is_source: boolean;
  layer: string;
  type: string;
  receiver_ip_addr: ?cwn_network.IPAddr;
  sender_ip_addr: ?cwn_network.IPAddr;
  scheduled_run_time: ?Date;
  threads: number;
  subscription_id: string;

  constructor(props: $Shape<SpeedTest> = {}): void {
    if (!props) {
      props = {};
    }

    this.receiver_ip = null;
    if (props.hasOwnProperty('receiver_ip')) {
      const v = props.receiver_ip;
      this.receiver_ip = v && new cwn_network.IP(v);
    }

    this.sender_ip = null;
    if (props.hasOwnProperty('sender_ip')) {
      const v = props.sender_ip;
      this.sender_ip = v && new cwn_network.IP(v);
    }

    this.receiver_id = '';
    if (props.hasOwnProperty('receiver_id')) {
      const v = props.receiver_id;
      this.receiver_id = v;
    }

    this.sender_id = '';
    if (props.hasOwnProperty('sender_id')) {
      const v = props.sender_id;
      this.sender_id = v;
    }

    this.receiver_is_source = false;
    if (props.hasOwnProperty('receiver_is_source')) {
      const v = props.receiver_is_source;
      this.receiver_is_source = !!v;
    }

    this.sender_is_source = false;
    if (props.hasOwnProperty('sender_is_source')) {
      const v = props.sender_is_source;
      this.sender_is_source = !!v;
    }

    this.layer = '';
    if (props.hasOwnProperty('layer')) {
      const v = props.layer;
      this.layer = v;
    }

    this.type = '';
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.receiver_ip_addr = null;
    if (props.hasOwnProperty('receiver_ip_addr')) {
      const v = props.receiver_ip_addr;
      this.receiver_ip_addr = v && new cwn_network.IPAddr(v);
    }

    this.sender_ip_addr = null;
    if (props.hasOwnProperty('sender_ip_addr')) {
      const v = props.sender_ip_addr;
      this.sender_ip_addr = v && new cwn_network.IPAddr(v);
    }

    this.scheduled_run_time = null;
    if (props.hasOwnProperty('scheduled_run_time')) {
      const v = props.scheduled_run_time;
      this.scheduled_run_time = v && new Date(v);
    }

    this.threads = 0;
    if (props.hasOwnProperty('threads')) {
      const v = props.threads;
      this.threads = v;
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }
  }

  getReceiverIp(): cwn_network.IP {
    if (this.receiver_ip) {
      return this.receiver_ip;
    }
    return new cwn_network.IP();
  }

  getSenderIp(): cwn_network.IP {
    if (this.sender_ip) {
      return this.sender_ip;
    }
    return new cwn_network.IP();
  }

  getReceiverIpAddr(): cwn_network.IPAddr {
    if (this.receiver_ip_addr) {
      return this.receiver_ip_addr;
    }
    return new cwn_network.IPAddr();
  }

  getSenderIpAddr(): cwn_network.IPAddr {
    if (this.sender_ip_addr) {
      return this.sender_ip_addr;
    }
    return new cwn_network.IPAddr();
  }
}
export {SpeedTest};

class SpeedTestEvent {
  receiver_ip: string;
  sender_ip: string;
  receiver_ip_addr: string;
  sender_ip_addr: string;
  receiver_ip_addr_zone: string;
  sender_ip_addr_zone: string;
  receiver_id: string;
  sender_id: string;
  receiver_is_source: boolean;
  sender_is_source: boolean;
  scheduled_run_time: ?Date;
  event_time: ?Date;
  type: string;
  source: string;
  dry_run: boolean;
  test_type: string;
  layer: string;

  constructor(props: $Shape<SpeedTestEvent> = {}): void {
    if (!props) {
      props = {};
    }

    this.receiver_ip = '';
    if (props.hasOwnProperty('receiver_ip')) {
      const v = props.receiver_ip;
      this.receiver_ip = v;
    }

    this.sender_ip = '';
    if (props.hasOwnProperty('sender_ip')) {
      const v = props.sender_ip;
      this.sender_ip = v;
    }

    this.receiver_ip_addr = '';
    if (props.hasOwnProperty('receiver_ip_addr')) {
      const v = props.receiver_ip_addr;
      this.receiver_ip_addr = v;
    }

    this.sender_ip_addr = '';
    if (props.hasOwnProperty('sender_ip_addr')) {
      const v = props.sender_ip_addr;
      this.sender_ip_addr = v;
    }

    this.receiver_ip_addr_zone = '';
    if (props.hasOwnProperty('receiver_ip_addr_zone')) {
      const v = props.receiver_ip_addr_zone;
      this.receiver_ip_addr_zone = v;
    }

    this.sender_ip_addr_zone = '';
    if (props.hasOwnProperty('sender_ip_addr_zone')) {
      const v = props.sender_ip_addr_zone;
      this.sender_ip_addr_zone = v;
    }

    this.receiver_id = '';
    if (props.hasOwnProperty('receiver_id')) {
      const v = props.receiver_id;
      this.receiver_id = v;
    }

    this.sender_id = '';
    if (props.hasOwnProperty('sender_id')) {
      const v = props.sender_id;
      this.sender_id = v;
    }

    this.receiver_is_source = false;
    if (props.hasOwnProperty('receiver_is_source')) {
      const v = props.receiver_is_source;
      this.receiver_is_source = !!v;
    }

    this.sender_is_source = false;
    if (props.hasOwnProperty('sender_is_source')) {
      const v = props.sender_is_source;
      this.sender_is_source = !!v;
    }

    this.scheduled_run_time = null;
    if (props.hasOwnProperty('scheduled_run_time')) {
      const v = props.scheduled_run_time;
      this.scheduled_run_time = v && new Date(v);
    }

    this.event_time = null;
    if (props.hasOwnProperty('event_time')) {
      const v = props.event_time;
      this.event_time = v && new Date(v);
    }

    this.type = '';
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.source = '';
    if (props.hasOwnProperty('source')) {
      const v = props.source;
      this.source = v;
    }

    this.dry_run = false;
    if (props.hasOwnProperty('dry_run')) {
      const v = props.dry_run;
      this.dry_run = !!v;
    }

    this.test_type = '';
    if (props.hasOwnProperty('test_type')) {
      const v = props.test_type;
      this.test_type = v;
    }

    this.layer = '';
    if (props.hasOwnProperty('layer')) {
      const v = props.layer;
      this.layer = v;
    }
  }
}
export {SpeedTestEvent};

class Result_InterfaceByteCounter {
  interface_name: string;
  tx_bytes: string;
  rx_bytes: string;

  constructor(props: $Shape<Result_InterfaceByteCounter> = {}): void {
    if (!props) {
      props = {};
    }

    this.interface_name = '';
    if (props.hasOwnProperty('interface_name')) {
      const v = props.interface_name;
      this.interface_name = v;
    }

    this.tx_bytes = '0';
    if (props.hasOwnProperty('tx_bytes')) {
      const v = props.tx_bytes;
      this.tx_bytes = v;
    }

    this.rx_bytes = '0';
    if (props.hasOwnProperty('rx_bytes')) {
      const v = props.rx_bytes;
      this.rx_bytes = v;
    }
  }
}
export {Result_InterfaceByteCounter};
class Result {
  duration: number;
  bytes: string;
  interface_byte_counters: Array<Result_InterfaceByteCounter>;
  adjusted_bytes: string;
  samples: Array<string>;
  sample_window_size_ms: string;
  active_interface: string;
  capacity_bytes: string;
  duration_ns: string;
  tcp_info: ?cwn_network_sys.TCPInfo;

  constructor(props: $Shape<Result> = {}): void {
    if (!props) {
      props = {};
    }

    this.duration = 0;
    if (props.hasOwnProperty('duration')) {
      const v = props.duration;
      this.duration = v;
    }

    this.bytes = '0';
    if (props.hasOwnProperty('bytes')) {
      const v = props.bytes;
      this.bytes = v;
    }

    this.interface_byte_counters = [];
    if (props.hasOwnProperty('interface_byte_counters')) {
      const v = props.interface_byte_counters;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field interface_byte_counters should be array',
        );
      }
      this.interface_byte_counters = v.map(function(v) {
        return new Result_InterfaceByteCounter(v);
      });
    }

    this.adjusted_bytes = '0';
    if (props.hasOwnProperty('adjusted_bytes')) {
      const v = props.adjusted_bytes;
      this.adjusted_bytes = v;
    }

    this.samples = [];
    if (props.hasOwnProperty('samples')) {
      const v = props.samples;
      if (!Array.isArray(v)) {
        throw new Error('repeated field samples should be array');
      }
      this.samples = v.map(function(v) {
        return v;
      });
    }

    this.sample_window_size_ms = '0';
    if (props.hasOwnProperty('sample_window_size_ms')) {
      const v = props.sample_window_size_ms;
      this.sample_window_size_ms = v;
    }

    this.active_interface = '';
    if (props.hasOwnProperty('active_interface')) {
      const v = props.active_interface;
      this.active_interface = v;
    }

    this.capacity_bytes = '0';
    if (props.hasOwnProperty('capacity_bytes')) {
      const v = props.capacity_bytes;
      this.capacity_bytes = v;
    }

    this.duration_ns = '0';
    if (props.hasOwnProperty('duration_ns')) {
      const v = props.duration_ns;
      this.duration_ns = v;
    }

    this.tcp_info = null;
    if (props.hasOwnProperty('tcp_info')) {
      const v = props.tcp_info;
      this.tcp_info = v && new cwn_network_sys.TCPInfo(v);
    }
  }

  getTcpInfo(): cwn_network_sys.TCPInfo {
    if (this.tcp_info) {
      return this.tcp_info;
    }
    return new cwn_network_sys.TCPInfo();
  }
}
export {Result};

class Computed {
  mbps: number;
  trimmed_mbps: number;
  adjusted_mbps: number;
  trimmed_adjusted_mbps: number;
  capacity_mbps: number;
  trimmed_capacity_mbps: number;

  constructor(props: $Shape<Computed> = {}): void {
    if (!props) {
      props = {};
    }

    this.mbps = 0;
    if (props.hasOwnProperty('mbps')) {
      const v = props.mbps;
      this.mbps = v;
    }

    this.trimmed_mbps = 0;
    if (props.hasOwnProperty('trimmed_mbps')) {
      const v = props.trimmed_mbps;
      this.trimmed_mbps = v;
    }

    this.adjusted_mbps = 0;
    if (props.hasOwnProperty('adjusted_mbps')) {
      const v = props.adjusted_mbps;
      this.adjusted_mbps = v;
    }

    this.trimmed_adjusted_mbps = 0;
    if (props.hasOwnProperty('trimmed_adjusted_mbps')) {
      const v = props.trimmed_adjusted_mbps;
      this.trimmed_adjusted_mbps = v;
    }

    this.capacity_mbps = 0;
    if (props.hasOwnProperty('capacity_mbps')) {
      const v = props.capacity_mbps;
      this.capacity_mbps = v;
    }

    this.trimmed_capacity_mbps = 0;
    if (props.hasOwnProperty('trimmed_capacity_mbps')) {
      const v = props.trimmed_capacity_mbps;
      this.trimmed_capacity_mbps = v;
    }
  }
}
export {Computed};

class SpeedTestQueue {
  device_id: string;
  queue: Array<SpeedTest>;

  constructor(props: $Shape<SpeedTestQueue> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.queue = [];
    if (props.hasOwnProperty('queue')) {
      const v = props.queue;
      if (!Array.isArray(v)) {
        throw new Error('repeated field queue should be array');
      }
      this.queue = v.map(function(v) {
        return new SpeedTest(v);
      });
    }
  }
}
export {SpeedTestQueue};

class AuditAgentSpeedTestStart {
  direction: Direction;
  server: string;
  network: string;
  address: string;

  constructor(props: $Shape<AuditAgentSpeedTestStart> = {}): void {
    if (!props) {
      props = {};
    }

    this.direction = DirectionValue(0);
    if (props.hasOwnProperty('direction')) {
      const v = props.direction;
      this.direction = v;
    }

    this.server = '';
    if (props.hasOwnProperty('server')) {
      const v = props.server;
      this.server = v;
    }

    this.network = '';
    if (props.hasOwnProperty('network')) {
      const v = props.network;
      this.network = v;
    }

    this.address = '';
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v;
    }
  }
}
export {AuditAgentSpeedTestStart};

class AuditAgentSpeedTestEnd {
  direction: Direction;
  server: string;
  result: ?Computed;
  network: string;
  address: string;

  constructor(props: $Shape<AuditAgentSpeedTestEnd> = {}): void {
    if (!props) {
      props = {};
    }

    this.direction = DirectionValue(0);
    if (props.hasOwnProperty('direction')) {
      const v = props.direction;
      this.direction = v;
    }

    this.server = '';
    if (props.hasOwnProperty('server')) {
      const v = props.server;
      this.server = v;
    }

    this.result = null;
    if (props.hasOwnProperty('result')) {
      const v = props.result;
      this.result = v && new Computed(v);
    }

    this.network = '';
    if (props.hasOwnProperty('network')) {
      const v = props.network;
      this.network = v;
    }

    this.address = '';
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v;
    }
  }

  getResult(): Computed {
    if (this.result) {
      return this.result;
    }
    return new Computed();
  }
}
export {AuditAgentSpeedTestEnd};

class DeviceSpeedTest {
  speedtests: Array<SpeedTest>;
  device_id: string;
  source_id: string;

  constructor(props: $Shape<DeviceSpeedTest> = {}): void {
    if (!props) {
      props = {};
    }

    this.speedtests = [];
    if (props.hasOwnProperty('speedtests')) {
      const v = props.speedtests;
      if (!Array.isArray(v)) {
        throw new Error('repeated field speedtests should be array');
      }
      this.speedtests = v.map(function(v) {
        return new SpeedTest(v);
      });
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.source_id = '';
    if (props.hasOwnProperty('source_id')) {
      const v = props.source_id;
      this.source_id = v;
    }
  }
}
export {DeviceSpeedTest};

// END cwn/speedtest/models.proto
