// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_ubnt_unifi from '../../cwn/ubnt/unifi';
import * as cwn_tech from '../../cwn/tech';
import * as cwn_stripe from '../../cwn/stripe';
import * as cwn_site from '../../cwn/site';
import * as cwn_signup from '../../cwn/signup';
import * as cwn_partner from '../../cwn/partner';
import * as cwn_log from '../../cwn/log';
import * as cwn_lead from '../../cwn/lead';
import * as cwn_han from '../../cwn/han';
import * as cwn_geocoder from '../../cwn/geocoder';
import * as cwn_customers from '../../cwn/customers';
import * as cwn_billing from '../../cwn/billing';
import * as cwn_auth from '../../cwn/auth';
import * as cwn_analytics from '../../cwn/analytics';

// START cwn/web/authenticated_service.proto

class CheckTokenReply {
  uid: string;

  constructor(props: $Shape<CheckTokenReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.uid = '';
    if (props.hasOwnProperty('uid')) {
      const v = props.uid;
      this.uid = v;
    }
  }
}
export {CheckTokenReply};

class GetCustomerReply {
  customer: ?cwn_customers.Customer;
  account: ?cwn_auth.Account;
  referral_link: string;

  constructor(props: $Shape<GetCustomerReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.customer = null;
    if (props.hasOwnProperty('customer')) {
      const v = props.customer;
      this.customer = v && new cwn_customers.Customer(v);
    }

    this.account = null;
    if (props.hasOwnProperty('account')) {
      const v = props.account;
      this.account = v && new cwn_auth.Account(v);
    }

    this.referral_link = '';
    if (props.hasOwnProperty('referral_link')) {
      const v = props.referral_link;
      this.referral_link = v;
    }
  }

  getCustomer(): cwn_customers.Customer {
    if (this.customer) {
      return this.customer;
    }
    return new cwn_customers.Customer();
  }

  getAccount(): cwn_auth.Account {
    if (this.account) {
      return this.account;
    }
    return new cwn_auth.Account();
  }
}
export {GetCustomerReply};

class SubscriptionPlan {
  subscription_id: string;
  signup_id: string;
  service_address: ?cwn_customers.Address;
  instrument: ?cwn_billing.Instrument;
  created: ?Date;
  start_date: ?Date;
  coupons: Array<cwn_billing.Coupon>;

  constructor(props: $Shape<SubscriptionPlan> = {}): void {
    if (!props) {
      props = {};
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }

    this.signup_id = '0';
    if (props.hasOwnProperty('signup_id')) {
      const v = props.signup_id;
      this.signup_id = v;
    }

    this.service_address = null;
    if (props.hasOwnProperty('service_address')) {
      const v = props.service_address;
      this.service_address = v && new cwn_customers.Address(v);
    }

    this.instrument = null;
    if (props.hasOwnProperty('instrument')) {
      const v = props.instrument;
      this.instrument = v && new cwn_billing.Instrument(v);
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.start_date = null;
    if (props.hasOwnProperty('start_date')) {
      const v = props.start_date;
      this.start_date = v && new Date(v);
    }

    this.coupons = [];
    if (props.hasOwnProperty('coupons')) {
      const v = props.coupons;
      if (!Array.isArray(v)) {
        throw new Error('repeated field coupons should be array');
      }
      this.coupons = v.map(function(v) {
        return new cwn_billing.Coupon(v);
      });
    }
  }

  getServiceAddress(): cwn_customers.Address {
    if (this.service_address) {
      return this.service_address;
    }
    return new cwn_customers.Address();
  }

  getInstrument(): cwn_billing.Instrument {
    if (this.instrument) {
      return this.instrument;
    }
    return new cwn_billing.Instrument();
  }
}
export {SubscriptionPlan};

class SetPhoneRequest {
  mobile: string;

  constructor(props: $Shape<SetPhoneRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.mobile = '';
    if (props.hasOwnProperty('mobile')) {
      const v = props.mobile;
      this.mobile = v;
    }
  }
}
export {SetPhoneRequest};

class SetAccountDetailsRequest {
  name: string;
  phone: string;
  password: string;

  constructor(props: $Shape<SetAccountDetailsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.phone = '';
    if (props.hasOwnProperty('phone')) {
      const v = props.phone;
      this.phone = v;
    }

    this.password = '';
    if (props.hasOwnProperty('password')) {
      const v = props.password;
      this.password = v;
    }
  }
}
export {SetAccountDetailsRequest};

class ListInvoicesRequest {
  subscription_id: string;

  constructor(props: $Shape<ListInvoicesRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.subscription_id = '0';
    if (props.hasOwnProperty('subscription_id')) {
      const v = props.subscription_id;
      this.subscription_id = v;
    }
  }
}
export {ListInvoicesRequest};

class ListInvoicesReply {
  invoices: Array<cwn_billing.Invoice>;

  constructor(props: $Shape<ListInvoicesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.invoices = [];
    if (props.hasOwnProperty('invoices')) {
      const v = props.invoices;
      if (!Array.isArray(v)) {
        throw new Error('repeated field invoices should be array');
      }
      this.invoices = v.map(function(v) {
        return new cwn_billing.Invoice(v);
      });
    }
  }
}
export {ListInvoicesReply};

class GetLedgerCredentialRequest {
  ledger_or_subscription_id: string;

  constructor(props: $Shape<GetLedgerCredentialRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.ledger_or_subscription_id = '0';
    if (props.hasOwnProperty('ledger_or_subscription_id')) {
      const v = props.ledger_or_subscription_id;
      this.ledger_or_subscription_id = v;
    }
  }
}
export {GetLedgerCredentialRequest};

class GetLedgerCredentialReply {
  id: string;

  constructor(props: $Shape<GetLedgerCredentialReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }
  }
}
export {GetLedgerCredentialReply};

class GetCustomerInfoReply_CustomerInfo {
  id: string;
  name: string;
  email: string;
  disable_sms: boolean;
  disable_marketing_email: boolean;
  referral_link: string;
  phone_number: string;

  constructor(props: $Shape<GetCustomerInfoReply_CustomerInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.disable_sms = false;
    if (props.hasOwnProperty('disable_sms')) {
      const v = props.disable_sms;
      this.disable_sms = !!v;
    }

    this.disable_marketing_email = false;
    if (props.hasOwnProperty('disable_marketing_email')) {
      const v = props.disable_marketing_email;
      this.disable_marketing_email = !!v;
    }

    this.referral_link = '';
    if (props.hasOwnProperty('referral_link')) {
      const v = props.referral_link;
      this.referral_link = v;
    }

    this.phone_number = '';
    if (props.hasOwnProperty('phone_number')) {
      const v = props.phone_number;
      this.phone_number = v;
    }
  }
}
export {GetCustomerInfoReply_CustomerInfo};
class GetCustomerInfoReply {
  customer_info: ?GetCustomerInfoReply_CustomerInfo;

  constructor(props: $Shape<GetCustomerInfoReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.customer_info = null;
    if (props.hasOwnProperty('customer_info')) {
      const v = props.customer_info;
      this.customer_info = v && new GetCustomerInfoReply_CustomerInfo(v);
    }
  }

  getCustomerInfo(): GetCustomerInfoReply_CustomerInfo {
    if (this.customer_info) {
      return this.customer_info;
    }
    return new GetCustomerInfoReply_CustomerInfo();
  }
}
export {GetCustomerInfoReply};

class InternetAddress_LedgerInfo {
  statements: Array<cwn_billing.LedgerStatement>;
  in_arrears: boolean;
  scheduled_payment: ?cwn_billing.ScheduledPayment;

  constructor(props: $Shape<InternetAddress_LedgerInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.statements = [];
    if (props.hasOwnProperty('statements')) {
      const v = props.statements;
      if (!Array.isArray(v)) {
        throw new Error('repeated field statements should be array');
      }
      this.statements = v.map(function(v) {
        return new cwn_billing.LedgerStatement(v);
      });
    }

    this.in_arrears = false;
    if (props.hasOwnProperty('in_arrears')) {
      const v = props.in_arrears;
      this.in_arrears = !!v;
    }

    this.scheduled_payment = null;
    if (props.hasOwnProperty('scheduled_payment')) {
      const v = props.scheduled_payment;
      this.scheduled_payment = v && new cwn_billing.ScheduledPayment(v);
    }
  }

  getScheduledPayment(): cwn_billing.ScheduledPayment {
    if (this.scheduled_payment) {
      return this.scheduled_payment;
    }
    return new cwn_billing.ScheduledPayment();
  }
}
export {InternetAddress_LedgerInfo};
class InternetAddress_SubscriptionInfo {
  id: string;
  state: cwn_customers.Subscription_State;
  ledger_info: ?InternetAddress_LedgerInfo;
  plan: cwn_customers.Subscription_Plan;

  constructor(props: $Shape<InternetAddress_SubscriptionInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.state = cwn_customers.Subscription_StateValue(0);
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }

    this.ledger_info = null;
    if (props.hasOwnProperty('ledger_info')) {
      const v = props.ledger_info;
      this.ledger_info = v && new InternetAddress_LedgerInfo(v);
    }

    this.plan = cwn_customers.Subscription_PlanValue(0);
    if (props.hasOwnProperty('plan')) {
      const v = props.plan;
      this.plan = v;
    }
  }

  getLedgerInfo(): InternetAddress_LedgerInfo {
    if (this.ledger_info) {
      return this.ledger_info;
    }
    return new InternetAddress_LedgerInfo();
  }
}
export {InternetAddress_SubscriptionInfo};
class InternetAddress_LeadInfo {
  id: string;
  state: cwn_lead.Lead_State;
  reason_for_rejection: cwn_lead.Lead_ReasonForRejection;
  plan: cwn_customers.Subscription_Plan;

  constructor(props: $Shape<InternetAddress_LeadInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.state = cwn_lead.Lead_StateValue(0);
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }

    this.reason_for_rejection = cwn_lead.Lead_ReasonForRejectionValue(0);
    if (props.hasOwnProperty('reason_for_rejection')) {
      const v = props.reason_for_rejection;
      this.reason_for_rejection = v;
    }

    this.plan = cwn_customers.Subscription_PlanValue(0);
    if (props.hasOwnProperty('plan')) {
      const v = props.plan;
      this.plan = v;
    }
  }
}
export {InternetAddress_LeadInfo};
class InternetAddress_AppointmentInfo {
  id: string;
  datetime: string;

  constructor(props: $Shape<InternetAddress_AppointmentInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.datetime = '';
    if (props.hasOwnProperty('datetime')) {
      const v = props.datetime;
      this.datetime = v;
    }
  }
}
export {InternetAddress_AppointmentInfo};
class InternetAddress_HanInfo {
  id: string;
  ap_macs: Array<string>;
  wlans: Array<cwn_han.Wlan>;
  controller: string;
  status_by_mac: {[key: string]: ?cwn_ubnt_unifi.Status};
  eero_wlans: Array<cwn_han.Wlan>;
  eero_serial_numbers: Array<string>;

  constructor(props: $Shape<InternetAddress_HanInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.ap_macs = [];
    if (props.hasOwnProperty('ap_macs')) {
      const v = props.ap_macs;
      if (!Array.isArray(v)) {
        throw new Error('repeated field ap_macs should be array');
      }
      this.ap_macs = v.map(function(v) {
        return v;
      });
    }

    this.wlans = [];
    if (props.hasOwnProperty('wlans')) {
      const v = props.wlans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field wlans should be array');
      }
      this.wlans = v.map(function(v) {
        return new cwn_han.Wlan(v);
      });
    }

    this.controller = '';
    if (props.hasOwnProperty('controller')) {
      const v = props.controller;
      this.controller = v;
    }

    this.status_by_mac = {};
    if (props.hasOwnProperty('status_by_mac')) {
      const v = props.status_by_mac;
      for (const key: any of Object.keys(v)) {
        this.status_by_mac[key] = new cwn_ubnt_unifi.Status((v[key]: any));
      }
    }

    this.eero_wlans = [];
    if (props.hasOwnProperty('eero_wlans')) {
      const v = props.eero_wlans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field eero_wlans should be array');
      }
      this.eero_wlans = v.map(function(v) {
        return new cwn_han.Wlan(v);
      });
    }

    this.eero_serial_numbers = [];
    if (props.hasOwnProperty('eero_serial_numbers')) {
      const v = props.eero_serial_numbers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field eero_serial_numbers should be array');
      }
      this.eero_serial_numbers = v.map(function(v) {
        return v;
      });
    }
  }
}
export {InternetAddress_HanInfo};
class InternetAddress {
  subscription_info: ?InternetAddress_SubscriptionInfo;
  lead_info: ?InternetAddress_LeadInfo;
  appointment_info: ?InternetAddress_AppointmentInfo;
  address: ?cwn_customers.Address;
  geocoded_location: ?cwn_geocoder.GeoPoint;
  raw_address: string;
  instrument: ?cwn_billing.Instrument;
  available_plans: Array<cwn_customers.Subscription_Plan>;
  han_info: ?InternetAddress_HanInfo;

  constructor(props: $Shape<InternetAddress> = {}): void {
    if (!props) {
      props = {};
    }

    this.subscription_info = null;
    if (props.hasOwnProperty('subscription_info')) {
      const v = props.subscription_info;
      this.subscription_info = v && new InternetAddress_SubscriptionInfo(v);
    }

    this.lead_info = null;
    if (props.hasOwnProperty('lead_info')) {
      const v = props.lead_info;
      this.lead_info = v && new InternetAddress_LeadInfo(v);
    }

    this.appointment_info = null;
    if (props.hasOwnProperty('appointment_info')) {
      const v = props.appointment_info;
      this.appointment_info = v && new InternetAddress_AppointmentInfo(v);
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.geocoded_location = null;
    if (props.hasOwnProperty('geocoded_location')) {
      const v = props.geocoded_location;
      this.geocoded_location = v && new cwn_geocoder.GeoPoint(v);
    }

    this.raw_address = '';
    if (props.hasOwnProperty('raw_address')) {
      const v = props.raw_address;
      this.raw_address = v;
    }

    this.instrument = null;
    if (props.hasOwnProperty('instrument')) {
      const v = props.instrument;
      this.instrument = v && new cwn_billing.Instrument(v);
    }

    this.available_plans = [];
    if (props.hasOwnProperty('available_plans')) {
      const v = props.available_plans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field available_plans should be array');
      }
      this.available_plans = v.map(function(v) {
        return v;
      });
    }

    this.han_info = null;
    if (props.hasOwnProperty('han_info')) {
      const v = props.han_info;
      this.han_info = v && new InternetAddress_HanInfo(v);
    }
  }

  getSubscriptionInfo(): InternetAddress_SubscriptionInfo {
    if (this.subscription_info) {
      return this.subscription_info;
    }
    return new InternetAddress_SubscriptionInfo();
  }

  getLeadInfo(): InternetAddress_LeadInfo {
    if (this.lead_info) {
      return this.lead_info;
    }
    return new InternetAddress_LeadInfo();
  }

  getAppointmentInfo(): InternetAddress_AppointmentInfo {
    if (this.appointment_info) {
      return this.appointment_info;
    }
    return new InternetAddress_AppointmentInfo();
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }

  getGeocodedLocation(): cwn_geocoder.GeoPoint {
    if (this.geocoded_location) {
      return this.geocoded_location;
    }
    return new cwn_geocoder.GeoPoint();
  }

  getInstrument(): cwn_billing.Instrument {
    if (this.instrument) {
      return this.instrument;
    }
    return new cwn_billing.Instrument();
  }

  getHanInfo(): InternetAddress_HanInfo {
    if (this.han_info) {
      return this.han_info;
    }
    return new InternetAddress_HanInfo();
  }
}
export {InternetAddress};

class GetInternetAddressesReply {
  internet_addresses: Array<InternetAddress>;

  constructor(props: $Shape<GetInternetAddressesReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.internet_addresses = [];
    if (props.hasOwnProperty('internet_addresses')) {
      const v = props.internet_addresses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field internet_addresses should be array');
      }
      this.internet_addresses = v.map(function(v) {
        return new InternetAddress(v);
      });
    }
  }
}
export {GetInternetAddressesReply};

class RemoveAddressRequest {
  internet_address: ?InternetAddress;

  constructor(props: $Shape<RemoveAddressRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.internet_address = null;
    if (props.hasOwnProperty('internet_address')) {
      const v = props.internet_address;
      this.internet_address = v && new InternetAddress(v);
    }
  }

  getInternetAddress(): InternetAddress {
    if (this.internet_address) {
      return this.internet_address;
    }
    return new InternetAddress();
  }
}
export {RemoveAddressRequest};

class CancelAppointmentForAddressRequest {
  internet_address: ?InternetAddress;
  closed_reason: cwn_tech.FlexibleWorkOrder_ClosedReason;
  closed_reason_other: string;

  constructor(props: $Shape<CancelAppointmentForAddressRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.internet_address = null;
    if (props.hasOwnProperty('internet_address')) {
      const v = props.internet_address;
      this.internet_address = v && new InternetAddress(v);
    }

    this.closed_reason = cwn_tech.FlexibleWorkOrder_ClosedReasonValue(0);
    if (props.hasOwnProperty('closed_reason')) {
      const v = props.closed_reason;
      this.closed_reason = v;
    }

    this.closed_reason_other = '';
    if (props.hasOwnProperty('closed_reason_other')) {
      const v = props.closed_reason_other;
      this.closed_reason_other = v;
    }
  }

  getInternetAddress(): InternetAddress {
    if (this.internet_address) {
      return this.internet_address;
    }
    return new InternetAddress();
  }
}
export {CancelAppointmentForAddressRequest};

class ListAppointmentsForDayRequest {
  internet_address: ?InternetAddress;
  day: ?Date;

  constructor(props: $Shape<ListAppointmentsForDayRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.internet_address = null;
    if (props.hasOwnProperty('internet_address')) {
      const v = props.internet_address;
      this.internet_address = v && new InternetAddress(v);
    }

    this.day = null;
    if (props.hasOwnProperty('day')) {
      const v = props.day;
      this.day = v && new Date(v);
    }
  }

  getInternetAddress(): InternetAddress {
    if (this.internet_address) {
      return this.internet_address;
    }
    return new InternetAddress();
  }
}
export {ListAppointmentsForDayRequest};

class ListAppointmentsForDayReply {
  appointments: Array<Date>;

  constructor(props: $Shape<ListAppointmentsForDayReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.appointments = [];
    if (props.hasOwnProperty('appointments')) {
      const v = props.appointments;
      if (!Array.isArray(v)) {
        throw new Error('repeated field appointments should be array');
      }
      this.appointments = v.map(function(v) {
        return new Date(v);
      });
    }
  }
}
export {ListAppointmentsForDayReply};

class GetAPStateRequest {
  mac: string;

  constructor(props: $Shape<GetAPStateRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.mac = '';
    if (props.hasOwnProperty('mac')) {
      const v = props.mac;
      this.mac = v;
    }
  }
}
export {GetAPStateRequest};

class GetAPStateReply {
  state: cwn_ubnt_unifi.Status_State;

  constructor(props: $Shape<GetAPStateReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.state = cwn_ubnt_unifi.Status_StateValue(0);
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }
  }
}
export {GetAPStateReply};

class UpdateWlansRequest {
  han_id: string;
  wlans: Array<cwn_han.Wlan>;
  confirmed: boolean;

  constructor(props: $Shape<UpdateWlansRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.han_id = '0';
    if (props.hasOwnProperty('han_id')) {
      const v = props.han_id;
      this.han_id = v;
    }

    this.wlans = [];
    if (props.hasOwnProperty('wlans')) {
      const v = props.wlans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field wlans should be array');
      }
      this.wlans = v.map(function(v) {
        return new cwn_han.Wlan(v);
      });
    }

    this.confirmed = false;
    if (props.hasOwnProperty('confirmed')) {
      const v = props.confirmed;
      this.confirmed = !!v;
    }
  }
}
export {UpdateWlansRequest};

class UpdateWlansReply {
  error: string;
  wlans: Array<cwn_han.Wlan>;
  confirm_messages: Array<string>;

  constructor(props: $Shape<UpdateWlansReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.error = '';
    if (props.hasOwnProperty('error')) {
      const v = props.error;
      this.error = v;
    }

    this.wlans = [];
    if (props.hasOwnProperty('wlans')) {
      const v = props.wlans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field wlans should be array');
      }
      this.wlans = v.map(function(v) {
        return new cwn_han.Wlan(v);
      });
    }

    this.confirm_messages = [];
    if (props.hasOwnProperty('confirm_messages')) {
      const v = props.confirm_messages;
      if (!Array.isArray(v)) {
        throw new Error('repeated field confirm_messages should be array');
      }
      this.confirm_messages = v.map(function(v) {
        return v;
      });
    }
  }
}
export {UpdateWlansReply};

class GetInstrumentsReply {
  instruments: Array<cwn_billing.Instrument>;

  constructor(props: $Shape<GetInstrumentsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.instruments = [];
    if (props.hasOwnProperty('instruments')) {
      const v = props.instruments;
      if (!Array.isArray(v)) {
        throw new Error('repeated field instruments should be array');
      }
      this.instruments = v.map(function(v) {
        return new cwn_billing.Instrument(v);
      });
    }
  }
}
export {GetInstrumentsReply};

class AddInstrumentRequest {
  stripe_token_id: string;

  constructor(props: $Shape<AddInstrumentRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.stripe_token_id = '';
    if (props.hasOwnProperty('stripe_token_id')) {
      const v = props.stripe_token_id;
      this.stripe_token_id = v;
    }
  }
}
export {AddInstrumentRequest};

class AddInstrumentReply {
  stripe_error: string;
  instrument: ?cwn_billing.Instrument;

  constructor(props: $Shape<AddInstrumentReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.stripe_error = '';
    if (props.hasOwnProperty('stripe_error')) {
      const v = props.stripe_error;
      this.stripe_error = v;
    }

    this.instrument = null;
    if (props.hasOwnProperty('instrument')) {
      const v = props.instrument;
      this.instrument = v && new cwn_billing.Instrument(v);
    }
  }

  getInstrument(): cwn_billing.Instrument {
    if (this.instrument) {
      return this.instrument;
    }
    return new cwn_billing.Instrument();
  }
}
export {AddInstrumentReply};

class DeleteInstrumentRequest {
  instrument_id: string;

  constructor(props: $Shape<DeleteInstrumentRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.instrument_id = '0';
    if (props.hasOwnProperty('instrument_id')) {
      const v = props.instrument_id;
      this.instrument_id = v;
    }
  }
}
export {DeleteInstrumentRequest};

class DeleteInstrumentReply {
  stripe_error: string;

  constructor(props: $Shape<DeleteInstrumentReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.stripe_error = '';
    if (props.hasOwnProperty('stripe_error')) {
      const v = props.stripe_error;
      this.stripe_error = v;
    }
  }
}
export {DeleteInstrumentReply};

class AddAddressRequest_Form {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  buildingType: cwn_site.Site_BuildingType;
  numberOfUnits: cwn_site.Site_NumberOfUnits;

  constructor(props: $Shape<AddAddressRequest_Form> = {}): void {
    if (!props) {
      props = {};
    }

    this.addressLine1 = '';
    if (props.hasOwnProperty('addressLine1')) {
      const v = props.addressLine1;
      this.addressLine1 = v;
    }

    this.addressLine2 = '';
    if (props.hasOwnProperty('addressLine2')) {
      const v = props.addressLine2;
      this.addressLine2 = v;
    }

    this.city = '';
    if (props.hasOwnProperty('city')) {
      const v = props.city;
      this.city = v;
    }

    this.state = '';
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }

    this.zip = '';
    if (props.hasOwnProperty('zip')) {
      const v = props.zip;
      this.zip = v;
    }

    this.buildingType = cwn_site.Site_BuildingTypeValue(0);
    if (props.hasOwnProperty('buildingType')) {
      const v = props.buildingType;
      this.buildingType = v;
    }

    this.numberOfUnits = cwn_site.Site_NumberOfUnitsValue(0);
    if (props.hasOwnProperty('numberOfUnits')) {
      const v = props.numberOfUnits;
      this.numberOfUnits = v;
    }
  }
}
export {AddAddressRequest_Form};
class AddAddressRequest {
  form: ?AddAddressRequest_Form;
  geocodedAddress: ?cwn_customers.Address;
  geocodedLatLng: ?cwn_geocoder.GeoPoint;

  constructor(props: $Shape<AddAddressRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.form = null;
    if (props.hasOwnProperty('form')) {
      const v = props.form;
      this.form = v && new AddAddressRequest_Form(v);
    }

    this.geocodedAddress = null;
    if (props.hasOwnProperty('geocodedAddress')) {
      const v = props.geocodedAddress;
      this.geocodedAddress = v && new cwn_customers.Address(v);
    }

    this.geocodedLatLng = null;
    if (props.hasOwnProperty('geocodedLatLng')) {
      const v = props.geocodedLatLng;
      this.geocodedLatLng = v && new cwn_geocoder.GeoPoint(v);
    }
  }

  getForm(): AddAddressRequest_Form {
    if (this.form) {
      return this.form;
    }
    return new AddAddressRequest_Form();
  }

  getGeocodedAddress(): cwn_customers.Address {
    if (this.geocodedAddress) {
      return this.geocodedAddress;
    }
    return new cwn_customers.Address();
  }

  getGeocodedLatLng(): cwn_geocoder.GeoPoint {
    if (this.geocodedLatLng) {
      return this.geocodedLatLng;
    }
    return new cwn_geocoder.GeoPoint();
  }
}
export {AddAddressRequest};

export const AddAddressReply_ResultValues = {
  INVALID: 0,
  FORM_ERROR: 1,
  CREATED_WITH_ERROR: 2,
  OK: 3,
};
type AddAddressReply_Result = $Keys<typeof AddAddressReply_ResultValues>;
export type {AddAddressReply_Result};

export function AddAddressReply_ResultValue(n: number): AddAddressReply_Result {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'FORM_ERROR';

    case 2:
      return 'CREATED_WITH_ERROR';

    case 3:
      return 'OK';

    default:
      return 'INVALID';
  }
}

class AddAddressReply {
  result: AddAddressReply_Result;
  form_error: string;
  cookie: string;

  constructor(props: $Shape<AddAddressReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.result = AddAddressReply_ResultValue(0);
    if (props.hasOwnProperty('result')) {
      const v = props.result;
      this.result = v;
    }

    this.form_error = '';
    if (props.hasOwnProperty('form_error')) {
      const v = props.form_error;
      this.form_error = v;
    }

    this.cookie = '';
    if (props.hasOwnProperty('cookie')) {
      const v = props.cookie;
      this.cookie = v;
    }
  }
}
export {AddAddressReply};

class SetInstrumentForAddressRequest {
  internet_address: ?InternetAddress;

  constructor(props: $Shape<SetInstrumentForAddressRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.internet_address = null;
    if (props.hasOwnProperty('internet_address')) {
      const v = props.internet_address;
      this.internet_address = v && new InternetAddress(v);
    }
  }

  getInternetAddress(): InternetAddress {
    if (this.internet_address) {
      return this.internet_address;
    }
    return new InternetAddress();
  }
}
export {SetInstrumentForAddressRequest};

class SetInstrumentForAddressReply {
  error: string;

  constructor(props: $Shape<SetInstrumentForAddressReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.error = '';
    if (props.hasOwnProperty('error')) {
      const v = props.error;
      this.error = v;
    }
  }
}
export {SetInstrumentForAddressReply};

class GenerateCookieForLeadRequest {
  lead_id: string;

  constructor(props: $Shape<GenerateCookieForLeadRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }
  }
}
export {GenerateCookieForLeadRequest};

class GenerateCookieForLeadReply {
  cookie: string;

  constructor(props: $Shape<GenerateCookieForLeadReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.cookie = '';
    if (props.hasOwnProperty('cookie')) {
      const v = props.cookie;
      this.cookie = v;
    }
  }
}
export {GenerateCookieForLeadReply};

class AuthenticatedService {
  baseURL: string;
  additionalHeaders: Array<Array<string>>;
  headersProvider: ?() => Array<Array<string>>;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.additionalHeaders = [];
  }

  async call<Req, Res>(
    method: string,
    req: Req,
    ResClass: Class<Res>,
  ): Promise<Res> {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    this.additionalHeaders.forEach(function(h) {
      headers.append(h[0], h[1]);
    });

    if (this.headersProvider) {
      this.headersProvider().forEach(h => {
        headers.append(h[0], h[1]);
      });
    }

    const credentials =
      process.env.NODE_ENV === 'production' ? 'same-origin' : 'include';

    let res: Response;
    let resText: string;
    try {
      res = await fetch(
        this.baseURL + '/cwn.web.AuthenticatedService/' + method,
        {
          credentials,
          method: 'POST',
          headers: headers,
          body: JSON.stringify(req),
        },
      );
      resText = await res.text();
    } catch (e) {
      if (e instanceof TypeError) {
        throw new Error(
          'Could not connect to server (reason: ' +
            e.message +
            ') Check your Internet connection and try again.',
        );
      } else {
        throw e;
      }
    }

    if (res.ok) {
      try {
        const json = JSON.parse(resText);
        // https://flow.org/en/docs/types/utilities/#toc-class
        // $FlowFixMe this will work in a future version of flow
        return new ResClass(json);
      } catch (e) {
        throw new Error(
          'Could not parse response for ' +
            method +
            ': ' +
            e.message +
            ' payload: ' +
            resText,
        );
      }
    }

    let errMessage: string;
    try {
      const errJSON = JSON.parse(resText);
      if (errJSON.code && errJSON.message) {
        // RPCError
        errMessage = errJSON.code + ': ' + errJSON.message;
      } else {
        errMessage = JSON.stringify(errJSON, null, 2);
      }
    } catch (e) {
      errMessage = resText;
    }
    throw new Error(
      'Request for ' +
        method +
        ' failed: ' +
        res.status +
        ' ' +
        res.statusText +
        ' ' +
        errMessage,
    );
  }

  addHeader(name: string, value: string) {
    this.additionalHeaders.push([name, value]);
  }

  async CheckToken(req: EmptyRequest): Promise<CheckTokenReply> {
    return await this.call('CheckToken', req, CheckTokenReply);
  }
  async GetCustomer(req: EmptyRequest): Promise<GetCustomerReply> {
    return await this.call('GetCustomer', req, GetCustomerReply);
  }
  async SetPhone(req: SetPhoneRequest): Promise<EmptyReply> {
    return await this.call('SetPhone', req, EmptyReply);
  }
  async SetAccountDetails(req: SetAccountDetailsRequest): Promise<EmptyReply> {
    return await this.call('SetAccountDetails', req, EmptyReply);
  }
  async PostEmailUpdate(req: EmptyRequest): Promise<EmptyReply> {
    return await this.call('PostEmailUpdate', req, EmptyReply);
  }
  async ListInvoices(req: ListInvoicesRequest): Promise<ListInvoicesReply> {
    return await this.call('ListInvoices', req, ListInvoicesReply);
  }
  async GetLedgerCredential(
    req: GetLedgerCredentialRequest,
  ): Promise<GetLedgerCredentialReply> {
    return await this.call(
      'GetLedgerCredential',
      req,
      GetLedgerCredentialReply,
    );
  }
  async GetCustomerInfo(req: EmptyRequest): Promise<GetCustomerInfoReply> {
    return await this.call('GetCustomerInfo', req, GetCustomerInfoReply);
  }
  async GetInternetAddresses(
    req: EmptyRequest,
  ): Promise<GetInternetAddressesReply> {
    return await this.call(
      'GetInternetAddresses',
      req,
      GetInternetAddressesReply,
    );
  }
  async CancelAppointmentForAddress(
    req: CancelAppointmentForAddressRequest,
  ): Promise<EmptyReply> {
    return await this.call('CancelAppointmentForAddress', req, EmptyReply);
  }
  async ListAppointmentsForDay(
    req: ListAppointmentsForDayRequest,
  ): Promise<ListAppointmentsForDayReply> {
    return await this.call(
      'ListAppointmentsForDay',
      req,
      ListAppointmentsForDayReply,
    );
  }
  async GetAPState(req: GetAPStateRequest): Promise<GetAPStateReply> {
    return await this.call('GetAPState', req, GetAPStateReply);
  }
  async UpdateWlans(req: UpdateWlansRequest): Promise<UpdateWlansReply> {
    return await this.call('UpdateWlans', req, UpdateWlansReply);
  }
  async GetInstruments(req: EmptyRequest): Promise<GetInstrumentsReply> {
    return await this.call('GetInstruments', req, GetInstrumentsReply);
  }
  async AddInstrument(req: AddInstrumentRequest): Promise<AddInstrumentReply> {
    return await this.call('AddInstrument', req, AddInstrumentReply);
  }
  async DeleteInstrument(
    req: DeleteInstrumentRequest,
  ): Promise<DeleteInstrumentReply> {
    return await this.call('DeleteInstrument', req, DeleteInstrumentReply);
  }
  async RemoveAddress(req: RemoveAddressRequest): Promise<EmptyReply> {
    return await this.call('RemoveAddress', req, EmptyReply);
  }
  async AddAddress(req: AddAddressRequest): Promise<AddAddressReply> {
    return await this.call('AddAddress', req, AddAddressReply);
  }
  async SetInstrumentForAddress(
    req: SetInstrumentForAddressRequest,
  ): Promise<SetInstrumentForAddressReply> {
    return await this.call(
      'SetInstrumentForAddress',
      req,
      SetInstrumentForAddressReply,
    );
  }
  async GenerateCookieForLead(
    req: GenerateCookieForLeadRequest,
  ): Promise<GenerateCookieForLeadReply> {
    return await this.call(
      'GenerateCookieForLead',
      req,
      GenerateCookieForLeadReply,
    );
  }
}
export {AuthenticatedService};

// END cwn/web/authenticated_service.proto

// START cwn/web/service.proto

export const StatusValues = {
  INVALID: 0,
  OK: 1,
  UNAUTHORIZED: 2,
  ERROR: 3,
};
type Status = $Keys<typeof StatusValues>;
export type {Status};

export function StatusValue(n: number): Status {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'OK';

    case 2:
      return 'UNAUTHORIZED';

    case 3:
      return 'ERROR';

    default:
      return 'INVALID';
  }
}

class EmptyRequest {
  constructor(props: $Shape<EmptyRequest> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {EmptyRequest};

class EmptyReply {
  constructor(props: $Shape<EmptyReply> = {}): void {
    if (!props) {
      props = {};
    }
  }
}
export {EmptyReply};

class CheckAddressRequest {
  address: ?cwn_customers.Address;
  location: ?cwn_geocoder.GeoPoint;

  constructor(props: $Shape<CheckAddressRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.location = null;
    if (props.hasOwnProperty('location')) {
      const v = props.location;
      this.location = v && new cwn_geocoder.GeoPoint(v);
    }
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }

  getLocation(): cwn_geocoder.GeoPoint {
    if (this.location) {
      return this.location;
    }
    return new cwn_geocoder.GeoPoint();
  }
}
export {CheckAddressRequest};

class GetExistingTermsReply {
  status: Status;
  terms: ?cwn_signup.TermsOfService;

  constructor(props: $Shape<GetExistingTermsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.terms = null;
    if (props.hasOwnProperty('terms')) {
      const v = props.terms;
      this.terms = v && new cwn_signup.TermsOfService(v);
    }
  }

  getTerms(): cwn_signup.TermsOfService {
    if (this.terms) {
      return this.terms;
    }
    return new cwn_signup.TermsOfService();
  }
}
export {GetExistingTermsReply};

class AgreeToTermsReply {
  status: Status;

  constructor(props: $Shape<AgreeToTermsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }
  }
}
export {AgreeToTermsReply};

class CheckLeadStateReply {
  status: Status;
  state: cwn_lead.Lead_State;
  reason_for_rejection: cwn_lead.Lead_ReasonForRejection;

  constructor(props: $Shape<CheckLeadStateReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.state = cwn_lead.Lead_StateValue(0);
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }

    this.reason_for_rejection = cwn_lead.Lead_ReasonForRejectionValue(0);
    if (props.hasOwnProperty('reason_for_rejection')) {
      const v = props.reason_for_rejection;
      this.reason_for_rejection = v;
    }
  }
}
export {CheckLeadStateReply};

class CreateLandlordPermissionHelpTicketReply {
  status: Status;

  constructor(
    props: $Shape<CreateLandlordPermissionHelpTicketReply> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }
  }
}
export {CreateLandlordPermissionHelpTicketReply};

class EjectAutoacceptReply {
  status: Status;

  constructor(props: $Shape<EjectAutoacceptReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }
  }
}
export {EjectAutoacceptReply};

class GetAddressReply {
  status: Status;
  address: ?cwn_customers.Address;
  raw_address: string;

  constructor(props: $Shape<GetAddressReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.raw_address = '';
    if (props.hasOwnProperty('raw_address')) {
      const v = props.raw_address;
      this.raw_address = v;
    }
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }
}
export {GetAddressReply};

class IdentifyUserReply {
  status: Status;
  customer_id: string;
  lead_id: string;
  email: string;

  constructor(props: $Shape<IdentifyUserReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.customer_id = '';
    if (props.hasOwnProperty('customer_id')) {
      const v = props.customer_id;
      this.customer_id = v;
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }
  }
}
export {IdentifyUserReply};

class GetOnboardingStepRequest {
  reschedule: boolean;
  reselect_plan: boolean;

  constructor(props: $Shape<GetOnboardingStepRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.reschedule = false;
    if (props.hasOwnProperty('reschedule')) {
      const v = props.reschedule;
      this.reschedule = !!v;
    }

    this.reselect_plan = false;
    if (props.hasOwnProperty('reselect_plan')) {
      const v = props.reselect_plan;
      this.reselect_plan = !!v;
    }
  }
}
export {GetOnboardingStepRequest};

class GetOnboardingStepReply {
  status: Status;
  onboarding_step: cwn_lead.OnboardingStep;

  constructor(props: $Shape<GetOnboardingStepReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.onboarding_step = cwn_lead.OnboardingStepValue(0);
    if (props.hasOwnProperty('onboarding_step')) {
      const v = props.onboarding_step;
      this.onboarding_step = v;
    }
  }
}
export {GetOnboardingStepReply};

class GetReasonForRejectionReply {
  status: Status;
  reason_for_rejection: cwn_lead.Lead_ReasonForRejection;

  constructor(props: $Shape<GetReasonForRejectionReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.reason_for_rejection = cwn_lead.Lead_ReasonForRejectionValue(0);
    if (props.hasOwnProperty('reason_for_rejection')) {
      const v = props.reason_for_rejection;
      this.reason_for_rejection = v;
    }
  }
}
export {GetReasonForRejectionReply};

class GetSiteAddressReply {
  status: Status;
  address: ?cwn_customers.Address;

  constructor(props: $Shape<GetSiteAddressReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }
}
export {GetSiteAddressReply};

class GetSiteBuildingTypeReply {
  status: Status;
  site_building_type: cwn_site.Site_BuildingType;

  constructor(props: $Shape<GetSiteBuildingTypeReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.site_building_type = cwn_site.Site_BuildingTypeValue(0);
    if (props.hasOwnProperty('site_building_type')) {
      const v = props.site_building_type;
      this.site_building_type = v;
    }
  }
}
export {GetSiteBuildingTypeReply};

class GetSiteStatusReply {
  status: Status;
  site_status: ?cwn_site.StatusV2;

  constructor(props: $Shape<GetSiteStatusReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.site_status = null;
    if (props.hasOwnProperty('site_status')) {
      const v = props.site_status;
      this.site_status = v && new cwn_site.StatusV2(v);
    }
  }

  getSiteStatus(): cwn_site.StatusV2 {
    if (this.site_status) {
      return this.site_status;
    }
    return new cwn_site.StatusV2();
  }
}
export {GetSiteStatusReply};

class GetAppointmentDurationReply {
  status: Status;
  duration: string;

  constructor(props: $Shape<GetAppointmentDurationReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.duration = '0';
    if (props.hasOwnProperty('duration')) {
      const v = props.duration;
      this.duration = v;
    }
  }
}
export {GetAppointmentDurationReply};

class NewCustomerRequest_Form {
  name: string;
  email: string;
  phone: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  buildingType: cwn_site.Site_BuildingType;
  numberOfUnits: cwn_site.Site_NumberOfUnits;

  constructor(props: $Shape<NewCustomerRequest_Form> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.phone = '';
    if (props.hasOwnProperty('phone')) {
      const v = props.phone;
      this.phone = v;
    }

    this.addressLine1 = '';
    if (props.hasOwnProperty('addressLine1')) {
      const v = props.addressLine1;
      this.addressLine1 = v;
    }

    this.addressLine2 = '';
    if (props.hasOwnProperty('addressLine2')) {
      const v = props.addressLine2;
      this.addressLine2 = v;
    }

    this.city = '';
    if (props.hasOwnProperty('city')) {
      const v = props.city;
      this.city = v;
    }

    this.state = '';
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }

    this.zip = '';
    if (props.hasOwnProperty('zip')) {
      const v = props.zip;
      this.zip = v;
    }

    this.buildingType = cwn_site.Site_BuildingTypeValue(0);
    if (props.hasOwnProperty('buildingType')) {
      const v = props.buildingType;
      this.buildingType = v;
    }

    this.numberOfUnits = cwn_site.Site_NumberOfUnitsValue(0);
    if (props.hasOwnProperty('numberOfUnits')) {
      const v = props.numberOfUnits;
      this.numberOfUnits = v;
    }
  }
}
export {NewCustomerRequest_Form};
class NewCustomerRequest {
  form: ?NewCustomerRequest_Form;
  referrer: string;
  geocodedAddress: ?cwn_customers.Address;
  geocodedLatLng: ?cwn_geocoder.GeoPoint;
  token: string;
  passwordless: boolean;
  signup_v2: ?cwn_signup.SignupV2;

  constructor(props: $Shape<NewCustomerRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.form = null;
    if (props.hasOwnProperty('form')) {
      const v = props.form;
      this.form = v && new NewCustomerRequest_Form(v);
    }

    this.referrer = '';
    if (props.hasOwnProperty('referrer')) {
      const v = props.referrer;
      this.referrer = v;
    }

    this.geocodedAddress = null;
    if (props.hasOwnProperty('geocodedAddress')) {
      const v = props.geocodedAddress;
      this.geocodedAddress = v && new cwn_customers.Address(v);
    }

    this.geocodedLatLng = null;
    if (props.hasOwnProperty('geocodedLatLng')) {
      const v = props.geocodedLatLng;
      this.geocodedLatLng = v && new cwn_geocoder.GeoPoint(v);
    }

    this.token = '';
    if (props.hasOwnProperty('token')) {
      const v = props.token;
      this.token = v;
    }

    this.passwordless = false;
    if (props.hasOwnProperty('passwordless')) {
      const v = props.passwordless;
      this.passwordless = !!v;
    }

    this.signup_v2 = null;
    if (props.hasOwnProperty('signup_v2')) {
      const v = props.signup_v2;
      this.signup_v2 = v && new cwn_signup.SignupV2(v);
    }
  }

  getForm(): NewCustomerRequest_Form {
    if (this.form) {
      return this.form;
    }
    return new NewCustomerRequest_Form();
  }

  getGeocodedAddress(): cwn_customers.Address {
    if (this.geocodedAddress) {
      return this.geocodedAddress;
    }
    return new cwn_customers.Address();
  }

  getGeocodedLatLng(): cwn_geocoder.GeoPoint {
    if (this.geocodedLatLng) {
      return this.geocodedLatLng;
    }
    return new cwn_geocoder.GeoPoint();
  }

  getSignupV2(): cwn_signup.SignupV2 {
    if (this.signup_v2) {
      return this.signup_v2;
    }
    return new cwn_signup.SignupV2();
  }
}
export {NewCustomerRequest};

export const NewCustomerReply_ResultValues = {
  INVALID: 0,
  FORM_ERROR: 1,
  INVALID_PROMO: 2,
  ACCOUNT_EXISTS: 3,
  CREATED_WITH_ERROR: 4,
  OK: 5,
};
type NewCustomerReply_Result = $Keys<typeof NewCustomerReply_ResultValues>;
export type {NewCustomerReply_Result};

export function NewCustomerReply_ResultValue(
  n: number,
): NewCustomerReply_Result {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'FORM_ERROR';

    case 2:
      return 'INVALID_PROMO';

    case 3:
      return 'ACCOUNT_EXISTS';

    case 4:
      return 'CREATED_WITH_ERROR';

    case 5:
      return 'OK';

    default:
      return 'INVALID';
  }
}

class NewCustomerReply {
  result: NewCustomerReply_Result;
  form_error: string;
  customer_id: string;
  lead_id: string;

  constructor(props: $Shape<NewCustomerReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.result = NewCustomerReply_ResultValue(0);
    if (props.hasOwnProperty('result')) {
      const v = props.result;
      this.result = v;
    }

    this.form_error = '';
    if (props.hasOwnProperty('form_error')) {
      const v = props.form_error;
      this.form_error = v;
    }

    this.customer_id = '';
    if (props.hasOwnProperty('customer_id')) {
      const v = props.customer_id;
      this.customer_id = v;
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }
  }
}
export {NewCustomerReply};

class GetLandlordPermissionInfoReply {
  status: Status;
  needs_permission: boolean;
  landlord_information: ?cwn_lead.Lead_LandlordInformation;
  helpful_information_for_your_landlord_email_sent: boolean;

  constructor(props: $Shape<GetLandlordPermissionInfoReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.needs_permission = false;
    if (props.hasOwnProperty('needs_permission')) {
      const v = props.needs_permission;
      this.needs_permission = !!v;
    }

    this.landlord_information = null;
    if (props.hasOwnProperty('landlord_information')) {
      const v = props.landlord_information;
      this.landlord_information = v && new cwn_lead.Lead_LandlordInformation(v);
    }

    this.helpful_information_for_your_landlord_email_sent = false;
    if (
      props.hasOwnProperty('helpful_information_for_your_landlord_email_sent')
    ) {
      const v = props.helpful_information_for_your_landlord_email_sent;
      this.helpful_information_for_your_landlord_email_sent = !!v;
    }
  }

  getLandlordInformation(): cwn_lead.Lead_LandlordInformation {
    if (this.landlord_information) {
      return this.landlord_information;
    }
    return new cwn_lead.Lead_LandlordInformation();
  }
}
export {GetLandlordPermissionInfoReply};

class SendHelpfulInformationForYourLandlordEmailReply {
  status: Status;

  constructor(
    props: $Shape<SendHelpfulInformationForYourLandlordEmailReply> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }
  }
}
export {SendHelpfulInformationForYourLandlordEmailReply};

class SetLandlordInformationRequest {
  landlord_information: ?cwn_lead.Lead_LandlordInformation;

  constructor(props: $Shape<SetLandlordInformationRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.landlord_information = null;
    if (props.hasOwnProperty('landlord_information')) {
      const v = props.landlord_information;
      this.landlord_information = v && new cwn_lead.Lead_LandlordInformation(v);
    }
  }

  getLandlordInformation(): cwn_lead.Lead_LandlordInformation {
    if (this.landlord_information) {
      return this.landlord_information;
    }
    return new cwn_lead.Lead_LandlordInformation();
  }
}
export {SetLandlordInformationRequest};

class SetLandlordInformationReply {
  status: Status;
  form_error: string;

  constructor(props: $Shape<SetLandlordInformationReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.form_error = '';
    if (props.hasOwnProperty('form_error')) {
      const v = props.form_error;
      this.form_error = v;
    }
  }
}
export {SetLandlordInformationReply};

class GetAvailablePlansForLeadRequest {
  update_eligible_plans_seen: boolean;

  constructor(props: $Shape<GetAvailablePlansForLeadRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.update_eligible_plans_seen = false;
    if (props.hasOwnProperty('update_eligible_plans_seen')) {
      const v = props.update_eligible_plans_seen;
      this.update_eligible_plans_seen = !!v;
    }
  }
}
export {GetAvailablePlansForLeadRequest};

class GetAvailablePlansForLeadReply {
  status: Status;
  available_plans: Array<cwn_customers.Subscription_Plan>;
  eligible_plans: Array<cwn_customers.Subscription_Plan>;

  constructor(props: $Shape<GetAvailablePlansForLeadReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.available_plans = [];
    if (props.hasOwnProperty('available_plans')) {
      const v = props.available_plans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field available_plans should be array');
      }
      this.available_plans = v.map(function(v) {
        return v;
      });
    }

    this.eligible_plans = [];
    if (props.hasOwnProperty('eligible_plans')) {
      const v = props.eligible_plans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field eligible_plans should be array');
      }
      this.eligible_plans = v.map(function(v) {
        return v;
      });
    }
  }
}
export {GetAvailablePlansForLeadReply};

class GetLeadPlanReply {
  status: Status;
  plan: cwn_customers.Subscription_Plan;

  constructor(props: $Shape<GetLeadPlanReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.plan = cwn_customers.Subscription_PlanValue(0);
    if (props.hasOwnProperty('plan')) {
      const v = props.plan;
      this.plan = v;
    }
  }
}
export {GetLeadPlanReply};

class GetLeadPromoValidationReply {
  status: Status;
  validation: ?cwn_billing.PromoValidation;

  constructor(props: $Shape<GetLeadPromoValidationReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.validation = null;
    if (props.hasOwnProperty('validation')) {
      const v = props.validation;
      this.validation = v && new cwn_billing.PromoValidation(v);
    }
  }

  getValidation(): cwn_billing.PromoValidation {
    if (this.validation) {
      return this.validation;
    }
    return new cwn_billing.PromoValidation();
  }
}
export {GetLeadPromoValidationReply};

class SetLeadPlanRequest {
  plan: cwn_customers.Subscription_Plan;
  eligible_plans: Array<cwn_customers.Subscription_Plan>;

  constructor(props: $Shape<SetLeadPlanRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.plan = cwn_customers.Subscription_PlanValue(0);
    if (props.hasOwnProperty('plan')) {
      const v = props.plan;
      this.plan = v;
    }

    this.eligible_plans = [];
    if (props.hasOwnProperty('eligible_plans')) {
      const v = props.eligible_plans;
      if (!Array.isArray(v)) {
        throw new Error('repeated field eligible_plans should be array');
      }
      this.eligible_plans = v.map(function(v) {
        return v;
      });
    }
  }
}
export {SetLeadPlanRequest};

class SetLeadPlanReply {
  status: Status;

  constructor(props: $Shape<SetLeadPlanReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }
  }
}
export {SetLeadPlanReply};

class SetTokenRequest {
  token: string;

  constructor(props: $Shape<SetTokenRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.token = '';
    if (props.hasOwnProperty('token')) {
      const v = props.token;
      this.token = v;
    }
  }
}
export {SetTokenRequest};

class SetTokenReply {
  status: Status;

  constructor(props: $Shape<SetTokenReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }
  }
}
export {SetTokenReply};

class CreateSignupV2Reply {
  status: Status;

  constructor(props: $Shape<CreateSignupV2Reply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }
  }
}
export {CreateSignupV2Reply};

class UpdateSignupV2Request {
  signup_v2: ?cwn_signup.SignupV2;

  constructor(props: $Shape<UpdateSignupV2Request> = {}): void {
    if (!props) {
      props = {};
    }

    this.signup_v2 = null;
    if (props.hasOwnProperty('signup_v2')) {
      const v = props.signup_v2;
      this.signup_v2 = v && new cwn_signup.SignupV2(v);
    }
  }

  getSignupV2(): cwn_signup.SignupV2 {
    if (this.signup_v2) {
      return this.signup_v2;
    }
    return new cwn_signup.SignupV2();
  }
}
export {UpdateSignupV2Request};

class UpdateSignupV2Reply {
  status: Status;

  constructor(props: $Shape<UpdateSignupV2Reply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }
  }
}
export {UpdateSignupV2Reply};

class BookAppointmentV2Request {
  appointment: ?Date;

  constructor(props: $Shape<BookAppointmentV2Request> = {}): void {
    if (!props) {
      props = {};
    }

    this.appointment = null;
    if (props.hasOwnProperty('appointment')) {
      const v = props.appointment;
      this.appointment = v && new Date(v);
    }
  }
}
export {BookAppointmentV2Request};

class BookAppointmentV2Reply {
  status: Status;

  constructor(props: $Shape<BookAppointmentV2Reply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }
  }
}
export {BookAppointmentV2Reply};

class CancelExistingAppointmentReply {
  status: Status;

  constructor(props: $Shape<CancelExistingAppointmentReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }
  }
}
export {CancelExistingAppointmentReply};

class GetAppointmentReply {
  status: Status;
  appointment: ?Date;

  constructor(props: $Shape<GetAppointmentReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.appointment = null;
    if (props.hasOwnProperty('appointment')) {
      const v = props.appointment;
      this.appointment = v && new Date(v);
    }
  }
}
export {GetAppointmentReply};

class GetDayAvailabilityRequest {
  day: ?Date;

  constructor(props: $Shape<GetDayAvailabilityRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.day = null;
    if (props.hasOwnProperty('day')) {
      const v = props.day;
      this.day = v && new Date(v);
    }
  }
}
export {GetDayAvailabilityRequest};

class GetDayAvailabilityReply {
  status: Status;
  slots: Array<Date>;

  constructor(props: $Shape<GetDayAvailabilityReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.slots = [];
    if (props.hasOwnProperty('slots')) {
      const v = props.slots;
      if (!Array.isArray(v)) {
        throw new Error('repeated field slots should be array');
      }
      this.slots = v.map(function(v) {
        return new Date(v);
      });
    }
  }
}
export {GetDayAvailabilityReply};

class GetExistingAppointmentReply {
  status: Status;
  existing_appointment: ?Date;

  constructor(props: $Shape<GetExistingAppointmentReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.existing_appointment = null;
    if (props.hasOwnProperty('existing_appointment')) {
      const v = props.existing_appointment;
      this.existing_appointment = v && new Date(v);
    }
  }
}
export {GetExistingAppointmentReply};

class GetNextAvailableAppointmentResponse {
  status: Status;
  next: ?Date;

  constructor(props: $Shape<GetNextAvailableAppointmentResponse> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.next = null;
    if (props.hasOwnProperty('next')) {
      const v = props.next;
      this.next = v && new Date(v);
    }
  }
}
export {GetNextAvailableAppointmentResponse};

class GetWeeksAvailabilityRequest {
  batch_size: number;

  constructor(props: $Shape<GetWeeksAvailabilityRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.batch_size = 0;
    if (props.hasOwnProperty('batch_size')) {
      const v = props.batch_size;
      this.batch_size = v;
    }
  }
}
export {GetWeeksAvailabilityRequest};

class GetWeeksAvailabilityReply_Day {
  date: ?Date;
  available: boolean;

  constructor(props: $Shape<GetWeeksAvailabilityReply_Day> = {}): void {
    if (!props) {
      props = {};
    }

    this.date = null;
    if (props.hasOwnProperty('date')) {
      const v = props.date;
      this.date = v && new Date(v);
    }

    this.available = false;
    if (props.hasOwnProperty('available')) {
      const v = props.available;
      this.available = !!v;
    }
  }
}
export {GetWeeksAvailabilityReply_Day};
class GetWeeksAvailabilityReply_Week {
  days: Array<GetWeeksAvailabilityReply_Day>;

  constructor(props: $Shape<GetWeeksAvailabilityReply_Week> = {}): void {
    if (!props) {
      props = {};
    }

    this.days = [];
    if (props.hasOwnProperty('days')) {
      const v = props.days;
      if (!Array.isArray(v)) {
        throw new Error('repeated field days should be array');
      }
      this.days = v.map(function(v) {
        return new GetWeeksAvailabilityReply_Day(v);
      });
    }
  }
}
export {GetWeeksAvailabilityReply_Week};
class GetWeeksAvailabilityReply {
  status: Status;
  weeks: Array<GetWeeksAvailabilityReply_Week>;

  constructor(props: $Shape<GetWeeksAvailabilityReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.weeks = [];
    if (props.hasOwnProperty('weeks')) {
      const v = props.weeks;
      if (!Array.isArray(v)) {
        throw new Error('repeated field weeks should be array');
      }
      this.weeks = v.map(function(v) {
        return new GetWeeksAvailabilityReply_Week(v);
      });
    }
  }
}
export {GetWeeksAvailabilityReply};

class WorkOrderApptToken {
  token: string;

  constructor(props: $Shape<WorkOrderApptToken> = {}): void {
    if (!props) {
      props = {};
    }

    this.token = '';
    if (props.hasOwnProperty('token')) {
      const v = props.token;
      this.token = v;
    }
  }
}
export {WorkOrderApptToken};

export const GetWorkOrderBookingStatusReply_BookStatusValues = {
  INVALID_WORK_ORDER: 0,
  UNBOOKED: 1,
  BOOKED: 2,
};
type GetWorkOrderBookingStatusReply_BookStatus = $Keys<
  typeof GetWorkOrderBookingStatusReply_BookStatusValues,
>;
export type {GetWorkOrderBookingStatusReply_BookStatus};

export function GetWorkOrderBookingStatusReply_BookStatusValue(
  n: number,
): GetWorkOrderBookingStatusReply_BookStatus {
  switch (n) {
    case 0:
      return 'INVALID_WORK_ORDER';

    case 1:
      return 'UNBOOKED';

    case 2:
      return 'BOOKED';

    default:
      return 'INVALID_WORK_ORDER';
  }
}

class GetWorkOrderBookingStatusReply {
  book_status: GetWorkOrderBookingStatusReply_BookStatus;
  address: ?cwn_customers.Address;
  location: ?cwn_geocoder.GeoPoint;
  appointment: ?Date;
  duration_minutes: string;
  time_window: boolean;

  constructor(props: $Shape<GetWorkOrderBookingStatusReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.book_status = GetWorkOrderBookingStatusReply_BookStatusValue(0);
    if (props.hasOwnProperty('book_status')) {
      const v = props.book_status;
      this.book_status = v;
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.location = null;
    if (props.hasOwnProperty('location')) {
      const v = props.location;
      this.location = v && new cwn_geocoder.GeoPoint(v);
    }

    this.appointment = null;
    if (props.hasOwnProperty('appointment')) {
      const v = props.appointment;
      this.appointment = v && new Date(v);
    }

    this.duration_minutes = '';
    if (props.hasOwnProperty('duration_minutes')) {
      const v = props.duration_minutes;
      this.duration_minutes = v;
    }

    this.time_window = false;
    if (props.hasOwnProperty('time_window')) {
      const v = props.time_window;
      this.time_window = !!v;
    }
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }

  getLocation(): cwn_geocoder.GeoPoint {
    if (this.location) {
      return this.location;
    }
    return new cwn_geocoder.GeoPoint();
  }
}
export {GetWorkOrderBookingStatusReply};

class GetWorkOrderDayAvailabilityRequest {
  token: string;
  day: ?Date;

  constructor(props: $Shape<GetWorkOrderDayAvailabilityRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.token = '';
    if (props.hasOwnProperty('token')) {
      const v = props.token;
      this.token = v;
    }

    this.day = null;
    if (props.hasOwnProperty('day')) {
      const v = props.day;
      this.day = v && new Date(v);
    }
  }
}
export {GetWorkOrderDayAvailabilityRequest};

class BookWorkOrderApptRequest {
  token: string;
  appointment: ?Date;

  constructor(props: $Shape<BookWorkOrderApptRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.token = '';
    if (props.hasOwnProperty('token')) {
      const v = props.token;
      this.token = v;
    }

    this.appointment = null;
    if (props.hasOwnProperty('appointment')) {
      const v = props.appointment;
      this.appointment = v && new Date(v);
    }
  }
}
export {BookWorkOrderApptRequest};

class GetWorkOrderNextAvailableApptReply {
  next: ?Date;

  constructor(props: $Shape<GetWorkOrderNextAvailableApptReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.next = null;
    if (props.hasOwnProperty('next')) {
      const v = props.next;
      this.next = v && new Date(v);
    }
  }
}
export {GetWorkOrderNextAvailableApptReply};

class GetStripeCardReply {
  status: Status;
  card: ?cwn_stripe.Card;

  constructor(props: $Shape<GetStripeCardReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.card = null;
    if (props.hasOwnProperty('card')) {
      const v = props.card;
      this.card = v && new cwn_stripe.Card(v);
    }
  }

  getCard(): cwn_stripe.Card {
    if (this.card) {
      return this.card;
    }
    return new cwn_stripe.Card();
  }
}
export {GetStripeCardReply};

class SetStripeCardRequest {
  card: ?cwn_stripe.Card;
  stripe_token_id: string;

  constructor(props: $Shape<SetStripeCardRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.card = null;
    if (props.hasOwnProperty('card')) {
      const v = props.card;
      this.card = v && new cwn_stripe.Card(v);
    }

    this.stripe_token_id = '';
    if (props.hasOwnProperty('stripe_token_id')) {
      const v = props.stripe_token_id;
      this.stripe_token_id = v;
    }
  }

  getCard(): cwn_stripe.Card {
    if (this.card) {
      return this.card;
    }
    return new cwn_stripe.Card();
  }
}
export {SetStripeCardRequest};

class SetStripeCardReply {
  status: Status;
  stripe_error: ?cwn_stripe.Error;

  constructor(props: $Shape<SetStripeCardReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.stripe_error = null;
    if (props.hasOwnProperty('stripe_error')) {
      const v = props.stripe_error;
      this.stripe_error = v && new cwn_stripe.Error(v);
    }
  }

  getStripeError(): cwn_stripe.Error {
    if (this.stripe_error) {
      return this.stripe_error;
    }
    return new cwn_stripe.Error();
  }
}
export {SetStripeCardReply};

class GetAppliedPromoReply {
  status: Status;
  promo_code: ?cwn_billing.PromoCode;

  constructor(props: $Shape<GetAppliedPromoReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.promo_code = null;
    if (props.hasOwnProperty('promo_code')) {
      const v = props.promo_code;
      this.promo_code = v && new cwn_billing.PromoCode(v);
    }
  }

  getPromoCode(): cwn_billing.PromoCode {
    if (this.promo_code) {
      return this.promo_code;
    }
    return new cwn_billing.PromoCode();
  }
}
export {GetAppliedPromoReply};

class ApplyPromoRequest {
  code: string;

  constructor(props: $Shape<ApplyPromoRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.code = '';
    if (props.hasOwnProperty('code')) {
      const v = props.code;
      this.code = v;
    }
  }
}
export {ApplyPromoRequest};

class ApplyPromoReply {
  status: Status;
  validation: ?cwn_billing.PromoValidation;

  constructor(props: $Shape<ApplyPromoReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.validation = null;
    if (props.hasOwnProperty('validation')) {
      const v = props.validation;
      this.validation = v && new cwn_billing.PromoValidation(v);
    }
  }

  getValidation(): cwn_billing.PromoValidation {
    if (this.validation) {
      return this.validation;
    }
    return new cwn_billing.PromoValidation();
  }
}
export {ApplyPromoReply};

class GetReferralNameFromTokenRequest {
  referral_token: string;

  constructor(props: $Shape<GetReferralNameFromTokenRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.referral_token = '';
    if (props.hasOwnProperty('referral_token')) {
      const v = props.referral_token;
      this.referral_token = v;
    }
  }
}
export {GetReferralNameFromTokenRequest};

class GetReferralNameFromTokenReply {
  name: string;
  bad_token: boolean;

  constructor(props: $Shape<GetReferralNameFromTokenReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.bad_token = false;
    if (props.hasOwnProperty('bad_token')) {
      const v = props.bad_token;
      this.bad_token = !!v;
    }
  }
}
export {GetReferralNameFromTokenReply};

class UnsubscribeFromMarketingEmailsRequest {
  email: string;

  constructor(props: $Shape<UnsubscribeFromMarketingEmailsRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }
  }
}
export {UnsubscribeFromMarketingEmailsRequest};

class UnsubscribeFromMarketingEmailsReply {
  invalid: boolean;

  constructor(props: $Shape<UnsubscribeFromMarketingEmailsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.invalid = false;
    if (props.hasOwnProperty('invalid')) {
      const v = props.invalid;
      this.invalid = !!v;
    }
  }
}
export {UnsubscribeFromMarketingEmailsReply};

class ValidatePromoRequest {
  code: string;

  constructor(props: $Shape<ValidatePromoRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.code = '';
    if (props.hasOwnProperty('code')) {
      const v = props.code;
      this.code = v;
    }
  }
}
export {ValidatePromoRequest};

class ValidatePromoReply {
  status: Status;
  validation: ?cwn_billing.PromoValidation;
  referrer_name: string;

  constructor(props: $Shape<ValidatePromoReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.validation = null;
    if (props.hasOwnProperty('validation')) {
      const v = props.validation;
      this.validation = v && new cwn_billing.PromoValidation(v);
    }

    this.referrer_name = '';
    if (props.hasOwnProperty('referrer_name')) {
      const v = props.referrer_name;
      this.referrer_name = v;
    }
  }

  getValidation(): cwn_billing.PromoValidation {
    if (this.validation) {
      return this.validation;
    }
    return new cwn_billing.PromoValidation();
  }
}
export {ValidatePromoReply};

class GetReferralsReply {
  status: Status;
  referrals: Array<cwn_lead.SignUpFlowReferral>;

  constructor(props: $Shape<GetReferralsReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.referrals = [];
    if (props.hasOwnProperty('referrals')) {
      const v = props.referrals;
      if (!Array.isArray(v)) {
        throw new Error('repeated field referrals should be array');
      }
      this.referrals = v.map(function(v) {
        return new cwn_lead.SignUpFlowReferral(v);
      });
    }
  }
}
export {GetReferralsReply};

class InviteFromLeadRequest {
  emails: Array<string>;

  constructor(props: $Shape<InviteFromLeadRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.emails = [];
    if (props.hasOwnProperty('emails')) {
      const v = props.emails;
      if (!Array.isArray(v)) {
        throw new Error('repeated field emails should be array');
      }
      this.emails = v.map(function(v) {
        return v;
      });
    }
  }
}
export {InviteFromLeadRequest};

class GetPartnerLeadReply {
  partner_lead: ?cwn_partner.PartnerLead;

  constructor(props: $Shape<GetPartnerLeadReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.partner_lead = null;
    if (props.hasOwnProperty('partner_lead')) {
      const v = props.partner_lead;
      this.partner_lead = v && new cwn_partner.PartnerLead(v);
    }
  }

  getPartnerLead(): cwn_partner.PartnerLead {
    if (this.partner_lead) {
      return this.partner_lead;
    }
    return new cwn_partner.PartnerLead();
  }
}
export {GetPartnerLeadReply};

export const UpdatePartnerLeadRequest_ValidationTypeValues = {
  ALL: 0,
  ADDRESS_FIELDS: 1,
  MANAGEMENT_FIELDS: 2,
  APPLICANT_FIELDS: 3,
};
type UpdatePartnerLeadRequest_ValidationType = $Keys<
  typeof UpdatePartnerLeadRequest_ValidationTypeValues,
>;
export type {UpdatePartnerLeadRequest_ValidationType};

export function UpdatePartnerLeadRequest_ValidationTypeValue(
  n: number,
): UpdatePartnerLeadRequest_ValidationType {
  switch (n) {
    case 0:
      return 'ALL';

    case 1:
      return 'ADDRESS_FIELDS';

    case 2:
      return 'MANAGEMENT_FIELDS';

    case 3:
      return 'APPLICANT_FIELDS';

    default:
      return 'ALL';
  }
}

class UpdatePartnerLeadRequest {
  partner_lead: ?cwn_partner.PartnerLead;
  validation_type: UpdatePartnerLeadRequest_ValidationType;

  constructor(props: $Shape<UpdatePartnerLeadRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.partner_lead = null;
    if (props.hasOwnProperty('partner_lead')) {
      const v = props.partner_lead;
      this.partner_lead = v && new cwn_partner.PartnerLead(v);
    }

    this.validation_type = UpdatePartnerLeadRequest_ValidationTypeValue(0);
    if (props.hasOwnProperty('validation_type')) {
      const v = props.validation_type;
      this.validation_type = v;
    }
  }

  getPartnerLead(): cwn_partner.PartnerLead {
    if (this.partner_lead) {
      return this.partner_lead;
    }
    return new cwn_partner.PartnerLead();
  }
}
export {UpdatePartnerLeadRequest};

export const UpdatePartnerLeadReply_Error_FieldValues = {
  NONE: 0,
  ADDRESS_LINE_1: 1,
  CITY: 2,
  STATE: 3,
  ZIP: 4,
  BUILDING_TYPE: 5,
  NUMBER_OF_UNITS: 6,
  MANAGEMENT_TYPE: 7,
  MANAGEMENT_NAME: 8,
  MANAGEMENT_EMAIL: 9,
  MANAGEMENT_PHONE: 10,
  MANAGEMENT_ADDRESS_LINE_1: 11,
  MANAGEMENT_CITY: 12,
  MANAGEMENT_STATE: 13,
  MANAGEMENT_ZIP: 14,
  OTHER_MANAGEMENT_TYPE: 15,
  APPLICANT_NAME: 16,
  TERMS_OF_SERVICE_AGREED: 17,
  APPLICANT_EMAIL: 18,
  APPLICANT_PHONE: 19,
  APPLICANT_RELATIONSHIP_TYPE: 20,
  OTHER_APPLICANT_RELATIONSHIP_TYPE: 21,
};
type UpdatePartnerLeadReply_Error_Field = $Keys<
  typeof UpdatePartnerLeadReply_Error_FieldValues,
>;
export type {UpdatePartnerLeadReply_Error_Field};

export function UpdatePartnerLeadReply_Error_FieldValue(
  n: number,
): UpdatePartnerLeadReply_Error_Field {
  switch (n) {
    case 0:
      return 'NONE';

    case 1:
      return 'ADDRESS_LINE_1';

    case 2:
      return 'CITY';

    case 3:
      return 'STATE';

    case 4:
      return 'ZIP';

    case 5:
      return 'BUILDING_TYPE';

    case 6:
      return 'NUMBER_OF_UNITS';

    case 7:
      return 'MANAGEMENT_TYPE';

    case 8:
      return 'MANAGEMENT_NAME';

    case 9:
      return 'MANAGEMENT_EMAIL';

    case 10:
      return 'MANAGEMENT_PHONE';

    case 11:
      return 'MANAGEMENT_ADDRESS_LINE_1';

    case 12:
      return 'MANAGEMENT_CITY';

    case 13:
      return 'MANAGEMENT_STATE';

    case 14:
      return 'MANAGEMENT_ZIP';

    case 15:
      return 'OTHER_MANAGEMENT_TYPE';

    case 16:
      return 'APPLICANT_NAME';

    case 17:
      return 'TERMS_OF_SERVICE_AGREED';

    case 18:
      return 'APPLICANT_EMAIL';

    case 19:
      return 'APPLICANT_PHONE';

    case 20:
      return 'APPLICANT_RELATIONSHIP_TYPE';

    case 21:
      return 'OTHER_APPLICANT_RELATIONSHIP_TYPE';

    default:
      return 'NONE';
  }
}

class UpdatePartnerLeadReply_Error {
  user_facing_description: string;
  field: UpdatePartnerLeadReply_Error_Field;

  constructor(props: $Shape<UpdatePartnerLeadReply_Error> = {}): void {
    if (!props) {
      props = {};
    }

    this.user_facing_description = '';
    if (props.hasOwnProperty('user_facing_description')) {
      const v = props.user_facing_description;
      this.user_facing_description = v;
    }

    this.field = UpdatePartnerLeadReply_Error_FieldValue(0);
    if (props.hasOwnProperty('field')) {
      const v = props.field;
      this.field = v;
    }
  }
}
export {UpdatePartnerLeadReply_Error};
class UpdatePartnerLeadReply {
  errors: Array<UpdatePartnerLeadReply_Error>;

  constructor(props: $Shape<UpdatePartnerLeadReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.errors = [];
    if (props.hasOwnProperty('errors')) {
      const v = props.errors;
      if (!Array.isArray(v)) {
        throw new Error('repeated field errors should be array');
      }
      this.errors = v.map(function(v) {
        return new UpdatePartnerLeadReply_Error(v);
      });
    }
  }
}
export {UpdatePartnerLeadReply};

class EmailRequest {
  email: string;

  constructor(props: $Shape<EmailRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }
  }
}
export {EmailRequest};

class VerifyEmailRequest {
  email: string;
  token: string;

  constructor(props: $Shape<VerifyEmailRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }

    this.token = '';
    if (props.hasOwnProperty('token')) {
      const v = props.token;
      this.token = v;
    }
  }
}
export {VerifyEmailRequest};

class UpdatePasswordRequest {
  password: string;
  token: string;

  constructor(props: $Shape<UpdatePasswordRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.password = '';
    if (props.hasOwnProperty('password')) {
      const v = props.password;
      this.password = v;
    }

    this.token = '';
    if (props.hasOwnProperty('token')) {
      const v = props.token;
      this.token = v;
    }
  }
}
export {UpdatePasswordRequest};

class DevelopmentLoginRequest {
  email: string;

  constructor(props: $Shape<DevelopmentLoginRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.email = '';
    if (props.hasOwnProperty('email')) {
      const v = props.email;
      this.email = v;
    }
  }
}
export {DevelopmentLoginRequest};

class DevelopmentLoginReply {
  cookie: string;

  constructor(props: $Shape<DevelopmentLoginReply> = {}): void {
    if (!props) {
      props = {};
    }

    this.cookie = '';
    if (props.hasOwnProperty('cookie')) {
      const v = props.cookie;
      this.cookie = v;
    }
  }
}
export {DevelopmentLoginReply};

class LogRequest_Message {
  severity: cwn_log.Severity;
  message: string;
  stack: string;

  constructor(props: $Shape<LogRequest_Message> = {}): void {
    if (!props) {
      props = {};
    }

    this.severity = cwn_log.SeverityValue(0);
    if (props.hasOwnProperty('severity')) {
      const v = props.severity;
      this.severity = v;
    }

    this.message = '';
    if (props.hasOwnProperty('message')) {
      const v = props.message;
      this.message = v;
    }

    this.stack = '';
    if (props.hasOwnProperty('stack')) {
      const v = props.stack;
      this.stack = v;
    }
  }
}
export {LogRequest_Message};
class LogRequest {
  messages: Array<LogRequest_Message>;

  constructor(props: $Shape<LogRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.messages = [];
    if (props.hasOwnProperty('messages')) {
      const v = props.messages;
      if (!Array.isArray(v)) {
        throw new Error('repeated field messages should be array');
      }
      this.messages = v.map(function(v) {
        return new LogRequest_Message(v);
      });
    }
  }
}
export {LogRequest};

class EventRequest {
  events: Array<cwn_analytics.Event>;

  constructor(props: $Shape<EventRequest> = {}): void {
    if (!props) {
      props = {};
    }

    this.events = [];
    if (props.hasOwnProperty('events')) {
      const v = props.events;
      if (!Array.isArray(v)) {
        throw new Error('repeated field events should be array');
      }
      this.events = v.map(function(v) {
        return new cwn_analytics.Event(v);
      });
    }
  }
}
export {EventRequest};

class WebService {
  baseURL: string;
  additionalHeaders: Array<Array<string>>;
  headersProvider: ?() => Array<Array<string>>;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.additionalHeaders = [];
  }

  async call<Req, Res>(
    method: string,
    req: Req,
    ResClass: Class<Res>,
  ): Promise<Res> {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    this.additionalHeaders.forEach(function(h) {
      headers.append(h[0], h[1]);
    });

    if (this.headersProvider) {
      this.headersProvider().forEach(h => {
        headers.append(h[0], h[1]);
      });
    }

    const credentials =
      process.env.NODE_ENV === 'production' ? 'same-origin' : 'include';

    let res: Response;
    let resText: string;
    try {
      res = await fetch(this.baseURL + '/cwn.web.WebService/' + method, {
        credentials,
        method: 'POST',
        headers: headers,
        body: JSON.stringify(req),
      });
      resText = await res.text();
    } catch (e) {
      if (e instanceof TypeError) {
        throw new Error(
          'Could not connect to server (reason: ' +
            e.message +
            ') Check your Internet connection and try again.',
        );
      } else {
        throw e;
      }
    }

    if (res.ok) {
      try {
        const json = JSON.parse(resText);
        // https://flow.org/en/docs/types/utilities/#toc-class
        // $FlowFixMe this will work in a future version of flow
        return new ResClass(json);
      } catch (e) {
        throw new Error(
          'Could not parse response for ' +
            method +
            ': ' +
            e.message +
            ' payload: ' +
            resText,
        );
      }
    }

    let errMessage: string;
    try {
      const errJSON = JSON.parse(resText);
      if (errJSON.code && errJSON.message) {
        // RPCError
        errMessage = errJSON.code + ': ' + errJSON.message;
      } else {
        errMessage = JSON.stringify(errJSON, null, 2);
      }
    } catch (e) {
      errMessage = resText;
    }
    throw new Error(
      'Request for ' +
        method +
        ' failed: ' +
        res.status +
        ' ' +
        res.statusText +
        ' ' +
        errMessage,
    );
  }

  addHeader(name: string, value: string) {
    this.additionalHeaders.push([name, value]);
  }

  async CheckAddress(req: CheckAddressRequest): Promise<EmptyReply> {
    return await this.call('CheckAddress', req, EmptyReply);
  }
  async GetExistingTerms(req: EmptyRequest): Promise<GetExistingTermsReply> {
    return await this.call('GetExistingTerms', req, GetExistingTermsReply);
  }
  async AgreeToTerms(req: EmptyRequest): Promise<AgreeToTermsReply> {
    return await this.call('AgreeToTerms', req, AgreeToTermsReply);
  }
  async CheckLeadState(req: EmptyRequest): Promise<CheckLeadStateReply> {
    return await this.call('CheckLeadState', req, CheckLeadStateReply);
  }
  async CreateLandlordPermissionHelpTicket(
    req: EmptyRequest,
  ): Promise<CreateLandlordPermissionHelpTicketReply> {
    return await this.call(
      'CreateLandlordPermissionHelpTicket',
      req,
      CreateLandlordPermissionHelpTicketReply,
    );
  }
  async EjectAutoaccept(req: EmptyRequest): Promise<EjectAutoacceptReply> {
    return await this.call('EjectAutoaccept', req, EjectAutoacceptReply);
  }
  async GetAddress(req: EmptyRequest): Promise<GetAddressReply> {
    return await this.call('GetAddress', req, GetAddressReply);
  }
  async IdentifyUser(req: EmptyRequest): Promise<IdentifyUserReply> {
    return await this.call('IdentifyUser', req, IdentifyUserReply);
  }
  async GetOnboardingStep(
    req: GetOnboardingStepRequest,
  ): Promise<GetOnboardingStepReply> {
    return await this.call('GetOnboardingStep', req, GetOnboardingStepReply);
  }
  async GetReasonForRejection(
    req: EmptyRequest,
  ): Promise<GetReasonForRejectionReply> {
    return await this.call(
      'GetReasonForRejection',
      req,
      GetReasonForRejectionReply,
    );
  }
  async GetSiteAddress(req: EmptyRequest): Promise<GetSiteAddressReply> {
    return await this.call('GetSiteAddress', req, GetSiteAddressReply);
  }
  async GetSiteBuildingType(
    req: EmptyRequest,
  ): Promise<GetSiteBuildingTypeReply> {
    return await this.call(
      'GetSiteBuildingType',
      req,
      GetSiteBuildingTypeReply,
    );
  }
  async GetSiteStatus(req: EmptyRequest): Promise<GetSiteStatusReply> {
    return await this.call('GetSiteStatus', req, GetSiteStatusReply);
  }
  async GetAppointmentDuration(
    req: EmptyRequest,
  ): Promise<GetAppointmentDurationReply> {
    return await this.call(
      'GetAppointmentDuration',
      req,
      GetAppointmentDurationReply,
    );
  }
  async NewCustomer(req: NewCustomerRequest): Promise<NewCustomerReply> {
    return await this.call('NewCustomer', req, NewCustomerReply);
  }
  async GetLandlordPermissionInfo(
    req: EmptyRequest,
  ): Promise<GetLandlordPermissionInfoReply> {
    return await this.call(
      'GetLandlordPermissionInfo',
      req,
      GetLandlordPermissionInfoReply,
    );
  }
  async SendHelpfulInformationForYourLandlordEmail(
    req: EmptyRequest,
  ): Promise<SendHelpfulInformationForYourLandlordEmailReply> {
    return await this.call(
      'SendHelpfulInformationForYourLandlordEmail',
      req,
      SendHelpfulInformationForYourLandlordEmailReply,
    );
  }
  async SetLandlordInformation(
    req: SetLandlordInformationRequest,
  ): Promise<SetLandlordInformationReply> {
    return await this.call(
      'SetLandlordInformation',
      req,
      SetLandlordInformationReply,
    );
  }
  async GetAvailablePlansForLead(
    req: GetAvailablePlansForLeadRequest,
  ): Promise<GetAvailablePlansForLeadReply> {
    return await this.call(
      'GetAvailablePlansForLead',
      req,
      GetAvailablePlansForLeadReply,
    );
  }
  async GetLeadPlan(req: EmptyRequest): Promise<GetLeadPlanReply> {
    return await this.call('GetLeadPlan', req, GetLeadPlanReply);
  }
  async GetLeadPromoValidation(
    req: EmptyRequest,
  ): Promise<GetLeadPromoValidationReply> {
    return await this.call(
      'GetLeadPromoValidation',
      req,
      GetLeadPromoValidationReply,
    );
  }
  async SetLeadPlan(req: SetLeadPlanRequest): Promise<SetLeadPlanReply> {
    return await this.call('SetLeadPlan', req, SetLeadPlanReply);
  }
  async SetToken(req: SetTokenRequest): Promise<SetTokenReply> {
    return await this.call('SetToken', req, SetTokenReply);
  }
  async BookAppointmentV2(
    req: BookAppointmentV2Request,
  ): Promise<BookAppointmentV2Reply> {
    return await this.call('BookAppointmentV2', req, BookAppointmentV2Reply);
  }
  async CancelExistingAppointment(
    req: EmptyRequest,
  ): Promise<CancelExistingAppointmentReply> {
    return await this.call(
      'CancelExistingAppointment',
      req,
      CancelExistingAppointmentReply,
    );
  }
  async GetAppointment(req: EmptyRequest): Promise<GetAppointmentReply> {
    return await this.call('GetAppointment', req, GetAppointmentReply);
  }
  async GetDayAvailability(
    req: GetDayAvailabilityRequest,
  ): Promise<GetDayAvailabilityReply> {
    return await this.call('GetDayAvailability', req, GetDayAvailabilityReply);
  }
  async GetExistingAppointment(
    req: EmptyRequest,
  ): Promise<GetExistingAppointmentReply> {
    return await this.call(
      'GetExistingAppointment',
      req,
      GetExistingAppointmentReply,
    );
  }
  async GetNextAvailableAppointment(
    req: EmptyRequest,
  ): Promise<GetNextAvailableAppointmentResponse> {
    return await this.call(
      'GetNextAvailableAppointment',
      req,
      GetNextAvailableAppointmentResponse,
    );
  }
  async GetWeeksAvailability(
    req: GetWeeksAvailabilityRequest,
  ): Promise<GetWeeksAvailabilityReply> {
    return await this.call(
      'GetWeeksAvailability',
      req,
      GetWeeksAvailabilityReply,
    );
  }
  async GetWorkOrderBookingStatus(
    req: WorkOrderApptToken,
  ): Promise<GetWorkOrderBookingStatusReply> {
    return await this.call(
      'GetWorkOrderBookingStatus',
      req,
      GetWorkOrderBookingStatusReply,
    );
  }
  async GetWorkOrderDayAvailability(
    req: GetWorkOrderDayAvailabilityRequest,
  ): Promise<GetDayAvailabilityReply> {
    return await this.call(
      'GetWorkOrderDayAvailability',
      req,
      GetDayAvailabilityReply,
    );
  }
  async BookWorkOrderAppt(req: BookWorkOrderApptRequest): Promise<EmptyReply> {
    return await this.call('BookWorkOrderAppt', req, EmptyReply);
  }
  async CancelWorkOrderAppt(req: WorkOrderApptToken): Promise<EmptyReply> {
    return await this.call('CancelWorkOrderAppt', req, EmptyReply);
  }
  async RescheduleWorkOrderAppt(
    req: BookWorkOrderApptRequest,
  ): Promise<EmptyReply> {
    return await this.call('RescheduleWorkOrderAppt', req, EmptyReply);
  }
  async GetWorkOrderNextAvailableAppt(
    req: WorkOrderApptToken,
  ): Promise<GetWorkOrderNextAvailableApptReply> {
    return await this.call(
      'GetWorkOrderNextAvailableAppt',
      req,
      GetWorkOrderNextAvailableApptReply,
    );
  }
  async GetStripeCard(req: EmptyRequest): Promise<GetStripeCardReply> {
    return await this.call('GetStripeCard', req, GetStripeCardReply);
  }
  async SetStripeCard(req: SetStripeCardRequest): Promise<SetStripeCardReply> {
    return await this.call('SetStripeCard', req, SetStripeCardReply);
  }
  async VisitedReferralStep(req: EmptyRequest): Promise<EmptyReply> {
    return await this.call('VisitedReferralStep', req, EmptyReply);
  }
  async GetAppliedPromo(req: EmptyRequest): Promise<GetAppliedPromoReply> {
    return await this.call('GetAppliedPromo', req, GetAppliedPromoReply);
  }
  async ApplyPromo(req: ApplyPromoRequest): Promise<ApplyPromoReply> {
    return await this.call('ApplyPromo', req, ApplyPromoReply);
  }
  async GetReferralNameFromToken(
    req: GetReferralNameFromTokenRequest,
  ): Promise<GetReferralNameFromTokenReply> {
    return await this.call(
      'GetReferralNameFromToken',
      req,
      GetReferralNameFromTokenReply,
    );
  }
  async UnsubscribeFromMarketingEmails(
    req: UnsubscribeFromMarketingEmailsRequest,
  ): Promise<UnsubscribeFromMarketingEmailsReply> {
    return await this.call(
      'UnsubscribeFromMarketingEmails',
      req,
      UnsubscribeFromMarketingEmailsReply,
    );
  }
  async ValidatePromo(req: ValidatePromoRequest): Promise<ValidatePromoReply> {
    return await this.call('ValidatePromo', req, ValidatePromoReply);
  }
  async GetReferrals(req: EmptyRequest): Promise<GetReferralsReply> {
    return await this.call('GetReferrals', req, GetReferralsReply);
  }
  async InviteFromLead(req: InviteFromLeadRequest): Promise<EmptyReply> {
    return await this.call('InviteFromLead', req, EmptyReply);
  }
  async GetPartnerLead(req: EmptyRequest): Promise<GetPartnerLeadReply> {
    return await this.call('GetPartnerLead', req, GetPartnerLeadReply);
  }
  async SubmitPartnerLead(
    req: UpdatePartnerLeadRequest,
  ): Promise<UpdatePartnerLeadReply> {
    return await this.call('SubmitPartnerLead', req, UpdatePartnerLeadReply);
  }
  async UpdatePartnerLead(
    req: UpdatePartnerLeadRequest,
  ): Promise<UpdatePartnerLeadReply> {
    return await this.call('UpdatePartnerLead', req, UpdatePartnerLeadReply);
  }
  async CreateSignupV2(req: EmptyRequest): Promise<CreateSignupV2Reply> {
    return await this.call('CreateSignupV2', req, CreateSignupV2Reply);
  }
  async UpdateSignupV2(
    req: UpdateSignupV2Request,
  ): Promise<UpdateSignupV2Reply> {
    return await this.call('UpdateSignupV2', req, UpdateSignupV2Reply);
  }
  async DevelopmentLogin(
    req: DevelopmentLoginRequest,
  ): Promise<DevelopmentLoginReply> {
    return await this.call('DevelopmentLogin', req, DevelopmentLoginReply);
  }
  async Log(req: LogRequest): Promise<EmptyReply> {
    return await this.call('Log', req, EmptyReply);
  }
  async Event(req: EventRequest): Promise<EmptyReply> {
    return await this.call('Event', req, EmptyReply);
  }
}
export {WebService};

// END cwn/web/service.proto
