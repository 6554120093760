// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../../google/protobuf';
/* eslint-enable */

// START cwn/site/maintmode/models.proto

export const MaintenanceTypeValues = {
  INVALID: 0,
  DEVICE_REPLACEMENT: 1,
  SWITCH_REPLACEMENT: 2,
  DEVICE_UPGRADE: 3,
  RADIO_REPLACEMENT: 4,
  RADIO_UPGRADE: 5,
  CAPACITY_UPGRADE: 6,
};
type MaintenanceType = $Keys<typeof MaintenanceTypeValues>;
export type {MaintenanceType};

export function MaintenanceTypeValue(n: number): MaintenanceType {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'DEVICE_REPLACEMENT';

    case 2:
      return 'SWITCH_REPLACEMENT';

    case 3:
      return 'DEVICE_UPGRADE';

    case 4:
      return 'RADIO_REPLACEMENT';

    case 5:
      return 'RADIO_UPGRADE';

    case 6:
      return 'CAPACITY_UPGRADE';

    default:
      return 'INVALID';
  }
}

class MaintenanceMode {
  site_id: string;
  created: ?Date;
  expires: ?Date;
  initiator: string;
  maintenance_type: MaintenanceType;
  edge_weight_override_ids: Array<string>;
  site_weight_override_id: string;
  enabled: boolean;
  updated: ?Date;

  constructor(props: $Shape<MaintenanceMode> = {}): void {
    if (!props) {
      props = {};
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.expires = null;
    if (props.hasOwnProperty('expires')) {
      const v = props.expires;
      this.expires = v && new Date(v);
    }

    this.initiator = '';
    if (props.hasOwnProperty('initiator')) {
      const v = props.initiator;
      this.initiator = v;
    }

    this.maintenance_type = MaintenanceTypeValue(0);
    if (props.hasOwnProperty('maintenance_type')) {
      const v = props.maintenance_type;
      this.maintenance_type = v;
    }

    this.edge_weight_override_ids = [];
    if (props.hasOwnProperty('edge_weight_override_ids')) {
      const v = props.edge_weight_override_ids;
      if (!Array.isArray(v)) {
        throw new Error(
          'repeated field edge_weight_override_ids should be array',
        );
      }
      this.edge_weight_override_ids = v.map(function(v) {
        return v;
      });
    }

    this.site_weight_override_id = '';
    if (props.hasOwnProperty('site_weight_override_id')) {
      const v = props.site_weight_override_id;
      this.site_weight_override_id = v;
    }

    this.enabled = false;
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = !!v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }
  }
}
export {MaintenanceMode};

// END cwn/site/maintmode/models.proto
