// @flow
import firebase, {type FirebaseUser} from 'firebase/app';
import 'firebase/auth';

import {
  ChangePasswordError,
  AuthActionModes,
  type AuthResult,
  type AuthStateChangeCallback,
  type AuthController,
  type AuthActionMode,
  type Unsubscribe,
} from 'auth/AuthController';
import {UpdateEmailError} from './AuthController';

export type FirebaseConfig = {|
  apiKey: string,
  authDomain: string,
  projectId: string,
|};

type AuthError = typeof firebase.auth.Error;

export class FirebaseAuthController implements AuthController {
  app: firebase.app.App;

  constructor(config: FirebaseConfig) {
    this.app = firebase.initializeApp(config);
  }

  latestToken: ?string;
  token = () => this.latestToken;

  handleUser = async (user: FirebaseUser) => {
    const token = await user.getIdToken(true);
    this.latestToken = token;
    this.handleAuthResult({token});
  };

  authenticated = () => {
    return Boolean(this.app.auth().currentUser && this.latestToken);
  };

  unsubscribe: ?Unsubscribe = null;
  onAuthStateChanged = (cb: AuthStateChangeCallback): Unsubscribe => {
    this.authStateChangedCallbacks.push(cb);

    if (!this.unsubscribe) {
      this.unsubscribe = this.app
        .auth()
        .onAuthStateChanged(this.handleAuthStateChanged);
    }

    return () => {
      this.authStateChangedCallbacks = this.authStateChangedCallbacks.filter(
        callback => callback !== cb,
      );

      if (this.unsubscribe && !this.authStateChangedCallbacks.length) {
        this.unsubscribe();
        this.unsubscribe = null;
      }
    };
  };

  handleAuthStateChanged = (user: ?FirebaseUser) => {
    if (user) {
      this.handleUser(user);
    } else {
      this.handleAuthResult(null);
    }
  };

  authStateChangedCallbacks: AuthStateChangeCallback[] = [];
  handleAuthResult = (r: AuthResult) => {
    if (!this.loaded) {
      this.loaded = true;
    }

    for (const cb of this.authStateChangedCallbacks) {
      cb(r);
    }
  };

  loaded: boolean = false;
  initialLoadComplete = () => this.loaded;

  getAuthActionMode = (search: ?string): ?AuthActionMode => {
    if (!search) {
      return null;
    }

    const params = new URLSearchParams(search);
    switch (params.get('mode')) {
      case 'resetPassword':
        return AuthActionModes.RESET_PASSWORD;
      case 'recoverEmail':
        return AuthActionModes.RECOVER_EMAIL;
      case 'verifyEmail':
        return AuthActionModes.VERIFY_EMAIL;
      default:
        return null;
    }
  };

  getAuthActionCode = (search: ?string): ?string => {
    if (!search) {
      return null;
    }

    const params = new URLSearchParams(search);
    return params.get('oobCode');
  };

  createUserWithEmailAndPassword = async (email: string, password: string) => {
    try {
      const userCredential = await this.app
        .auth()
        .createUserWithEmailAndPassword(email, password);
      await this.handleUser(userCredential.user);
    } catch (e) {
      return e;
    }
  };

  signInWithEmailAndPassword = async (
    email: string,
    password: string,
    remember: boolean,
  ) => {
    await this.app
      .auth()
      .setPersistence(
        remember
          ? firebase.auth.Auth.Persistence.LOCAL
          : firebase.auth.Auth.Persistence.SESSION,
      );

    try {
      const userCredential = await this.app
        .auth()
        .signInWithEmailAndPassword(email, password);
      await this.handleUser(userCredential.user);
    } catch (e) {
      switch (e.code) {
        case 'auth/wrong-password':
          throw new Error(
            "The email address and/or password you entered doesn't match our records. Please try again.",
          );
        case 'auth/user-disabled':
          throw new Error('Account has been disabled.');
        case 'auth/user-not-found':
          throw new Error(
            "The email address and/or password you entered doesn't match our records. Please try again.",
          );
        case 'auth/invalid-email':
          throw new Error('Email is invalid.');
        default:
          throw e;
      }
    }
  };

  signInWithToken = async (token: string, remember: boolean) => {
    await this.app
      .auth()
      .setPersistence(
        remember
          ? firebase.auth.Auth.Persistence.LOCAL
          : firebase.auth.Auth.Persistence.SESSION,
      );

    try {
      const userCredential = await this.app.auth().signInWithCustomToken(token);
      await this.handleUser(userCredential.user);
    } catch (e) {
      switch (e.code) {
        default:
          throw new Error('Invalid token');
      }
    }
  };

  resetPassword = async (email: string) => {
    try {
      await this.app.auth().sendPasswordResetEmail(email);
    } catch (e) {
      switch (e.code) {
        case 'auth/invalid-email': {
          throw new Error('Email is invalid.');
        }
        case 'auth/user-not-found': {
          throw new Error(
            "The email address and/or password you entered doesn't match our records. Please try again.",
          );
        }
        default: {
          throw e;
        }
      }
    }
  };

  verifyPasswordResetCode = async (code: string): Promise<string> => {
    try {
      return await this.app.auth().verifyPasswordResetCode(code);
    } catch (e) {
      switch (e.code) {
        case 'auth/expired-action-code': {
          throw new Error('This reset password link has expired.');
        }
        case 'auth/invalid-action-code': {
          throw new Error(
            'This reset password link is invalid or has already been used.',
          );
        }
        case 'auth/user-disabled': {
          throw new Error('This email address has been disabled.');
        }
        case 'auth/user-not-found': {
          throw new Error(
            'There is no email address that matches this reset password link.',
          );
        }
        default: {
          throw e;
        }
      }
    }
  };

  confirmPasswordReset = async (code: string, newPassword: string) => {
    try {
      await this.app.auth().confirmPasswordReset(code, newPassword);
    } catch (e) {
      switch (e.code) {
        case 'auth/expired-action-code': {
          throw new Error('This reset password link has expired.');
        }
        case 'auth/invalid-action-code': {
          throw new Error(
            'This reset password link is invalid or has already been used.',
          );
        }
        case 'auth/user-disabled': {
          throw new Error('This email address has been disabled.');
        }
        case 'auth/user-not-found': {
          throw new Error(
            "The email address and/or password you entered doesn't match our records. Please try again.",
          );
        }
        case 'auth/weak-password': {
          throw new Error(
            'This password is too weak. Please use a strong password.',
          );
        }
        default: {
          throw e;
        }
      }
    }
  };

  applyRecoverEmailCode = async (code: string): Promise<string> => {
    const auth = this.app.auth();
    try {
      // validate action code
      const actionCodeInfo = await auth.checkActionCode(code);
      const restoredEmail = actionCodeInfo.data.email;

      // revert to old email
      await auth.applyActionCode(code);

      return restoredEmail;
    } catch (e) {
      switch (e.code) {
        case 'auth/expired-action-code':
          throw new Error('This email recovery link has expired.');
        case 'auth/invalid-action-code':
        case 'auth/user-not-found':
        case 'auth/user-disabled':
        default:
          throw new Error(
            'This email recovery link is invalid or has already been used.',
          );
      }
    }
  };

  applyVerifyEmailCode = async (code: string): Promise<string> => {
    const auth = this.app.auth();
    try {
      // validate action code
      const actionCodeInfo = await auth.checkActionCode(code);
      const email = actionCodeInfo.data.email;

      // verify email
      await auth.applyActionCode(code);

      return email;
    } catch (e) {
      switch (e.code) {
        case 'auth/expired-action-code':
          throw new Error('This email verification link has expired.');
        case 'auth/invalid-action-code':
        case 'auth/user-not-found':
        case 'auth/user-disabled':
        default:
          throw new Error(
            'This email verification link is invalid or has already been used.',
          );
      }
    }
  };

  changePassword = async (oldPassword: string, newPassword: string) => {
    const user = this.app.auth().currentUser;
    if (!user || !user.email) {
      throw new ChangePasswordError(
        'Email address is missing from this account',
        'message',
      );
    }

    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        oldPassword,
      );
      await user.reauthenticateWithCredential(credential);
    } catch (e) {
      switch (e.code) {
        case 'auth/wrong-password':
          throw new ChangePasswordError(
            "The password you entered doesn't match our records. Please try again.",
            'oldPassword',
          );
        case 'auth/invalid-credential': {
          throw new ChangePasswordError(
            "The email address and/or password you entered doesn't match our records. Please try again.",
            'oldPassword',
          );
        }
        default: {
          throw new ChangePasswordError(e.message, 'message');
        }
      }
    }

    try {
      await user.updatePassword(newPassword);
    } catch (e) {
      switch (e.code) {
        case 'auth/weak-password': {
          throw new ChangePasswordError(
            'This password is too weak. Please use a strong password.',
            'password',
          );
        }
        default: {
          throw new ChangePasswordError(e.message, 'message');
        }
      }
    }
  };

  setPasswordWithToken = async (token: string, password: string) => {
    // the firebase API doesn't let us directly set a password with a token, so we have to:
    //   - log in the user with the custom token
    //   - update the user's password while logged in
    //   - immediately log them back out

    let credential: ?typeof firebase.auth.FirebaseUserCredential;

    try {
      credential = await this.app.auth().signInWithCustomToken(token);
    } catch (e) {
      throw new Error('Invalid token');
    }

    let setPasswordError: ?AuthError;

    try {
      await credential.user.updatePassword(password);
    } catch (e) {
      setPasswordError = e;
    }

    // this API shouldn't change the login state, so log back out
    if (credential) {
      try {
        await this.app.auth().signOut();
      } catch (e) {
        console.error(e);
      }
    }

    if (setPasswordError) {
      switch (setPasswordError.code) {
        case 'auth/weak-password': {
          throw new ChangePasswordError(
            'This password is too weak. Please use a strong password.',
            'password',
          );
        }
        default: {
          throw new ChangePasswordError(setPasswordError.message, 'message');
        }
      }
    }
  };

  updateEmail = async (newEmail: string, password: string) => {
    const user = this.app.auth().currentUser;
    const oldEmail = user && user.email;
    if (!user || !oldEmail) {
      throw new Error('Email address is missing from this account');
    }
    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        oldEmail,
        password,
      );
      await user.reauthenticateWithCredential(credential);
    } catch (e) {
      switch (e.code) {
        case 'auth/invalid-credential':
        case 'auth/wrong-password':
          throw new UpdateEmailError(
            "The password you entered doesn't match our records. Please try again.",
            'password',
          );
        default: {
          throw new UpdateEmailError(e.message, 'email');
        }
      }
    }
    try {
      await user.updateEmail(newEmail);
    } catch (e) {
      switch (e.code) {
        case 'auth/invalid-email':
        case 'auth/email-already-in-use':
          throw new UpdateEmailError(
            'The new email you entered is either invalid or has already been claimed.',
            'email',
          );
        default:
          throw new Error(e.message);
      }
    }
  };

  getTokenExpiration = (token: string): number => {
    try {
      const jwt = JSON.parse(atob(token.split('.')[1]));
      const exp = jwt && jwt.exp;

      if (typeof exp === 'number') {
        return exp * 1000;
      }
    } catch (e) {
      console.error(e);
    }
    return 0;
  };

  signOut = async () => {
    await this.app.auth().signOut();
  };
}

export default FirebaseAuthController;
