// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../../google/protobuf';
/* eslint-enable */
import * as cwn_proc from '../../../cwn/proc';
import * as cwn_fs from '../../../cwn/fs';
import * as cwn_config from '../../../cwn/config';

// START cwn/devices/status/models.proto

class Heartbeat {
  device_id: string;
  created: ?Date;
  boot_id: string;
  process_infos: Array<cwn_proc.Info>;

  constructor(props: $Shape<Heartbeat> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.boot_id = '';
    if (props.hasOwnProperty('boot_id')) {
      const v = props.boot_id;
      this.boot_id = v;
    }

    this.process_infos = [];
    if (props.hasOwnProperty('process_infos')) {
      const v = props.process_infos;
      if (!Array.isArray(v)) {
        throw new Error('repeated field process_infos should be array');
      }
      this.process_infos = v.map(function(v) {
        return new cwn_proc.Info(v);
      });
    }
  }
}
export {Heartbeat};

class MostRecentHeartbeat {
  device_id: string;
  heartbeat: ?Heartbeat;

  constructor(props: $Shape<MostRecentHeartbeat> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.heartbeat = null;
    if (props.hasOwnProperty('heartbeat')) {
      const v = props.heartbeat;
      this.heartbeat = v && new Heartbeat(v);
    }
  }

  getHeartbeat(): Heartbeat {
    if (this.heartbeat) {
      return this.heartbeat;
    }
    return new Heartbeat();
  }
}
export {MostRecentHeartbeat};

class Snapshot {
  id: string;
  device_id: string;
  boot_id: string;
  site_id: string;
  created: ?Date;
  snapshot: ?cwn_fs.Snapshot;

  constructor(props: $Shape<Snapshot> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.boot_id = '';
    if (props.hasOwnProperty('boot_id')) {
      const v = props.boot_id;
      this.boot_id = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.snapshot = null;
    if (props.hasOwnProperty('snapshot')) {
      const v = props.snapshot;
      this.snapshot = v && new cwn_fs.Snapshot(v);
    }
  }

  getSnapshot(): cwn_fs.Snapshot {
    if (this.snapshot) {
      return this.snapshot;
    }
    return new cwn_fs.Snapshot();
  }
}
export {Snapshot};

class Config {
  device_id: string;
  updated: ?Date;
  config: ?cwn_config.Config;
  node_network_config: ?cwn_config.NodeNetworkConfig;

  constructor(props: $Shape<Config> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.config = null;
    if (props.hasOwnProperty('config')) {
      const v = props.config;
      this.config = v && new cwn_config.Config(v);
    }

    this.node_network_config = null;
    if (props.hasOwnProperty('node_network_config')) {
      const v = props.node_network_config;
      this.node_network_config = v && new cwn_config.NodeNetworkConfig(v);
    }
  }

  getConfig(): cwn_config.Config {
    if (this.config) {
      return this.config;
    }
    return new cwn_config.Config();
  }

  getNodeNetworkConfig(): cwn_config.NodeNetworkConfig {
    if (this.node_network_config) {
      return this.node_network_config;
    }
    return new cwn_config.NodeNetworkConfig();
  }
}
export {Config};

class HWMon_Mon_Temp {
  name: string;
  crit: string;
  crit_alarm: string;
  input: string;
  label: string;
  max: string;

  constructor(props: $Shape<HWMon_Mon_Temp> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.crit = '0';
    if (props.hasOwnProperty('crit')) {
      const v = props.crit;
      this.crit = v;
    }

    this.crit_alarm = '0';
    if (props.hasOwnProperty('crit_alarm')) {
      const v = props.crit_alarm;
      this.crit_alarm = v;
    }

    this.input = '0';
    if (props.hasOwnProperty('input')) {
      const v = props.input;
      this.input = v;
    }

    this.label = '';
    if (props.hasOwnProperty('label')) {
      const v = props.label;
      this.label = v;
    }

    this.max = '0';
    if (props.hasOwnProperty('max')) {
      const v = props.max;
      this.max = v;
    }
  }
}
export {HWMon_Mon_Temp};
class HWMon_Mon {
  name: string;
  temps: Array<HWMon_Mon_Temp>;

  constructor(props: $Shape<HWMon_Mon> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.temps = [];
    if (props.hasOwnProperty('temps')) {
      const v = props.temps;
      if (!Array.isArray(v)) {
        throw new Error('repeated field temps should be array');
      }
      this.temps = v.map(function(v) {
        return new HWMon_Mon_Temp(v);
      });
    }
  }
}
export {HWMon_Mon};
class HWMon {
  device_id: string;
  updated: ?Date;
  mons: Array<HWMon_Mon>;

  constructor(props: $Shape<HWMon> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.mons = [];
    if (props.hasOwnProperty('mons')) {
      const v = props.mons;
      if (!Array.isArray(v)) {
        throw new Error('repeated field mons should be array');
      }
      this.mons = v.map(function(v) {
        return new HWMon_Mon(v);
      });
    }
  }
}
export {HWMon};

class LoadAvg {
  device_id: string;
  updated: ?Date;
  load_avg: ?cwn_proc.LoadAvg;

  constructor(props: $Shape<LoadAvg> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.load_avg = null;
    if (props.hasOwnProperty('load_avg')) {
      const v = props.load_avg;
      this.load_avg = v && new cwn_proc.LoadAvg(v);
    }
  }

  getLoadAvg(): cwn_proc.LoadAvg {
    if (this.load_avg) {
      return this.load_avg;
    }
    return new cwn_proc.LoadAvg();
  }
}
export {LoadAvg};

class MemInfo {
  device_id: string;
  updated: ?Date;
  mem_info: ?cwn_proc.MemInfo;

  constructor(props: $Shape<MemInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.mem_info = null;
    if (props.hasOwnProperty('mem_info')) {
      const v = props.mem_info;
      this.mem_info = v && new cwn_proc.MemInfo(v);
    }
  }

  getMemInfo(): cwn_proc.MemInfo {
    if (this.mem_info) {
      return this.mem_info;
    }
    return new cwn_proc.MemInfo();
  }
}
export {MemInfo};

class MmcInfo_Device {
  name: string;
  mmc_info: ?cwn_proc.MmcInfo;

  constructor(props: $Shape<MmcInfo_Device> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.mmc_info = null;
    if (props.hasOwnProperty('mmc_info')) {
      const v = props.mmc_info;
      this.mmc_info = v && new cwn_proc.MmcInfo(v);
    }
  }

  getMmcInfo(): cwn_proc.MmcInfo {
    if (this.mmc_info) {
      return this.mmc_info;
    }
    return new cwn_proc.MmcInfo();
  }
}
export {MmcInfo_Device};
class MmcInfo {
  device_id: string;
  updated: ?Date;
  devices: Array<MmcInfo_Device>;

  constructor(props: $Shape<MmcInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.devices = [];
    if (props.hasOwnProperty('devices')) {
      const v = props.devices;
      if (!Array.isArray(v)) {
        throw new Error('repeated field devices should be array');
      }
      this.devices = v.map(function(v) {
        return new MmcInfo_Device(v);
      });
    }
  }
}
export {MmcInfo};

class Mounts {
  device_id: string;
  updated: ?Date;
  mounts: Array<cwn_fs.Mount>;

  constructor(props: $Shape<Mounts> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.mounts = [];
    if (props.hasOwnProperty('mounts')) {
      const v = props.mounts;
      if (!Array.isArray(v)) {
        throw new Error('repeated field mounts should be array');
      }
      this.mounts = v.map(function(v) {
        return new cwn_fs.Mount(v);
      });
    }
  }
}
export {Mounts};

class ProcNetDev {
  device_id: string;
  updated: ?Date;
  lines: Array<cwn_proc.NetDevLine>;

  constructor(props: $Shape<ProcNetDev> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.lines = [];
    if (props.hasOwnProperty('lines')) {
      const v = props.lines;
      if (!Array.isArray(v)) {
        throw new Error('repeated field lines should be array');
      }
      this.lines = v.map(function(v) {
        return new cwn_proc.NetDevLine(v);
      });
    }
  }
}
export {ProcNetDev};

class ProcNetDevHistory {
  id: string;
  proc_net_dev: ?ProcNetDev;

  constructor(props: $Shape<ProcNetDevHistory> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.proc_net_dev = null;
    if (props.hasOwnProperty('proc_net_dev')) {
      const v = props.proc_net_dev;
      this.proc_net_dev = v && new ProcNetDev(v);
    }
  }

  getProcNetDev(): ProcNetDev {
    if (this.proc_net_dev) {
      return this.proc_net_dev;
    }
    return new ProcNetDev();
  }
}
export {ProcNetDevHistory};

class Stat {
  device_id: string;
  updated: ?Date;
  stat: ?cwn_proc.Stat;

  constructor(props: $Shape<Stat> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.stat = null;
    if (props.hasOwnProperty('stat')) {
      const v = props.stat;
      this.stat = v && new cwn_proc.Stat(v);
    }
  }

  getStat(): cwn_proc.Stat {
    if (this.stat) {
      return this.stat;
    }
    return new cwn_proc.Stat();
  }
}
export {Stat};

class Thermal_ThermalZone_TripPoint {
  name: string;
  type: string;
  temp: string;
  hyst: string;

  constructor(props: $Shape<Thermal_ThermalZone_TripPoint> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.type = '';
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.temp = '0';
    if (props.hasOwnProperty('temp')) {
      const v = props.temp;
      this.temp = v;
    }

    this.hyst = '0';
    if (props.hasOwnProperty('hyst')) {
      const v = props.hyst;
      this.hyst = v;
    }
  }
}
export {Thermal_ThermalZone_TripPoint};
class Thermal_ThermalZone {
  name: string;
  type: string;
  temp: string;
  trip_points: Array<Thermal_ThermalZone_TripPoint>;

  constructor(props: $Shape<Thermal_ThermalZone> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.type = '';
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.temp = '0';
    if (props.hasOwnProperty('temp')) {
      const v = props.temp;
      this.temp = v;
    }

    this.trip_points = [];
    if (props.hasOwnProperty('trip_points')) {
      const v = props.trip_points;
      if (!Array.isArray(v)) {
        throw new Error('repeated field trip_points should be array');
      }
      this.trip_points = v.map(function(v) {
        return new Thermal_ThermalZone_TripPoint(v);
      });
    }
  }
}
export {Thermal_ThermalZone};
class Thermal {
  device_id: string;
  updated: ?Date;
  thermal_zones: Array<Thermal_ThermalZone>;

  constructor(props: $Shape<Thermal> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.thermal_zones = [];
    if (props.hasOwnProperty('thermal_zones')) {
      const v = props.thermal_zones;
      if (!Array.isArray(v)) {
        throw new Error('repeated field thermal_zones should be array');
      }
      this.thermal_zones = v.map(function(v) {
        return new Thermal_ThermalZone(v);
      });
    }
  }
}
export {Thermal};

class Conntrack_ConntrackCounters {
  entries: string;
  drops: string;

  constructor(props: $Shape<Conntrack_ConntrackCounters> = {}): void {
    if (!props) {
      props = {};
    }

    this.entries = '0';
    if (props.hasOwnProperty('entries')) {
      const v = props.entries;
      this.entries = v;
    }

    this.drops = '0';
    if (props.hasOwnProperty('drops')) {
      const v = props.drops;
      this.drops = v;
    }
  }
}
export {Conntrack_ConntrackCounters};
class Conntrack {
  device_id: string;
  updated: ?Date;
  counters: Array<Conntrack_ConntrackCounters>;

  constructor(props: $Shape<Conntrack> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.counters = [];
    if (props.hasOwnProperty('counters')) {
      const v = props.counters;
      if (!Array.isArray(v)) {
        throw new Error('repeated field counters should be array');
      }
      this.counters = v.map(function(v) {
        return new Conntrack_ConntrackCounters(v);
      });
    }
  }
}
export {Conntrack};

class Softnetstats_SoftnetstatCounters {
  frames: string;
  drops: string;
  timesqueeze: string;
  cpucollision: string;
  rps: string;
  flowlimit: string;

  constructor(props: $Shape<Softnetstats_SoftnetstatCounters> = {}): void {
    if (!props) {
      props = {};
    }

    this.frames = '0';
    if (props.hasOwnProperty('frames')) {
      const v = props.frames;
      this.frames = v;
    }

    this.drops = '0';
    if (props.hasOwnProperty('drops')) {
      const v = props.drops;
      this.drops = v;
    }

    this.timesqueeze = '0';
    if (props.hasOwnProperty('timesqueeze')) {
      const v = props.timesqueeze;
      this.timesqueeze = v;
    }

    this.cpucollision = '0';
    if (props.hasOwnProperty('cpucollision')) {
      const v = props.cpucollision;
      this.cpucollision = v;
    }

    this.rps = '0';
    if (props.hasOwnProperty('rps')) {
      const v = props.rps;
      this.rps = v;
    }

    this.flowlimit = '0';
    if (props.hasOwnProperty('flowlimit')) {
      const v = props.flowlimit;
      this.flowlimit = v;
    }
  }
}
export {Softnetstats_SoftnetstatCounters};
class Softnetstats {
  device_id: string;
  updated: ?Date;
  counters: Array<Softnetstats_SoftnetstatCounters>;

  constructor(props: $Shape<Softnetstats> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.counters = [];
    if (props.hasOwnProperty('counters')) {
      const v = props.counters;
      if (!Array.isArray(v)) {
        throw new Error('repeated field counters should be array');
      }
      this.counters = v.map(function(v) {
        return new Softnetstats_SoftnetstatCounters(v);
      });
    }
  }
}
export {Softnetstats};

class UptimeStat {
  device_id: string;
  updated: ?Date;
  uptime: ?cwn_proc.UptimeInfo;

  constructor(props: $Shape<UptimeStat> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.uptime = null;
    if (props.hasOwnProperty('uptime')) {
      const v = props.uptime;
      this.uptime = v && new cwn_proc.UptimeInfo(v);
    }
  }

  getUptime(): cwn_proc.UptimeInfo {
    if (this.uptime) {
      return this.uptime;
    }
    return new cwn_proc.UptimeInfo();
  }
}
export {UptimeStat};

// END cwn/devices/status/models.proto
