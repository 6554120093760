// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */

// START cwn/cpm/models.proto

export const ArchitectureValues = {
  UNKNOWN: 0,
  X86_64: 1,
  ARM: 2,
  ARM64: 3,
  ALL: 4,
  MIPSLE: 5,
  MIPS64: 6,
};
type Architecture = $Keys<typeof ArchitectureValues>;
export type {Architecture};

export function ArchitectureValue(n: number): Architecture {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'X86_64';

    case 2:
      return 'ARM';

    case 3:
      return 'ARM64';

    case 4:
      return 'ALL';

    case 5:
      return 'MIPSLE';

    case 6:
      return 'MIPS64';

    default:
      return 'UNKNOWN';
  }
}

export const Package_TypeValues = {
  SERVICE: 0,
  DATA: 1,
};
type Package_Type = $Keys<typeof Package_TypeValues>;
export type {Package_Type};

export function Package_TypeValue(n: number): Package_Type {
  switch (n) {
    case 0:
      return 'SERVICE';

    case 1:
      return 'DATA';

    default:
      return 'SERVICE';
  }
}

class Package {
  name: string;
  latest_version: number;
  updated: ?Date;
  type: Package_Type;

  constructor(props: $Shape<Package> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.latest_version = 0;
    if (props.hasOwnProperty('latest_version')) {
      const v = props.latest_version;
      this.latest_version = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.type = Package_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }
  }
}
export {Package};

class Version {
  package_name: string;
  version: number;
  created: ?Date;
  git_sha: string;
  comment: string;

  constructor(props: $Shape<Version> = {}): void {
    if (!props) {
      props = {};
    }

    this.package_name = '';
    if (props.hasOwnProperty('package_name')) {
      const v = props.package_name;
      this.package_name = v;
    }

    this.version = 0;
    if (props.hasOwnProperty('version')) {
      const v = props.version;
      this.version = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.git_sha = '';
    if (props.hasOwnProperty('git_sha')) {
      const v = props.git_sha;
      this.git_sha = v;
    }

    this.comment = '';
    if (props.hasOwnProperty('comment')) {
      const v = props.comment;
      this.comment = v;
    }
  }
}
export {Version};

class Archive {
  package_name: string;
  version: number;
  architecture: Architecture;
  created: ?Date;
  md5sum: string;

  constructor(props: $Shape<Archive> = {}): void {
    if (!props) {
      props = {};
    }

    this.package_name = '';
    if (props.hasOwnProperty('package_name')) {
      const v = props.package_name;
      this.package_name = v;
    }

    this.version = 0;
    if (props.hasOwnProperty('version')) {
      const v = props.version;
      this.version = v;
    }

    this.architecture = ArchitectureValue(0);
    if (props.hasOwnProperty('architecture')) {
      const v = props.architecture;
      this.architecture = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.md5sum = '';
    if (props.hasOwnProperty('md5sum')) {
      const v = props.md5sum;
      this.md5sum = v;
    }
  }
}
export {Archive};

class DefaultArchive {
  archive: ?Archive;
  updated: ?Date;

  constructor(props: $Shape<DefaultArchive> = {}): void {
    if (!props) {
      props = {};
    }

    this.archive = null;
    if (props.hasOwnProperty('archive')) {
      const v = props.archive;
      this.archive = v && new Archive(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }
  }

  getArchive(): Archive {
    if (this.archive) {
      return this.archive;
    }
    return new Archive();
  }
}
export {DefaultArchive};

class DefaultArchives {
  default_archives: Array<DefaultArchive>;

  constructor(props: $Shape<DefaultArchives> = {}): void {
    if (!props) {
      props = {};
    }

    this.default_archives = [];
    if (props.hasOwnProperty('default_archives')) {
      const v = props.default_archives;
      if (!Array.isArray(v)) {
        throw new Error('repeated field default_archives should be array');
      }
      this.default_archives = v.map(function(v) {
        return new DefaultArchive(v);
      });
    }
  }
}
export {DefaultArchives};

// END cwn/cpm/models.proto
