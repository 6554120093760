// @flow

export type AuthResult = null | {token: string};
export type AuthStateChangeCallback = AuthResult => void;
export type Unsubscribe = () => void;

export const AuthActionModes = {
  RESET_PASSWORD: 'RESET_PASSWORD',
  RECOVER_EMAIL: 'RECOVER_EMAIL',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
};

export type AuthActionMode = $Keys<typeof AuthActionModes>;

export interface AuthController {
  authenticated: () => boolean;
  initialLoadComplete: () => boolean;
  token: () => ?string;
  onAuthStateChanged: ((AuthResult) => void) => Unsubscribe;
  getAuthActionMode: (search: ?string) => ?AuthActionMode;
  getAuthActionCode: (search: ?string) => ?string;
  createUserWithEmailAndPassword: (
    email: string,
    password: string,
  ) => Promise<?Error>;
  signInWithEmailAndPassword: (
    email: string,
    password: string,
    rememberSession: boolean,
  ) => Promise<?Error>;
  signInWithToken: (token: string, rememberSession: boolean) => Promise<void>;
  signOut: () => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  setPasswordWithToken: (token: string, password: string) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  verifyPasswordResetCode: (code: string) => Promise<string>;
  applyRecoverEmailCode: (code: string) => Promise<string>;
  applyVerifyEmailCode: (code: string) => Promise<string>;
  confirmPasswordReset: (code: string, password: string) => Promise<void>;
  getTokenExpiration: (token: string) => number;
  updateEmail: (newEmail: string, password: string) => Promise<void>;
}

export class AuthError<T> extends Error {
  field: T;
  constructor(message: string, field: T) {
    super(message);
    this.field = field;
  }
}

export class ChangePasswordError extends AuthError<
  'oldPassword' | 'password' | 'passwordConfirm' | 'message',
> {}

export class UpdateEmailError extends AuthError<'email' | 'password'> {}
