// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */

// START cwn/module/models.proto

class ModuleRunStatus {
  device_id: string;
  module_runs: Array<ModuleRun>;
  updated: ?Date;
  agent_uptime: number;

  constructor(props: $Shape<ModuleRunStatus> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.module_runs = [];
    if (props.hasOwnProperty('module_runs')) {
      const v = props.module_runs;
      if (!Array.isArray(v)) {
        throw new Error('repeated field module_runs should be array');
      }
      this.module_runs = v.map(function(v) {
        return new ModuleRun(v);
      });
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.agent_uptime = 0;
    if (props.hasOwnProperty('agent_uptime')) {
      const v = props.agent_uptime;
      this.agent_uptime = v;
    }
  }
}
export {ModuleRunStatus};

class ModuleRun {
  name: string;
  timestamp: ?Date;

  constructor(props: $Shape<ModuleRun> = {}): void {
    if (!props) {
      props = {};
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.timestamp = null;
    if (props.hasOwnProperty('timestamp')) {
      const v = props.timestamp;
      this.timestamp = v && new Date(v);
    }
  }
}
export {ModuleRun};

// END cwn/module/models.proto
