// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../../google/protobuf';
/* eslint-enable */
import * as cwn_network from '../../../cwn/network';

// START cwn/ubnt/status/models.proto

class AirmaxStatus_Interface_Status {
  cable_len: string;
  duplex: boolean;
  ipaddr: string;
  plugged: boolean;
  speed: string;
  rx_bytes: string;
  rx_errors: string;
  tx_bytes: string;
  tx_errors: string;
  snr: Array<string>;

  constructor(props: $Shape<AirmaxStatus_Interface_Status> = {}): void {
    if (!props) {
      props = {};
    }

    this.cable_len = '0';
    if (props.hasOwnProperty('cable_len')) {
      const v = props.cable_len;
      this.cable_len = v;
    }

    this.duplex = false;
    if (props.hasOwnProperty('duplex')) {
      const v = props.duplex;
      this.duplex = !!v;
    }

    this.ipaddr = '';
    if (props.hasOwnProperty('ipaddr')) {
      const v = props.ipaddr;
      this.ipaddr = v;
    }

    this.plugged = false;
    if (props.hasOwnProperty('plugged')) {
      const v = props.plugged;
      this.plugged = !!v;
    }

    this.speed = '0';
    if (props.hasOwnProperty('speed')) {
      const v = props.speed;
      this.speed = v;
    }

    this.rx_bytes = '0';
    if (props.hasOwnProperty('rx_bytes')) {
      const v = props.rx_bytes;
      this.rx_bytes = v;
    }

    this.rx_errors = '0';
    if (props.hasOwnProperty('rx_errors')) {
      const v = props.rx_errors;
      this.rx_errors = v;
    }

    this.tx_bytes = '0';
    if (props.hasOwnProperty('tx_bytes')) {
      const v = props.tx_bytes;
      this.tx_bytes = v;
    }

    this.tx_errors = '0';
    if (props.hasOwnProperty('tx_errors')) {
      const v = props.tx_errors;
      this.tx_errors = v;
    }

    this.snr = [];
    if (props.hasOwnProperty('snr')) {
      const v = props.snr;
      if (!Array.isArray(v)) {
        throw new Error('repeated field snr should be array');
      }
      this.snr = v.map(function(v) {
        return v;
      });
    }
  }
}
export {AirmaxStatus_Interface_Status};
class AirmaxStatus_Interface {
  enabled: boolean;
  duplex: boolean;
  hwaddr: string;
  ifname: string;
  mtu: string;
  status: ?AirmaxStatus_Interface_Status;

  constructor(props: $Shape<AirmaxStatus_Interface> = {}): void {
    if (!props) {
      props = {};
    }

    this.enabled = false;
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = !!v;
    }

    this.duplex = false;
    if (props.hasOwnProperty('duplex')) {
      const v = props.duplex;
      this.duplex = !!v;
    }

    this.hwaddr = '';
    if (props.hasOwnProperty('hwaddr')) {
      const v = props.hwaddr;
      this.hwaddr = v;
    }

    this.ifname = '';
    if (props.hasOwnProperty('ifname')) {
      const v = props.ifname;
      this.ifname = v;
    }

    this.mtu = '0';
    if (props.hasOwnProperty('mtu')) {
      const v = props.mtu;
      this.mtu = v;
    }

    this.status = null;
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v && new AirmaxStatus_Interface_Status(v);
    }
  }

  getStatus(): AirmaxStatus_Interface_Status {
    if (this.status) {
      return this.status;
    }
    return new AirmaxStatus_Interface_Status();
  }
}
export {AirmaxStatus_Interface};
class AirmaxStatus_Host {
  cpuload: number;
  devmodel: string;
  freeram: string;
  fwversion: string;
  hostname: string;
  loadavg: number;
  netrole: string;
  temperature: string;
  totalram: string;
  uptime: string;
  time: string;
  timestamp: string;

  constructor(props: $Shape<AirmaxStatus_Host> = {}): void {
    if (!props) {
      props = {};
    }

    this.cpuload = 0;
    if (props.hasOwnProperty('cpuload')) {
      const v = props.cpuload;
      this.cpuload = v;
    }

    this.devmodel = '';
    if (props.hasOwnProperty('devmodel')) {
      const v = props.devmodel;
      this.devmodel = v;
    }

    this.freeram = '0';
    if (props.hasOwnProperty('freeram')) {
      const v = props.freeram;
      this.freeram = v;
    }

    this.fwversion = '';
    if (props.hasOwnProperty('fwversion')) {
      const v = props.fwversion;
      this.fwversion = v;
    }

    this.hostname = '';
    if (props.hasOwnProperty('hostname')) {
      const v = props.hostname;
      this.hostname = v;
    }

    this.loadavg = 0;
    if (props.hasOwnProperty('loadavg')) {
      const v = props.loadavg;
      this.loadavg = v;
    }

    this.netrole = '';
    if (props.hasOwnProperty('netrole')) {
      const v = props.netrole;
      this.netrole = v;
    }

    this.temperature = '0';
    if (props.hasOwnProperty('temperature')) {
      const v = props.temperature;
      this.temperature = v;
    }

    this.totalram = '0';
    if (props.hasOwnProperty('totalram')) {
      const v = props.totalram;
      this.totalram = v;
    }

    this.uptime = '0';
    if (props.hasOwnProperty('uptime')) {
      const v = props.uptime;
      this.uptime = v;
    }

    this.time = '';
    if (props.hasOwnProperty('time')) {
      const v = props.time;
      this.time = v;
    }

    this.timestamp = '0';
    if (props.hasOwnProperty('timestamp')) {
      const v = props.timestamp;
      this.timestamp = v;
    }
  }
}
export {AirmaxStatus_Host};
class AirmaxStatus_Wireless_Sta_Airmax_Detail {
  cinr: string;
  usage: string;

  constructor(
    props: $Shape<AirmaxStatus_Wireless_Sta_Airmax_Detail> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.cinr = '0';
    if (props.hasOwnProperty('cinr')) {
      const v = props.cinr;
      this.cinr = v;
    }

    this.usage = '0';
    if (props.hasOwnProperty('usage')) {
      const v = props.usage;
      this.usage = v;
    }
  }
}
export {AirmaxStatus_Wireless_Sta_Airmax_Detail};
class AirmaxStatus_Wireless_Sta_Airmax {
  downlink_capacity: string;
  uplink_capacity: string;
  rx: ?AirmaxStatus_Wireless_Sta_Airmax_Detail;
  tx: ?AirmaxStatus_Wireless_Sta_Airmax_Detail;
  actual_priority: string;
  beam: string;
  desired_priority: string;
  atpc_status: string;

  constructor(props: $Shape<AirmaxStatus_Wireless_Sta_Airmax> = {}): void {
    if (!props) {
      props = {};
    }

    this.downlink_capacity = '0';
    if (props.hasOwnProperty('downlink_capacity')) {
      const v = props.downlink_capacity;
      this.downlink_capacity = v;
    }

    this.uplink_capacity = '0';
    if (props.hasOwnProperty('uplink_capacity')) {
      const v = props.uplink_capacity;
      this.uplink_capacity = v;
    }

    this.rx = null;
    if (props.hasOwnProperty('rx')) {
      const v = props.rx;
      this.rx = v && new AirmaxStatus_Wireless_Sta_Airmax_Detail(v);
    }

    this.tx = null;
    if (props.hasOwnProperty('tx')) {
      const v = props.tx;
      this.tx = v && new AirmaxStatus_Wireless_Sta_Airmax_Detail(v);
    }

    this.actual_priority = '0';
    if (props.hasOwnProperty('actual_priority')) {
      const v = props.actual_priority;
      this.actual_priority = v;
    }

    this.beam = '0';
    if (props.hasOwnProperty('beam')) {
      const v = props.beam;
      this.beam = v;
    }

    this.desired_priority = '0';
    if (props.hasOwnProperty('desired_priority')) {
      const v = props.desired_priority;
      this.desired_priority = v;
    }

    this.atpc_status = '0';
    if (props.hasOwnProperty('atpc_status')) {
      const v = props.atpc_status;
      this.atpc_status = v;
    }
  }

  getRx(): AirmaxStatus_Wireless_Sta_Airmax_Detail {
    if (this.rx) {
      return this.rx;
    }
    return new AirmaxStatus_Wireless_Sta_Airmax_Detail();
  }

  getTx(): AirmaxStatus_Wireless_Sta_Airmax_Detail {
    if (this.tx) {
      return this.tx;
    }
    return new AirmaxStatus_Wireless_Sta_Airmax_Detail();
  }
}
export {AirmaxStatus_Wireless_Sta_Airmax};
class AirmaxStatus_Wireless_Sta_Remote_Eth {
  ifname: string;
  enabled: boolean;
  plugged: boolean;
  duplex: boolean;
  speed: string;
  snr: Array<string>;
  cable_len: string;

  constructor(props: $Shape<AirmaxStatus_Wireless_Sta_Remote_Eth> = {}): void {
    if (!props) {
      props = {};
    }

    this.ifname = '';
    if (props.hasOwnProperty('ifname')) {
      const v = props.ifname;
      this.ifname = v;
    }

    this.enabled = false;
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = !!v;
    }

    this.plugged = false;
    if (props.hasOwnProperty('plugged')) {
      const v = props.plugged;
      this.plugged = !!v;
    }

    this.duplex = false;
    if (props.hasOwnProperty('duplex')) {
      const v = props.duplex;
      this.duplex = !!v;
    }

    this.speed = '0';
    if (props.hasOwnProperty('speed')) {
      const v = props.speed;
      this.speed = v;
    }

    this.snr = [];
    if (props.hasOwnProperty('snr')) {
      const v = props.snr;
      if (!Array.isArray(v)) {
        throw new Error('repeated field snr should be array');
      }
      this.snr = v.map(function(v) {
        return v;
      });
    }

    this.cable_len = '0';
    if (props.hasOwnProperty('cable_len')) {
      const v = props.cable_len;
      this.cable_len = v;
    }
  }
}
export {AirmaxStatus_Wireless_Sta_Remote_Eth};
class AirmaxStatus_Wireless_Sta_Remote {
  chainrssi: Array<string>;
  distance: string;
  hostname: string;
  mode: string;
  noisefloor: string;
  platform: string;
  signal: string;
  tx_power: string;
  version: string;
  uptime: string;
  time: string;
  cpuload: number;
  temperature: string;
  totalram: string;
  freeram: string;
  netrole: string;
  compat_11n: string;
  rssi: string;
  rx_chainmask: string;
  tx_ratedata: Array<string>;
  tx_bytes: string;
  rx_bytes: string;
  ethlist: Array<AirmaxStatus_Wireless_Sta_Remote_Eth>;

  constructor(props: $Shape<AirmaxStatus_Wireless_Sta_Remote> = {}): void {
    if (!props) {
      props = {};
    }

    this.chainrssi = [];
    if (props.hasOwnProperty('chainrssi')) {
      const v = props.chainrssi;
      if (!Array.isArray(v)) {
        throw new Error('repeated field chainrssi should be array');
      }
      this.chainrssi = v.map(function(v) {
        return v;
      });
    }

    this.distance = '0';
    if (props.hasOwnProperty('distance')) {
      const v = props.distance;
      this.distance = v;
    }

    this.hostname = '';
    if (props.hasOwnProperty('hostname')) {
      const v = props.hostname;
      this.hostname = v;
    }

    this.mode = '';
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }

    this.noisefloor = '0';
    if (props.hasOwnProperty('noisefloor')) {
      const v = props.noisefloor;
      this.noisefloor = v;
    }

    this.platform = '';
    if (props.hasOwnProperty('platform')) {
      const v = props.platform;
      this.platform = v;
    }

    this.signal = '0';
    if (props.hasOwnProperty('signal')) {
      const v = props.signal;
      this.signal = v;
    }

    this.tx_power = '0';
    if (props.hasOwnProperty('tx_power')) {
      const v = props.tx_power;
      this.tx_power = v;
    }

    this.version = '';
    if (props.hasOwnProperty('version')) {
      const v = props.version;
      this.version = v;
    }

    this.uptime = '0';
    if (props.hasOwnProperty('uptime')) {
      const v = props.uptime;
      this.uptime = v;
    }

    this.time = '';
    if (props.hasOwnProperty('time')) {
      const v = props.time;
      this.time = v;
    }

    this.cpuload = 0;
    if (props.hasOwnProperty('cpuload')) {
      const v = props.cpuload;
      this.cpuload = v;
    }

    this.temperature = '0';
    if (props.hasOwnProperty('temperature')) {
      const v = props.temperature;
      this.temperature = v;
    }

    this.totalram = '0';
    if (props.hasOwnProperty('totalram')) {
      const v = props.totalram;
      this.totalram = v;
    }

    this.freeram = '0';
    if (props.hasOwnProperty('freeram')) {
      const v = props.freeram;
      this.freeram = v;
    }

    this.netrole = '';
    if (props.hasOwnProperty('netrole')) {
      const v = props.netrole;
      this.netrole = v;
    }

    this.compat_11n = '0';
    if (props.hasOwnProperty('compat_11n')) {
      const v = props.compat_11n;
      this.compat_11n = v;
    }

    this.rssi = '0';
    if (props.hasOwnProperty('rssi')) {
      const v = props.rssi;
      this.rssi = v;
    }

    this.rx_chainmask = '0';
    if (props.hasOwnProperty('rx_chainmask')) {
      const v = props.rx_chainmask;
      this.rx_chainmask = v;
    }

    this.tx_ratedata = [];
    if (props.hasOwnProperty('tx_ratedata')) {
      const v = props.tx_ratedata;
      if (!Array.isArray(v)) {
        throw new Error('repeated field tx_ratedata should be array');
      }
      this.tx_ratedata = v.map(function(v) {
        return v;
      });
    }

    this.tx_bytes = '0';
    if (props.hasOwnProperty('tx_bytes')) {
      const v = props.tx_bytes;
      this.tx_bytes = v;
    }

    this.rx_bytes = '0';
    if (props.hasOwnProperty('rx_bytes')) {
      const v = props.rx_bytes;
      this.rx_bytes = v;
    }

    this.ethlist = [];
    if (props.hasOwnProperty('ethlist')) {
      const v = props.ethlist;
      if (!Array.isArray(v)) {
        throw new Error('repeated field ethlist should be array');
      }
      this.ethlist = v.map(function(v) {
        return new AirmaxStatus_Wireless_Sta_Remote_Eth(v);
      });
    }
  }
}
export {AirmaxStatus_Wireless_Sta_Remote};
class AirmaxStatus_Wireless_Sta_Stats {
  rx_bytes: string;
  rx_packets: string;
  rx_pps: string;
  tx_bytes: string;
  tx_packets: string;
  tx_pps: string;

  constructor(props: $Shape<AirmaxStatus_Wireless_Sta_Stats> = {}): void {
    if (!props) {
      props = {};
    }

    this.rx_bytes = '0';
    if (props.hasOwnProperty('rx_bytes')) {
      const v = props.rx_bytes;
      this.rx_bytes = v;
    }

    this.rx_packets = '0';
    if (props.hasOwnProperty('rx_packets')) {
      const v = props.rx_packets;
      this.rx_packets = v;
    }

    this.rx_pps = '0';
    if (props.hasOwnProperty('rx_pps')) {
      const v = props.rx_pps;
      this.rx_pps = v;
    }

    this.tx_bytes = '0';
    if (props.hasOwnProperty('tx_bytes')) {
      const v = props.tx_bytes;
      this.tx_bytes = v;
    }

    this.tx_packets = '0';
    if (props.hasOwnProperty('tx_packets')) {
      const v = props.tx_packets;
      this.tx_packets = v;
    }

    this.tx_pps = '0';
    if (props.hasOwnProperty('tx_pps')) {
      const v = props.tx_pps;
      this.tx_pps = v;
    }
  }
}
export {AirmaxStatus_Wireless_Sta_Stats};
class AirmaxStatus_Wireless_Sta {
  airmax: ?AirmaxStatus_Wireless_Sta_Airmax;
  chainrssi: Array<string>;
  distance: string;
  lastip: string;
  mac: string;
  noisefloor: string;
  rssi: string;
  signal: string;
  tx_latency: string;
  tx_lretries: string;
  tx_packets: string;
  tx_sretries: string;
  uptime: string;
  remote: ?AirmaxStatus_Wireless_Sta_Remote;
  tx_idx: string;
  rx_idx: string;
  tx_nss: string;
  rx_nss: string;
  tx_ratedata: Array<string>;
  stats: ?AirmaxStatus_Wireless_Sta_Stats;

  constructor(props: $Shape<AirmaxStatus_Wireless_Sta> = {}): void {
    if (!props) {
      props = {};
    }

    this.airmax = null;
    if (props.hasOwnProperty('airmax')) {
      const v = props.airmax;
      this.airmax = v && new AirmaxStatus_Wireless_Sta_Airmax(v);
    }

    this.chainrssi = [];
    if (props.hasOwnProperty('chainrssi')) {
      const v = props.chainrssi;
      if (!Array.isArray(v)) {
        throw new Error('repeated field chainrssi should be array');
      }
      this.chainrssi = v.map(function(v) {
        return v;
      });
    }

    this.distance = '0';
    if (props.hasOwnProperty('distance')) {
      const v = props.distance;
      this.distance = v;
    }

    this.lastip = '';
    if (props.hasOwnProperty('lastip')) {
      const v = props.lastip;
      this.lastip = v;
    }

    this.mac = '';
    if (props.hasOwnProperty('mac')) {
      const v = props.mac;
      this.mac = v;
    }

    this.noisefloor = '0';
    if (props.hasOwnProperty('noisefloor')) {
      const v = props.noisefloor;
      this.noisefloor = v;
    }

    this.rssi = '0';
    if (props.hasOwnProperty('rssi')) {
      const v = props.rssi;
      this.rssi = v;
    }

    this.signal = '0';
    if (props.hasOwnProperty('signal')) {
      const v = props.signal;
      this.signal = v;
    }

    this.tx_latency = '0';
    if (props.hasOwnProperty('tx_latency')) {
      const v = props.tx_latency;
      this.tx_latency = v;
    }

    this.tx_lretries = '0';
    if (props.hasOwnProperty('tx_lretries')) {
      const v = props.tx_lretries;
      this.tx_lretries = v;
    }

    this.tx_packets = '0';
    if (props.hasOwnProperty('tx_packets')) {
      const v = props.tx_packets;
      this.tx_packets = v;
    }

    this.tx_sretries = '0';
    if (props.hasOwnProperty('tx_sretries')) {
      const v = props.tx_sretries;
      this.tx_sretries = v;
    }

    this.uptime = '0';
    if (props.hasOwnProperty('uptime')) {
      const v = props.uptime;
      this.uptime = v;
    }

    this.remote = null;
    if (props.hasOwnProperty('remote')) {
      const v = props.remote;
      this.remote = v && new AirmaxStatus_Wireless_Sta_Remote(v);
    }

    this.tx_idx = '0';
    if (props.hasOwnProperty('tx_idx')) {
      const v = props.tx_idx;
      this.tx_idx = v;
    }

    this.rx_idx = '0';
    if (props.hasOwnProperty('rx_idx')) {
      const v = props.rx_idx;
      this.rx_idx = v;
    }

    this.tx_nss = '0';
    if (props.hasOwnProperty('tx_nss')) {
      const v = props.tx_nss;
      this.tx_nss = v;
    }

    this.rx_nss = '0';
    if (props.hasOwnProperty('rx_nss')) {
      const v = props.rx_nss;
      this.rx_nss = v;
    }

    this.tx_ratedata = [];
    if (props.hasOwnProperty('tx_ratedata')) {
      const v = props.tx_ratedata;
      if (!Array.isArray(v)) {
        throw new Error('repeated field tx_ratedata should be array');
      }
      this.tx_ratedata = v.map(function(v) {
        return v;
      });
    }

    this.stats = null;
    if (props.hasOwnProperty('stats')) {
      const v = props.stats;
      this.stats = v && new AirmaxStatus_Wireless_Sta_Stats(v);
    }
  }

  getAirmax(): AirmaxStatus_Wireless_Sta_Airmax {
    if (this.airmax) {
      return this.airmax;
    }
    return new AirmaxStatus_Wireless_Sta_Airmax();
  }

  getRemote(): AirmaxStatus_Wireless_Sta_Remote {
    if (this.remote) {
      return this.remote;
    }
    return new AirmaxStatus_Wireless_Sta_Remote();
  }

  getStats(): AirmaxStatus_Wireless_Sta_Stats {
    if (this.stats) {
      return this.stats;
    }
    return new AirmaxStatus_Wireless_Sta_Stats();
  }
}
export {AirmaxStatus_Wireless_Sta};
class AirmaxStatus_Wireless_Polling {
  dcap: string;
  ucap: string;
  use: string;
  tx_use: string;
  rx_use: string;
  atpc_status: string;
  fixed_frame: boolean;
  gps_sync: boolean;

  constructor(props: $Shape<AirmaxStatus_Wireless_Polling> = {}): void {
    if (!props) {
      props = {};
    }

    this.dcap = '0';
    if (props.hasOwnProperty('dcap')) {
      const v = props.dcap;
      this.dcap = v;
    }

    this.ucap = '0';
    if (props.hasOwnProperty('ucap')) {
      const v = props.ucap;
      this.ucap = v;
    }

    this.use = '0';
    if (props.hasOwnProperty('use')) {
      const v = props.use;
      this.use = v;
    }

    this.tx_use = '0';
    if (props.hasOwnProperty('tx_use')) {
      const v = props.tx_use;
      this.tx_use = v;
    }

    this.rx_use = '0';
    if (props.hasOwnProperty('rx_use')) {
      const v = props.rx_use;
      this.rx_use = v;
    }

    this.atpc_status = '0';
    if (props.hasOwnProperty('atpc_status')) {
      const v = props.atpc_status;
      this.atpc_status = v;
    }

    this.fixed_frame = false;
    if (props.hasOwnProperty('fixed_frame')) {
      const v = props.fixed_frame;
      this.fixed_frame = !!v;
    }

    this.gps_sync = false;
    if (props.hasOwnProperty('gps_sync')) {
      const v = props.gps_sync;
      this.gps_sync = !!v;
    }
  }
}
export {AirmaxStatus_Wireless_Polling};
class AirmaxStatus_Wireless {
  apmac: string;
  center1_freq: string;
  chanbw: string;
  essid: string;
  frequency: string;
  mode: string;
  noisef: string;
  txpower: string;
  sta: Array<AirmaxStatus_Wireless_Sta>;
  compat_11n: string;
  hide_essid: string;
  dfs: string;
  distance: string;
  security: string;
  aprepeater: boolean;
  rstatus: string;
  chambw: string;
  rx_chainmask: string;
  tx_chainmask: string;
  cac_state: string;
  rx_idx: string;
  rx_nss: string;
  tx_idx: string;
  tx_nss: string;
  count: string;
  polling: ?AirmaxStatus_Wireless_Polling;

  constructor(props: $Shape<AirmaxStatus_Wireless> = {}): void {
    if (!props) {
      props = {};
    }

    this.apmac = '';
    if (props.hasOwnProperty('apmac')) {
      const v = props.apmac;
      this.apmac = v;
    }

    this.center1_freq = '0';
    if (props.hasOwnProperty('center1_freq')) {
      const v = props.center1_freq;
      this.center1_freq = v;
    }

    this.chanbw = '0';
    if (props.hasOwnProperty('chanbw')) {
      const v = props.chanbw;
      this.chanbw = v;
    }

    this.essid = '';
    if (props.hasOwnProperty('essid')) {
      const v = props.essid;
      this.essid = v;
    }

    this.frequency = '0';
    if (props.hasOwnProperty('frequency')) {
      const v = props.frequency;
      this.frequency = v;
    }

    this.mode = '';
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }

    this.noisef = '0';
    if (props.hasOwnProperty('noisef')) {
      const v = props.noisef;
      this.noisef = v;
    }

    this.txpower = '0';
    if (props.hasOwnProperty('txpower')) {
      const v = props.txpower;
      this.txpower = v;
    }

    this.sta = [];
    if (props.hasOwnProperty('sta')) {
      const v = props.sta;
      if (!Array.isArray(v)) {
        throw new Error('repeated field sta should be array');
      }
      this.sta = v.map(function(v) {
        return new AirmaxStatus_Wireless_Sta(v);
      });
    }

    this.compat_11n = '0';
    if (props.hasOwnProperty('compat_11n')) {
      const v = props.compat_11n;
      this.compat_11n = v;
    }

    this.hide_essid = '0';
    if (props.hasOwnProperty('hide_essid')) {
      const v = props.hide_essid;
      this.hide_essid = v;
    }

    this.dfs = '0';
    if (props.hasOwnProperty('dfs')) {
      const v = props.dfs;
      this.dfs = v;
    }

    this.distance = '0';
    if (props.hasOwnProperty('distance')) {
      const v = props.distance;
      this.distance = v;
    }

    this.security = '';
    if (props.hasOwnProperty('security')) {
      const v = props.security;
      this.security = v;
    }

    this.aprepeater = false;
    if (props.hasOwnProperty('aprepeater')) {
      const v = props.aprepeater;
      this.aprepeater = !!v;
    }

    this.rstatus = '0';
    if (props.hasOwnProperty('rstatus')) {
      const v = props.rstatus;
      this.rstatus = v;
    }

    this.chambw = '0';
    if (props.hasOwnProperty('chambw')) {
      const v = props.chambw;
      this.chambw = v;
    }

    this.rx_chainmask = '0';
    if (props.hasOwnProperty('rx_chainmask')) {
      const v = props.rx_chainmask;
      this.rx_chainmask = v;
    }

    this.tx_chainmask = '0';
    if (props.hasOwnProperty('tx_chainmask')) {
      const v = props.tx_chainmask;
      this.tx_chainmask = v;
    }

    this.cac_state = '0';
    if (props.hasOwnProperty('cac_state')) {
      const v = props.cac_state;
      this.cac_state = v;
    }

    this.rx_idx = '0';
    if (props.hasOwnProperty('rx_idx')) {
      const v = props.rx_idx;
      this.rx_idx = v;
    }

    this.rx_nss = '0';
    if (props.hasOwnProperty('rx_nss')) {
      const v = props.rx_nss;
      this.rx_nss = v;
    }

    this.tx_idx = '0';
    if (props.hasOwnProperty('tx_idx')) {
      const v = props.tx_idx;
      this.tx_idx = v;
    }

    this.tx_nss = '0';
    if (props.hasOwnProperty('tx_nss')) {
      const v = props.tx_nss;
      this.tx_nss = v;
    }

    this.count = '0';
    if (props.hasOwnProperty('count')) {
      const v = props.count;
      this.count = v;
    }

    this.polling = null;
    if (props.hasOwnProperty('polling')) {
      const v = props.polling;
      this.polling = v && new AirmaxStatus_Wireless_Polling(v);
    }
  }

  getPolling(): AirmaxStatus_Wireless_Polling {
    if (this.polling) {
      return this.polling;
    }
    return new AirmaxStatus_Wireless_Polling();
  }
}
export {AirmaxStatus_Wireless};
class AirmaxStatus_Chain {
  number: string;
  name: string;

  constructor(props: $Shape<AirmaxStatus_Chain> = {}): void {
    if (!props) {
      props = {};
    }

    this.number = '0';
    if (props.hasOwnProperty('number')) {
      const v = props.number;
      this.number = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }
  }
}
export {AirmaxStatus_Chain};
class AirmaxStatus_Config {
  testmode: boolean;
  testmode_time: string;

  constructor(props: $Shape<AirmaxStatus_Config> = {}): void {
    if (!props) {
      props = {};
    }

    this.testmode = false;
    if (props.hasOwnProperty('testmode')) {
      const v = props.testmode;
      this.testmode = !!v;
    }

    this.testmode_time = '0';
    if (props.hasOwnProperty('testmode_time')) {
      const v = props.testmode_time;
      this.testmode_time = v;
    }
  }
}
export {AirmaxStatus_Config};
class AirmaxStatus_Services {
  dhcpc: boolean;
  dhcpd: boolean;
  pppoe: boolean;
  airview: string;

  constructor(props: $Shape<AirmaxStatus_Services> = {}): void {
    if (!props) {
      props = {};
    }

    this.dhcpc = false;
    if (props.hasOwnProperty('dhcpc')) {
      const v = props.dhcpc;
      this.dhcpc = !!v;
    }

    this.dhcpd = false;
    if (props.hasOwnProperty('dhcpd')) {
      const v = props.dhcpd;
      this.dhcpd = !!v;
    }

    this.pppoe = false;
    if (props.hasOwnProperty('pppoe')) {
      const v = props.pppoe;
      this.pppoe = !!v;
    }

    this.airview = '0';
    if (props.hasOwnProperty('airview')) {
      const v = props.airview;
      this.airview = v;
    }
  }
}
export {AirmaxStatus_Services};
class AirmaxStatus_Firewall {
  iptables: boolean;
  ebtables: boolean;

  constructor(props: $Shape<AirmaxStatus_Firewall> = {}): void {
    if (!props) {
      props = {};
    }

    this.iptables = false;
    if (props.hasOwnProperty('iptables')) {
      const v = props.iptables;
      this.iptables = !!v;
    }

    this.ebtables = false;
    if (props.hasOwnProperty('ebtables')) {
      const v = props.ebtables;
      this.ebtables = !!v;
    }
  }
}
export {AirmaxStatus_Firewall};
class AirmaxStatus {
  interfaces: Array<AirmaxStatus_Interface>;
  host: ?AirmaxStatus_Host;
  wireless: ?AirmaxStatus_Wireless;
  chain_names: Array<AirmaxStatus_Chain>;
  genuine: string;
  config: ?AirmaxStatus_Config;
  services: ?AirmaxStatus_Services;
  firewall: ?AirmaxStatus_Firewall;
  portfw: boolean;

  constructor(props: $Shape<AirmaxStatus> = {}): void {
    if (!props) {
      props = {};
    }

    this.interfaces = [];
    if (props.hasOwnProperty('interfaces')) {
      const v = props.interfaces;
      if (!Array.isArray(v)) {
        throw new Error('repeated field interfaces should be array');
      }
      this.interfaces = v.map(function(v) {
        return new AirmaxStatus_Interface(v);
      });
    }

    this.host = null;
    if (props.hasOwnProperty('host')) {
      const v = props.host;
      this.host = v && new AirmaxStatus_Host(v);
    }

    this.wireless = null;
    if (props.hasOwnProperty('wireless')) {
      const v = props.wireless;
      this.wireless = v && new AirmaxStatus_Wireless(v);
    }

    this.chain_names = [];
    if (props.hasOwnProperty('chain_names')) {
      const v = props.chain_names;
      if (!Array.isArray(v)) {
        throw new Error('repeated field chain_names should be array');
      }
      this.chain_names = v.map(function(v) {
        return new AirmaxStatus_Chain(v);
      });
    }

    this.genuine = '';
    if (props.hasOwnProperty('genuine')) {
      const v = props.genuine;
      this.genuine = v;
    }

    this.config = null;
    if (props.hasOwnProperty('config')) {
      const v = props.config;
      this.config = v && new AirmaxStatus_Config(v);
    }

    this.services = null;
    if (props.hasOwnProperty('services')) {
      const v = props.services;
      this.services = v && new AirmaxStatus_Services(v);
    }

    this.firewall = null;
    if (props.hasOwnProperty('firewall')) {
      const v = props.firewall;
      this.firewall = v && new AirmaxStatus_Firewall(v);
    }

    this.portfw = false;
    if (props.hasOwnProperty('portfw')) {
      const v = props.portfw;
      this.portfw = !!v;
    }
  }

  getHost(): AirmaxStatus_Host {
    if (this.host) {
      return this.host;
    }
    return new AirmaxStatus_Host();
  }

  getWireless(): AirmaxStatus_Wireless {
    if (this.wireless) {
      return this.wireless;
    }
    return new AirmaxStatus_Wireless();
  }

  getConfig(): AirmaxStatus_Config {
    if (this.config) {
      return this.config;
    }
    return new AirmaxStatus_Config();
  }

  getServices(): AirmaxStatus_Services {
    if (this.services) {
      return this.services;
    }
    return new AirmaxStatus_Services();
  }

  getFirewall(): AirmaxStatus_Firewall {
    if (this.firewall) {
      return this.firewall;
    }
    return new AirmaxStatus_Firewall();
  }
}
export {AirmaxStatus};

class RadioStatus {
  mac_address: string;
  created: ?Date;
  ip: ?cwn_network.IP;
  airmax_status: ?AirmaxStatus;
  id: string;

  constructor(props: $Shape<RadioStatus> = {}): void {
    if (!props) {
      props = {};
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.ip = null;
    if (props.hasOwnProperty('ip')) {
      const v = props.ip;
      this.ip = v && new cwn_network.IP(v);
    }

    this.airmax_status = null;
    if (props.hasOwnProperty('airmax_status')) {
      const v = props.airmax_status;
      this.airmax_status = v && new AirmaxStatus(v);
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }
  }

  getIp(): cwn_network.IP {
    if (this.ip) {
      return this.ip;
    }
    return new cwn_network.IP();
  }

  getAirmaxStatus(): AirmaxStatus {
    if (this.airmax_status) {
      return this.airmax_status;
    }
    return new AirmaxStatus();
  }
}
export {RadioStatus};

class AirfiberStatus_InterfaceStatus {
  plugged: string;
  speed: string;
  duplex: string;

  constructor(props: $Shape<AirfiberStatus_InterfaceStatus> = {}): void {
    if (!props) {
      props = {};
    }

    this.plugged = '0';
    if (props.hasOwnProperty('plugged')) {
      const v = props.plugged;
      this.plugged = v;
    }

    this.speed = '0';
    if (props.hasOwnProperty('speed')) {
      const v = props.speed;
      this.speed = v;
    }

    this.duplex = '0';
    if (props.hasOwnProperty('duplex')) {
      const v = props.duplex;
      this.duplex = v;
    }
  }
}
export {AirfiberStatus_InterfaceStatus};
class AirfiberStatus_Standard_Host_CpuTimes {
  total: string;
  busy: string;

  constructor(props: $Shape<AirfiberStatus_Standard_Host_CpuTimes> = {}): void {
    if (!props) {
      props = {};
    }

    this.total = '';
    if (props.hasOwnProperty('total')) {
      const v = props.total;
      this.total = v;
    }

    this.busy = '';
    if (props.hasOwnProperty('busy')) {
      const v = props.busy;
      this.busy = v;
    }
  }
}
export {AirfiberStatus_Standard_Host_CpuTimes};
class AirfiberStatus_Standard_Host {
  uptime: string;
  time: string;
  fwversion: string;
  hostname: string;
  devmodel: string;
  netrole: string;
  loadavg: string;
  totalram: string;
  freeram: string;
  cputimes: ?AirfiberStatus_Standard_Host_CpuTimes;

  constructor(props: $Shape<AirfiberStatus_Standard_Host> = {}): void {
    if (!props) {
      props = {};
    }

    this.uptime = '0';
    if (props.hasOwnProperty('uptime')) {
      const v = props.uptime;
      this.uptime = v;
    }

    this.time = '';
    if (props.hasOwnProperty('time')) {
      const v = props.time;
      this.time = v;
    }

    this.fwversion = '';
    if (props.hasOwnProperty('fwversion')) {
      const v = props.fwversion;
      this.fwversion = v;
    }

    this.hostname = '';
    if (props.hasOwnProperty('hostname')) {
      const v = props.hostname;
      this.hostname = v;
    }

    this.devmodel = '';
    if (props.hasOwnProperty('devmodel')) {
      const v = props.devmodel;
      this.devmodel = v;
    }

    this.netrole = '';
    if (props.hasOwnProperty('netrole')) {
      const v = props.netrole;
      this.netrole = v;
    }

    this.loadavg = '';
    if (props.hasOwnProperty('loadavg')) {
      const v = props.loadavg;
      this.loadavg = v;
    }

    this.totalram = '';
    if (props.hasOwnProperty('totalram')) {
      const v = props.totalram;
      this.totalram = v;
    }

    this.freeram = '';
    if (props.hasOwnProperty('freeram')) {
      const v = props.freeram;
      this.freeram = v;
    }

    this.cputimes = null;
    if (props.hasOwnProperty('cputimes')) {
      const v = props.cputimes;
      this.cputimes = v && new AirfiberStatus_Standard_Host_CpuTimes(v);
    }
  }

  getCputimes(): AirfiberStatus_Standard_Host_CpuTimes {
    if (this.cputimes) {
      return this.cputimes;
    }
    return new AirfiberStatus_Standard_Host_CpuTimes();
  }
}
export {AirfiberStatus_Standard_Host};
class AirfiberStatus_Standard_Wireless_Polling {
  enabled: string;
  quality: string;
  capacity: string;
  priority: string;
  noack: string;
  airsync_mode: string;
  airsync_connections: string;
  airsync_down_util: string;
  airsync_up_util: string;
  airselect: string;
  airselect_interval: string;

  constructor(
    props: $Shape<AirfiberStatus_Standard_Wireless_Polling> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.enabled = '0';
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = v;
    }

    this.quality = '0';
    if (props.hasOwnProperty('quality')) {
      const v = props.quality;
      this.quality = v;
    }

    this.capacity = '0';
    if (props.hasOwnProperty('capacity')) {
      const v = props.capacity;
      this.capacity = v;
    }

    this.priority = '0';
    if (props.hasOwnProperty('priority')) {
      const v = props.priority;
      this.priority = v;
    }

    this.noack = '0';
    if (props.hasOwnProperty('noack')) {
      const v = props.noack;
      this.noack = v;
    }

    this.airsync_mode = '0';
    if (props.hasOwnProperty('airsync_mode')) {
      const v = props.airsync_mode;
      this.airsync_mode = v;
    }

    this.airsync_connections = '0';
    if (props.hasOwnProperty('airsync_connections')) {
      const v = props.airsync_connections;
      this.airsync_connections = v;
    }

    this.airsync_down_util = '0';
    if (props.hasOwnProperty('airsync_down_util')) {
      const v = props.airsync_down_util;
      this.airsync_down_util = v;
    }

    this.airsync_up_util = '0';
    if (props.hasOwnProperty('airsync_up_util')) {
      const v = props.airsync_up_util;
      this.airsync_up_util = v;
    }

    this.airselect = '0';
    if (props.hasOwnProperty('airselect')) {
      const v = props.airselect;
      this.airselect = v;
    }

    this.airselect_interval = '0';
    if (props.hasOwnProperty('airselect_interval')) {
      const v = props.airselect_interval;
      this.airselect_interval = v;
    }
  }
}
export {AirfiberStatus_Standard_Wireless_Polling};
class AirfiberStatus_Standard_Wireless_Stats {
  rx_nwids: string;
  rx_crypts: string;
  rx_frags: string;
  tx_retries: string;
  missed_beacons: string;
  err_other: string;

  constructor(
    props: $Shape<AirfiberStatus_Standard_Wireless_Stats> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.rx_nwids = '0';
    if (props.hasOwnProperty('rx_nwids')) {
      const v = props.rx_nwids;
      this.rx_nwids = v;
    }

    this.rx_crypts = '0';
    if (props.hasOwnProperty('rx_crypts')) {
      const v = props.rx_crypts;
      this.rx_crypts = v;
    }

    this.rx_frags = '0';
    if (props.hasOwnProperty('rx_frags')) {
      const v = props.rx_frags;
      this.rx_frags = v;
    }

    this.tx_retries = '0';
    if (props.hasOwnProperty('tx_retries')) {
      const v = props.tx_retries;
      this.tx_retries = v;
    }

    this.missed_beacons = '0';
    if (props.hasOwnProperty('missed_beacons')) {
      const v = props.missed_beacons;
      this.missed_beacons = v;
    }

    this.err_other = '0';
    if (props.hasOwnProperty('err_other')) {
      const v = props.err_other;
      this.err_other = v;
    }
  }
}
export {AirfiberStatus_Standard_Wireless_Stats};
class AirfiberStatus_Standard_Wireless {
  mode: string;
  essid: string;
  hide_essid: string;
  apmac: string;
  countrycode: string;
  channel: string;
  frequency: string;
  dfs: string;
  opmode: string;
  antenna: string;
  chains: string;
  signal: string;
  rssi: string;
  noisef: string;
  txpower: string;
  ack: string;
  distance: string;
  ccq: string;
  txrate: string;
  rxrate: string;
  security: string;
  qos: string;
  rstatus: string;
  count: string;
  polling: ?AirfiberStatus_Standard_Wireless_Polling;
  stats: ?AirfiberStatus_Standard_Wireless_Stats;
  wds: string;
  aprepeater: string;
  chwidth: string;
  chanbw: string;
  cwmmode: string;
  rx_chainmask: string;
  tx_chainmask: string;
  chainrssi: Array<string>;
  chainrssimgmt: Array<string>;
  chainrssiext: Array<string>;

  constructor(props: $Shape<AirfiberStatus_Standard_Wireless> = {}): void {
    if (!props) {
      props = {};
    }

    this.mode = '';
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }

    this.essid = '';
    if (props.hasOwnProperty('essid')) {
      const v = props.essid;
      this.essid = v;
    }

    this.hide_essid = '0';
    if (props.hasOwnProperty('hide_essid')) {
      const v = props.hide_essid;
      this.hide_essid = v;
    }

    this.apmac = '';
    if (props.hasOwnProperty('apmac')) {
      const v = props.apmac;
      this.apmac = v;
    }

    this.countrycode = '0';
    if (props.hasOwnProperty('countrycode')) {
      const v = props.countrycode;
      this.countrycode = v;
    }

    this.channel = '0';
    if (props.hasOwnProperty('channel')) {
      const v = props.channel;
      this.channel = v;
    }

    this.frequency = '';
    if (props.hasOwnProperty('frequency')) {
      const v = props.frequency;
      this.frequency = v;
    }

    this.dfs = '';
    if (props.hasOwnProperty('dfs')) {
      const v = props.dfs;
      this.dfs = v;
    }

    this.opmode = '';
    if (props.hasOwnProperty('opmode')) {
      const v = props.opmode;
      this.opmode = v;
    }

    this.antenna = '';
    if (props.hasOwnProperty('antenna')) {
      const v = props.antenna;
      this.antenna = v;
    }

    this.chains = '';
    if (props.hasOwnProperty('chains')) {
      const v = props.chains;
      this.chains = v;
    }

    this.signal = '0';
    if (props.hasOwnProperty('signal')) {
      const v = props.signal;
      this.signal = v;
    }

    this.rssi = '0';
    if (props.hasOwnProperty('rssi')) {
      const v = props.rssi;
      this.rssi = v;
    }

    this.noisef = '0';
    if (props.hasOwnProperty('noisef')) {
      const v = props.noisef;
      this.noisef = v;
    }

    this.txpower = '0';
    if (props.hasOwnProperty('txpower')) {
      const v = props.txpower;
      this.txpower = v;
    }

    this.ack = '0';
    if (props.hasOwnProperty('ack')) {
      const v = props.ack;
      this.ack = v;
    }

    this.distance = '0';
    if (props.hasOwnProperty('distance')) {
      const v = props.distance;
      this.distance = v;
    }

    this.ccq = '0';
    if (props.hasOwnProperty('ccq')) {
      const v = props.ccq;
      this.ccq = v;
    }

    this.txrate = '';
    if (props.hasOwnProperty('txrate')) {
      const v = props.txrate;
      this.txrate = v;
    }

    this.rxrate = '';
    if (props.hasOwnProperty('rxrate')) {
      const v = props.rxrate;
      this.rxrate = v;
    }

    this.security = '';
    if (props.hasOwnProperty('security')) {
      const v = props.security;
      this.security = v;
    }

    this.qos = '';
    if (props.hasOwnProperty('qos')) {
      const v = props.qos;
      this.qos = v;
    }

    this.rstatus = '0';
    if (props.hasOwnProperty('rstatus')) {
      const v = props.rstatus;
      this.rstatus = v;
    }

    this.count = '0';
    if (props.hasOwnProperty('count')) {
      const v = props.count;
      this.count = v;
    }

    this.polling = null;
    if (props.hasOwnProperty('polling')) {
      const v = props.polling;
      this.polling = v && new AirfiberStatus_Standard_Wireless_Polling(v);
    }

    this.stats = null;
    if (props.hasOwnProperty('stats')) {
      const v = props.stats;
      this.stats = v && new AirfiberStatus_Standard_Wireless_Stats(v);
    }

    this.wds = '0';
    if (props.hasOwnProperty('wds')) {
      const v = props.wds;
      this.wds = v;
    }

    this.aprepeater = '0';
    if (props.hasOwnProperty('aprepeater')) {
      const v = props.aprepeater;
      this.aprepeater = v;
    }

    this.chwidth = '0';
    if (props.hasOwnProperty('chwidth')) {
      const v = props.chwidth;
      this.chwidth = v;
    }

    this.chanbw = '0';
    if (props.hasOwnProperty('chanbw')) {
      const v = props.chanbw;
      this.chanbw = v;
    }

    this.cwmmode = '0';
    if (props.hasOwnProperty('cwmmode')) {
      const v = props.cwmmode;
      this.cwmmode = v;
    }

    this.rx_chainmask = '0';
    if (props.hasOwnProperty('rx_chainmask')) {
      const v = props.rx_chainmask;
      this.rx_chainmask = v;
    }

    this.tx_chainmask = '0';
    if (props.hasOwnProperty('tx_chainmask')) {
      const v = props.tx_chainmask;
      this.tx_chainmask = v;
    }

    this.chainrssi = [];
    if (props.hasOwnProperty('chainrssi')) {
      const v = props.chainrssi;
      if (!Array.isArray(v)) {
        throw new Error('repeated field chainrssi should be array');
      }
      this.chainrssi = v.map(function(v) {
        return v;
      });
    }

    this.chainrssimgmt = [];
    if (props.hasOwnProperty('chainrssimgmt')) {
      const v = props.chainrssimgmt;
      if (!Array.isArray(v)) {
        throw new Error('repeated field chainrssimgmt should be array');
      }
      this.chainrssimgmt = v.map(function(v) {
        return v;
      });
    }

    this.chainrssiext = [];
    if (props.hasOwnProperty('chainrssiext')) {
      const v = props.chainrssiext;
      if (!Array.isArray(v)) {
        throw new Error('repeated field chainrssiext should be array');
      }
      this.chainrssiext = v.map(function(v) {
        return v;
      });
    }
  }

  getPolling(): AirfiberStatus_Standard_Wireless_Polling {
    if (this.polling) {
      return this.polling;
    }
    return new AirfiberStatus_Standard_Wireless_Polling();
  }

  getStats(): AirfiberStatus_Standard_Wireless_Stats {
    if (this.stats) {
      return this.stats;
    }
    return new AirfiberStatus_Standard_Wireless_Stats();
  }
}
export {AirfiberStatus_Standard_Wireless};
class AirfiberStatus_Standard_Airfiber {
  enabled: string;
  linkmode: string;
  oldlinkmode: string;
  linkstate: string;
  duplex: string;
  tx_frequency: string;
  rx_frequency: string;
  txchanbw: string;
  rxchanbw: string;
  dutycycle: string;
  regdomain: string;
  gps_sync: string;
  tdelay: string;
  rxcapacity: string;
  txcapacity: string;
  txpower: string;
  rxgain: string;
  rxpower0: string;
  rxpower1: string;
  rxpower0valid: string;
  rxpower1valid: string;
  rxoverload0: string;
  rxoverload1: string;
  remote_txpower: string;
  remote_rxgain: string;
  remote_mac: string;
  remote_ip: string;
  remote_rxpower0: string;
  remote_rxpower1: string;
  remote_rxpower0valid: string;
  remote_rxpower1valid: string;
  remote_rxoverload0: string;
  remote_rxoverload1: string;
  barrange: string;
  rbarrange: string;
  minbarpwr: string;
  txmodrate: string;
  remote_txmodrate: string;
  data_speed: string;
  linkuptime: string;
  tx_frequency1: string;
  rx_frequency1: string;
  tx_frequency2: string;
  rx_frequency2: string;
  tx_frequency3: string;
  rx_frequency3: string;
  dfsdomain: string;
  dfsdetects: string;
  dfsstatetx1: string;
  dfsstatetx2: string;
  dfsstatetx3: string;
  dfstimertx1: string;
  dfstimertx2: string;
  dfstimertx3: string;
  datalength: string;
  datasnrcdt: string;
  datastate: string;
  partstate: string;
  dualfreqmode: string;
  sisomode: string;
  condpwrout: string;
  antgain: string;
  cableloss: string;
  idealpwr: string;
  ridealpwr: string;
  framelength: string;
  maxtxpower: string;

  constructor(props: $Shape<AirfiberStatus_Standard_Airfiber> = {}): void {
    if (!props) {
      props = {};
    }

    this.enabled = '0';
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = v;
    }

    this.linkmode = '';
    if (props.hasOwnProperty('linkmode')) {
      const v = props.linkmode;
      this.linkmode = v;
    }

    this.oldlinkmode = '';
    if (props.hasOwnProperty('oldlinkmode')) {
      const v = props.oldlinkmode;
      this.oldlinkmode = v;
    }

    this.linkstate = '';
    if (props.hasOwnProperty('linkstate')) {
      const v = props.linkstate;
      this.linkstate = v;
    }

    this.duplex = '';
    if (props.hasOwnProperty('duplex')) {
      const v = props.duplex;
      this.duplex = v;
    }

    this.tx_frequency = '0';
    if (props.hasOwnProperty('tx_frequency')) {
      const v = props.tx_frequency;
      this.tx_frequency = v;
    }

    this.rx_frequency = '0';
    if (props.hasOwnProperty('rx_frequency')) {
      const v = props.rx_frequency;
      this.rx_frequency = v;
    }

    this.txchanbw = '';
    if (props.hasOwnProperty('txchanbw')) {
      const v = props.txchanbw;
      this.txchanbw = v;
    }

    this.rxchanbw = '';
    if (props.hasOwnProperty('rxchanbw')) {
      const v = props.rxchanbw;
      this.rxchanbw = v;
    }

    this.dutycycle = '0';
    if (props.hasOwnProperty('dutycycle')) {
      const v = props.dutycycle;
      this.dutycycle = v;
    }

    this.regdomain = '';
    if (props.hasOwnProperty('regdomain')) {
      const v = props.regdomain;
      this.regdomain = v;
    }

    this.gps_sync = '0';
    if (props.hasOwnProperty('gps_sync')) {
      const v = props.gps_sync;
      this.gps_sync = v;
    }

    this.tdelay = '0';
    if (props.hasOwnProperty('tdelay')) {
      const v = props.tdelay;
      this.tdelay = v;
    }

    this.rxcapacity = '0';
    if (props.hasOwnProperty('rxcapacity')) {
      const v = props.rxcapacity;
      this.rxcapacity = v;
    }

    this.txcapacity = '0';
    if (props.hasOwnProperty('txcapacity')) {
      const v = props.txcapacity;
      this.txcapacity = v;
    }

    this.txpower = '0';
    if (props.hasOwnProperty('txpower')) {
      const v = props.txpower;
      this.txpower = v;
    }

    this.rxgain = '';
    if (props.hasOwnProperty('rxgain')) {
      const v = props.rxgain;
      this.rxgain = v;
    }

    this.rxpower0 = '0';
    if (props.hasOwnProperty('rxpower0')) {
      const v = props.rxpower0;
      this.rxpower0 = v;
    }

    this.rxpower1 = '0';
    if (props.hasOwnProperty('rxpower1')) {
      const v = props.rxpower1;
      this.rxpower1 = v;
    }

    this.rxpower0valid = '0';
    if (props.hasOwnProperty('rxpower0valid')) {
      const v = props.rxpower0valid;
      this.rxpower0valid = v;
    }

    this.rxpower1valid = '0';
    if (props.hasOwnProperty('rxpower1valid')) {
      const v = props.rxpower1valid;
      this.rxpower1valid = v;
    }

    this.rxoverload0 = '0';
    if (props.hasOwnProperty('rxoverload0')) {
      const v = props.rxoverload0;
      this.rxoverload0 = v;
    }

    this.rxoverload1 = '0';
    if (props.hasOwnProperty('rxoverload1')) {
      const v = props.rxoverload1;
      this.rxoverload1 = v;
    }

    this.remote_txpower = '0';
    if (props.hasOwnProperty('remote_txpower')) {
      const v = props.remote_txpower;
      this.remote_txpower = v;
    }

    this.remote_rxgain = '';
    if (props.hasOwnProperty('remote_rxgain')) {
      const v = props.remote_rxgain;
      this.remote_rxgain = v;
    }

    this.remote_mac = '';
    if (props.hasOwnProperty('remote_mac')) {
      const v = props.remote_mac;
      this.remote_mac = v;
    }

    this.remote_ip = '';
    if (props.hasOwnProperty('remote_ip')) {
      const v = props.remote_ip;
      this.remote_ip = v;
    }

    this.remote_rxpower0 = '0';
    if (props.hasOwnProperty('remote_rxpower0')) {
      const v = props.remote_rxpower0;
      this.remote_rxpower0 = v;
    }

    this.remote_rxpower1 = '0';
    if (props.hasOwnProperty('remote_rxpower1')) {
      const v = props.remote_rxpower1;
      this.remote_rxpower1 = v;
    }

    this.remote_rxpower0valid = '0';
    if (props.hasOwnProperty('remote_rxpower0valid')) {
      const v = props.remote_rxpower0valid;
      this.remote_rxpower0valid = v;
    }

    this.remote_rxpower1valid = '0';
    if (props.hasOwnProperty('remote_rxpower1valid')) {
      const v = props.remote_rxpower1valid;
      this.remote_rxpower1valid = v;
    }

    this.remote_rxoverload0 = '0';
    if (props.hasOwnProperty('remote_rxoverload0')) {
      const v = props.remote_rxoverload0;
      this.remote_rxoverload0 = v;
    }

    this.remote_rxoverload1 = '0';
    if (props.hasOwnProperty('remote_rxoverload1')) {
      const v = props.remote_rxoverload1;
      this.remote_rxoverload1 = v;
    }

    this.barrange = '0';
    if (props.hasOwnProperty('barrange')) {
      const v = props.barrange;
      this.barrange = v;
    }

    this.rbarrange = '0';
    if (props.hasOwnProperty('rbarrange')) {
      const v = props.rbarrange;
      this.rbarrange = v;
    }

    this.minbarpwr = '0';
    if (props.hasOwnProperty('minbarpwr')) {
      const v = props.minbarpwr;
      this.minbarpwr = v;
    }

    this.txmodrate = '';
    if (props.hasOwnProperty('txmodrate')) {
      const v = props.txmodrate;
      this.txmodrate = v;
    }

    this.remote_txmodrate = '';
    if (props.hasOwnProperty('remote_txmodrate')) {
      const v = props.remote_txmodrate;
      this.remote_txmodrate = v;
    }

    this.data_speed = '';
    if (props.hasOwnProperty('data_speed')) {
      const v = props.data_speed;
      this.data_speed = v;
    }

    this.linkuptime = '0';
    if (props.hasOwnProperty('linkuptime')) {
      const v = props.linkuptime;
      this.linkuptime = v;
    }

    this.tx_frequency1 = '0';
    if (props.hasOwnProperty('tx_frequency1')) {
      const v = props.tx_frequency1;
      this.tx_frequency1 = v;
    }

    this.rx_frequency1 = '0';
    if (props.hasOwnProperty('rx_frequency1')) {
      const v = props.rx_frequency1;
      this.rx_frequency1 = v;
    }

    this.tx_frequency2 = '0';
    if (props.hasOwnProperty('tx_frequency2')) {
      const v = props.tx_frequency2;
      this.tx_frequency2 = v;
    }

    this.rx_frequency2 = '0';
    if (props.hasOwnProperty('rx_frequency2')) {
      const v = props.rx_frequency2;
      this.rx_frequency2 = v;
    }

    this.tx_frequency3 = '0';
    if (props.hasOwnProperty('tx_frequency3')) {
      const v = props.tx_frequency3;
      this.tx_frequency3 = v;
    }

    this.rx_frequency3 = '0';
    if (props.hasOwnProperty('rx_frequency3')) {
      const v = props.rx_frequency3;
      this.rx_frequency3 = v;
    }

    this.dfsdomain = '';
    if (props.hasOwnProperty('dfsdomain')) {
      const v = props.dfsdomain;
      this.dfsdomain = v;
    }

    this.dfsdetects = '0';
    if (props.hasOwnProperty('dfsdetects')) {
      const v = props.dfsdetects;
      this.dfsdetects = v;
    }

    this.dfsstatetx1 = '';
    if (props.hasOwnProperty('dfsstatetx1')) {
      const v = props.dfsstatetx1;
      this.dfsstatetx1 = v;
    }

    this.dfsstatetx2 = '';
    if (props.hasOwnProperty('dfsstatetx2')) {
      const v = props.dfsstatetx2;
      this.dfsstatetx2 = v;
    }

    this.dfsstatetx3 = '';
    if (props.hasOwnProperty('dfsstatetx3')) {
      const v = props.dfsstatetx3;
      this.dfsstatetx3 = v;
    }

    this.dfstimertx1 = '0';
    if (props.hasOwnProperty('dfstimertx1')) {
      const v = props.dfstimertx1;
      this.dfstimertx1 = v;
    }

    this.dfstimertx2 = '0';
    if (props.hasOwnProperty('dfstimertx2')) {
      const v = props.dfstimertx2;
      this.dfstimertx2 = v;
    }

    this.dfstimertx3 = '0';
    if (props.hasOwnProperty('dfstimertx3')) {
      const v = props.dfstimertx3;
      this.dfstimertx3 = v;
    }

    this.datalength = '0';
    if (props.hasOwnProperty('datalength')) {
      const v = props.datalength;
      this.datalength = v;
    }

    this.datasnrcdt = '0';
    if (props.hasOwnProperty('datasnrcdt')) {
      const v = props.datasnrcdt;
      this.datasnrcdt = v;
    }

    this.datastate = '0';
    if (props.hasOwnProperty('datastate')) {
      const v = props.datastate;
      this.datastate = v;
    }

    this.partstate = '0';
    if (props.hasOwnProperty('partstate')) {
      const v = props.partstate;
      this.partstate = v;
    }

    this.dualfreqmode = '0';
    if (props.hasOwnProperty('dualfreqmode')) {
      const v = props.dualfreqmode;
      this.dualfreqmode = v;
    }

    this.sisomode = '0';
    if (props.hasOwnProperty('sisomode')) {
      const v = props.sisomode;
      this.sisomode = v;
    }

    this.condpwrout = '0';
    if (props.hasOwnProperty('condpwrout')) {
      const v = props.condpwrout;
      this.condpwrout = v;
    }

    this.antgain = '0';
    if (props.hasOwnProperty('antgain')) {
      const v = props.antgain;
      this.antgain = v;
    }

    this.cableloss = '0';
    if (props.hasOwnProperty('cableloss')) {
      const v = props.cableloss;
      this.cableloss = v;
    }

    this.idealpwr = '0';
    if (props.hasOwnProperty('idealpwr')) {
      const v = props.idealpwr;
      this.idealpwr = v;
    }

    this.ridealpwr = '0';
    if (props.hasOwnProperty('ridealpwr')) {
      const v = props.ridealpwr;
      this.ridealpwr = v;
    }

    this.framelength = '';
    if (props.hasOwnProperty('framelength')) {
      const v = props.framelength;
      this.framelength = v;
    }

    this.maxtxpower = '0';
    if (props.hasOwnProperty('maxtxpower')) {
      const v = props.maxtxpower;
      this.maxtxpower = v;
    }
  }
}
export {AirfiberStatus_Standard_Airfiber};
class AirfiberStatus_Standard_Airview {
  enabled: string;

  constructor(props: $Shape<AirfiberStatus_Standard_Airview> = {}): void {
    if (!props) {
      props = {};
    }

    this.enabled = '0';
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = v;
    }
  }
}
export {AirfiberStatus_Standard_Airview};
class AirfiberStatus_Standard_Services {
  dhcpc: string;
  dhcpd: string;
  pppoe: string;

  constructor(props: $Shape<AirfiberStatus_Standard_Services> = {}): void {
    if (!props) {
      props = {};
    }

    this.dhcpc = '0';
    if (props.hasOwnProperty('dhcpc')) {
      const v = props.dhcpc;
      this.dhcpc = v;
    }

    this.dhcpd = '0';
    if (props.hasOwnProperty('dhcpd')) {
      const v = props.dhcpd;
      this.dhcpd = v;
    }

    this.pppoe = '0';
    if (props.hasOwnProperty('pppoe')) {
      const v = props.pppoe;
      this.pppoe = v;
    }
  }
}
export {AirfiberStatus_Standard_Services};
class AirfiberStatus_Standard_Firewall {
  iptables: string;
  ebtables: string;

  constructor(props: $Shape<AirfiberStatus_Standard_Firewall> = {}): void {
    if (!props) {
      props = {};
    }

    this.iptables = '0';
    if (props.hasOwnProperty('iptables')) {
      const v = props.iptables;
      this.iptables = v;
    }

    this.ebtables = '0';
    if (props.hasOwnProperty('ebtables')) {
      const v = props.ebtables;
      this.ebtables = v;
    }
  }
}
export {AirfiberStatus_Standard_Firewall};
class AirfiberStatus_Standard_Interface_Services {
  dhcpc: boolean;
  dhcpd: boolean;
  pppoe: boolean;

  constructor(
    props: $Shape<AirfiberStatus_Standard_Interface_Services> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.dhcpc = false;
    if (props.hasOwnProperty('dhcpc')) {
      const v = props.dhcpc;
      this.dhcpc = !!v;
    }

    this.dhcpd = false;
    if (props.hasOwnProperty('dhcpd')) {
      const v = props.dhcpd;
      this.dhcpd = !!v;
    }

    this.pppoe = false;
    if (props.hasOwnProperty('pppoe')) {
      const v = props.pppoe;
      this.pppoe = !!v;
    }
  }
}
export {AirfiberStatus_Standard_Interface_Services};
class AirfiberStatus_Standard_Interface {
  ifname: string;
  hwaddr: string;
  enabled: boolean;
  status: ?AirfiberStatus_InterfaceStatus;
  services: ?AirfiberStatus_Standard_Interface_Services;

  constructor(props: $Shape<AirfiberStatus_Standard_Interface> = {}): void {
    if (!props) {
      props = {};
    }

    this.ifname = '';
    if (props.hasOwnProperty('ifname')) {
      const v = props.ifname;
      this.ifname = v;
    }

    this.hwaddr = '';
    if (props.hasOwnProperty('hwaddr')) {
      const v = props.hwaddr;
      this.hwaddr = v;
    }

    this.enabled = false;
    if (props.hasOwnProperty('enabled')) {
      const v = props.enabled;
      this.enabled = !!v;
    }

    this.status = null;
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v && new AirfiberStatus_InterfaceStatus(v);
    }

    this.services = null;
    if (props.hasOwnProperty('services')) {
      const v = props.services;
      this.services = v && new AirfiberStatus_Standard_Interface_Services(v);
    }
  }

  getStatus(): AirfiberStatus_InterfaceStatus {
    if (this.status) {
      return this.status;
    }
    return new AirfiberStatus_InterfaceStatus();
  }

  getServices(): AirfiberStatus_Standard_Interface_Services {
    if (this.services) {
      return this.services;
    }
    return new AirfiberStatus_Standard_Interface_Services();
  }
}
export {AirfiberStatus_Standard_Interface};
class AirfiberStatus_Standard_Gps {
  status: string;
  fix: string;
  sats: string;
  dop: number;
  lat: number;
  lon: number;
  alt: number;

  constructor(props: $Shape<AirfiberStatus_Standard_Gps> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = '0';
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.fix = '0';
    if (props.hasOwnProperty('fix')) {
      const v = props.fix;
      this.fix = v;
    }

    this.sats = '0';
    if (props.hasOwnProperty('sats')) {
      const v = props.sats;
      this.sats = v;
    }

    this.dop = 0;
    if (props.hasOwnProperty('dop')) {
      const v = props.dop;
      this.dop = v;
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.lon = 0;
    if (props.hasOwnProperty('lon')) {
      const v = props.lon;
      this.lon = v;
    }

    this.alt = 0;
    if (props.hasOwnProperty('alt')) {
      const v = props.alt;
      this.alt = v;
    }
  }
}
export {AirfiberStatus_Standard_Gps};
class AirfiberStatus_Standard {
  host: ?AirfiberStatus_Standard_Host;
  wireless: ?AirfiberStatus_Standard_Wireless;
  airfiber: ?AirfiberStatus_Standard_Airfiber;
  airview: ?AirfiberStatus_Standard_Airview;
  services: ?AirfiberStatus_Standard_Services;
  firewall: ?AirfiberStatus_Standard_Firewall;
  genuine: string;
  interfaces: Array<AirfiberStatus_Standard_Interface>;
  gps: ?AirfiberStatus_Standard_Gps;

  constructor(props: $Shape<AirfiberStatus_Standard> = {}): void {
    if (!props) {
      props = {};
    }

    this.host = null;
    if (props.hasOwnProperty('host')) {
      const v = props.host;
      this.host = v && new AirfiberStatus_Standard_Host(v);
    }

    this.wireless = null;
    if (props.hasOwnProperty('wireless')) {
      const v = props.wireless;
      this.wireless = v && new AirfiberStatus_Standard_Wireless(v);
    }

    this.airfiber = null;
    if (props.hasOwnProperty('airfiber')) {
      const v = props.airfiber;
      this.airfiber = v && new AirfiberStatus_Standard_Airfiber(v);
    }

    this.airview = null;
    if (props.hasOwnProperty('airview')) {
      const v = props.airview;
      this.airview = v && new AirfiberStatus_Standard_Airview(v);
    }

    this.services = null;
    if (props.hasOwnProperty('services')) {
      const v = props.services;
      this.services = v && new AirfiberStatus_Standard_Services(v);
    }

    this.firewall = null;
    if (props.hasOwnProperty('firewall')) {
      const v = props.firewall;
      this.firewall = v && new AirfiberStatus_Standard_Firewall(v);
    }

    this.genuine = '';
    if (props.hasOwnProperty('genuine')) {
      const v = props.genuine;
      this.genuine = v;
    }

    this.interfaces = [];
    if (props.hasOwnProperty('interfaces')) {
      const v = props.interfaces;
      if (!Array.isArray(v)) {
        throw new Error('repeated field interfaces should be array');
      }
      this.interfaces = v.map(function(v) {
        return new AirfiberStatus_Standard_Interface(v);
      });
    }

    this.gps = null;
    if (props.hasOwnProperty('gps')) {
      const v = props.gps;
      this.gps = v && new AirfiberStatus_Standard_Gps(v);
    }
  }

  getHost(): AirfiberStatus_Standard_Host {
    if (this.host) {
      return this.host;
    }
    return new AirfiberStatus_Standard_Host();
  }

  getWireless(): AirfiberStatus_Standard_Wireless {
    if (this.wireless) {
      return this.wireless;
    }
    return new AirfiberStatus_Standard_Wireless();
  }

  getAirfiber(): AirfiberStatus_Standard_Airfiber {
    if (this.airfiber) {
      return this.airfiber;
    }
    return new AirfiberStatus_Standard_Airfiber();
  }

  getAirview(): AirfiberStatus_Standard_Airview {
    if (this.airview) {
      return this.airview;
    }
    return new AirfiberStatus_Standard_Airview();
  }

  getServices(): AirfiberStatus_Standard_Services {
    if (this.services) {
      return this.services;
    }
    return new AirfiberStatus_Standard_Services();
  }

  getFirewall(): AirfiberStatus_Standard_Firewall {
    if (this.firewall) {
      return this.firewall;
    }
    return new AirfiberStatus_Standard_Firewall();
  }

  getGps(): AirfiberStatus_Standard_Gps {
    if (this.gps) {
      return this.gps;
    }
    return new AirfiberStatus_Standard_Gps();
  }
}
export {AirfiberStatus_Standard};
class AirfiberStatus_New_Status_Host_MemInfo {
  total: string;
  free: string;
  buffers: string;

  constructor(
    props: $Shape<AirfiberStatus_New_Status_Host_MemInfo> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.total = '0';
    if (props.hasOwnProperty('total')) {
      const v = props.total;
      this.total = v;
    }

    this.free = '0';
    if (props.hasOwnProperty('free')) {
      const v = props.free;
      this.free = v;
    }

    this.buffers = '0';
    if (props.hasOwnProperty('buffers')) {
      const v = props.buffers;
      this.buffers = v;
    }
  }
}
export {AirfiberStatus_New_Status_Host_MemInfo};
class AirfiberStatus_New_Status_Host {
  uptime: string;
  time: string;
  utime: string;
  hostname: string;
  fwversion: string;
  role: string;
  loadavg: Array<number>;
  meminfo: ?AirfiberStatus_New_Status_Host_MemInfo;

  constructor(props: $Shape<AirfiberStatus_New_Status_Host> = {}): void {
    if (!props) {
      props = {};
    }

    this.uptime = '';
    if (props.hasOwnProperty('uptime')) {
      const v = props.uptime;
      this.uptime = v;
    }

    this.time = '';
    if (props.hasOwnProperty('time')) {
      const v = props.time;
      this.time = v;
    }

    this.utime = '';
    if (props.hasOwnProperty('utime')) {
      const v = props.utime;
      this.utime = v;
    }

    this.hostname = '';
    if (props.hasOwnProperty('hostname')) {
      const v = props.hostname;
      this.hostname = v;
    }

    this.fwversion = '';
    if (props.hasOwnProperty('fwversion')) {
      const v = props.fwversion;
      this.fwversion = v;
    }

    this.role = '';
    if (props.hasOwnProperty('role')) {
      const v = props.role;
      this.role = v;
    }

    this.loadavg = [];
    if (props.hasOwnProperty('loadavg')) {
      const v = props.loadavg;
      if (!Array.isArray(v)) {
        throw new Error('repeated field loadavg should be array');
      }
      this.loadavg = v.map(function(v) {
        return v;
      });
    }

    this.meminfo = null;
    if (props.hasOwnProperty('meminfo')) {
      const v = props.meminfo;
      this.meminfo = v && new AirfiberStatus_New_Status_Host_MemInfo(v);
    }
  }

  getMeminfo(): AirfiberStatus_New_Status_Host_MemInfo {
    if (this.meminfo) {
      return this.meminfo;
    }
    return new AirfiberStatus_New_Status_Host_MemInfo();
  }
}
export {AirfiberStatus_New_Status_Host};
class AirfiberStatus_New_Status_Interface_Status {
  plugged: string;
  speed: string;
  duplex: string;

  constructor(
    props: $Shape<AirfiberStatus_New_Status_Interface_Status> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.plugged = '0';
    if (props.hasOwnProperty('plugged')) {
      const v = props.plugged;
      this.plugged = v;
    }

    this.speed = '0';
    if (props.hasOwnProperty('speed')) {
      const v = props.speed;
      this.speed = v;
    }

    this.duplex = '0';
    if (props.hasOwnProperty('duplex')) {
      const v = props.duplex;
      this.duplex = v;
    }
  }
}
export {AirfiberStatus_New_Status_Interface_Status};
class AirfiberStatus_New_Status_Interface_IPV4 {
  addr: string;
  netmask: string;
  broadcast: string;

  constructor(
    props: $Shape<AirfiberStatus_New_Status_Interface_IPV4> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.addr = '';
    if (props.hasOwnProperty('addr')) {
      const v = props.addr;
      this.addr = v;
    }

    this.netmask = '';
    if (props.hasOwnProperty('netmask')) {
      const v = props.netmask;
      this.netmask = v;
    }

    this.broadcast = '';
    if (props.hasOwnProperty('broadcast')) {
      const v = props.broadcast;
      this.broadcast = v;
    }
  }
}
export {AirfiberStatus_New_Status_Interface_IPV4};
class AirfiberStatus_New_Status_Interface_Stats {
  rx_packets: string;
  rx_bytes: string;
  rx_errors: string;
  rx_dropped: string;
  rx_overruns: string;
  rx_frame: string;
  rx_compressed: string;
  rx_multicast: string;
  tx_packets: string;
  tx_bytes: string;
  tx_errors: string;
  tx_dropped: string;
  tx_overruns: string;
  tx_carrier: string;
  tx_compressed: string;
  collisions: string;

  constructor(
    props: $Shape<AirfiberStatus_New_Status_Interface_Stats> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.rx_packets = '';
    if (props.hasOwnProperty('rx_packets')) {
      const v = props.rx_packets;
      this.rx_packets = v;
    }

    this.rx_bytes = '';
    if (props.hasOwnProperty('rx_bytes')) {
      const v = props.rx_bytes;
      this.rx_bytes = v;
    }

    this.rx_errors = '';
    if (props.hasOwnProperty('rx_errors')) {
      const v = props.rx_errors;
      this.rx_errors = v;
    }

    this.rx_dropped = '';
    if (props.hasOwnProperty('rx_dropped')) {
      const v = props.rx_dropped;
      this.rx_dropped = v;
    }

    this.rx_overruns = '';
    if (props.hasOwnProperty('rx_overruns')) {
      const v = props.rx_overruns;
      this.rx_overruns = v;
    }

    this.rx_frame = '';
    if (props.hasOwnProperty('rx_frame')) {
      const v = props.rx_frame;
      this.rx_frame = v;
    }

    this.rx_compressed = '';
    if (props.hasOwnProperty('rx_compressed')) {
      const v = props.rx_compressed;
      this.rx_compressed = v;
    }

    this.rx_multicast = '';
    if (props.hasOwnProperty('rx_multicast')) {
      const v = props.rx_multicast;
      this.rx_multicast = v;
    }

    this.tx_packets = '';
    if (props.hasOwnProperty('tx_packets')) {
      const v = props.tx_packets;
      this.tx_packets = v;
    }

    this.tx_bytes = '';
    if (props.hasOwnProperty('tx_bytes')) {
      const v = props.tx_bytes;
      this.tx_bytes = v;
    }

    this.tx_errors = '';
    if (props.hasOwnProperty('tx_errors')) {
      const v = props.tx_errors;
      this.tx_errors = v;
    }

    this.tx_dropped = '';
    if (props.hasOwnProperty('tx_dropped')) {
      const v = props.tx_dropped;
      this.tx_dropped = v;
    }

    this.tx_overruns = '';
    if (props.hasOwnProperty('tx_overruns')) {
      const v = props.tx_overruns;
      this.tx_overruns = v;
    }

    this.tx_carrier = '';
    if (props.hasOwnProperty('tx_carrier')) {
      const v = props.tx_carrier;
      this.tx_carrier = v;
    }

    this.tx_compressed = '';
    if (props.hasOwnProperty('tx_compressed')) {
      const v = props.tx_compressed;
      this.tx_compressed = v;
    }

    this.collisions = '';
    if (props.hasOwnProperty('collisions')) {
      const v = props.collisions;
      this.collisions = v;
    }
  }
}
export {AirfiberStatus_New_Status_Interface_Stats};
class AirfiberStatus_New_Status_Interface {
  ifname: string;
  ifindex: string;
  flags: string;
  hwaddr: string;
  hwtype: string;
  mtu: string;
  status: Array<AirfiberStatus_New_Status_Interface_Status>;
  ipv4: ?AirfiberStatus_New_Status_Interface_IPV4;
  stats: ?AirfiberStatus_New_Status_Interface_Stats;

  constructor(props: $Shape<AirfiberStatus_New_Status_Interface> = {}): void {
    if (!props) {
      props = {};
    }

    this.ifname = '';
    if (props.hasOwnProperty('ifname')) {
      const v = props.ifname;
      this.ifname = v;
    }

    this.ifindex = '';
    if (props.hasOwnProperty('ifindex')) {
      const v = props.ifindex;
      this.ifindex = v;
    }

    this.flags = '';
    if (props.hasOwnProperty('flags')) {
      const v = props.flags;
      this.flags = v;
    }

    this.hwaddr = '';
    if (props.hasOwnProperty('hwaddr')) {
      const v = props.hwaddr;
      this.hwaddr = v;
    }

    this.hwtype = '';
    if (props.hasOwnProperty('hwtype')) {
      const v = props.hwtype;
      this.hwtype = v;
    }

    this.mtu = '';
    if (props.hasOwnProperty('mtu')) {
      const v = props.mtu;
      this.mtu = v;
    }

    this.status = [];
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      if (!Array.isArray(v)) {
        throw new Error('repeated field status should be array');
      }
      this.status = v.map(function(v) {
        return new AirfiberStatus_New_Status_Interface_Status(v);
      });
    }

    this.ipv4 = null;
    if (props.hasOwnProperty('ipv4')) {
      const v = props.ipv4;
      this.ipv4 = v && new AirfiberStatus_New_Status_Interface_IPV4(v);
    }

    this.stats = null;
    if (props.hasOwnProperty('stats')) {
      const v = props.stats;
      this.stats = v && new AirfiberStatus_New_Status_Interface_Stats(v);
    }
  }

  getIpv4(): AirfiberStatus_New_Status_Interface_IPV4 {
    if (this.ipv4) {
      return this.ipv4;
    }
    return new AirfiberStatus_New_Status_Interface_IPV4();
  }

  getStats(): AirfiberStatus_New_Status_Interface_Stats {
    if (this.stats) {
      return this.stats;
    }
    return new AirfiberStatus_New_Status_Interface_Stats();
  }
}
export {AirfiberStatus_New_Status_Interface};
class AirfiberStatus_New_Status {
  host: ?AirfiberStatus_New_Status_Host;
  interfaces: Array<AirfiberStatus_New_Status_Interface>;

  constructor(props: $Shape<AirfiberStatus_New_Status> = {}): void {
    if (!props) {
      props = {};
    }

    this.host = null;
    if (props.hasOwnProperty('host')) {
      const v = props.host;
      this.host = v && new AirfiberStatus_New_Status_Host(v);
    }

    this.interfaces = [];
    if (props.hasOwnProperty('interfaces')) {
      const v = props.interfaces;
      if (!Array.isArray(v)) {
        throw new Error('repeated field interfaces should be array');
      }
      this.interfaces = v.map(function(v) {
        return new AirfiberStatus_New_Status_Interface(v);
      });
    }
  }

  getHost(): AirfiberStatus_New_Status_Host {
    if (this.host) {
      return this.host;
    }
    return new AirfiberStatus_New_Status_Host();
  }
}
export {AirfiberStatus_New_Status};
class AirfiberStatus_New {
  status: ?AirfiberStatus_New_Status;

  constructor(props: $Shape<AirfiberStatus_New> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = null;
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v && new AirfiberStatus_New_Status(v);
    }
  }

  getStatus(): AirfiberStatus_New_Status {
    if (this.status) {
      return this.status;
    }
    return new AirfiberStatus_New_Status();
  }
}
export {AirfiberStatus_New};
class AirfiberStatus {
  standard: ?AirfiberStatus_Standard;
  new: ?AirfiberStatus_New;

  constructor(props: $Shape<AirfiberStatus> = {}): void {
    if (!props) {
      props = {};
    }

    this.standard = null;
    if (props.hasOwnProperty('standard')) {
      const v = props.standard;
      this.standard = v && new AirfiberStatus_Standard(v);
    }

    this.new = null;
    if (props.hasOwnProperty('new')) {
      const v = props.new;
      this.new = v && new AirfiberStatus_New(v);
    }
  }

  getStandard(): AirfiberStatus_Standard {
    if (this.standard) {
      return this.standard;
    }
    return new AirfiberStatus_Standard();
  }

  getNew(): AirfiberStatus_New {
    if (this.new) {
      return this.new;
    }
    return new AirfiberStatus_New();
  }
}
export {AirfiberStatus};

// END cwn/ubnt/status/models.proto
