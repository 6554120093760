// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_log from '../../cwn/log';

// START cwn/audit/models.proto

class Record {
  id: string;
  recorded: ?Date;
  log_line: ?cwn_log.Line;
  in_bq: boolean;

  constructor(props: $Shape<Record> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.recorded = null;
    if (props.hasOwnProperty('recorded')) {
      const v = props.recorded;
      this.recorded = v && new Date(v);
    }

    this.log_line = null;
    if (props.hasOwnProperty('log_line')) {
      const v = props.log_line;
      this.log_line = v && new cwn_log.Line(v);
    }

    this.in_bq = false;
    if (props.hasOwnProperty('in_bq')) {
      const v = props.in_bq;
      this.in_bq = !!v;
    }
  }

  getLogLine(): cwn_log.Line {
    if (this.log_line) {
      return this.log_line;
    }
    return new cwn_log.Line();
  }
}
export {Record};

class RecordDisplay {
  id: string;
  device_id: string;
  created: ?Date;
  initiator: string;
  action: string;
  message: string;
  blob: string;

  constructor(props: $Shape<RecordDisplay> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.initiator = '';
    if (props.hasOwnProperty('initiator')) {
      const v = props.initiator;
      this.initiator = v;
    }

    this.action = '';
    if (props.hasOwnProperty('action')) {
      const v = props.action;
      this.action = v;
    }

    this.message = '';
    if (props.hasOwnProperty('message')) {
      const v = props.message;
      this.message = v;
    }

    this.blob = '';
    if (props.hasOwnProperty('blob')) {
      const v = props.blob;
      this.blob = v;
    }
  }
}
export {RecordDisplay};

class BqRecord {
  id: string;
  recorded: ?Date;
  log_line: ?cwn_log.BqLine;

  constructor(props: $Shape<BqRecord> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.recorded = null;
    if (props.hasOwnProperty('recorded')) {
      const v = props.recorded;
      this.recorded = v && new Date(v);
    }

    this.log_line = null;
    if (props.hasOwnProperty('log_line')) {
      const v = props.log_line;
      this.log_line = v && new cwn_log.BqLine(v);
    }
  }

  getLogLine(): cwn_log.BqLine {
    if (this.log_line) {
      return this.log_line;
    }
    return new cwn_log.BqLine();
  }
}
export {BqRecord};

// END cwn/audit/models.proto
