// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../../google/protobuf';
/* eslint-enable */

// START cwn/lidar/los/models.proto

class Obstruction {
  latitude: number;
  longitude: number;
  height: number;
  type: string;

  constructor(props: $Shape<Obstruction> = {}): void {
    if (!props) {
      props = {};
    }

    this.latitude = 0;
    if (props.hasOwnProperty('latitude')) {
      const v = props.latitude;
      this.latitude = v;
    }

    this.longitude = 0;
    if (props.hasOwnProperty('longitude')) {
      const v = props.longitude;
      this.longitude = v;
    }

    this.height = 0;
    if (props.hasOwnProperty('height')) {
      const v = props.height;
      this.height = v;
    }

    this.type = '';
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }
  }
}
export {Obstruction};

class Cache {
  id: string;
  updated: ?Date;
  obstructions: Array<Obstruction>;

  constructor(props: $Shape<Cache> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.obstructions = [];
    if (props.hasOwnProperty('obstructions')) {
      const v = props.obstructions;
      if (!Array.isArray(v)) {
        throw new Error('repeated field obstructions should be array');
      }
      this.obstructions = v.map(function(v) {
        return new Obstruction(v);
      });
    }
  }
}
export {Cache};

// END cwn/lidar/los/models.proto
