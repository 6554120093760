// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_network from '../../cwn/network';
import * as cwn_asset from '../../cwn/asset';

// START cwn/moca/models.proto

class Channel_TX {
  power: string;
  phy_rate: string;
  bit_loading: string;

  constructor(props: $Shape<Channel_TX> = {}): void {
    if (!props) {
      props = {};
    }

    this.power = '0';
    if (props.hasOwnProperty('power')) {
      const v = props.power;
      this.power = v;
    }

    this.phy_rate = '0';
    if (props.hasOwnProperty('phy_rate')) {
      const v = props.phy_rate;
      this.phy_rate = v;
    }

    this.bit_loading = '';
    if (props.hasOwnProperty('bit_loading')) {
      const v = props.bit_loading;
      this.bit_loading = v;
    }
  }
}
export {Channel_TX};
class Channel_RX {
  power: number;
  phy_rate: string;
  bit_loading: string;
  snr: number;

  constructor(props: $Shape<Channel_RX> = {}): void {
    if (!props) {
      props = {};
    }

    this.power = 0;
    if (props.hasOwnProperty('power')) {
      const v = props.power;
      this.power = v;
    }

    this.phy_rate = '0';
    if (props.hasOwnProperty('phy_rate')) {
      const v = props.phy_rate;
      this.phy_rate = v;
    }

    this.bit_loading = '';
    if (props.hasOwnProperty('bit_loading')) {
      const v = props.bit_loading;
      this.bit_loading = v;
    }

    this.snr = 0;
    if (props.hasOwnProperty('snr')) {
      const v = props.snr;
      this.snr = v;
    }
  }
}
export {Channel_RX};
class Channel {
  frequency: string;
  tx: ?Channel_TX;
  rx: ?Channel_RX;

  constructor(props: $Shape<Channel> = {}): void {
    if (!props) {
      props = {};
    }

    this.frequency = '0';
    if (props.hasOwnProperty('frequency')) {
      const v = props.frequency;
      this.frequency = v;
    }

    this.tx = null;
    if (props.hasOwnProperty('tx')) {
      const v = props.tx;
      this.tx = v && new Channel_TX(v);
    }

    this.rx = null;
    if (props.hasOwnProperty('rx')) {
      const v = props.rx;
      this.rx = v && new Channel_RX(v);
    }
  }

  getTx(): Channel_TX {
    if (this.tx) {
      return this.tx;
    }
    return new Channel_TX();
  }

  getRx(): Channel_RX {
    if (this.rx) {
      return this.rx;
    }
    return new Channel_RX();
  }
}
export {Channel};

class Node {
  hw_addr: ?cwn_network.HwAddr;
  channels: Array<Channel>;

  constructor(props: $Shape<Node> = {}): void {
    if (!props) {
      props = {};
    }

    this.hw_addr = null;
    if (props.hasOwnProperty('hw_addr')) {
      const v = props.hw_addr;
      this.hw_addr = v && new cwn_network.HwAddr(v);
    }

    this.channels = [];
    if (props.hasOwnProperty('channels')) {
      const v = props.channels;
      if (!Array.isArray(v)) {
        throw new Error('repeated field channels should be array');
      }
      this.channels = v.map(function(v) {
        return new Channel(v);
      });
    }
  }

  getHwAddr(): cwn_network.HwAddr {
    if (this.hw_addr) {
      return this.hw_addr;
    }
    return new cwn_network.HwAddr();
  }
}
export {Node};

class Modem_Ethernet {
  link: boolean;
  speed: string;
  full_duplex: boolean;

  constructor(props: $Shape<Modem_Ethernet> = {}): void {
    if (!props) {
      props = {};
    }

    this.link = false;
    if (props.hasOwnProperty('link')) {
      const v = props.link;
      this.link = !!v;
    }

    this.speed = '0';
    if (props.hasOwnProperty('speed')) {
      const v = props.speed;
      this.speed = v;
    }

    this.full_duplex = false;
    if (props.hasOwnProperty('full_duplex')) {
      const v = props.full_duplex;
      this.full_duplex = !!v;
    }
  }
}
export {Modem_Ethernet};
class Modem {
  ip_addr: ?cwn_network.IPAddr;
  hw_addr: ?cwn_network.HwAddr;
  asset_model: cwn_asset.Model;
  firmware_version: string;
  uptime: string;
  link_uptime: string;
  peers: Array<Node>;
  ethernet: ?Modem_Ethernet;
  created: ?Date;
  device_id: string;
  device_interface_name: string;
  headend: boolean;

  constructor(props: $Shape<Modem> = {}): void {
    if (!props) {
      props = {};
    }

    this.ip_addr = null;
    if (props.hasOwnProperty('ip_addr')) {
      const v = props.ip_addr;
      this.ip_addr = v && new cwn_network.IPAddr(v);
    }

    this.hw_addr = null;
    if (props.hasOwnProperty('hw_addr')) {
      const v = props.hw_addr;
      this.hw_addr = v && new cwn_network.HwAddr(v);
    }

    this.asset_model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('asset_model')) {
      const v = props.asset_model;
      this.asset_model = v;
    }

    this.firmware_version = '';
    if (props.hasOwnProperty('firmware_version')) {
      const v = props.firmware_version;
      this.firmware_version = v;
    }

    this.uptime = '0';
    if (props.hasOwnProperty('uptime')) {
      const v = props.uptime;
      this.uptime = v;
    }

    this.link_uptime = '0';
    if (props.hasOwnProperty('link_uptime')) {
      const v = props.link_uptime;
      this.link_uptime = v;
    }

    this.peers = [];
    if (props.hasOwnProperty('peers')) {
      const v = props.peers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field peers should be array');
      }
      this.peers = v.map(function(v) {
        return new Node(v);
      });
    }

    this.ethernet = null;
    if (props.hasOwnProperty('ethernet')) {
      const v = props.ethernet;
      this.ethernet = v && new Modem_Ethernet(v);
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.device_interface_name = '';
    if (props.hasOwnProperty('device_interface_name')) {
      const v = props.device_interface_name;
      this.device_interface_name = v;
    }

    this.headend = false;
    if (props.hasOwnProperty('headend')) {
      const v = props.headend;
      this.headend = !!v;
    }
  }

  getIpAddr(): cwn_network.IPAddr {
    if (this.ip_addr) {
      return this.ip_addr;
    }
    return new cwn_network.IPAddr();
  }

  getHwAddr(): cwn_network.HwAddr {
    if (this.hw_addr) {
      return this.hw_addr;
    }
    return new cwn_network.HwAddr();
  }

  getEthernet(): Modem_Ethernet {
    if (this.ethernet) {
      return this.ethernet;
    }
    return new Modem_Ethernet();
  }
}
export {Modem};

// END cwn/moca/models.proto
