// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

import * as cwn_site_build from '../../../../cwn/site/build';

// START cwn/site/build/viewmodels/models.proto

class RequiredWorkViewModel {
  work_order_id: string;
  add_device: ?cwn_site_build.AddDevice;
  add_radio: ?cwn_site_build.AddRadio;
  remove_radio: ?cwn_site_build.RemoveRadio;
  repoint_radio: ?cwn_site_build.RepointRadio;
  move_mount: ?cwn_site_build.MoveMount;
  add_mount_without_radios: ?cwn_site_build.AddMountWithoutRadios;
  add_mount_with_radios: ?cwn_site_build.AddMountWithRadios;

  constructor(props: $Shape<RequiredWorkViewModel> = {}): void {
    if (!props) {
      props = {};
    }

    this.work_order_id = '0';
    if (props.hasOwnProperty('work_order_id')) {
      const v = props.work_order_id;
      this.work_order_id = v;
    }

    this.add_device = null;
    if (props.hasOwnProperty('add_device')) {
      const v = props.add_device;
      this.add_device = v && new cwn_site_build.AddDevice(v);
    }

    this.add_radio = null;
    if (props.hasOwnProperty('add_radio')) {
      const v = props.add_radio;
      this.add_radio = v && new cwn_site_build.AddRadio(v);
    }

    this.remove_radio = null;
    if (props.hasOwnProperty('remove_radio')) {
      const v = props.remove_radio;
      this.remove_radio = v && new cwn_site_build.RemoveRadio(v);
    }

    this.repoint_radio = null;
    if (props.hasOwnProperty('repoint_radio')) {
      const v = props.repoint_radio;
      this.repoint_radio = v && new cwn_site_build.RepointRadio(v);
    }

    this.move_mount = null;
    if (props.hasOwnProperty('move_mount')) {
      const v = props.move_mount;
      this.move_mount = v && new cwn_site_build.MoveMount(v);
    }

    this.add_mount_without_radios = null;
    if (props.hasOwnProperty('add_mount_without_radios')) {
      const v = props.add_mount_without_radios;
      this.add_mount_without_radios =
        v && new cwn_site_build.AddMountWithoutRadios(v);
    }

    this.add_mount_with_radios = null;
    if (props.hasOwnProperty('add_mount_with_radios')) {
      const v = props.add_mount_with_radios;
      this.add_mount_with_radios =
        v && new cwn_site_build.AddMountWithRadios(v);
    }
  }

  getAddDevice(): cwn_site_build.AddDevice {
    if (this.add_device) {
      return this.add_device;
    }
    return new cwn_site_build.AddDevice();
  }

  getAddRadio(): cwn_site_build.AddRadio {
    if (this.add_radio) {
      return this.add_radio;
    }
    return new cwn_site_build.AddRadio();
  }

  getRemoveRadio(): cwn_site_build.RemoveRadio {
    if (this.remove_radio) {
      return this.remove_radio;
    }
    return new cwn_site_build.RemoveRadio();
  }

  getRepointRadio(): cwn_site_build.RepointRadio {
    if (this.repoint_radio) {
      return this.repoint_radio;
    }
    return new cwn_site_build.RepointRadio();
  }

  getMoveMount(): cwn_site_build.MoveMount {
    if (this.move_mount) {
      return this.move_mount;
    }
    return new cwn_site_build.MoveMount();
  }

  getAddMountWithoutRadios(): cwn_site_build.AddMountWithoutRadios {
    if (this.add_mount_without_radios) {
      return this.add_mount_without_radios;
    }
    return new cwn_site_build.AddMountWithoutRadios();
  }

  getAddMountWithRadios(): cwn_site_build.AddMountWithRadios {
    if (this.add_mount_with_radios) {
      return this.add_mount_with_radios;
    }
    return new cwn_site_build.AddMountWithRadios();
  }
}
export {RequiredWorkViewModel};

// END cwn/site/build/viewmodels/models.proto
