// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */

// START cwn/serviceability/models.proto

class SiteServiceabilityTolerance {
  id: string;
  average_customer_speed: number;
  has_speed_tests: boolean;
  hops_from_relay_or_source: string;
  updated: ?Date;
  deleted: boolean;
  layer: string;
  ad_srl_count: string;
  available_ad_ports: string;

  constructor(props: $Shape<SiteServiceabilityTolerance> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.average_customer_speed = 0;
    if (props.hasOwnProperty('average_customer_speed')) {
      const v = props.average_customer_speed;
      this.average_customer_speed = v;
    }

    this.has_speed_tests = false;
    if (props.hasOwnProperty('has_speed_tests')) {
      const v = props.has_speed_tests;
      this.has_speed_tests = !!v;
    }

    this.hops_from_relay_or_source = '0';
    if (props.hasOwnProperty('hops_from_relay_or_source')) {
      const v = props.hops_from_relay_or_source;
      this.hops_from_relay_or_source = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.deleted = false;
    if (props.hasOwnProperty('deleted')) {
      const v = props.deleted;
      this.deleted = !!v;
    }

    this.layer = '';
    if (props.hasOwnProperty('layer')) {
      const v = props.layer;
      this.layer = v;
    }

    this.ad_srl_count = '0';
    if (props.hasOwnProperty('ad_srl_count')) {
      const v = props.ad_srl_count;
      this.ad_srl_count = v;
    }

    this.available_ad_ports = '0';
    if (props.hasOwnProperty('available_ad_ports')) {
      const v = props.available_ad_ports;
      this.available_ad_ports = v;
    }
  }
}
export {SiteServiceabilityTolerance};

export const Geo_MarketStatusValues = {
  UNKNOWN: 0,
  NOT_LIVE: 1,
  LIVE: 2,
  COMING_SOON: 3,
  PAUSED: 4,
};
type Geo_MarketStatus = $Keys<typeof Geo_MarketStatusValues>;
export type {Geo_MarketStatus};

export function Geo_MarketStatusValue(n: number): Geo_MarketStatus {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'NOT_LIVE';

    case 2:
      return 'LIVE';

    case 3:
      return 'COMING_SOON';

    case 4:
      return 'PAUSED';

    default:
      return 'UNKNOWN';
  }
}

class Geo {
  geofence_id: string;
  created: ?Date;
  updated: ?Date;
  market_status: Geo_MarketStatus;
  max_shot_distance: number;
  total_shots: number;
  total_shots_with_relays: number;
  shots_to_relays: number;
  auto_reject_limit: number;
  max_hops: number;
  inactive: boolean;
  max_own_building_obstructions: string;
  max_not_own_building_obstructions: string;

  constructor(props: $Shape<Geo> = {}): void {
    if (!props) {
      props = {};
    }

    this.geofence_id = '0';
    if (props.hasOwnProperty('geofence_id')) {
      const v = props.geofence_id;
      this.geofence_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.market_status = Geo_MarketStatusValue(0);
    if (props.hasOwnProperty('market_status')) {
      const v = props.market_status;
      this.market_status = v;
    }

    this.max_shot_distance = 0;
    if (props.hasOwnProperty('max_shot_distance')) {
      const v = props.max_shot_distance;
      this.max_shot_distance = v;
    }

    this.total_shots = 0;
    if (props.hasOwnProperty('total_shots')) {
      const v = props.total_shots;
      this.total_shots = v;
    }

    this.total_shots_with_relays = 0;
    if (props.hasOwnProperty('total_shots_with_relays')) {
      const v = props.total_shots_with_relays;
      this.total_shots_with_relays = v;
    }

    this.shots_to_relays = 0;
    if (props.hasOwnProperty('shots_to_relays')) {
      const v = props.shots_to_relays;
      this.shots_to_relays = v;
    }

    this.auto_reject_limit = 0;
    if (props.hasOwnProperty('auto_reject_limit')) {
      const v = props.auto_reject_limit;
      this.auto_reject_limit = v;
    }

    this.max_hops = 0;
    if (props.hasOwnProperty('max_hops')) {
      const v = props.max_hops;
      this.max_hops = v;
    }

    this.inactive = false;
    if (props.hasOwnProperty('inactive')) {
      const v = props.inactive;
      this.inactive = !!v;
    }

    this.max_own_building_obstructions = '0';
    if (props.hasOwnProperty('max_own_building_obstructions')) {
      const v = props.max_own_building_obstructions;
      this.max_own_building_obstructions = v;
    }

    this.max_not_own_building_obstructions = '0';
    if (props.hasOwnProperty('max_not_own_building_obstructions')) {
      const v = props.max_not_own_building_obstructions;
      this.max_not_own_building_obstructions = v;
    }
  }
}
export {Geo};

// END cwn/serviceability/models.proto
