// @flow
import React, {type Node} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'ui';

type Props = {|
  open: boolean,
  title?: Node,
  message?: Node,
  onClose: (event: ?Event, reason: string) => void,
|};

export default function ErrorDialog({title, message, open, onClose}: Props) {
  return (
    <Dialog onClose={onClose} open={open}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {message && (
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ErrorDialog.defaultProps = {
  title: <span>Something went wrong</span>,
  message: (
    <span>
      If the issue persists, please contact our support team at{' '}
      <a href="mailto:support@common.net">support@common.net</a>.
    </span>
  ),
};
