// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */

// START cwn/ops/models.proto

export const InstallTimesTypeValues = {
  UNKNOWN: 0,
  STANDARD: 1,
  SAN_LEANDRO: 2,
};
type InstallTimesType = $Keys<typeof InstallTimesTypeValues>;
export type {InstallTimesType};

export function InstallTimesTypeValue(n: number): InstallTimesType {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'STANDARD';

    case 2:
      return 'SAN_LEANDRO';

    default:
      return 'UNKNOWN';
  }
}

export const Geo_AcuityConfig_ApiKeyEnumValues = {
  UNKNOWN: 0,
  EAST_BAY: 1,
  SOUTH_BAY: 2,
};
type Geo_AcuityConfig_ApiKeyEnum = $Keys<
  typeof Geo_AcuityConfig_ApiKeyEnumValues,
>;
export type {Geo_AcuityConfig_ApiKeyEnum};

export function Geo_AcuityConfig_ApiKeyEnumValue(
  n: number,
): Geo_AcuityConfig_ApiKeyEnum {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'EAST_BAY';

    case 2:
      return 'SOUTH_BAY';

    default:
      return 'UNKNOWN';
  }
}

class Geo_AcuityConfig_AppointmentTypes {
  default_sfh_type: number;
  default_mdu_type: number;
  default_small_mdu_type: number;
  basic_sfh_type: number;
  basic_mdu_type: number;
  basic_small_mdu_type: number;
  fast_sfh_type: number;
  fast_mdu_type: number;
  fast_small_mdu_type: number;
  fastest_sfh_type: number;
  fastest_mdu_type: number;
  fastest_small_mdu_type: number;
  moca_mdu_type: number;

  constructor(props: $Shape<Geo_AcuityConfig_AppointmentTypes> = {}): void {
    if (!props) {
      props = {};
    }

    this.default_sfh_type = 0;
    if (props.hasOwnProperty('default_sfh_type')) {
      const v = props.default_sfh_type;
      this.default_sfh_type = v;
    }

    this.default_mdu_type = 0;
    if (props.hasOwnProperty('default_mdu_type')) {
      const v = props.default_mdu_type;
      this.default_mdu_type = v;
    }

    this.default_small_mdu_type = 0;
    if (props.hasOwnProperty('default_small_mdu_type')) {
      const v = props.default_small_mdu_type;
      this.default_small_mdu_type = v;
    }

    this.basic_sfh_type = 0;
    if (props.hasOwnProperty('basic_sfh_type')) {
      const v = props.basic_sfh_type;
      this.basic_sfh_type = v;
    }

    this.basic_mdu_type = 0;
    if (props.hasOwnProperty('basic_mdu_type')) {
      const v = props.basic_mdu_type;
      this.basic_mdu_type = v;
    }

    this.basic_small_mdu_type = 0;
    if (props.hasOwnProperty('basic_small_mdu_type')) {
      const v = props.basic_small_mdu_type;
      this.basic_small_mdu_type = v;
    }

    this.fast_sfh_type = 0;
    if (props.hasOwnProperty('fast_sfh_type')) {
      const v = props.fast_sfh_type;
      this.fast_sfh_type = v;
    }

    this.fast_mdu_type = 0;
    if (props.hasOwnProperty('fast_mdu_type')) {
      const v = props.fast_mdu_type;
      this.fast_mdu_type = v;
    }

    this.fast_small_mdu_type = 0;
    if (props.hasOwnProperty('fast_small_mdu_type')) {
      const v = props.fast_small_mdu_type;
      this.fast_small_mdu_type = v;
    }

    this.fastest_sfh_type = 0;
    if (props.hasOwnProperty('fastest_sfh_type')) {
      const v = props.fastest_sfh_type;
      this.fastest_sfh_type = v;
    }

    this.fastest_mdu_type = 0;
    if (props.hasOwnProperty('fastest_mdu_type')) {
      const v = props.fastest_mdu_type;
      this.fastest_mdu_type = v;
    }

    this.fastest_small_mdu_type = 0;
    if (props.hasOwnProperty('fastest_small_mdu_type')) {
      const v = props.fastest_small_mdu_type;
      this.fastest_small_mdu_type = v;
    }

    this.moca_mdu_type = 0;
    if (props.hasOwnProperty('moca_mdu_type')) {
      const v = props.moca_mdu_type;
      this.moca_mdu_type = v;
    }
  }
}
export {Geo_AcuityConfig_AppointmentTypes};
class Geo_AcuityConfig {
  api_key_enum: Geo_AcuityConfig_ApiKeyEnum;
  user_id: string;
  appointment_types: ?Geo_AcuityConfig_AppointmentTypes;

  constructor(props: $Shape<Geo_AcuityConfig> = {}): void {
    if (!props) {
      props = {};
    }

    this.api_key_enum = Geo_AcuityConfig_ApiKeyEnumValue(0);
    if (props.hasOwnProperty('api_key_enum')) {
      const v = props.api_key_enum;
      this.api_key_enum = v;
    }

    this.user_id = '';
    if (props.hasOwnProperty('user_id')) {
      const v = props.user_id;
      this.user_id = v;
    }

    this.appointment_types = null;
    if (props.hasOwnProperty('appointment_types')) {
      const v = props.appointment_types;
      this.appointment_types = v && new Geo_AcuityConfig_AppointmentTypes(v);
    }
  }

  getAppointmentTypes(): Geo_AcuityConfig_AppointmentTypes {
    if (this.appointment_types) {
      return this.appointment_types;
    }
    return new Geo_AcuityConfig_AppointmentTypes();
  }
}
export {Geo_AcuityConfig};
class Geo_ZendeskConfig {
  area_tag: string;
  area_repr: string;

  constructor(props: $Shape<Geo_ZendeskConfig> = {}): void {
    if (!props) {
      props = {};
    }

    this.area_tag = '';
    if (props.hasOwnProperty('area_tag')) {
      const v = props.area_tag;
      this.area_tag = v;
    }

    this.area_repr = '';
    if (props.hasOwnProperty('area_repr')) {
      const v = props.area_repr;
      this.area_repr = v;
    }
  }
}
export {Geo_ZendeskConfig};
class Geo {
  geofence_id: string;
  created: ?Date;
  updated: ?Date;
  inactive: boolean;
  acuity_config: ?Geo_AcuityConfig;
  zendesk_config: ?Geo_ZendeskConfig;
  install_times_type: InstallTimesType;

  constructor(props: $Shape<Geo> = {}): void {
    if (!props) {
      props = {};
    }

    this.geofence_id = '0';
    if (props.hasOwnProperty('geofence_id')) {
      const v = props.geofence_id;
      this.geofence_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.inactive = false;
    if (props.hasOwnProperty('inactive')) {
      const v = props.inactive;
      this.inactive = !!v;
    }

    this.acuity_config = null;
    if (props.hasOwnProperty('acuity_config')) {
      const v = props.acuity_config;
      this.acuity_config = v && new Geo_AcuityConfig(v);
    }

    this.zendesk_config = null;
    if (props.hasOwnProperty('zendesk_config')) {
      const v = props.zendesk_config;
      this.zendesk_config = v && new Geo_ZendeskConfig(v);
    }

    this.install_times_type = InstallTimesTypeValue(0);
    if (props.hasOwnProperty('install_times_type')) {
      const v = props.install_times_type;
      this.install_times_type = v;
    }
  }

  getAcuityConfig(): Geo_AcuityConfig {
    if (this.acuity_config) {
      return this.acuity_config;
    }
    return new Geo_AcuityConfig();
  }

  getZendeskConfig(): Geo_ZendeskConfig {
    if (this.zendesk_config) {
      return this.zendesk_config;
    }
    return new Geo_ZendeskConfig();
  }
}
export {Geo};

// END cwn/ops/models.proto
