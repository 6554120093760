// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_site from '../../cwn/site';
import * as cwn_devices_detect from '../../cwn/devices/detect';

// START cwn/inforad/models.proto

class UptimeSummary_DeviceStatus {
  device_id: string;
  site_id: string;
  area_id: string;
  uptime_pct_24_hours: number;
  currently_online: boolean;
  node_type: cwn_site.Site_NodeType;
  last_heartbeat: ?Date;
  platform: cwn_devices_detect.Platform;

  constructor(props: $Shape<UptimeSummary_DeviceStatus> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.area_id = '0';
    if (props.hasOwnProperty('area_id')) {
      const v = props.area_id;
      this.area_id = v;
    }

    this.uptime_pct_24_hours = 0;
    if (props.hasOwnProperty('uptime_pct_24_hours')) {
      const v = props.uptime_pct_24_hours;
      this.uptime_pct_24_hours = v;
    }

    this.currently_online = false;
    if (props.hasOwnProperty('currently_online')) {
      const v = props.currently_online;
      this.currently_online = !!v;
    }

    this.node_type = cwn_site.Site_NodeTypeValue(0);
    if (props.hasOwnProperty('node_type')) {
      const v = props.node_type;
      this.node_type = v;
    }

    this.last_heartbeat = null;
    if (props.hasOwnProperty('last_heartbeat')) {
      const v = props.last_heartbeat;
      this.last_heartbeat = v && new Date(v);
    }

    this.platform = cwn_devices_detect.PlatformValue(0);
    if (props.hasOwnProperty('platform')) {
      const v = props.platform;
      this.platform = v;
    }
  }
}
export {UptimeSummary_DeviceStatus};
class UptimeSummary {
  device_statuses: Array<UptimeSummary_DeviceStatus>;

  constructor(props: $Shape<UptimeSummary> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_statuses = [];
    if (props.hasOwnProperty('device_statuses')) {
      const v = props.device_statuses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field device_statuses should be array');
      }
      this.device_statuses = v.map(function(v) {
        return new UptimeSummary_DeviceStatus(v);
      });
    }
  }
}
export {UptimeSummary};

// END cwn/inforad/models.proto
