//@flow
import React, {Children} from 'react';
import PropTypes from 'prop-types';
import {AuthenticatedService} from 'pb/cwn/web';
import {type AuthController} from 'auth';

export const AuthenticatedServicePropTypes = {
  authenticatedService: PropTypes.object,
};

export type AuthenticatedServiceContextType = {
  authenticatedService: AuthenticatedService,
};

type AuthProps = {|
  authenticatedService: AuthenticatedService,
  authController: AuthController,
  children: any,
|};

const AUTH_HEADER = 'x-auth-token';

export class AuthenticatedServiceProvider extends React.PureComponent<
  AuthProps,
> {
  static childContextTypes = AuthenticatedServicePropTypes;

  getChildContext() {
    const {authenticatedService} = this.props;
    return {authenticatedService};
  }

  componentDidMount() {
    this.props.authenticatedService.headersProvider = () => {
      return [[AUTH_HEADER, this.props.authController.token() || '']];
    };
  }

  render() {
    return Children.only(this.props.children);
  }
}
