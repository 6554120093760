// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */

// START cwn/vars/models.proto

export const VarValues = {
  INVALID: 0,
  AGENT_MD5SUM: 1,
  BUP_MD5SUM: 2,
  XC_MD5SUM: 3,
  WA_MD5SUM: 4,
  UNIFI_CONTROLLER_LOC: 6,
  REBOOT_RADIOS_CRON: 8,
  OVERRIDE_RADIO_SCAN_LIST: 9,
  SERVER_DOCTOR_OVERRIDE: 10,
  HAN_QDISC: 11,
  ML2560_MD5SUM: 12,
  ROUTING_ALGORITHM: 14,
  SOURCE_DOCTOR_OVERRIDE: 18,
  ROUTING_TABLE_OVERRIDE: 19,
  DNSMASQ_MD5SUM: 42,
  ENABLE_SOURCE_IP6TABLES: 48,
  ML60LW_MD5SUM: 51,
  CUSTOM_SNAPSHOT_PATHS: 52,
  CUSTOM_SNAPSHOT_COMMANDS: 53,
  ROS_ARM_MD5SUM: 56,
  SAVE_SWITCH_CONFIGS: 59,
  BOOST_NETDEV_BUDGET: 66,
  ENABLE_UDP_OUTBOUND_RATELIMIT: 67,
  AIROS_PTMP_AP_TX_POWER: 68,
  BRIDGE_MB_IP: 74,
  OSPF_SOURCES: 75,
  RADIO_GW: 76,
  SNMPD_MD5SUM: 77,
  BRIDGE_SNMP: 78,
  DBUS_WPA_HOSTAPD: 130,
  AIRMAX_ATPC_THRESHOLD: 136,
  FAKE_DIAGNOSES: 146,
  LayerZ_SPEEDTEST_SERVER_OVERRIDE: 154,
  LayerY_SPEEDTEST_SERVER_OVERRIDE: 155,
  AGENT_PING_METRIC_EXPORT_FREQ_SECS: 161,
  AGENT_PING_CONFIG_FREQ_SECS: 162,
  AGENT_PING_PING_FREQ_MS: 163,
  AGENT_PING_RUN_MODULE: 164,
  KICK_NEWLY_DOWNED_INTERFACES: 165,
  BLOCK_GET_ARCHIVE_BINARY: 168,
  AIROS_TDD_FRAMING_FLEXIBLE_NEW: 169,
  SNAPSHOT_MODULE_PERIOD: 173,
  LINK_WEIGHT_DECREASE_INTERVAL_MINUTES: 174,
  RADIO_MODEL_EDGEWEIGHT_MULTIPLIER: 189,
  MIKROTIK_RADIO_WAP_60G_INVENTORY_LOW: 218,
  MIKROTIK_RADIO_WAP_60G_AP_INVENTORY_LOW: 191,
  MIKROTIK_RADIO_LHG_60G_INVENTORY_LOW: 192,
  TAG_FOR_NEW_WORK_ORDER: 193,
  SWO_BAD_SITES: 196,
  CA2_MD5SUM: 197,
  DIJKSTRA_INTERFACES_SHARE_EWOS: 198,
  DEPLOY_AUTOMATED_FREQUENCY_PLAN: 206,
  PLAN_60_GHZ: 215,
  DISABLE_AGENT_OAUTH: 217,
  SNAPSHOT_MINIMAL: 219,
  LESS_SITE_HEALTH_CHECKS: 220,
  REFACTORED_GET_ROUTING_TABLE: 221,
  AGENT_DIRECT_GET_ROUTING_TABLE: 222,
  BQ_DEVICE_SYSLOG: 223,
  BQ_RADIO_STATUS: 224,
  BQ_PING: 225,
  BQ_MOCA: 226,
  BQ_BRIDGES: 227,
  BQ_AUDIT: 228,
  BQ_METRICS: 229,
  BQ_UNIFI: 230,
  BQ_ROUTING_ETL: 231,
  BQ_DISCOVERED_RADIO_HISTORY: 232,
  BQ_LESS_MODEL_SYNC: 233,
  IPAM_RADIO_CONFIG: 234,
  DISABLE_V4_DISCOVERY: 235,
  DISABLE_V4_PEERING: 236,
  RADIO_INTERFACES: 237,
  SKIP_LAYER_A_ADDRS: 238,
};
type Var = $Keys<typeof VarValues>;
export type {Var};

export function VarValue(n: number): Var {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'AGENT_MD5SUM';

    case 2:
      return 'BUP_MD5SUM';

    case 3:
      return 'XC_MD5SUM';

    case 4:
      return 'WA_MD5SUM';

    case 6:
      return 'UNIFI_CONTROLLER_LOC';

    case 8:
      return 'REBOOT_RADIOS_CRON';

    case 9:
      return 'OVERRIDE_RADIO_SCAN_LIST';

    case 10:
      return 'SERVER_DOCTOR_OVERRIDE';

    case 11:
      return 'HAN_QDISC';

    case 12:
      return 'ML2560_MD5SUM';

    case 14:
      return 'ROUTING_ALGORITHM';

    case 18:
      return 'SOURCE_DOCTOR_OVERRIDE';

    case 19:
      return 'ROUTING_TABLE_OVERRIDE';

    case 42:
      return 'DNSMASQ_MD5SUM';

    case 48:
      return 'ENABLE_SOURCE_IP6TABLES';

    case 51:
      return 'ML60LW_MD5SUM';

    case 52:
      return 'CUSTOM_SNAPSHOT_PATHS';

    case 53:
      return 'CUSTOM_SNAPSHOT_COMMANDS';

    case 56:
      return 'ROS_ARM_MD5SUM';

    case 59:
      return 'SAVE_SWITCH_CONFIGS';

    case 66:
      return 'BOOST_NETDEV_BUDGET';

    case 67:
      return 'ENABLE_UDP_OUTBOUND_RATELIMIT';

    case 68:
      return 'AIROS_PTMP_AP_TX_POWER';

    case 74:
      return 'BRIDGE_MB_IP';

    case 75:
      return 'OSPF_SOURCES';

    case 76:
      return 'RADIO_GW';

    case 77:
      return 'SNMPD_MD5SUM';

    case 78:
      return 'BRIDGE_SNMP';

    case 130:
      return 'DBUS_WPA_HOSTAPD';

    case 136:
      return 'AIRMAX_ATPC_THRESHOLD';

    case 146:
      return 'FAKE_DIAGNOSES';

    case 154:
      return 'LayerZ_SPEEDTEST_SERVER_OVERRIDE';

    case 155:
      return 'LayerY_SPEEDTEST_SERVER_OVERRIDE';

    case 161:
      return 'AGENT_PING_METRIC_EXPORT_FREQ_SECS';

    case 162:
      return 'AGENT_PING_CONFIG_FREQ_SECS';

    case 163:
      return 'AGENT_PING_PING_FREQ_MS';

    case 164:
      return 'AGENT_PING_RUN_MODULE';

    case 165:
      return 'KICK_NEWLY_DOWNED_INTERFACES';

    case 168:
      return 'BLOCK_GET_ARCHIVE_BINARY';

    case 169:
      return 'AIROS_TDD_FRAMING_FLEXIBLE_NEW';

    case 173:
      return 'SNAPSHOT_MODULE_PERIOD';

    case 174:
      return 'LINK_WEIGHT_DECREASE_INTERVAL_MINUTES';

    case 189:
      return 'RADIO_MODEL_EDGEWEIGHT_MULTIPLIER';

    case 218:
      return 'MIKROTIK_RADIO_WAP_60G_INVENTORY_LOW';

    case 191:
      return 'MIKROTIK_RADIO_WAP_60G_AP_INVENTORY_LOW';

    case 192:
      return 'MIKROTIK_RADIO_LHG_60G_INVENTORY_LOW';

    case 193:
      return 'TAG_FOR_NEW_WORK_ORDER';

    case 196:
      return 'SWO_BAD_SITES';

    case 197:
      return 'CA2_MD5SUM';

    case 198:
      return 'DIJKSTRA_INTERFACES_SHARE_EWOS';

    case 206:
      return 'DEPLOY_AUTOMATED_FREQUENCY_PLAN';

    case 215:
      return 'PLAN_60_GHZ';

    case 217:
      return 'DISABLE_AGENT_OAUTH';

    case 219:
      return 'SNAPSHOT_MINIMAL';

    case 220:
      return 'LESS_SITE_HEALTH_CHECKS';

    case 221:
      return 'REFACTORED_GET_ROUTING_TABLE';

    case 222:
      return 'AGENT_DIRECT_GET_ROUTING_TABLE';

    case 223:
      return 'BQ_DEVICE_SYSLOG';

    case 224:
      return 'BQ_RADIO_STATUS';

    case 225:
      return 'BQ_PING';

    case 226:
      return 'BQ_MOCA';

    case 227:
      return 'BQ_BRIDGES';

    case 228:
      return 'BQ_AUDIT';

    case 229:
      return 'BQ_METRICS';

    case 230:
      return 'BQ_UNIFI';

    case 231:
      return 'BQ_ROUTING_ETL';

    case 232:
      return 'BQ_DISCOVERED_RADIO_HISTORY';

    case 233:
      return 'BQ_LESS_MODEL_SYNC';

    case 234:
      return 'IPAM_RADIO_CONFIG';

    case 235:
      return 'DISABLE_V4_DISCOVERY';

    case 236:
      return 'DISABLE_V4_PEERING';

    case 237:
      return 'RADIO_INTERFACES';

    case 238:
      return 'SKIP_LAYER_A_ADDRS';

    default:
      return 'INVALID';
  }
}

export const DoctorOverrideValueValues = {
  DOCTOR_OVERRIDE_NONE: 0,
  DOCTOR_ENABLE: 1,
  DOCTOR_DISABLE: 2,
};
type DoctorOverrideValue = $Keys<typeof DoctorOverrideValueValues>;
export type {DoctorOverrideValue};

export function DoctorOverrideValueValue(n: number): DoctorOverrideValue {
  switch (n) {
    case 0:
      return 'DOCTOR_OVERRIDE_NONE';

    case 1:
      return 'DOCTOR_ENABLE';

    case 2:
      return 'DOCTOR_DISABLE';

    default:
      return 'DOCTOR_OVERRIDE_NONE';
  }
}

export const DisableSystemctlTimersValueValues = {
  DISABLE_SYSTEMCTL_TIMERS_NONE: 0,
  DISABLE_SYSTEMCTL_TIMERS_NO: 1,
  DISABLE_SYSTEMCTL_TIMERS_YES: 2,
};
type DisableSystemctlTimersValue = $Keys<
  typeof DisableSystemctlTimersValueValues,
>;
export type {DisableSystemctlTimersValue};

export function DisableSystemctlTimersValueValue(
  n: number,
): DisableSystemctlTimersValue {
  switch (n) {
    case 0:
      return 'DISABLE_SYSTEMCTL_TIMERS_NONE';

    case 1:
      return 'DISABLE_SYSTEMCTL_TIMERS_NO';

    case 2:
      return 'DISABLE_SYSTEMCTL_TIMERS_YES';

    default:
      return 'DISABLE_SYSTEMCTL_TIMERS_NONE';
  }
}

export const AgentOsExecErrorStrategyValueValues = {
  AGENT_OS_EXEC_ERROR_STRATEGY_OLD: 0,
  AGENT_OS_EXEC_ERROR_STRATEGY_NEW: 1,
};
type AgentOsExecErrorStrategyValue = $Keys<
  typeof AgentOsExecErrorStrategyValueValues,
>;
export type {AgentOsExecErrorStrategyValue};

export function AgentOsExecErrorStrategyValueValue(
  n: number,
): AgentOsExecErrorStrategyValue {
  switch (n) {
    case 0:
      return 'AGENT_OS_EXEC_ERROR_STRATEGY_OLD';

    case 1:
      return 'AGENT_OS_EXEC_ERROR_STRATEGY_NEW';

    default:
      return 'AGENT_OS_EXEC_ERROR_STRATEGY_OLD';
  }
}

export const RebootOnKernelPanicValueValues = {
  REBOOT_ON_KERNEL_PANIC_NO: 0,
  REBOOT_ON_KERNEL_PANIC_YES: 1,
};
type RebootOnKernelPanicValue = $Keys<typeof RebootOnKernelPanicValueValues>;
export type {RebootOnKernelPanicValue};

export function RebootOnKernelPanicValueValue(
  n: number,
): RebootOnKernelPanicValue {
  switch (n) {
    case 0:
      return 'REBOOT_ON_KERNEL_PANIC_NO';

    case 1:
      return 'REBOOT_ON_KERNEL_PANIC_YES';

    default:
      return 'REBOOT_ON_KERNEL_PANIC_NO';
  }
}

export const TuneNeighborTableStrategyValueValues = {
  TUNE_NEIGHBOR_TABLE_STRATEGY_OLD: 0,
  TUNE_NEIGHBOR_TABLE_STRATEGY_NEW: 1,
};
type TuneNeighborTableStrategyValue = $Keys<
  typeof TuneNeighborTableStrategyValueValues,
>;
export type {TuneNeighborTableStrategyValue};

export function TuneNeighborTableStrategyValueValue(
  n: number,
): TuneNeighborTableStrategyValue {
  switch (n) {
    case 0:
      return 'TUNE_NEIGHBOR_TABLE_STRATEGY_OLD';

    case 1:
      return 'TUNE_NEIGHBOR_TABLE_STRATEGY_NEW';

    default:
      return 'TUNE_NEIGHBOR_TABLE_STRATEGY_OLD';
  }
}

class Value {
  str: string;
  int: string;

  constructor(props: $Shape<Value> = {}): void {
    if (!props) {
      props = {};
    }

    this.str = '';
    if (props.hasOwnProperty('str')) {
      const v = props.str;
      this.str = v;
    }

    this.int = '0';
    if (props.hasOwnProperty('int')) {
      const v = props.int;
      this.int = v;
    }
  }
}
export {Value};

class Bucket {
  id: string;
  updated: ?Date;
  var: Var;
  description: string;
  value: ?Value;
  deleted: boolean;

  constructor(props: $Shape<Bucket> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.var = VarValue(0);
    if (props.hasOwnProperty('var')) {
      const v = props.var;
      this.var = v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.value = null;
    if (props.hasOwnProperty('value')) {
      const v = props.value;
      this.value = v && new Value(v);
    }

    this.deleted = false;
    if (props.hasOwnProperty('deleted')) {
      const v = props.deleted;
      this.deleted = !!v;
    }
  }

  getValue(): Value {
    if (this.value) {
      return this.value;
    }
    return new Value();
  }
}
export {Bucket};

class Buckets {
  buckets: Array<Bucket>;

  constructor(props: $Shape<Buckets> = {}): void {
    if (!props) {
      props = {};
    }

    this.buckets = [];
    if (props.hasOwnProperty('buckets')) {
      const v = props.buckets;
      if (!Array.isArray(v)) {
        throw new Error('repeated field buckets should be array');
      }
      this.buckets = v.map(function(v) {
        return new Bucket(v);
      });
    }
  }
}
export {Buckets};

class BucketLog {
  id: string;
  bucket: ?Bucket;
  deleted: boolean;

  constructor(props: $Shape<BucketLog> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.bucket = null;
    if (props.hasOwnProperty('bucket')) {
      const v = props.bucket;
      this.bucket = v && new Bucket(v);
    }

    this.deleted = false;
    if (props.hasOwnProperty('deleted')) {
      const v = props.deleted;
      this.deleted = !!v;
    }
  }

  getBucket(): Bucket {
    if (this.bucket) {
      return this.bucket;
    }
    return new Bucket();
  }
}
export {BucketLog};

export const Key_TypeValues = {
  INVALID: 0,
  SUBSCRIPTION: 1,
  DEVICE: 2,
  SITE: 3,
  MAC: 4,
  AREA: 5,
  GLOBAL: 6,
  BOOT_ID: 7,
  USER: 8,
  MODEL: 9,
};
type Key_Type = $Keys<typeof Key_TypeValues>;
export type {Key_Type};

export function Key_TypeValue(n: number): Key_Type {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'SUBSCRIPTION';

    case 2:
      return 'DEVICE';

    case 3:
      return 'SITE';

    case 4:
      return 'MAC';

    case 5:
      return 'AREA';

    case 6:
      return 'GLOBAL';

    case 7:
      return 'BOOT_ID';

    case 8:
      return 'USER';

    case 9:
      return 'MODEL';

    default:
      return 'INVALID';
  }
}

class Key {
  type: Key_Type;
  string_id: string;
  int_id: string;

  constructor(props: $Shape<Key> = {}): void {
    if (!props) {
      props = {};
    }

    this.type = Key_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.string_id = '';
    if (props.hasOwnProperty('string_id')) {
      const v = props.string_id;
      this.string_id = v;
    }

    this.int_id = '0';
    if (props.hasOwnProperty('int_id')) {
      const v = props.int_id;
      this.int_id = v;
    }
  }
}
export {Key};

class Assignments {
  key: ?Key;
  bucket_ids_by_var: {[key: number]: string};
  updated: ?Date;

  constructor(props: $Shape<Assignments> = {}): void {
    if (!props) {
      props = {};
    }

    this.key = null;
    if (props.hasOwnProperty('key')) {
      const v = props.key;
      this.key = v && new Key(v);
    }

    this.bucket_ids_by_var = {};
    if (props.hasOwnProperty('bucket_ids_by_var')) {
      const v = props.bucket_ids_by_var;
      for (const key: any of Object.keys(v)) {
        this.bucket_ids_by_var[key] = v[key];
      }
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }
  }

  getKey(): Key {
    if (this.key) {
      return this.key;
    }
    return new Key();
  }
}
export {Assignments};

class AssignmentsLog {
  id: string;
  assignments: ?Assignments;

  constructor(props: $Shape<AssignmentsLog> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.assignments = null;
    if (props.hasOwnProperty('assignments')) {
      const v = props.assignments;
      this.assignments = v && new Assignments(v);
    }
  }

  getAssignments(): Assignments {
    if (this.assignments) {
      return this.assignments;
    }
    return new Assignments();
  }
}
export {AssignmentsLog};

class VarInfo {
  var: Var;
  name: string;
  description: string;

  constructor(props: $Shape<VarInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.var = VarValue(0);
    if (props.hasOwnProperty('var')) {
      const v = props.var;
      this.var = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }
  }
}
export {VarInfo};

class Vars {
  values: {[key: number]: ?Value};

  constructor(props: $Shape<Vars> = {}): void {
    if (!props) {
      props = {};
    }

    this.values = {};
    if (props.hasOwnProperty('values')) {
      const v = props.values;
      for (const key: any of Object.keys(v)) {
        this.values[key] = new Value((v[key]: any));
      }
    }
  }
}
export {Vars};

// END cwn/vars/models.proto
