// @flow
import React, {PureComponent, type AbstractComponent} from 'react';

import {WebServicePropTypes, type WebServiceContextType} from 'gae/WebService';
import {WebService} from 'pb/cwn/web';

type InjectedProps = {|webService: WebService|};

export default function withWebService<Config>(
  C: AbstractComponent<{|...Config, ...InjectedProps|}>,
): AbstractComponent<Config> {
  return class Wrapped extends PureComponent<*> {
    static contextTypes = WebServicePropTypes;
    context: WebServiceContextType;

    render() {
      const {webService} = this.context;
      return <C {...this.props} webService={webService} />;
    }
  };
}
