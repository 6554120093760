// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

// START cwn/network/sys/models.proto

class TCPInfo {
  rtt_usec: string;
  rtt_var_usec: string;
  segs_in: string;
  segs_out: string;
  total_retrans: string;
  snd_mss: string;
  snd_cwnd: string;
  snd_cwnd_bytes: string;

  constructor(props: $Shape<TCPInfo> = {}): void {
    if (!props) {
      props = {};
    }

    this.rtt_usec = '0';
    if (props.hasOwnProperty('rtt_usec')) {
      const v = props.rtt_usec;
      this.rtt_usec = v;
    }

    this.rtt_var_usec = '0';
    if (props.hasOwnProperty('rtt_var_usec')) {
      const v = props.rtt_var_usec;
      this.rtt_var_usec = v;
    }

    this.segs_in = '0';
    if (props.hasOwnProperty('segs_in')) {
      const v = props.segs_in;
      this.segs_in = v;
    }

    this.segs_out = '0';
    if (props.hasOwnProperty('segs_out')) {
      const v = props.segs_out;
      this.segs_out = v;
    }

    this.total_retrans = '0';
    if (props.hasOwnProperty('total_retrans')) {
      const v = props.total_retrans;
      this.total_retrans = v;
    }

    this.snd_mss = '0';
    if (props.hasOwnProperty('snd_mss')) {
      const v = props.snd_mss;
      this.snd_mss = v;
    }

    this.snd_cwnd = '0';
    if (props.hasOwnProperty('snd_cwnd')) {
      const v = props.snd_cwnd;
      this.snd_cwnd = v;
    }

    this.snd_cwnd_bytes = '0';
    if (props.hasOwnProperty('snd_cwnd_bytes')) {
      const v = props.snd_cwnd_bytes;
      this.snd_cwnd_bytes = v;
    }
  }
}
export {TCPInfo};

// END cwn/network/sys/models.proto
