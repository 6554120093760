// @flow
import React, {PureComponent, type AbstractComponent} from 'react';

import {ErrorsContext} from 'errors/ErrorsProvider';
import {type OnError} from 'errors/types';

type InjectedProps = {|onError: OnError|};

export default function withErrors<Config>(
  C: AbstractComponent<{|...Config, ...InjectedProps|}>,
): AbstractComponent<Config> {
  return class Wrapped extends PureComponent<*> {
    static contextType = ErrorsContext;

    render() {
      const onError = this.context;
      return <C {...this.props} onError={onError} />;
    }
  };
}
