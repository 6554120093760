//@flow
import React, {type ComponentType, type Element} from 'react';
import {Route, Redirect} from 'react-router';

type AuthComponentProps = {|
  component?: ComponentType<*>,
  authenticated: boolean,
  redirectTo: string,
  path: string,
|};

type AuthRenderProps = {|
  render?: (props: *) => Element<*>,
  authenticated: boolean,
  redirectTo: string,
  path: string,
|};

type AuthRouteProps = AuthComponentProps | AuthRenderProps;

export class PrivateRoute extends React.PureComponent<AuthRouteProps> {
  render() {
    const {authenticated, redirectTo, ...rest} = this.props;

    if (rest.component) {
      delete rest.component;
    }

    if (rest.render) {
      delete rest.render;
    }

    return (
      <Route
        {...rest}
        render={props => {
          if (authenticated) {
            if (this.props.component) {
              const Component = this.props.component;
              return <Component {...props} />;
            } else if (this.props.render) {
              return this.props.render(props);
            } else {
              throw new Error('Need either `component` or `render`');
            }
          } else {
            return (
              <Redirect
                to={{
                  pathname: redirectTo,
                  state: {from: props.location},
                }}
              />
            );
          }
        }}
      />
    );
  }
}
