// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_proc from '../../cwn/proc';

// START cwn/boots/models.proto

class Boot {
  id: string;
  created: ?Date;
  device_id: string;
  software_profile: ?SoftwareProfile;
  last_heartbeat: ?Date;
  device_profile_id: string;

  constructor(props: $Shape<Boot> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.software_profile = null;
    if (props.hasOwnProperty('software_profile')) {
      const v = props.software_profile;
      this.software_profile = v && new SoftwareProfile(v);
    }

    this.last_heartbeat = null;
    if (props.hasOwnProperty('last_heartbeat')) {
      const v = props.last_heartbeat;
      this.last_heartbeat = v && new Date(v);
    }

    this.device_profile_id = '';
    if (props.hasOwnProperty('device_profile_id')) {
      const v = props.device_profile_id;
      this.device_profile_id = v;
    }
  }

  getSoftwareProfile(): SoftwareProfile {
    if (this.software_profile) {
      return this.software_profile;
    }
    return new SoftwareProfile();
  }
}
export {Boot};

class Heartbeat {
  boot_id: string;
  device_id: string;
  created: ?Date;
  proc_infos: Array<cwn_proc.Info>;

  constructor(props: $Shape<Heartbeat> = {}): void {
    if (!props) {
      props = {};
    }

    this.boot_id = '';
    if (props.hasOwnProperty('boot_id')) {
      const v = props.boot_id;
      this.boot_id = v;
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.proc_infos = [];
    if (props.hasOwnProperty('proc_infos')) {
      const v = props.proc_infos;
      if (!Array.isArray(v)) {
        throw new Error('repeated field proc_infos should be array');
      }
      this.proc_infos = v.map(function(v) {
        return new cwn_proc.Info(v);
      });
    }
  }
}
export {Heartbeat};

class Versions {
  base_os_md5sum: string;
  agent_repo_git_sha: string;
  agent_repo_git_status: string;
  build_timestamp: string;
  build_host: string;

  constructor(props: $Shape<Versions> = {}): void {
    if (!props) {
      props = {};
    }

    this.base_os_md5sum = '';
    if (props.hasOwnProperty('base_os_md5sum')) {
      const v = props.base_os_md5sum;
      this.base_os_md5sum = v;
    }

    this.agent_repo_git_sha = '';
    if (props.hasOwnProperty('agent_repo_git_sha')) {
      const v = props.agent_repo_git_sha;
      this.agent_repo_git_sha = v;
    }

    this.agent_repo_git_status = '';
    if (props.hasOwnProperty('agent_repo_git_status')) {
      const v = props.agent_repo_git_status;
      this.agent_repo_git_status = v;
    }

    this.build_timestamp = '0';
    if (props.hasOwnProperty('build_timestamp')) {
      const v = props.build_timestamp;
      this.build_timestamp = v;
    }

    this.build_host = '';
    if (props.hasOwnProperty('build_host')) {
      const v = props.build_host;
      this.build_host = v;
    }
  }
}
export {Versions};

class SoftwareProfile {
  system_information: string;
  package_version_md5sum: string;
  versions: ?Versions;
  installed_packages: Array<string>;

  constructor(props: $Shape<SoftwareProfile> = {}): void {
    if (!props) {
      props = {};
    }

    this.system_information = '';
    if (props.hasOwnProperty('system_information')) {
      const v = props.system_information;
      this.system_information = v;
    }

    this.package_version_md5sum = '';
    if (props.hasOwnProperty('package_version_md5sum')) {
      const v = props.package_version_md5sum;
      this.package_version_md5sum = v;
    }

    this.versions = null;
    if (props.hasOwnProperty('versions')) {
      const v = props.versions;
      this.versions = v && new Versions(v);
    }

    this.installed_packages = [];
    if (props.hasOwnProperty('installed_packages')) {
      const v = props.installed_packages;
      if (!Array.isArray(v)) {
        throw new Error('repeated field installed_packages should be array');
      }
      this.installed_packages = v.map(function(v) {
        return v;
      });
    }
  }

  getVersions(): Versions {
    if (this.versions) {
      return this.versions;
    }
    return new Versions();
  }
}
export {SoftwareProfile};

// END cwn/boots/models.proto
