// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

// START cwn/lidar/topography/models.proto

export const FlyoverIdValues = {
  UNKNOWN_FLYOVER: 0,
  MESA_2019: 1,
  EAST_BAY_2019: 2,
};
type FlyoverId = $Keys<typeof FlyoverIdValues>;
export type {FlyoverId};

export function FlyoverIdValue(n: number): FlyoverId {
  switch (n) {
    case 0:
      return 'UNKNOWN_FLYOVER';

    case 1:
      return 'MESA_2019';

    case 2:
      return 'EAST_BAY_2019';

    default:
      return 'UNKNOWN_FLYOVER';
  }
}

export const QsiBuildingPoint_ChimneyStatusTypeValues = {
  UNKNOWN: 0,
  IS_CHIMNEY: 1,
  NOT_CHIMNEY: 2,
};
type QsiBuildingPoint_ChimneyStatusType = $Keys<
  typeof QsiBuildingPoint_ChimneyStatusTypeValues,
>;
export type {QsiBuildingPoint_ChimneyStatusType};

export function QsiBuildingPoint_ChimneyStatusTypeValue(
  n: number,
): QsiBuildingPoint_ChimneyStatusType {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'IS_CHIMNEY';

    case 2:
      return 'NOT_CHIMNEY';

    default:
      return 'UNKNOWN';
  }
}

class QsiBuildingPoint {
  id: string;
  buildingId: string;
  lat: number;
  lon: number;
  geohash: string;
  roof_elevation_in_meters: number;
  area_id: string;
  version: string;
  indexed: boolean;
  chimney_status: QsiBuildingPoint_ChimneyStatusType;
  flyover_id: FlyoverId;

  constructor(props: $Shape<QsiBuildingPoint> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.buildingId = '';
    if (props.hasOwnProperty('buildingId')) {
      const v = props.buildingId;
      this.buildingId = v;
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.lon = 0;
    if (props.hasOwnProperty('lon')) {
      const v = props.lon;
      this.lon = v;
    }

    this.geohash = '';
    if (props.hasOwnProperty('geohash')) {
      const v = props.geohash;
      this.geohash = v;
    }

    this.roof_elevation_in_meters = 0;
    if (props.hasOwnProperty('roof_elevation_in_meters')) {
      const v = props.roof_elevation_in_meters;
      this.roof_elevation_in_meters = v;
    }

    this.area_id = '0';
    if (props.hasOwnProperty('area_id')) {
      const v = props.area_id;
      this.area_id = v;
    }

    this.version = '';
    if (props.hasOwnProperty('version')) {
      const v = props.version;
      this.version = v;
    }

    this.indexed = false;
    if (props.hasOwnProperty('indexed')) {
      const v = props.indexed;
      this.indexed = !!v;
    }

    this.chimney_status = QsiBuildingPoint_ChimneyStatusTypeValue(0);
    if (props.hasOwnProperty('chimney_status')) {
      const v = props.chimney_status;
      this.chimney_status = v;
    }

    this.flyover_id = FlyoverIdValue(0);
    if (props.hasOwnProperty('flyover_id')) {
      const v = props.flyover_id;
      this.flyover_id = v;
    }
  }
}
export {QsiBuildingPoint};

class UploadQBPArgs {
  file_name: string;
  flyover_id: FlyoverId;

  constructor(props: $Shape<UploadQBPArgs> = {}): void {
    if (!props) {
      props = {};
    }

    this.file_name = '';
    if (props.hasOwnProperty('file_name')) {
      const v = props.file_name;
      this.file_name = v;
    }

    this.flyover_id = FlyoverIdValue(0);
    if (props.hasOwnProperty('flyover_id')) {
      const v = props.flyover_id;
      this.flyover_id = v;
    }
  }
}
export {UploadQBPArgs};

class BuildingPointCacheEntry {
  lat: number;
  lon: number;
  buildingId: string;

  constructor(props: $Shape<BuildingPointCacheEntry> = {}): void {
    if (!props) {
      props = {};
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.lon = 0;
    if (props.hasOwnProperty('lon')) {
      const v = props.lon;
      this.lon = v;
    }

    this.buildingId = '';
    if (props.hasOwnProperty('buildingId')) {
      const v = props.buildingId;
      this.buildingId = v;
    }
  }
}
export {BuildingPointCacheEntry};

// END cwn/lidar/topography/models.proto
