// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

// START cwn/mikrotik/w60g/models.proto

export const ModeValues = {
  UNKNOWN: 0,
  AP_BRIDGE: 1,
  BRIDGE: 2,
  SNIFF: 3,
  STATION_BRIDGE: 4,
};
type Mode = $Keys<typeof ModeValues>;
export type {Mode};

export function ModeValue(n: number): Mode {
  switch (n) {
    case 0:
      return 'UNKNOWN';

    case 1:
      return 'AP_BRIDGE';

    case 2:
      return 'BRIDGE';

    case 3:
      return 'SNIFF';

    case 4:
      return 'STATION_BRIDGE';

    default:
      return 'UNKNOWN';
  }
}

class Monitor_Alignment_Axis {
  index: string;
  degree: number;
  centered: boolean;

  constructor(props: $Shape<Monitor_Alignment_Axis> = {}): void {
    if (!props) {
      props = {};
    }

    this.index = '0';
    if (props.hasOwnProperty('index')) {
      const v = props.index;
      this.index = v;
    }

    this.degree = 0;
    if (props.hasOwnProperty('degree')) {
      const v = props.degree;
      this.degree = v;
    }

    this.centered = false;
    if (props.hasOwnProperty('centered')) {
      const v = props.centered;
      this.centered = !!v;
    }
  }
}
export {Monitor_Alignment_Axis};
class Monitor_Alignment {
  horizontal: ?Monitor_Alignment_Axis;
  vertical: ?Monitor_Alignment_Axis;

  constructor(props: $Shape<Monitor_Alignment> = {}): void {
    if (!props) {
      props = {};
    }

    this.horizontal = null;
    if (props.hasOwnProperty('horizontal')) {
      const v = props.horizontal;
      this.horizontal = v && new Monitor_Alignment_Axis(v);
    }

    this.vertical = null;
    if (props.hasOwnProperty('vertical')) {
      const v = props.vertical;
      this.vertical = v && new Monitor_Alignment_Axis(v);
    }
  }

  getHorizontal(): Monitor_Alignment_Axis {
    if (this.horizontal) {
      return this.horizontal;
    }
    return new Monitor_Alignment_Axis();
  }

  getVertical(): Monitor_Alignment_Axis {
    if (this.vertical) {
      return this.vertical;
    }
    return new Monitor_Alignment_Axis();
  }
}
export {Monitor_Alignment};
class Monitor {
  id: string;
  comment: string;
  connected: boolean;
  distance: string;
  frequency: string;
  remote_address: string;
  rssi: string;
  signal: string;
  tx_mcs: string;
  tx_packet_error_rate: string;
  tx_phy_rate: string;
  tx_sector: string;
  tx_sector_info: string;
  beamforming_event: string;
  ten_s_average_rssi: number;
  alignment: ?Monitor_Alignment;

  constructor(props: $Shape<Monitor> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.comment = '';
    if (props.hasOwnProperty('comment')) {
      const v = props.comment;
      this.comment = v;
    }

    this.connected = false;
    if (props.hasOwnProperty('connected')) {
      const v = props.connected;
      this.connected = !!v;
    }

    this.distance = '0';
    if (props.hasOwnProperty('distance')) {
      const v = props.distance;
      this.distance = v;
    }

    this.frequency = '0';
    if (props.hasOwnProperty('frequency')) {
      const v = props.frequency;
      this.frequency = v;
    }

    this.remote_address = '';
    if (props.hasOwnProperty('remote_address')) {
      const v = props.remote_address;
      this.remote_address = v;
    }

    this.rssi = '0';
    if (props.hasOwnProperty('rssi')) {
      const v = props.rssi;
      this.rssi = v;
    }

    this.signal = '0';
    if (props.hasOwnProperty('signal')) {
      const v = props.signal;
      this.signal = v;
    }

    this.tx_mcs = '0';
    if (props.hasOwnProperty('tx_mcs')) {
      const v = props.tx_mcs;
      this.tx_mcs = v;
    }

    this.tx_packet_error_rate = '0';
    if (props.hasOwnProperty('tx_packet_error_rate')) {
      const v = props.tx_packet_error_rate;
      this.tx_packet_error_rate = v;
    }

    this.tx_phy_rate = '0';
    if (props.hasOwnProperty('tx_phy_rate')) {
      const v = props.tx_phy_rate;
      this.tx_phy_rate = v;
    }

    this.tx_sector = '0';
    if (props.hasOwnProperty('tx_sector')) {
      const v = props.tx_sector;
      this.tx_sector = v;
    }

    this.tx_sector_info = '';
    if (props.hasOwnProperty('tx_sector_info')) {
      const v = props.tx_sector_info;
      this.tx_sector_info = v;
    }

    this.beamforming_event = '0';
    if (props.hasOwnProperty('beamforming_event')) {
      const v = props.beamforming_event;
      this.beamforming_event = v;
    }

    this.ten_s_average_rssi = 0;
    if (props.hasOwnProperty('ten_s_average_rssi')) {
      const v = props.ten_s_average_rssi;
      this.ten_s_average_rssi = v;
    }

    this.alignment = null;
    if (props.hasOwnProperty('alignment')) {
      const v = props.alignment;
      this.alignment = v && new Monitor_Alignment(v);
    }
  }

  getAlignment(): Monitor_Alignment {
    if (this.alignment) {
      return this.alignment;
    }
    return new Monitor_Alignment();
  }
}
export {Monitor};

class Align {
  peers: Array<Monitor>;

  constructor(props: $Shape<Align> = {}): void {
    if (!props) {
      props = {};
    }

    this.peers = [];
    if (props.hasOwnProperty('peers')) {
      const v = props.peers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field peers should be array');
      }
      this.peers = v.map(function(v) {
        return new Monitor(v);
      });
    }
  }
}
export {Align};

class Interface {
  id: string;
  beamforming_event: string;
  frequency: string;
  mac_address: string;
  mode: Mode;
  rx_mpdu_crc_err: string;
  rx_mpdu_crc_ok: string;
  rx_ppdu: string;
  ssid: string;
  tx_fw_msdu: string;
  tx_io_msdu: string;
  tx_mpdu_new: string;
  tx_mpdu_retry: string;
  tx_mpdu_total: string;
  tx_ppdu: string;
  tx_ppdu_from_q: string;
  tx_sw_msdu: string;

  constructor(props: $Shape<Interface> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.beamforming_event = '0';
    if (props.hasOwnProperty('beamforming_event')) {
      const v = props.beamforming_event;
      this.beamforming_event = v;
    }

    this.frequency = '';
    if (props.hasOwnProperty('frequency')) {
      const v = props.frequency;
      this.frequency = v;
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.mode = ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }

    this.rx_mpdu_crc_err = '0';
    if (props.hasOwnProperty('rx_mpdu_crc_err')) {
      const v = props.rx_mpdu_crc_err;
      this.rx_mpdu_crc_err = v;
    }

    this.rx_mpdu_crc_ok = '0';
    if (props.hasOwnProperty('rx_mpdu_crc_ok')) {
      const v = props.rx_mpdu_crc_ok;
      this.rx_mpdu_crc_ok = v;
    }

    this.rx_ppdu = '0';
    if (props.hasOwnProperty('rx_ppdu')) {
      const v = props.rx_ppdu;
      this.rx_ppdu = v;
    }

    this.ssid = '';
    if (props.hasOwnProperty('ssid')) {
      const v = props.ssid;
      this.ssid = v;
    }

    this.tx_fw_msdu = '0';
    if (props.hasOwnProperty('tx_fw_msdu')) {
      const v = props.tx_fw_msdu;
      this.tx_fw_msdu = v;
    }

    this.tx_io_msdu = '0';
    if (props.hasOwnProperty('tx_io_msdu')) {
      const v = props.tx_io_msdu;
      this.tx_io_msdu = v;
    }

    this.tx_mpdu_new = '0';
    if (props.hasOwnProperty('tx_mpdu_new')) {
      const v = props.tx_mpdu_new;
      this.tx_mpdu_new = v;
    }

    this.tx_mpdu_retry = '0';
    if (props.hasOwnProperty('tx_mpdu_retry')) {
      const v = props.tx_mpdu_retry;
      this.tx_mpdu_retry = v;
    }

    this.tx_mpdu_total = '0';
    if (props.hasOwnProperty('tx_mpdu_total')) {
      const v = props.tx_mpdu_total;
      this.tx_mpdu_total = v;
    }

    this.tx_ppdu = '0';
    if (props.hasOwnProperty('tx_ppdu')) {
      const v = props.tx_ppdu;
      this.tx_ppdu = v;
    }

    this.tx_ppdu_from_q = '0';
    if (props.hasOwnProperty('tx_ppdu_from_q')) {
      const v = props.tx_ppdu_from_q;
      this.tx_ppdu_from_q = v;
    }

    this.tx_sw_msdu = '0';
    if (props.hasOwnProperty('tx_sw_msdu')) {
      const v = props.tx_sw_msdu;
      this.tx_sw_msdu = v;
    }
  }
}
export {Interface};

class Status {
  interface: ?Interface;
  peers: Array<Monitor>;

  constructor(props: $Shape<Status> = {}): void {
    if (!props) {
      props = {};
    }

    this.interface = null;
    if (props.hasOwnProperty('interface')) {
      const v = props.interface;
      this.interface = v && new Interface(v);
    }

    this.peers = [];
    if (props.hasOwnProperty('peers')) {
      const v = props.peers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field peers should be array');
      }
      this.peers = v.map(function(v) {
        return new Monitor(v);
      });
    }
  }

  getInterface(): Interface {
    if (this.interface) {
      return this.interface;
    }
    return new Interface();
  }
}
export {Status};

// END cwn/mikrotik/w60g/models.proto
