// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */

// START cwn/sms/models.proto

export const Task_TypeValues = {
  INVALID: 0,
  LAST_MINUTE_SLOT_REMINDER: 1,
  READY_TO_INSTALL: 2,
  MDU_ROOF_ACCESS: 3,
};
type Task_Type = $Keys<typeof Task_TypeValues>;
export type {Task_Type};

export function Task_TypeValue(n: number): Task_Type {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'LAST_MINUTE_SLOT_REMINDER';

    case 2:
      return 'READY_TO_INSTALL';

    case 3:
      return 'MDU_ROOF_ACCESS';

    default:
      return 'INVALID';
  }
}

export const Task_ErrorValues = {
  NONE: 0,
  RETRIES_EXCEEDED: 1,
  TERMINATION: 2,
};
type Task_Error = $Keys<typeof Task_ErrorValues>;
export type {Task_Error};

export function Task_ErrorValue(n: number): Task_Error {
  switch (n) {
    case 0:
      return 'NONE';

    case 1:
      return 'RETRIES_EXCEEDED';

    case 2:
      return 'TERMINATION';

    default:
      return 'NONE';
  }
}

class Task {
  id: string;
  type: Task_Type;
  customer_id: string;
  lead_id: string;
  created: ?Date;
  updated: ?Date;
  last_sent: ?Date;
  inactive: boolean;
  error: Task_Error;
  err_msg: string;
  retries: string;

  constructor(props: $Shape<Task> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.type = Task_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.customer_id = '';
    if (props.hasOwnProperty('customer_id')) {
      const v = props.customer_id;
      this.customer_id = v;
    }

    this.lead_id = '0';
    if (props.hasOwnProperty('lead_id')) {
      const v = props.lead_id;
      this.lead_id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.last_sent = null;
    if (props.hasOwnProperty('last_sent')) {
      const v = props.last_sent;
      this.last_sent = v && new Date(v);
    }

    this.inactive = false;
    if (props.hasOwnProperty('inactive')) {
      const v = props.inactive;
      this.inactive = !!v;
    }

    this.error = Task_ErrorValue(0);
    if (props.hasOwnProperty('error')) {
      const v = props.error;
      this.error = v;
    }

    this.err_msg = '';
    if (props.hasOwnProperty('err_msg')) {
      const v = props.err_msg;
      this.err_msg = v;
    }

    this.retries = '0';
    if (props.hasOwnProperty('retries')) {
      const v = props.retries;
      this.retries = v;
    }
  }
}
export {Task};

class Record {
  id: string;
  snapshot: ?Task;
  created: ?Date;

  constructor(props: $Shape<Record> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.snapshot = null;
    if (props.hasOwnProperty('snapshot')) {
      const v = props.snapshot;
      this.snapshot = v && new Task(v);
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }
  }

  getSnapshot(): Task {
    if (this.snapshot) {
      return this.snapshot;
    }
    return new Task();
  }
}
export {Record};

// END cwn/sms/models.proto
