//@flow
import React, {createContext, Children} from 'react';
import PropTypes from 'prop-types';
import {WebService} from 'pb/cwn/web';

export type WebServiceContextType = {
  webService: WebService,
};

const defaultWebService: WebServiceContextType = {
  webService: new WebService(''),
};

export const WebServiceContext = createContext<WebServiceContextType>(
  defaultWebService,
);

export const WebServicePropTypes = {
  webService: PropTypes.object,
};

type Props = {|
  webService: WebService,
  children: any,
|};
export class WebServiceProvider extends React.PureComponent<Props, void> {
  static childContextTypes = WebServicePropTypes;

  getChildContext() {
    const {webService} = this.props;
    return {webService};
  }

  render() {
    return (
      <WebServiceContext.Provider value={this.getChildContext()}>
        {Children.only(this.props.children)}
      </WebServiceContext.Provider>
    );
  }
}
