//@flow
import {defer} from 'lodash';
import {withStyles} from 'ui';
import PropTypes from 'prop-types';
import React, {createContext} from 'react';

import {AdminService} from 'pb/cwn/admin';

const PROXY_MESSAGE = `
  PROXYING TO ADMIN PRODUCTION! PROCEED WITH CAUTION!
`;

export const AdminServicePropTypes = {
  adminService: PropTypes.object,
  setProductionProxyEnabled: PropTypes.func,
  productionProxyEnabled: PropTypes.bool,
};

export type AdminServiceContextType = {
  adminService: AdminService,
  productionProxyEnabled: boolean,
  setProductionProxyEnabled: (enabled: boolean) => void,
};

const defaultAdminService: AdminServiceContextType = {
  adminService: new AdminService(''),
  productionProxyEnabled: false,
  setProductionProxyEnabled: () => {},
};

type Props = {
  adminService: AdminService,
  productionProxyAdminService: AdminService,
  children: any,
  classes: any,
};

type State = {|
  productionProxyEnabled: boolean,
  refresh: boolean,
|};

const style = theme => ({
  container: {
    position: 'absolute',
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
  },
  containerProxy: {
    paddingBottom: 64,
  },
  proxy: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: 64,
    borderTop: '3px solid black',
    background: 'red',
    zIndex: 10000,
    color: 'white',
    fontSize: 30,
    fontWeight: 800,
    display: 'flex',
    alignItems: 'center',
  },
});

export const AdminServiceContext = createContext<AdminServiceContextType>(
  defaultAdminService,
);

export const AdminServiceProvider = withStyles(style)(
  class AdminServiceProvider extends React.PureComponent<Props, State> {
    static childContextTypes = AdminServicePropTypes;
    state = {
      productionProxyEnabled:
        process.env.NODE_ENV === 'production'
          ? false
          : process.env.REACT_APP_PRODUCTION_PROXY === 'true',
      refresh: false,
    };

    setProductionProxyEnabled = (productionProxyEnabled: boolean) => {
      this.setState({productionProxyEnabled, refresh: true});
      defer(() => {
        this.setState({refresh: false});
      });
    };

    // need to leave this in until everyone's either using an HoC
    // or `useAdminService()`
    getChildContext() {
      return {
        adminService: this.state.productionProxyEnabled
          ? this.props.productionProxyAdminService
          : this.props.adminService,
        productionProxyEnabled: this.state.productionProxyEnabled,
        setProductionProxyEnabled: this.setProductionProxyEnabled,
      };
    }

    render() {
      const {classes} = this.props;

      if (this.state.refresh) {
        return <div>Remounting after admin service change...</div>;
      }

      const mq = <center>{PROXY_MESSAGE}</center>;

      const dev = process.env.NODE_ENV === 'development';
      const proxyEnabled = this.state.productionProxyEnabled;
      return (
        <AdminServiceContext.Provider value={this.getChildContext()}>
          <div className={classes.container}>
            {dev && proxyEnabled && <div className={classes.proxy}>{mq}</div>}
            <div className={classes.children}>{this.props.children}</div>
          </div>
        </AdminServiceContext.Provider>
      );
    }
  },
);
