// @flow
import {PureComponent, type Node} from 'react';

import {type ErrorInfo, type ComponentDidCatch} from 'errors/types';

type Props = {|
  children: Node,
  componentDidCatch: ComponentDidCatch,
|};

// only catches synchronous downstream errors thrown during rendering or lifecycle hooks, not super useful
// https://reactjs.org/docs/error-boundaries
export default class ErrorBoundary extends PureComponent<Props> {
  componentDidCatch(error: Error, info: ErrorInfo) {
    this.props.componentDidCatch(error, info);
  }

  render() {
    return this.props.children;
  }
}
