// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_network from '../../cwn/network';

// START cwn/peers2/models.proto

class Ping {
  time: ?Date;
  success: boolean;
  latency: ?google_protobuf.Duration;

  constructor(props: $Shape<Ping> = {}): void {
    if (!props) {
      props = {};
    }

    this.time = null;
    if (props.hasOwnProperty('time')) {
      const v = props.time;
      this.time = v && new Date(v);
    }

    this.success = false;
    if (props.hasOwnProperty('success')) {
      const v = props.success;
      this.success = !!v;
    }

    this.latency = null;
    if (props.hasOwnProperty('latency')) {
      const v = props.latency;
      this.latency = v && new google_protobuf.Duration(v);
    }
  }

  getLatency(): google_protobuf.Duration {
    if (this.latency) {
      return this.latency;
    }
    return new google_protobuf.Duration();
  }
}
export {Ping};

class Peer {
  device_id: string;
  iface_name: string;
  ip_addr: ?cwn_network.IPAddr;
  lastSeen: ?Date;
  raw_pings: Array<Ping>;
  remote_iface: string;

  constructor(props: $Shape<Peer> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.iface_name = '';
    if (props.hasOwnProperty('iface_name')) {
      const v = props.iface_name;
      this.iface_name = v;
    }

    this.ip_addr = null;
    if (props.hasOwnProperty('ip_addr')) {
      const v = props.ip_addr;
      this.ip_addr = v && new cwn_network.IPAddr(v);
    }

    this.lastSeen = null;
    if (props.hasOwnProperty('lastSeen')) {
      const v = props.lastSeen;
      this.lastSeen = v && new Date(v);
    }

    this.raw_pings = [];
    if (props.hasOwnProperty('raw_pings')) {
      const v = props.raw_pings;
      if (!Array.isArray(v)) {
        throw new Error('repeated field raw_pings should be array');
      }
      this.raw_pings = v.map(function(v) {
        return new Ping(v);
      });
    }

    this.remote_iface = '';
    if (props.hasOwnProperty('remote_iface')) {
      const v = props.remote_iface;
      this.remote_iface = v;
    }
  }

  getIpAddr(): cwn_network.IPAddr {
    if (this.ip_addr) {
      return this.ip_addr;
    }
    return new cwn_network.IPAddr();
  }
}
export {Peer};

class Status {
  device_id: string;
  peers: Array<Peer>;

  constructor(props: $Shape<Status> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.peers = [];
    if (props.hasOwnProperty('peers')) {
      const v = props.peers;
      if (!Array.isArray(v)) {
        throw new Error('repeated field peers should be array');
      }
      this.peers = v.map(function(v) {
        return new Peer(v);
      });
    }
  }
}
export {Status};

class StatusContainer {
  id: string;
  created: ?Date;
  status: ?Status;

  constructor(props: $Shape<StatusContainer> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.status = null;
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v && new Status(v);
    }
  }

  getStatus(): Status {
    if (this.status) {
      return this.status;
    }
    return new Status();
  }
}
export {StatusContainer};

class CurrentStatus {
  status: ?Status;
  created: ?Date;
  updated: ?Date;

  constructor(props: $Shape<CurrentStatus> = {}): void {
    if (!props) {
      props = {};
    }

    this.status = null;
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v && new Status(v);
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }
  }

  getStatus(): Status {
    if (this.status) {
      return this.status;
    }
    return new Status();
  }
}
export {CurrentStatus};

class DeviceWeights {
  device_id: string;
  last_updated: ?Date;
  weights_per_peer: {[key: string]: ?PeerWeights};

  constructor(props: $Shape<DeviceWeights> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.last_updated = null;
    if (props.hasOwnProperty('last_updated')) {
      const v = props.last_updated;
      this.last_updated = v && new Date(v);
    }

    this.weights_per_peer = {};
    if (props.hasOwnProperty('weights_per_peer')) {
      const v = props.weights_per_peer;
      for (const key: any of Object.keys(v)) {
        this.weights_per_peer[key] = new PeerWeights((v[key]: any));
      }
    }
  }
}
export {DeviceWeights};

class PeerWeights {
  weight_per_interface: {[key: string]: ?WeightData};
  remote_interfaces: {[key: string]: string};

  constructor(props: $Shape<PeerWeights> = {}): void {
    if (!props) {
      props = {};
    }

    this.weight_per_interface = {};
    if (props.hasOwnProperty('weight_per_interface')) {
      const v = props.weight_per_interface;
      for (const key: any of Object.keys(v)) {
        this.weight_per_interface[key] = new WeightData((v[key]: any));
      }
    }

    this.remote_interfaces = {};
    if (props.hasOwnProperty('remote_interfaces')) {
      const v = props.remote_interfaces;
      for (const key: any of Object.keys(v)) {
        this.remote_interfaces[key] = v[key];
      }
    }
  }
}
export {PeerWeights};

class WeightData {
  curr_weight: number;
  raw_data: Array<boolean>;
  last_weight_change: ?Date;

  constructor(props: $Shape<WeightData> = {}): void {
    if (!props) {
      props = {};
    }

    this.curr_weight = 0;
    if (props.hasOwnProperty('curr_weight')) {
      const v = props.curr_weight;
      this.curr_weight = v;
    }

    this.raw_data = [];
    if (props.hasOwnProperty('raw_data')) {
      const v = props.raw_data;
      if (!Array.isArray(v)) {
        throw new Error('repeated field raw_data should be array');
      }
      this.raw_data = v.map(function(v) {
        return !!v;
      });
    }

    this.last_weight_change = null;
    if (props.hasOwnProperty('last_weight_change')) {
      const v = props.last_weight_change;
      this.last_weight_change = v && new Date(v);
    }
  }
}
export {WeightData};

class PeerAudit {
  device_id: string;
  ip_addr: ?cwn_network.IPAddr;

  constructor(props: $Shape<PeerAudit> = {}): void {
    if (!props) {
      props = {};
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.ip_addr = null;
    if (props.hasOwnProperty('ip_addr')) {
      const v = props.ip_addr;
      this.ip_addr = v && new cwn_network.IPAddr(v);
    }
  }

  getIpAddr(): cwn_network.IPAddr {
    if (this.ip_addr) {
      return this.ip_addr;
    }
    return new cwn_network.IPAddr();
  }
}
export {PeerAudit};

// END cwn/peers2/models.proto
