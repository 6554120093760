//@flow
import React, {Children} from 'react';
import {type AuthController} from 'auth/AuthController';
import PropTypes from 'prop-types';

export const AuthControllerPropTypes = {
  authController: PropTypes.object,
};

export type AuthControllerContextType = {
  authController: AuthController,
};

type Props = {|
  controller: AuthController,
  children: any,
|};
export default class AuthProvider extends React.PureComponent<Props> {
  static childContextTypes = AuthControllerPropTypes;

  getChildContext() {
    const {controller} = this.props;
    return {authController: controller};
  }

  render() {
    return Children.only(this.props.children);
  }
}
