// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../../google/protobuf';
/* eslint-enable */
import * as cwn_vars from '../../../cwn/vars';
import * as cwn_site_build from '../../../cwn/site/build';
import * as cwn_site from '../../../cwn/site';
import * as cwn_radios from '../../../cwn/radios';
import * as cwn_devices_detect from '../../../cwn/devices/detect';
import * as cwn_customers from '../../../cwn/customers';
import * as cwn_bridges from '../../../cwn/bridges';
import * as cwn_asset from '../../../cwn/asset';

// START cwn/site/assessment/models.proto

export const IntransientLOSErrorTypeValues = {
  NO_LOS_ERROR: 0,
  OTHER_LOS_ERROR: 1,
  COORDINATES_OUTSIDE_PROJECT_AREA: 2,
  MOUNT_DISTANCE_TOO_SHORT: 3,
  MOUNT_DISTANCE_TOO_FAR: 4,
  RESULT_NOT_ALREADY_CACHED: 5,
};
type IntransientLOSErrorType = $Keys<typeof IntransientLOSErrorTypeValues>;
export type {IntransientLOSErrorType};

export function IntransientLOSErrorTypeValue(
  n: number,
): IntransientLOSErrorType {
  switch (n) {
    case 0:
      return 'NO_LOS_ERROR';

    case 1:
      return 'OTHER_LOS_ERROR';

    case 2:
      return 'COORDINATES_OUTSIDE_PROJECT_AREA';

    case 3:
      return 'MOUNT_DISTANCE_TOO_SHORT';

    case 4:
      return 'MOUNT_DISTANCE_TOO_FAR';

    case 5:
      return 'RESULT_NOT_ALREADY_CACHED';

    default:
      return 'NO_LOS_ERROR';
  }
}

class SiteAssessment_MountAssessment_ShotAssessment {
  target_mount_id: string;
  los_started: ?Date;
  los_finished: ?Date;
  los_cache_id: string;
  total_obstructions: string;
  has_override: boolean;
  override: ?SiteAssessment_ShotOverrideDetails;
  intransient_los_error: IntransientLOSErrorType;

  constructor(
    props: $Shape<SiteAssessment_MountAssessment_ShotAssessment> = {},
  ): void {
    if (!props) {
      props = {};
    }

    this.target_mount_id = '0';
    if (props.hasOwnProperty('target_mount_id')) {
      const v = props.target_mount_id;
      this.target_mount_id = v;
    }

    this.los_started = null;
    if (props.hasOwnProperty('los_started')) {
      const v = props.los_started;
      this.los_started = v && new Date(v);
    }

    this.los_finished = null;
    if (props.hasOwnProperty('los_finished')) {
      const v = props.los_finished;
      this.los_finished = v && new Date(v);
    }

    this.los_cache_id = '';
    if (props.hasOwnProperty('los_cache_id')) {
      const v = props.los_cache_id;
      this.los_cache_id = v;
    }

    this.total_obstructions = '0';
    if (props.hasOwnProperty('total_obstructions')) {
      const v = props.total_obstructions;
      this.total_obstructions = v;
    }

    this.has_override = false;
    if (props.hasOwnProperty('has_override')) {
      const v = props.has_override;
      this.has_override = !!v;
    }

    this.override = null;
    if (props.hasOwnProperty('override')) {
      const v = props.override;
      this.override = v && new SiteAssessment_ShotOverrideDetails(v);
    }

    this.intransient_los_error = IntransientLOSErrorTypeValue(0);
    if (props.hasOwnProperty('intransient_los_error')) {
      const v = props.intransient_los_error;
      this.intransient_los_error = v;
    }
  }

  getOverride(): SiteAssessment_ShotOverrideDetails {
    if (this.override) {
      return this.override;
    }
    return new SiteAssessment_ShotOverrideDetails();
  }
}
export {SiteAssessment_MountAssessment_ShotAssessment};
class SiteAssessment_MountAssessment {
  mount_details: ?SiteAssessment_MountDetails;
  mount: ?cwn_site_build.LocalMount;
  shot_assessments: Array<SiteAssessment_MountAssessment_ShotAssessment>;

  constructor(props: $Shape<SiteAssessment_MountAssessment> = {}): void {
    if (!props) {
      props = {};
    }

    this.mount_details = null;
    if (props.hasOwnProperty('mount_details')) {
      const v = props.mount_details;
      this.mount_details = v && new SiteAssessment_MountDetails(v);
    }

    this.mount = null;
    if (props.hasOwnProperty('mount')) {
      const v = props.mount;
      this.mount = v && new cwn_site_build.LocalMount(v);
    }

    this.shot_assessments = [];
    if (props.hasOwnProperty('shot_assessments')) {
      const v = props.shot_assessments;
      if (!Array.isArray(v)) {
        throw new Error('repeated field shot_assessments should be array');
      }
      this.shot_assessments = v.map(function(v) {
        return new SiteAssessment_MountAssessment_ShotAssessment(v);
      });
    }
  }

  getMountDetails(): SiteAssessment_MountDetails {
    if (this.mount_details) {
      return this.mount_details;
    }
    return new SiteAssessment_MountDetails();
  }

  getMount(): cwn_site_build.LocalMount {
    if (this.mount) {
      return this.mount;
    }
    return new cwn_site_build.LocalMount();
  }
}
export {SiteAssessment_MountAssessment};
class SiteAssessment_SiteDetails {
  id: string;
  node_type: cwn_site.Site_NodeType;
  status: ?cwn_site.StatusV2;
  device_id: string;

  constructor(props: $Shape<SiteAssessment_SiteDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.node_type = cwn_site.Site_NodeTypeValue(0);
    if (props.hasOwnProperty('node_type')) {
      const v = props.node_type;
      this.node_type = v;
    }

    this.status = null;
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v && new cwn_site.StatusV2(v);
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }
  }

  getStatus(): cwn_site.StatusV2 {
    if (this.status) {
      return this.status;
    }
    return new cwn_site.StatusV2();
  }
}
export {SiteAssessment_SiteDetails};
class SiteAssessment_DeviceProfileDetails {
  platform: cwn_devices_detect.Platform;

  constructor(props: $Shape<SiteAssessment_DeviceProfileDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.platform = cwn_devices_detect.PlatformValue(0);
    if (props.hasOwnProperty('platform')) {
      const v = props.platform;
      this.platform = v;
    }
  }
}
export {SiteAssessment_DeviceProfileDetails};
class SiteAssessment_PortStatusDetails {
  interface_name: string;
  link_state: boolean;
  poe_op_mode: cwn_bridges.PortStatus_PoeOpMode;

  constructor(props: $Shape<SiteAssessment_PortStatusDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.interface_name = '';
    if (props.hasOwnProperty('interface_name')) {
      const v = props.interface_name;
      this.interface_name = v;
    }

    this.link_state = false;
    if (props.hasOwnProperty('link_state')) {
      const v = props.link_state;
      this.link_state = !!v;
    }

    this.poe_op_mode = cwn_bridges.PortStatus_PoeOpModeValue(0);
    if (props.hasOwnProperty('poe_op_mode')) {
      const v = props.poe_op_mode;
      this.poe_op_mode = v;
    }
  }
}
export {SiteAssessment_PortStatusDetails};
class SiteAssessment_BridgeStatusDetails {
  port_statuses: Array<SiteAssessment_PortStatusDetails>;

  constructor(props: $Shape<SiteAssessment_BridgeStatusDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.port_statuses = [];
    if (props.hasOwnProperty('port_statuses')) {
      const v = props.port_statuses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field port_statuses should be array');
      }
      this.port_statuses = v.map(function(v) {
        return new SiteAssessment_PortStatusDetails(v);
      });
    }
  }
}
export {SiteAssessment_BridgeStatusDetails};
class SiteAssessment_DeviceDetails {
  id: string;
  profile: ?SiteAssessment_DeviceProfileDetails;
  bridge_statuses: Array<SiteAssessment_BridgeStatusDetails>;
  speed: number;
  uptime: number;

  constructor(props: $Shape<SiteAssessment_DeviceDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.profile = null;
    if (props.hasOwnProperty('profile')) {
      const v = props.profile;
      this.profile = v && new SiteAssessment_DeviceProfileDetails(v);
    }

    this.bridge_statuses = [];
    if (props.hasOwnProperty('bridge_statuses')) {
      const v = props.bridge_statuses;
      if (!Array.isArray(v)) {
        throw new Error('repeated field bridge_statuses should be array');
      }
      this.bridge_statuses = v.map(function(v) {
        return new SiteAssessment_BridgeStatusDetails(v);
      });
    }

    this.speed = 0;
    if (props.hasOwnProperty('speed')) {
      const v = props.speed;
      this.speed = v;
    }

    this.uptime = 0;
    if (props.hasOwnProperty('uptime')) {
      const v = props.uptime;
      this.uptime = v;
    }
  }

  getProfile(): SiteAssessment_DeviceProfileDetails {
    if (this.profile) {
      return this.profile;
    }
    return new SiteAssessment_DeviceProfileDetails();
  }
}
export {SiteAssessment_DeviceDetails};
class SiteAssessment_AntennaDetails {
  bw_azimuth: number;
  asset_model: cwn_asset.Model;
  gain: number;

  constructor(props: $Shape<SiteAssessment_AntennaDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.bw_azimuth = 0;
    if (props.hasOwnProperty('bw_azimuth')) {
      const v = props.bw_azimuth;
      this.bw_azimuth = v;
    }

    this.asset_model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('asset_model')) {
      const v = props.asset_model;
      this.asset_model = v;
    }

    this.gain = 0;
    if (props.hasOwnProperty('gain')) {
      const v = props.gain;
      this.gain = v;
    }
  }
}
export {SiteAssessment_AntennaDetails};
class SiteAssessment_SRLDetails {
  id: string;
  mac_address: string;
  site_id: string;
  mount_id: string;
  asset_model: cwn_asset.Model;
  heading: string;
  tx_power_dbm: number;
  mode: cwn_radios.Mode;
  antenna_details: ?SiteAssessment_AntennaDetails;

  constructor(props: $Shape<SiteAssessment_SRLDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.mount_id = '0';
    if (props.hasOwnProperty('mount_id')) {
      const v = props.mount_id;
      this.mount_id = v;
    }

    this.asset_model = cwn_asset.ModelValue(0);
    if (props.hasOwnProperty('asset_model')) {
      const v = props.asset_model;
      this.asset_model = v;
    }

    this.heading = '0';
    if (props.hasOwnProperty('heading')) {
      const v = props.heading;
      this.heading = v;
    }

    this.tx_power_dbm = 0;
    if (props.hasOwnProperty('tx_power_dbm')) {
      const v = props.tx_power_dbm;
      this.tx_power_dbm = v;
    }

    this.mode = cwn_radios.ModeValue(0);
    if (props.hasOwnProperty('mode')) {
      const v = props.mode;
      this.mode = v;
    }

    this.antenna_details = null;
    if (props.hasOwnProperty('antenna_details')) {
      const v = props.antenna_details;
      this.antenna_details = v && new SiteAssessment_AntennaDetails(v);
    }
  }

  getAntennaDetails(): SiteAssessment_AntennaDetails {
    if (this.antenna_details) {
      return this.antenna_details;
    }
    return new SiteAssessment_AntennaDetails();
  }
}
export {SiteAssessment_SRLDetails};
class SiteAssessment_RadioDetails {
  srl_id: string;
  target_srls: Array<string>;

  constructor(props: $Shape<SiteAssessment_RadioDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.srl_id = '0';
    if (props.hasOwnProperty('srl_id')) {
      const v = props.srl_id;
      this.srl_id = v;
    }

    this.target_srls = [];
    if (props.hasOwnProperty('target_srls')) {
      const v = props.target_srls;
      if (!Array.isArray(v)) {
        throw new Error('repeated field target_srls should be array');
      }
      this.target_srls = v.map(function(v) {
        return v;
      });
    }
  }
}
export {SiteAssessment_RadioDetails};
class SiteAssessment_MountDetails {
  id: string;
  site_id: string;
  lat: number;
  lng: number;
  height_in_meters: number;
  elevation_in_meters: number;
  radio_details: Array<SiteAssessment_RadioDetails>;

  constructor(props: $Shape<SiteAssessment_MountDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.lat = 0;
    if (props.hasOwnProperty('lat')) {
      const v = props.lat;
      this.lat = v;
    }

    this.lng = 0;
    if (props.hasOwnProperty('lng')) {
      const v = props.lng;
      this.lng = v;
    }

    this.height_in_meters = 0;
    if (props.hasOwnProperty('height_in_meters')) {
      const v = props.height_in_meters;
      this.height_in_meters = v;
    }

    this.elevation_in_meters = 0;
    if (props.hasOwnProperty('elevation_in_meters')) {
      const v = props.elevation_in_meters;
      this.elevation_in_meters = v;
    }

    this.radio_details = [];
    if (props.hasOwnProperty('radio_details')) {
      const v = props.radio_details;
      if (!Array.isArray(v)) {
        throw new Error('repeated field radio_details should be array');
      }
      this.radio_details = v.map(function(v) {
        return new SiteAssessment_RadioDetails(v);
      });
    }
  }
}
export {SiteAssessment_MountDetails};
class SiteAssessment_ShotOverrideDetails {
  has_line_of_sight: boolean;

  constructor(props: $Shape<SiteAssessment_ShotOverrideDetails> = {}): void {
    if (!props) {
      props = {};
    }

    this.has_line_of_sight = false;
    if (props.hasOwnProperty('has_line_of_sight')) {
      const v = props.has_line_of_sight;
      this.has_line_of_sight = !!v;
    }
  }
}
export {SiteAssessment_ShotOverrideDetails};
class SiteAssessment {
  id: string;
  created: ?Date;
  updated: ?Date;
  started: ?Date;
  finished: ?Date;
  site_details: ?SiteAssessment_SiteDetails;
  device_details: ?SiteAssessment_DeviceDetails;
  mount_assessments: Array<SiteAssessment_MountAssessment>;
  nearby_mounts: {[key: string]: ?SiteAssessment_MountDetails};
  nearby_sites: {[key: string]: ?SiteAssessment_SiteDetails};
  nearby_devices: {[key: string]: ?SiteAssessment_DeviceDetails};
  nearby_srls: {[key: string]: ?SiteAssessment_SRLDetails};
  vars: {[key: string]: ?cwn_vars.Value};

  constructor(props: $Shape<SiteAssessment> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.started = null;
    if (props.hasOwnProperty('started')) {
      const v = props.started;
      this.started = v && new Date(v);
    }

    this.finished = null;
    if (props.hasOwnProperty('finished')) {
      const v = props.finished;
      this.finished = v && new Date(v);
    }

    this.site_details = null;
    if (props.hasOwnProperty('site_details')) {
      const v = props.site_details;
      this.site_details = v && new SiteAssessment_SiteDetails(v);
    }

    this.device_details = null;
    if (props.hasOwnProperty('device_details')) {
      const v = props.device_details;
      this.device_details = v && new SiteAssessment_DeviceDetails(v);
    }

    this.mount_assessments = [];
    if (props.hasOwnProperty('mount_assessments')) {
      const v = props.mount_assessments;
      if (!Array.isArray(v)) {
        throw new Error('repeated field mount_assessments should be array');
      }
      this.mount_assessments = v.map(function(v) {
        return new SiteAssessment_MountAssessment(v);
      });
    }

    this.nearby_mounts = {};
    if (props.hasOwnProperty('nearby_mounts')) {
      const v = props.nearby_mounts;
      for (const key: any of Object.keys(v)) {
        this.nearby_mounts[key] = new SiteAssessment_MountDetails(
          (v[key]: any),
        );
      }
    }

    this.nearby_sites = {};
    if (props.hasOwnProperty('nearby_sites')) {
      const v = props.nearby_sites;
      for (const key: any of Object.keys(v)) {
        this.nearby_sites[key] = new SiteAssessment_SiteDetails((v[key]: any));
      }
    }

    this.nearby_devices = {};
    if (props.hasOwnProperty('nearby_devices')) {
      const v = props.nearby_devices;
      for (const key: any of Object.keys(v)) {
        this.nearby_devices[key] = new SiteAssessment_DeviceDetails(
          (v[key]: any),
        );
      }
    }

    this.nearby_srls = {};
    if (props.hasOwnProperty('nearby_srls')) {
      const v = props.nearby_srls;
      for (const key: any of Object.keys(v)) {
        this.nearby_srls[key] = new SiteAssessment_SRLDetails((v[key]: any));
      }
    }

    this.vars = {};
    if (props.hasOwnProperty('vars')) {
      const v = props.vars;
      for (const key: any of Object.keys(v)) {
        this.vars[key] = new cwn_vars.Value((v[key]: any));
      }
    }
  }

  getSiteDetails(): SiteAssessment_SiteDetails {
    if (this.site_details) {
      return this.site_details;
    }
    return new SiteAssessment_SiteDetails();
  }

  getDeviceDetails(): SiteAssessment_DeviceDetails {
    if (this.device_details) {
      return this.device_details;
    }
    return new SiteAssessment_DeviceDetails();
  }
}
export {SiteAssessment};

class PossibleBuild {
  build: ?cwn_site_build.Build;
  predicted_service_level: ?cwn_customers.ServiceLevel;

  constructor(props: $Shape<PossibleBuild> = {}): void {
    if (!props) {
      props = {};
    }

    this.build = null;
    if (props.hasOwnProperty('build')) {
      const v = props.build;
      this.build = v && new cwn_site_build.Build(v);
    }

    this.predicted_service_level = null;
    if (props.hasOwnProperty('predicted_service_level')) {
      const v = props.predicted_service_level;
      this.predicted_service_level = v && new cwn_customers.ServiceLevel(v);
    }
  }

  getBuild(): cwn_site_build.Build {
    if (this.build) {
      return this.build;
    }
    return new cwn_site_build.Build();
  }

  getPredictedServiceLevel(): cwn_customers.ServiceLevel {
    if (this.predicted_service_level) {
      return this.predicted_service_level;
    }
    return new cwn_customers.ServiceLevel();
  }
}
export {PossibleBuild};

// END cwn/site/assessment/models.proto
