// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

// START cwn/program/models.proto

export const ProgramValues = {
  INVALID: 0,
  TIER_ONE: 1,
  TIER_ONE_BETA: 3,
  TIER_TWO: 2,
};
type Program = $Keys<typeof ProgramValues>;
export type {Program};

export function ProgramValue(n: number): Program {
  switch (n) {
    case 0:
      return 'INVALID';

    case 1:
      return 'TIER_ONE';

    case 3:
      return 'TIER_ONE_BETA';

    case 2:
      return 'TIER_TWO';

    default:
      return 'INVALID';
  }
}

class Geo {
  geofence_id: string;
  program: Program;
  inactive: boolean;

  constructor(props: $Shape<Geo> = {}): void {
    if (!props) {
      props = {};
    }

    this.geofence_id = '0';
    if (props.hasOwnProperty('geofence_id')) {
      const v = props.geofence_id;
      this.geofence_id = v;
    }

    this.program = ProgramValue(0);
    if (props.hasOwnProperty('program')) {
      const v = props.program;
      this.program = v;
    }

    this.inactive = false;
    if (props.hasOwnProperty('inactive')) {
      const v = props.inactive;
      this.inactive = !!v;
    }
  }
}
export {Geo};

// END cwn/program/models.proto
