// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_geocoder from '../../cwn/geocoder';
import * as cwn_customers from '../../cwn/customers';

// START cwn/partner/models.proto

export const PartnerLead_StatusValues = {
  UNKNOWN_STATUS: 0,
  UNSUBMITTED: 1,
  SUBMITTED: 2,
  ACCEPTED: 3,
  REJECTED: 4,
};
type PartnerLead_Status = $Keys<typeof PartnerLead_StatusValues>;
export type {PartnerLead_Status};

export function PartnerLead_StatusValue(n: number): PartnerLead_Status {
  switch (n) {
    case 0:
      return 'UNKNOWN_STATUS';

    case 1:
      return 'UNSUBMITTED';

    case 2:
      return 'SUBMITTED';

    case 3:
      return 'ACCEPTED';

    case 4:
      return 'REJECTED';

    default:
      return 'UNKNOWN_STATUS';
  }
}

export const PartnerLead_NumberOfUnitsValues = {
  UNKNOWN_NUMBER_OF_UNITS: 0,
  ONE: 1,
  TWO_TO_FOUR: 2,
  FIVE_TO_ELEVEN: 3,
  TWELVE_TO_TWENTY: 8,
  TWENTY_ONE_TO_FIFTY: 5,
  FIFTY_ONE_PLUS: 6,
};
type PartnerLead_NumberOfUnits = $Keys<typeof PartnerLead_NumberOfUnitsValues>;
export type {PartnerLead_NumberOfUnits};

export function PartnerLead_NumberOfUnitsValue(
  n: number,
): PartnerLead_NumberOfUnits {
  switch (n) {
    case 0:
      return 'UNKNOWN_NUMBER_OF_UNITS';

    case 1:
      return 'ONE';

    case 2:
      return 'TWO_TO_FOUR';

    case 3:
      return 'FIVE_TO_ELEVEN';

    case 8:
      return 'TWELVE_TO_TWENTY';

    case 5:
      return 'TWENTY_ONE_TO_FIFTY';

    case 6:
      return 'FIFTY_ONE_PLUS';

    default:
      return 'UNKNOWN_NUMBER_OF_UNITS';
  }
}

export const PartnerLead_BuildingTypeValues = {
  UNKNOWN_BUILDING_TYPE: 0,
  COMMERCIAL: 1,
  RESIDENTIAL: 2,
  MIXED_USE: 3,
};
type PartnerLead_BuildingType = $Keys<typeof PartnerLead_BuildingTypeValues>;
export type {PartnerLead_BuildingType};

export function PartnerLead_BuildingTypeValue(
  n: number,
): PartnerLead_BuildingType {
  switch (n) {
    case 0:
      return 'UNKNOWN_BUILDING_TYPE';

    case 1:
      return 'COMMERCIAL';

    case 2:
      return 'RESIDENTIAL';

    case 3:
      return 'MIXED_USE';

    default:
      return 'UNKNOWN_BUILDING_TYPE';
  }
}

export const PartnerLead_ManagementTypeValues = {
  UNKNOWN_MANAGEMENT_TYPE: 0,
  PROPERTY_MANAGEMENT_COMPANY: 1,
  INDIVIDUAL_MANAGER: 2,
  BUILDING_OWNER_DIRECTLY: 3,
  OTHER_MANAGEMENT_TYPE: 4,
};
type PartnerLead_ManagementType = $Keys<
  typeof PartnerLead_ManagementTypeValues,
>;
export type {PartnerLead_ManagementType};

export function PartnerLead_ManagementTypeValue(
  n: number,
): PartnerLead_ManagementType {
  switch (n) {
    case 0:
      return 'UNKNOWN_MANAGEMENT_TYPE';

    case 1:
      return 'PROPERTY_MANAGEMENT_COMPANY';

    case 2:
      return 'INDIVIDUAL_MANAGER';

    case 3:
      return 'BUILDING_OWNER_DIRECTLY';

    case 4:
      return 'OTHER_MANAGEMENT_TYPE';

    default:
      return 'UNKNOWN_MANAGEMENT_TYPE';
  }
}

export const PartnerLead_RelationshipTypeValues = {
  UNKNOWN_RELATIONSHIP_TYPE: 0,
  BUILDING_OWNER: 1,
  EMPLOYEE_OF_PROPERTY_MANAGEMENT_COMPANY: 2,
  INDIVIDUAL_BUILDING_MANAGER: 3,
  OTHER_RELATIONSHIP_TYPE: 4,
};
type PartnerLead_RelationshipType = $Keys<
  typeof PartnerLead_RelationshipTypeValues,
>;
export type {PartnerLead_RelationshipType};

export function PartnerLead_RelationshipTypeValue(
  n: number,
): PartnerLead_RelationshipType {
  switch (n) {
    case 0:
      return 'UNKNOWN_RELATIONSHIP_TYPE';

    case 1:
      return 'BUILDING_OWNER';

    case 2:
      return 'EMPLOYEE_OF_PROPERTY_MANAGEMENT_COMPANY';

    case 3:
      return 'INDIVIDUAL_BUILDING_MANAGER';

    case 4:
      return 'OTHER_RELATIONSHIP_TYPE';

    default:
      return 'UNKNOWN_RELATIONSHIP_TYPE';
  }
}

class PartnerLead_RawAddressFields {
  address_line_1: string;
  address_line_2: string;
  zip: string;
  city: string;
  state: string;

  constructor(props: $Shape<PartnerLead_RawAddressFields> = {}): void {
    if (!props) {
      props = {};
    }

    this.address_line_1 = '';
    if (props.hasOwnProperty('address_line_1')) {
      const v = props.address_line_1;
      this.address_line_1 = v;
    }

    this.address_line_2 = '';
    if (props.hasOwnProperty('address_line_2')) {
      const v = props.address_line_2;
      this.address_line_2 = v;
    }

    this.zip = '';
    if (props.hasOwnProperty('zip')) {
      const v = props.zip;
      this.zip = v;
    }

    this.city = '';
    if (props.hasOwnProperty('city')) {
      const v = props.city;
      this.city = v;
    }

    this.state = '';
    if (props.hasOwnProperty('state')) {
      const v = props.state;
      this.state = v;
    }
  }
}
export {PartnerLead_RawAddressFields};
class PartnerLead {
  id: string;
  site_id: string;
  status: PartnerLead_Status;
  number_of_units: PartnerLead_NumberOfUnits;
  building_type: PartnerLead_BuildingType;
  management_type: PartnerLead_ManagementType;
  other_management_type: string;
  address: ?cwn_customers.Address;
  geocoded_address_location: ?cwn_geocoder.GeoPoint;
  raw_address_fields: ?PartnerLead_RawAddressFields;
  management_name: string;
  management_phone_number: string;
  management_email: string;
  management_address: ?PartnerLead_RawAddressFields;
  applicant_name: string;
  applicant_title: string;
  applicant_email: string;
  applicant_phone_number: string;
  applicant_relationship_type: PartnerLead_RelationshipType;
  other_application_relationship_type: string;
  terms_of_service: string;
  terms_of_service_agreed: boolean;
  created: ?Date;
  updated: ?Date;

  constructor(props: $Shape<PartnerLead> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.site_id = '';
    if (props.hasOwnProperty('site_id')) {
      const v = props.site_id;
      this.site_id = v;
    }

    this.status = PartnerLead_StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.number_of_units = PartnerLead_NumberOfUnitsValue(0);
    if (props.hasOwnProperty('number_of_units')) {
      const v = props.number_of_units;
      this.number_of_units = v;
    }

    this.building_type = PartnerLead_BuildingTypeValue(0);
    if (props.hasOwnProperty('building_type')) {
      const v = props.building_type;
      this.building_type = v;
    }

    this.management_type = PartnerLead_ManagementTypeValue(0);
    if (props.hasOwnProperty('management_type')) {
      const v = props.management_type;
      this.management_type = v;
    }

    this.other_management_type = '';
    if (props.hasOwnProperty('other_management_type')) {
      const v = props.other_management_type;
      this.other_management_type = v;
    }

    this.address = null;
    if (props.hasOwnProperty('address')) {
      const v = props.address;
      this.address = v && new cwn_customers.Address(v);
    }

    this.geocoded_address_location = null;
    if (props.hasOwnProperty('geocoded_address_location')) {
      const v = props.geocoded_address_location;
      this.geocoded_address_location = v && new cwn_geocoder.GeoPoint(v);
    }

    this.raw_address_fields = null;
    if (props.hasOwnProperty('raw_address_fields')) {
      const v = props.raw_address_fields;
      this.raw_address_fields = v && new PartnerLead_RawAddressFields(v);
    }

    this.management_name = '';
    if (props.hasOwnProperty('management_name')) {
      const v = props.management_name;
      this.management_name = v;
    }

    this.management_phone_number = '';
    if (props.hasOwnProperty('management_phone_number')) {
      const v = props.management_phone_number;
      this.management_phone_number = v;
    }

    this.management_email = '';
    if (props.hasOwnProperty('management_email')) {
      const v = props.management_email;
      this.management_email = v;
    }

    this.management_address = null;
    if (props.hasOwnProperty('management_address')) {
      const v = props.management_address;
      this.management_address = v && new PartnerLead_RawAddressFields(v);
    }

    this.applicant_name = '';
    if (props.hasOwnProperty('applicant_name')) {
      const v = props.applicant_name;
      this.applicant_name = v;
    }

    this.applicant_title = '';
    if (props.hasOwnProperty('applicant_title')) {
      const v = props.applicant_title;
      this.applicant_title = v;
    }

    this.applicant_email = '';
    if (props.hasOwnProperty('applicant_email')) {
      const v = props.applicant_email;
      this.applicant_email = v;
    }

    this.applicant_phone_number = '';
    if (props.hasOwnProperty('applicant_phone_number')) {
      const v = props.applicant_phone_number;
      this.applicant_phone_number = v;
    }

    this.applicant_relationship_type = PartnerLead_RelationshipTypeValue(0);
    if (props.hasOwnProperty('applicant_relationship_type')) {
      const v = props.applicant_relationship_type;
      this.applicant_relationship_type = v;
    }

    this.other_application_relationship_type = '';
    if (props.hasOwnProperty('other_application_relationship_type')) {
      const v = props.other_application_relationship_type;
      this.other_application_relationship_type = v;
    }

    this.terms_of_service = '';
    if (props.hasOwnProperty('terms_of_service')) {
      const v = props.terms_of_service;
      this.terms_of_service = v;
    }

    this.terms_of_service_agreed = false;
    if (props.hasOwnProperty('terms_of_service_agreed')) {
      const v = props.terms_of_service_agreed;
      this.terms_of_service_agreed = !!v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }
  }

  getAddress(): cwn_customers.Address {
    if (this.address) {
      return this.address;
    }
    return new cwn_customers.Address();
  }

  getGeocodedAddressLocation(): cwn_geocoder.GeoPoint {
    if (this.geocoded_address_location) {
      return this.geocoded_address_location;
    }
    return new cwn_geocoder.GeoPoint();
  }

  getRawAddressFields(): PartnerLead_RawAddressFields {
    if (this.raw_address_fields) {
      return this.raw_address_fields;
    }
    return new PartnerLead_RawAddressFields();
  }

  getManagementAddress(): PartnerLead_RawAddressFields {
    if (this.management_address) {
      return this.management_address;
    }
    return new PartnerLead_RawAddressFields();
  }
}
export {PartnerLead};

// END cwn/partner/models.proto
