// @flow
import React, {PureComponent, useContext, type Node} from 'react';

import ErrorBoundary from 'errors/ErrorBoundary';
import {
  type OnError,
  type RenderErrorDialog,
  type ErrorDetails,
  type ErrorInfo,
} from 'errors/types';

// default context value
function defaultOnError({error}: ErrorDetails) {
  console.error(error);
}

export const ErrorsContext = React.createContext<OnError>(defaultOnError);

type Props = {|
  onError: OnError,
  renderErrorDialog: RenderErrorDialog,
  children: Node,
|};

type State = {|
  errorDialogOpen: boolean,
  errorDetails: ?ErrorDetails,
|};

export default class ErrorsProvider extends PureComponent<Props, State> {
  state = {
    errorDialogOpen: false,
    errorDetails: null,
  };

  originalWindowOnError = null;

  componentDidMount() {
    this.originalWindowOnError = window.onerror;

    window.onerror = (
      event: Event | string,
      source?: string,
      line?: number,
      column?: number,
      error?: Error,
    ) => {
      this.handleUncaughtError(
        typeof error !== 'undefined'
          ? error
          : new Error(
              `${(event: any)} (<${(source: any)}>:${(line: any)}:${(column: any)})`,
            ),
      );

      if (typeof this.originalWindowOnError === 'function') {
        this.originalWindowOnError.call(
          window,
          event,
          source,
          line,
          column,
          error,
        );
      }
    };
  }

  componentWillUnmount() {
    window.onerror = this.originalWindowOnError;
  }

  handleError = (details: ErrorDetails) => {
    const {onError} = this.props;

    this.setState({errorDialogOpen: true, errorDetails: details}, () => {
      onError(details);
    });
  };

  handleUncaughtError = (error: Error) => {
    // *not* showing an error dialog on uncaught errors b/c this can happen in
    // benign scenarios, such as when an ad blocker extension stops google
    // analytics from doing something (which will throw an uncaught JS error)
    //
    // moral of the story: if you want the user to know there was an error,
    // wrap your async things in a try/catch, then call `onError` explicitly

    this.props.onError({error, description: 'UncaughtError'});
  };

  handleComponentDidCatch = (error: Error, info: ErrorInfo) => {
    this.handleError({error, description: info.componentStack});
  };

  handleCloseErrorDialog = () => {
    this.setState({errorDialogOpen: false});
  };

  render() {
    const {children, renderErrorDialog} = this.props;
    const {errorDialogOpen, errorDetails} = this.state;

    return (
      <ErrorBoundary componentDidCatch={this.handleComponentDidCatch}>
        <ErrorsContext.Provider value={this.handleError}>
          {children}
          {renderErrorDialog &&
            renderErrorDialog(
              errorDialogOpen,
              this.handleCloseErrorDialog,
              errorDetails,
            )}
        </ErrorsContext.Provider>
      </ErrorBoundary>
    );
  }
}

export function useErrors(): OnError {
  return useContext(ErrorsContext);
}
