// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_bridges from '../../cwn/bridges';

// START cwn/actions/models.proto

export const ServiceValues = {
  SERVICE_UNKNOWN: 0,
  SERVICE_DRIVER_IF_DAEMON: 1,
};
type Service = $Keys<typeof ServiceValues>;
export type {Service};

export function ServiceValue(n: number): Service {
  switch (n) {
    case 0:
      return 'SERVICE_UNKNOWN';

    case 1:
      return 'SERVICE_DRIVER_IF_DAEMON';

    default:
      return 'SERVICE_UNKNOWN';
  }
}

export const Action_StatusValues = {
  STATUS_UNKNOWN: 0,
  STATUS_PENDING: 1,
  STATUS_DONE: 2,
  STATUS_NOT_FOUND: 3,
  STATUS_ERROR: 4,
  STATUS_RETRIEVED: 5,
  STATUS_EXPIRED: 6,
};
type Action_Status = $Keys<typeof Action_StatusValues>;
export type {Action_Status};

export function Action_StatusValue(n: number): Action_Status {
  switch (n) {
    case 0:
      return 'STATUS_UNKNOWN';

    case 1:
      return 'STATUS_PENDING';

    case 2:
      return 'STATUS_DONE';

    case 3:
      return 'STATUS_NOT_FOUND';

    case 4:
      return 'STATUS_ERROR';

    case 5:
      return 'STATUS_RETRIEVED';

    case 6:
      return 'STATUS_EXPIRED';

    default:
      return 'STATUS_UNKNOWN';
  }
}

export const Action_TypeValues = {
  TYPE_UNKNOWN: 0,
  TYPE_RADIO_REBOOT: 1,
  TYPE_RADIO_FACTORY_RESET: 2,
  TYPE_BRIDGE_REBOOT: 3,
  TYPE_BRIDGE_SET_POE: 4,
  TYPE_BRIDGE_CYCLE_POE: 5,
  TYPE_DEVICE_REBOOT: 6,
  TYPE_RADIO_KICK_ETH0: 7,
  TYPE_RADIO_CYCLE_POE: 8,
  TYPE_DEVICE_RESTART_SERVICE: 9,
};
type Action_Type = $Keys<typeof Action_TypeValues>;
export type {Action_Type};

export function Action_TypeValue(n: number): Action_Type {
  switch (n) {
    case 0:
      return 'TYPE_UNKNOWN';

    case 1:
      return 'TYPE_RADIO_REBOOT';

    case 2:
      return 'TYPE_RADIO_FACTORY_RESET';

    case 3:
      return 'TYPE_BRIDGE_REBOOT';

    case 4:
      return 'TYPE_BRIDGE_SET_POE';

    case 5:
      return 'TYPE_BRIDGE_CYCLE_POE';

    case 6:
      return 'TYPE_DEVICE_REBOOT';

    case 7:
      return 'TYPE_RADIO_KICK_ETH0';

    case 8:
      return 'TYPE_RADIO_CYCLE_POE';

    case 9:
      return 'TYPE_DEVICE_RESTART_SERVICE';

    default:
      return 'TYPE_UNKNOWN';
  }
}

class Action_Bridge {
  target_interface: string;
  target_poe: cwn_bridges.PortStatus_PoeOpMode;

  constructor(props: $Shape<Action_Bridge> = {}): void {
    if (!props) {
      props = {};
    }

    this.target_interface = '';
    if (props.hasOwnProperty('target_interface')) {
      const v = props.target_interface;
      this.target_interface = v;
    }

    this.target_poe = cwn_bridges.PortStatus_PoeOpModeValue(0);
    if (props.hasOwnProperty('target_poe')) {
      const v = props.target_poe;
      this.target_poe = v;
    }
  }
}
export {Action_Bridge};
class Action {
  id: string;
  device_id: string;
  mac_address: string;
  created: ?Date;
  updated: ?Date;
  status: Action_Status;
  type: Action_Type;
  bridge: ?Action_Bridge;
  ttl_seconds: string;
  initiator: string;
  service: Service;

  constructor(props: $Shape<Action> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.device_id = '';
    if (props.hasOwnProperty('device_id')) {
      const v = props.device_id;
      this.device_id = v;
    }

    this.mac_address = '';
    if (props.hasOwnProperty('mac_address')) {
      const v = props.mac_address;
      this.mac_address = v;
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }

    this.updated = null;
    if (props.hasOwnProperty('updated')) {
      const v = props.updated;
      this.updated = v && new Date(v);
    }

    this.status = Action_StatusValue(0);
    if (props.hasOwnProperty('status')) {
      const v = props.status;
      this.status = v;
    }

    this.type = Action_TypeValue(0);
    if (props.hasOwnProperty('type')) {
      const v = props.type;
      this.type = v;
    }

    this.bridge = null;
    if (props.hasOwnProperty('bridge')) {
      const v = props.bridge;
      this.bridge = v && new Action_Bridge(v);
    }

    this.ttl_seconds = '0';
    if (props.hasOwnProperty('ttl_seconds')) {
      const v = props.ttl_seconds;
      this.ttl_seconds = v;
    }

    this.initiator = '';
    if (props.hasOwnProperty('initiator')) {
      const v = props.initiator;
      this.initiator = v;
    }

    this.service = ServiceValue(0);
    if (props.hasOwnProperty('service')) {
      const v = props.service;
      this.service = v;
    }
  }

  getBridge(): Action_Bridge {
    if (this.bridge) {
      return this.bridge;
    }
    return new Action_Bridge();
  }
}
export {Action};

class AuditAdminPutAction {
  action: ?Action;

  constructor(props: $Shape<AuditAdminPutAction> = {}): void {
    if (!props) {
      props = {};
    }

    this.action = null;
    if (props.hasOwnProperty('action')) {
      const v = props.action;
      this.action = v && new Action(v);
    }
  }

  getAction(): Action {
    if (this.action) {
      return this.action;
    }
    return new Action();
  }
}
export {AuditAdminPutAction};

class AuditAgentPerformAction {
  action: ?Action;

  constructor(props: $Shape<AuditAgentPerformAction> = {}): void {
    if (!props) {
      props = {};
    }

    this.action = null;
    if (props.hasOwnProperty('action')) {
      const v = props.action;
      this.action = v && new Action(v);
    }
  }

  getAction(): Action {
    if (this.action) {
      return this.action;
    }
    return new Action();
  }
}
export {AuditAgentPerformAction};

// END cwn/actions/models.proto
