// @flow

/* eslint no-use-before-define: 0, no-useless-constructor: 0 */

/* eslint-disable */
import * as google_protobuf from '../../google/protobuf';
/* eslint-enable */
import * as cwn_kml from '../../cwn/kml';

// START cwn/geofence/models.proto

class Geofence {
  id: string;
  name: string;
  description: string;
  polygons: Array<cwn_kml.Polygon>;
  created: ?Date;

  constructor(props: $Shape<Geofence> = {}): void {
    if (!props) {
      props = {};
    }

    this.id = '0';
    if (props.hasOwnProperty('id')) {
      const v = props.id;
      this.id = v;
    }

    this.name = '';
    if (props.hasOwnProperty('name')) {
      const v = props.name;
      this.name = v;
    }

    this.description = '';
    if (props.hasOwnProperty('description')) {
      const v = props.description;
      this.description = v;
    }

    this.polygons = [];
    if (props.hasOwnProperty('polygons')) {
      const v = props.polygons;
      if (!Array.isArray(v)) {
        throw new Error('repeated field polygons should be array');
      }
      this.polygons = v.map(function(v) {
        return new cwn_kml.Polygon(v);
      });
    }

    this.created = null;
    if (props.hasOwnProperty('created')) {
      const v = props.created;
      this.created = v && new Date(v);
    }
  }
}
export {Geofence};

// END cwn/geofence/models.proto
